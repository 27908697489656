import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

import { StringUtils } from '@assertiva/assertiva-ui';

const Range = ({
  id,
  categoryId,
  name,
  fieldId,
  min,
  max,
  isAge,
  isCurrency,
  value,
  handleChange,
  addFilter,
}) => {
  const [_value, setValue] = useState(value || [min, max]);
  const selectedFilters = useSelector(
    (state) => state.dashboard.selectedFilters
  );

  useEffect(() => {
    setValue([min, max]);
  }, [name]);

  const onSave = () => {
    handleChange({
      target: {
        type: 'text',
        name: name,
        value: _value,
      },
    });

    if (
      selectedFilters.some(
        (selectedFilter) =>
          selectedFilter.filterId === id &&
          selectedFilter.categoryId === categoryId
      )
    ) {
      return 0;
    }

    addFilter({ filterId: id, categoryId });
  };

  return (
    <Grid container spacing={1} alignItems='center'>
      <Grid item>
        <TextField
          label='De'
          type='text'
          name={name}
          id={`${fieldId}-de-input`}
          value={
            isCurrency
              ? StringUtils.formatMoney(_value[0]).convertFloatToMoney
              : _value[0]
          }
          onChange={(evt) =>
            setValue([
              isCurrency
                ? StringUtils.formatMoney(evt.target.value).convertMoneyToFloat
                : evt.target.value.replace(/[^0-9]*/g, ''),
              _value[1],
            ])
          }
          InputProps={{
            startAdornment: isCurrency && (
              <InputAdornment position='start'>R$</InputAdornment>
            ),
            endAdornment: isAge && (
              <InputAdornment position='end'>ano(s)</InputAdornment>
            ),
            inputProps: { min, max },
            fullWidth: true,
          }}
          error={parseFloat(_value[0]) > parseFloat(_value[1])}
          helperText={
            parseFloat(_value[0]) > parseFloat(_value[1]) &&
            'O valor inicial deve ser menor que o final.'
          }
          fullWidth
        />
      </Grid>

      <Grid item>
        <TextField
          label='Até'
          type='text'
          name={name}
          id={`${fieldId}-ate-input`}
          value={
            isCurrency
              ? StringUtils.formatMoney(_value[1]).convertFloatToMoney
              : _value[1]
          }
          onChange={(evt) =>
            setValue([
              _value[0],
              isCurrency
                ? StringUtils.formatMoney(evt.target.value).convertMoneyToFloat
                : evt.target.value.replace(/[^0-9]*/g, ''),
            ])
          }
          InputProps={{
            startAdornment: isCurrency && (
              <InputAdornment position='start'>R$</InputAdornment>
            ),
            endAdornment: isAge && (
              <InputAdornment position='end'>ano(s)</InputAdornment>
            ),
            inputProps: { min, max },
            fullWidth: true,
          }}
          error={parseFloat(_value[1]) < parseFloat(_value[0])}
          helperText={
            parseFloat(_value[1]) < parseFloat(_value[0]) &&
            'O valor final deve ser maior que o inicial.'
          }
          fullWidth
        />
      </Grid>

      <Grid item>
        <IconButton size='small' onClick={onSave}>
          <AddIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Range;
