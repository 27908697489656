export default [
	{ value: '0111301', label: '0111-3/01 - Cultivo de arroz' },
	{ value: '0111302', label: '0111-3/02 - Cultivo de milho' },
	{ value: '0111303', label: '0111-3/03 - Cultivo de trigo' },
	{
		value: '0111399',
		label:
			'0111-3/99 - Cultivo de outros cereais não especificados anteriormente',
	},
	{ value: '0112101', label: '0112-1/01 - Cultivo de algodão herbáceo' },
	{ value: '0112102', label: '0112-1/02 - Cultivo de juta' },
	{
		value: '0112199',
		label:
			'0112-1/99 - Cultivo de outras fibras de lavoura temporária não especificadas anteriormente',
	},
	{ value: '0113000', label: '0113-0/00 - Cultivo de cana-de-açúcar' },
	{ value: '0114800', label: '0114-8/00 - Cultivo de fumo' },
	{ value: '0115600', label: '0115-6/00 - Cultivo de soja' },
	{ value: '0116401', label: '0116-4/01 - Cultivo de amendoim' },
	{ value: '0116402', label: '0116-4/02 - Cultivo de girassol' },
	{ value: '0116403', label: '0116-4/03 - Cultivo de mamona' },
	{
		value: '0116499',
		label:
			'0116-4/99 - Cultivo de outras oleaginosas de lavoura temporária não especificadas anteriormente',
	},
	{ value: '0119901', label: '0119-9/01 - Cultivo de abacaxi' },
	{ value: '0119902', label: '0119-9/02 - Cultivo de alho' },
	{ value: '0119903', label: '0119-9/03 - Cultivo de batata-inglesa' },
	{ value: '0119904', label: '0119-9/04 - Cultivo de cebola' },
	{ value: '0119905', label: '0119-9/05 - Cultivo de feijão' },
	{ value: '0119906', label: '0119-9/06 - Cultivo de mandioca' },
	{ value: '0119907', label: '0119-9/07 - Cultivo de melão' },
	{ value: '0119908', label: '0119-9/08 - Cultivo de melancia' },
	{ value: '0119909', label: '0119-9/09 - Cultivo de tomate rasteiro' },
	{
		value: '0119999',
		label:
			'0119-9/99 - Cultivo de outras plantas de lavoura temporária não especificadas anteriormente',
	},
	{ value: '0121101', label: '0121-1/01 - Horticultura, exceto morango' },
	{ value: '0121102', label: '0121-1/02 - Cultivo de morango' },
	{
		value: '0122900',
		label: '0122-9/00 - Cultivo de flores e plantas ornamentais',
	},
	{ value: '0131800', label: '0131-8/00 - Cultivo de laranja' },
	{ value: '0132600', label: '0132-6/00 - Cultivo de uva' },
	{ value: '0133401', label: '0133-4/01 - Cultivo de açaí' },
	{ value: '0133402', label: '0133-4/02 - Cultivo de banana' },
	{ value: '0133403', label: '0133-4/03 - Cultivo de caju' },
	{
		value: '0133404',
		label: '0133-4/04 - Cultivo de cítricos, exceto laranja',
	},
	{ value: '0133405', label: '0133-4/05 - Cultivo de coco-da-baía' },
	{ value: '0133406', label: '0133-4/06 - Cultivo de guaraná' },
	{ value: '0133407', label: '0133-4/07 - Cultivo de maçã' },
	{ value: '0133408', label: '0133-4/08 - Cultivo de mamão' },
	{ value: '0133409', label: '0133-4/09 - Cultivo de maracujá' },
	{ value: '0133410', label: '0133-4/10 - Cultivo de manga' },
	{ value: '0133411', label: '0133-4/11 - Cultivo de pêssego' },
	{
		value: '0133499',
		label:
			'0133-4/99 - Cultivo de frutas de lavoura permanente não especificadas anteriormente',
	},
	{ value: '0134200', label: '0134-2/00 - Cultivo de café' },
	{ value: '0135100', label: '0135-1/00 - Cultivo de cacau' },
	{ value: '0139301', label: '0139-3/01 - Cultivo de chá-da-índia' },
	{ value: '0139302', label: '0139-3/02 - Cultivo de erva-mate' },
	{ value: '0139303', label: '0139-3/03 - Cultivo de pimenta-do-reino' },
	{
		value: '0139304',
		label:
			'0139-3/04 - Cultivo de plantas para condimento, exceto pimenta-do-reino',
	},
	{ value: '0139305', label: '0139-3/05 - Cultivo de dendê' },
	{ value: '0139306', label: '0139-3/06 - Cultivo de seringueira' },
	{
		value: '0139399',
		label:
			'0139-3/99 - Cultivo de outras plantas de lavoura permanente não especificadas anteriormente',
	},
	{
		value: '0141501',
		label:
			'0141-5/01 - Produção de sementes certificadas, exceto de forrageiras para pasto',
	},
	{
		value: '0141502',
		label:
			'0141-5/02 - Produção de sementes certificadas de forrageiras para formação de pasto',
	},
	{
		value: '0142300',
		label:
			'0142-3/00 - Produção de mudas e outras formas de propagação vegetal, certificadas',
	},
	{ value: '0151201', label: '0151-2/01 - Criação de bovinos para corte' },
	{ value: '0151202', label: '0151-2/02 - Criação de bovinos para leite' },
	{
		value: '0151203',
		label: '0151-2/03 - Criação de bovinos, exceto para corte e leite',
	},
	{ value: '0152101', label: '0152-1/01 - Criação de bufalinos' },
	{ value: '0152102', label: '0152-1/02 - Criação de equinos' },
	{ value: '0152103', label: '0152-1/03 - Criação de asininos e muares' },
	{ value: '0153901', label: '0153-9/01 - Criação de caprinos' },
	{
		value: '0153902',
		label: '0153-9/02 - Criação de ovinos, inclusive para produção de lã',
	},
	{ value: '0154700', label: '0154-7/00 - Criação de suínos' },
	{ value: '0155501', label: '0155-5/01 - Criação de frangos para corte' },
	{ value: '0155502', label: '0155-5/02 - Produção de pintos de um dia' },
	{
		value: '0155503',
		label: '0155-5/03 - Criação de outros galináceos, exceto para corte',
	},
	{
		value: '0155504',
		label: '0155-5/04 - Criação de aves, exceto galináceos',
	},
	{ value: '0155505', label: '0155-5/05 - Produção de ovos' },
	{ value: '0159801', label: '0159-8/01 - Apicultura' },
	{
		value: '0159802',
		label: '0159-8/02 - Criação de animais de estimação',
	},
	{ value: '0159803', label: '0159-8/03 - Criação de escargô' },
	{ value: '0159804', label: '0159-8/04 - Criação de bicho-da-seda' },
	{
		value: '0159899',
		label:
			'0159-8/99 - Criação de outros animais não especificados anteriormente',
	},
	{
		value: '0161001',
		label: '0161-0/01 - Serviço de pulverização e controle de pragas agrícolas',
	},
	{
		value: '0161002',
		label: '0161-0/02 - Serviço de poda de árvores para lavouras',
	},
	{
		value: '0161003',
		label: '0161-0/03 - Serviço de preparação de terreno, cultivo e colheita',
	},
	{
		value: '0161099',
		label:
			'0161-0/99 - Atividades de apoio à agricultura não especificadas anteriormente',
	},
	{
		value: '0162801',
		label: '0162-8/01 - Serviço de inseminação artificial em animais',
	},
	{
		value: '0162802',
		label: '0162-8/02 - Serviço de tosquiamento de ovinos',
	},
	{ value: '0162803', label: '0162-8/03 - Serviço de manejo de animais' },
	{
		value: '0162899',
		label:
			'0162-8/99 - Atividades de apoio à pecuária não especificadas anteriormente',
	},
	{ value: '0163600', label: '0163-6/00 - Atividades de pós-colheita' },
	{ value: '0170900', label: '0170-9/00 - Caça e serviços relacionados' },
	{ value: '0210101', label: '0210-1/01 - Cultivo de eucalipto' },
	{ value: '0210102', label: '0210-1/02 - Cultivo de acácia-negra' },
	{ value: '0210103', label: '0210-1/03 - Cultivo de pinus' },
	{ value: '0210104', label: '0210-1/04 - Cultivo de teca' },
	{
		value: '0210105',
		label:
			'0210-1/05 - Cultivo de espécies madeireiras, exceto eucalipto, acácia-negra, pinus e teca',
	},
	{
		value: '0210106',
		label: '0210-1/06 - Cultivo de mudas em viveiros florestais',
	},
	{
		value: '0210107',
		label: '0210-1/07 - Extração de madeira em florestas plantadas',
	},
	{
		value: '0210108',
		label: '0210-1/08 - Produção de carvão vegetal - florestas plantadas',
	},
	{
		value: '0210109',
		label:
			'0210-1/09 - Produção de casca de acácia-negra - florestas plantadas',
	},
	{
		value: '0210199',
		label:
			'0210-1/99 - Produção de produtos não madeireiros não especificados anteriormente em florestas plantadas',
	},
	{
		value: '0220901',
		label: '0220-9/01 - Extração de madeira em florestas nativas',
	},
	{
		value: '0220902',
		label: '0220-9/02 - Produção de carvão vegetal - florestas nativas',
	},
	{
		value: '0220903',
		label: '0220-9/03 - Coleta de castanha-do-pará em florestas nativas',
	},
	{
		value: '0220904',
		label: '0220-9/04 - Coleta de látex em florestas nativas',
	},
	{
		value: '0220905',
		label: '0220-9/05 - Coleta de palmito em florestas nativas',
	},
	{
		value: '0220906',
		label: '0220-9/06 - Conservação de florestas nativas',
	},
	{
		value: '0220999',
		label:
			'0220-9/99 - Coleta de produtos não madeireiros não especificados anteriormente em florestas nativas',
	},
	{
		value: '0230600',
		label: '0230-6/00 - Atividades de apoio à produção florestal',
	},
	{
		value: '0311601',
		label: '0311-6/01 - Pesca de peixes em água salgada',
	},
	{
		value: '0311602',
		label: '0311-6/02 - Pesca de crustáceos e moluscos em água salgada',
	},
	{
		value: '0311603',
		label: '0311-6/03 - Coleta de outros produtos marinhos',
	},
	{
		value: '0311604',
		label: '0311-6/04 - Atividades de apoio à pesca em água salgada',
	},
	{ value: '0312401', label: '0312-4/01 - Pesca de peixes em água doce' },
	{
		value: '0312402',
		label: '0312-4/02 - Pesca de crustáceos e moluscos em água doce',
	},
	{
		value: '0312403',
		label: '0312-4/03 - Coleta de outros produtos aquáticos de água doce',
	},
	{
		value: '0312404',
		label: '0312-4/04 - Atividades de apoio à pesca em água doce',
	},
	{
		value: '0321301',
		label: '0321-3/01 - Criação de peixes em água salgada e salobra',
	},
	{
		value: '0321302',
		label: '0321-3/02 - Criação de camarões em água salgada e salobra',
	},
	{
		value: '0321303',
		label:
			'0321-3/03 - Criação de ostras e mexilhões em água salgada e salobra',
	},
	{
		value: '0321304',
		label:
			'0321-3/04 - Criação de peixes ornamentais em água salgada e salobra',
	},
	{
		value: '0321305',
		label:
			'0321-3/05 - Atividades de apoio à aquicultura em água salgada e salobra',
	},
	{
		value: '0321399',
		label:
			'0321-3/99 - Cultivos e semicultivos da aquicultura em água salgada e salobra não especificados anteriormente',
	},
	{ value: '0322101', label: '0322-1/01 - Criação de peixes em água doce' },
	{
		value: '0322102',
		label: '0322-1/02 - Criação de camarões em água doce',
	},
	{
		value: '0322103',
		label: '0322-1/03 - Criação de ostras e mexilhões em água doce',
	},
	{
		value: '0322104',
		label: '0322-1/04 - Criação de peixes ornamentais em água doce',
	},
	{ value: '0322105', label: '0322-1/05 - Ranicultura' },
	{ value: '0322106', label: '0322-1/06 - Criação de jacaré' },
	{
		value: '0322107',
		label: '0322-1/07 - Atividades de apoio à aquicultura em água doce',
	},
	{
		value: '0322199',
		label:
			'0322-1/99 - Cultivos e semicultivos da aquicultura em água doce não especificados anteriormente',
	},
	{ value: '0500301', label: '0500-3/01 - Extração de carvão mineral' },
	{
		value: '0500302',
		label: '0500-3/02 - Beneficiamento de carvão mineral',
	},
	{
		value: '0600001',
		label: '0600-0/01 - Extração de petróleo e gás natural',
	},
	{
		value: '0600002',
		label: '0600-0/02 - Extração e beneficiamento de xisto',
	},
	{
		value: '0600003',
		label: '0600-0/03 - Extração e beneficiamento de areias betuminosas',
	},
	{ value: '0710301', label: '0710-3/01 - Extração de minério de ferro' },
	{
		value: '0710302',
		label:
			'0710-3/02 - Pelotização, sinterização e outros beneficiamentos de minério de ferro',
	},
	{
		value: '0721901',
		label: '0721-9/01 - Extração de minério de alumínio',
	},
	{
		value: '0721902',
		label: '0721-9/02 - Beneficiamento de minério de alumínio',
	},
	{ value: '0722701', label: '0722-7/01 - Extração de minério de estanho' },
	{
		value: '0722702',
		label: '0722-7/02 - Beneficiamento de minério de estanho',
	},
	{
		value: '0723501',
		label: '0723-5/01 - Extração de minério de manganês',
	},
	{
		value: '0723502',
		label: '0723-5/02 - Beneficiamento de minério de manganês',
	},
	{
		value: '0724301',
		label: '0724-3/01 - Extração de minério de metais preciosos',
	},
	{
		value: '0724302',
		label: '0724-3/02 - Beneficiamento de minério de metais preciosos',
	},
	{
		value: '0725100',
		label: '0725-1/00 - Extração de minerais radioativos',
	},
	{
		value: '0729401',
		label: '0729-4/01 - Extração de minérios de nióbio e titânio',
	},
	{
		value: '0729402',
		label: '0729-4/02 - Extração de minério de tungstênio',
	},
	{ value: '0729403', label: '0729-4/03 - Extração de minério de níquel' },
	{
		value: '0729404',
		label:
			'0729-4/04 - Extração de minérios de cobre, chumbo, zinco e outros minerais metálicos não ferrosos não especificados anteriormente',
	},
	{
		value: '0729405',
		label:
			'0729-4/05 - Beneficiamento de minérios de cobre, chumbo, zinco e outros minerais metálicos não ferrosos não especificados anteriormente',
	},
	{
		value: '0810001',
		label: '0810-0/01 - Extração de ardósia e beneficiamento associado',
	},
	{
		value: '0810002',
		label: '0810-0/02 - Extração de granito e beneficiamento associado',
	},
	{
		value: '0810003',
		label: '0810-0/03 - Extração de mármore e beneficiamento associado',
	},
	{
		value: '0810004',
		label:
			'0810-0/04 - Extração de calcário e dolomita e beneficiamento associado',
	},
	{ value: '0810005', label: '0810-0/05 - Extração de gesso e caulim' },
	{
		value: '0810006',
		label:
			'0810-0/06 - Extração de areia, cascalho ou pedregulho e beneficiamento associado',
	},
	{
		value: '0810007',
		label: '0810-0/07 - Extração de argila e beneficiamento associado',
	},
	{
		value: '0810008',
		label: '0810-0/08 - Extração de saibro e beneficiamento associado',
	},
	{
		value: '0810009',
		label: '0810-0/09 - Extração de basalto e beneficiamento associado',
	},
	{
		value: '0810010',
		label: '0810-0/10 - Beneficiamento de gesso e caulim associado à extração',
	},
	{
		value: '0810099',
		label:
			'0810-0/99 - Extração e britamento de pedras e outros materiais para construção e beneficiamento associado',
	},
	{
		value: '0891600',
		label:
			'0891-6/00 - Extração de minerais para fabricação de adubos, fertilizantes e outros produtos químicos',
	},
	{ value: '0892401', label: '0892-4/01 - Extração de sal marinho' },
	{ value: '0892402', label: '0892-4/02 - Extração de sal-gema' },
	{
		value: '0892403',
		label: '0892-4/03 - Refino e outros tratamentos do sal',
	},
	{
		value: '0893200',
		label: '0893-2/00 - Extração de gemas (pedras preciosas e semipreciosas)',
	},
	{ value: '0899101', label: '0899-1/01 - Extração de grafita' },
	{ value: '0899102', label: '0899-1/02 - Extração de quartzo' },
	{ value: '0899103', label: '0899-1/03 - Extração de amianto' },
	{
		value: '0899199',
		label:
			'0899-1/99 - Extração de outros minerais não metálicos não especificados anteriormente',
	},
	{
		value: '0910600',
		label:
			'0910-6/00 - Atividades de apoio à extração de petróleo e gás natural',
	},
	{
		value: '0990401',
		label: '0990-4/01 - Atividades de apoio à extração de minério de ferro',
	},
	{
		value: '0990402',
		label:
			'0990-4/02 - Atividades de apoio à extração de minerais metálicos não ferrosos',
	},
	{
		value: '0990403',
		label:
			'0990-4/03 - Atividades de apoio à extração de minerais não metálicos',
	},
	{ value: '1011201', label: '1011-2/01 - Frigorífico - abate de bovinos' },
	{ value: '1011202', label: '1011-2/02 - Frigorífico - abate de equinos' },
	{
		value: '1011203',
		label: '1011-2/03 - Frigorífico - abate de ovinos e caprinos',
	},
	{
		value: '1011204',
		label: '1011-2/04 - Frigorífico - abate de bufalinos',
	},
	{
		value: '1011205',
		label:
			'1011-2/05 - Matadouro - abate de reses sob contrato, exceto abate de suínos',
	},
	{ value: '1012101', label: '1012-1/01 - Abate de aves' },
	{ value: '1012102', label: '1012-1/02 - Abate de pequenos animais' },
	{ value: '1012103', label: '1012-1/03 - Frigorífico - abate de suínos' },
	{
		value: '1012104',
		label: '1012-1/04 - Matadouro - abate de suínos sob contrato',
	},
	{
		value: '1013901',
		label: '1013-9/01 - Fabricação de produtos de carne',
	},
	{
		value: '1013902',
		label: '1013-9/02 - Preparação de subprodutos do abate',
	},
	{
		value: '1020101',
		label: '1020-1/01 - Preservação de peixes, crustáceos e moluscos',
	},
	{
		value: '1020102',
		label:
			'1020-1/02 - Fabricação de conservas de peixes, crustáceos e moluscos',
	},
	{
		value: '1031700',
		label: '1031-7/00 - Fabricação de conservas de frutas',
	},
	{
		value: '1032501',
		label: '1032-5/01 - Fabricação de conservas de palmito',
	},
	{
		value: '1032599',
		label:
			'1032-5/99 - Fabricação de conservas de legumes e outros vegetais, exceto palmito',
	},
	{
		value: '1033301',
		label:
			'1033-3/01 - Fabricação de sucos concentrados de frutas, hortaliças e legumes',
	},
	{
		value: '1033302',
		label:
			'1033-3/02 - Fabricação de sucos de frutas, hortaliças e legumes, exceto concentrados',
	},
	{
		value: '1041400',
		label:
			'1041-4/00 - Fabricação de óleos vegetais em bruto, exceto óleo de milho',
	},
	{
		value: '1042200',
		label:
			'1042-2/00 - Fabricação de óleos vegetais refinados, exceto óleo de milho',
	},
	{
		value: '1043100',
		label:
			'1043-1/00 - Fabricação de margarina e outras gorduras vegetais e de óleos não comestíveis de animais',
	},
	{ value: '1051100', label: '1051-1/00 - Preparação do leite' },
	{ value: '1052000', label: '1052-0/00 - Fabricação de laticínios' },
	{
		value: '1053800',
		label: '1053-8/00 - Fabricação de sorvetes e outros gelados comestíveis',
	},
	{ value: '1061901', label: '1061-9/01 - Beneficiamento de arroz' },
	{
		value: '1061902',
		label: '1061-9/02 - Fabricação de produtos do arroz',
	},
	{
		value: '1062700',
		label: '1062-7/00 - Moagem de trigo e fabricação de derivados',
	},
	{
		value: '1063500',
		label: '1063-5/00 - Fabricação de farinha de mandioca e derivados',
	},
	{
		value: '1064300',
		label:
			'1064-3/00 - Fabricação de farinha de milho e derivados, exceto óleos de milho',
	},
	{
		value: '1065101',
		label: '1065-1/01 - Fabricação de amidos e féculas de vegetais',
	},
	{
		value: '1065102',
		label: '1065-1/02 - Fabricação de óleo de milho em bruto',
	},
	{
		value: '1065103',
		label: '1065-1/03 - Fabricação de óleo de milho refinado',
	},
	{
		value: '1066000',
		label: '1066-0/00 - Fabricação de alimentos para animais',
	},
	{
		value: '1069400',
		label:
			'1069-4/00 - Moagem e fabricação de produtos de origem vegetal não especificados anteriormente',
	},
	{ value: '1071600', label: '1071-6/00 - Fabricação de açúcar em bruto' },
	{
		value: '1072401',
		label: '1072-4/01 - Fabricação de açúcar de cana refinado',
	},
	{
		value: '1072402',
		label:
			'1072-4/02 - Fabricação de açúcar de cereais (dextrose) e de beterraba',
	},
	{ value: '1081301', label: '1081-3/01 - Beneficiamento de café' },
	{ value: '1081302', label: '1081-3/02 - Torrefação e moagem de café' },
	{
		value: '1082100',
		label: '1082-1/00 - Fabricação de produtos à base de café',
	},
	{
		value: '1091101',
		label: '1091-1/01 - Fabricação de produtos de panificação industrial',
	},
	{
		value: '1091102',
		label:
			'1091-1/02 - Fabricação de produtos de padaria e confeitaria com predominância de produção própria',
	},
	{
		value: '1092900',
		label: '1092-9/00 - Fabricação de biscoitos e bolachas',
	},
	{
		value: '1093701',
		label:
			'1093-7/01 - Fabricação de produtos derivados do cacau e de chocolates',
	},
	{
		value: '1093702',
		label:
			'1093-7/02 - Fabricação de frutas cristalizadas, balas e semelhantes',
	},
	{
		value: '1094500',
		label: '1094-5/00 - Fabricação de massas alimentícias',
	},
	{
		value: '1095300',
		label:
			'1095-3/00 - Fabricação de especiarias, molhos, temperos e condimentos',
	},
	{
		value: '1096100',
		label: '1096-1/00 - Fabricação de alimentos e pratos prontos',
	},
	{ value: '1099601', label: '1099-6/01 - Fabricação de vinagres' },
	{ value: '1099602', label: '1099-6/02 - Fabricação de pós-alimentícios' },
	{
		value: '1099603',
		label: '1099-6/03 - Fabricação de fermentos e leveduras',
	},
	{ value: '1099604', label: '1099-6/04 - Fabricação de gelo comum' },
	{
		value: '1099605',
		label: '1099-6/05 - Fabricação de produtos para infusão (chá, mate, etc.)',
	},
	{
		value: '1099606',
		label: '1099-6/06 - Fabricação de adoçantes naturais e artificiais',
	},
	{
		value: '1099607',
		label:
			'1099-6/07 - Fabricação de alimentos dietéticos e complementos alimentares',
	},
	{
		value: '1099699',
		label:
			'1099-6/99 - Fabricação de outros produtos alimentícios não especificados anteriormente',
	},
	{
		value: '1111901',
		label: '1111-9/01 - Fabricação de aguardente de cana-de-açúcar',
	},
	{
		value: '1111902',
		label: '1111-9/02 - Fabricação de outras aguardentes e bebidas destiladas',
	},
	{ value: '1112700', label: '1112-7/00 - Fabricação de vinho' },
	{
		value: '1113501',
		label: '1113-5/01 - Fabricação de malte, inclusive malte uísque',
	},
	{
		value: '1113502',
		label: '1113-5/02 - Fabricação de cervejas e chopes',
	},
	{ value: '1121600', label: '1121-6/00 - Fabricação de águas envasadas' },
	{ value: '1122401', label: '1122-4/01 - Fabricação de refrigerantes' },
	{
		value: '1122402',
		label:
			'1122-4/02 - Fabricação de chá mate e outros chás prontos para consumo',
	},
	{
		value: '1122403',
		label:
			'1122-4/03 - Fabricação de refrescos, xaropes e pós para refrescos, exceto refrescos de frutas',
	},
	{
		value: '1122404',
		label: '1122-4/04 - Fabricação de bebidas isotônicas',
	},
	{
		value: '1122499',
		label:
			'1122-4/99 - Fabricação de outras bebidas não alcoólicas não especificadas anteriormente',
	},
	{
		value: '1210700',
		label: '1210-7/00 - Processamento industrial do fumo',
	},
	{ value: '1220401', label: '1220-4/01 - Fabricação de cigarros' },
	{
		value: '1220402',
		label: '1220-4/02 - Fabricação de cigarrilhas e charutos',
	},
	{
		value: '1220403',
		label: '1220-4/03 - Fabricação de filtros para cigarros',
	},
	{
		value: '1220499',
		label:
			'1220-4/99 - Fabricação de outros produtos do fumo, exceto cigarros, cigarrilhas e charutos',
	},
	{
		value: '1311100',
		label: '1311-1/00 - Preparação e fiação de fibras de algodão',
	},
	{
		value: '1312000',
		label:
			'1312-0/00 - Preparação e fiação de fibras têxteis naturais, exceto algodão',
	},
	{
		value: '1313800',
		label: '1313-8/00 - Fiação de fibras artificiais e sintéticas',
	},
	{
		value: '1314600',
		label: '1314-6/00 - Fabricação de linhas para costurar e bordar',
	},
	{ value: '1321900', label: '1321-9/00 - Tecelagem de fios de algodão' },
	{
		value: '1322700',
		label:
			'1322-7/00 - Tecelagem de fios de fibras têxteis naturais, exceto algodão',
	},
	{
		value: '1323500',
		label: '1323-5/00 - Tecelagem de fios de fibras artificiais e sintéticas',
	},
	{ value: '1330800', label: '1330-8/00 - Fabricação de tecidos de malha' },
	{
		value: '1340501',
		label:
			'1340-5/01 - Estamparia e texturização em fios, tecidos, artefatos têxteis e peças do vestuário',
	},
	{
		value: '1340502',
		label:
			'1340-5/02 - Alvejamento, tingimento e torção em fios, tecidos, artefatos têxteis e peças do vestuário',
	},
	{
		value: '1340599',
		label:
			'1340-5/99 - Outros serviços de acabamento em fios, tecidos, artefatos têxteis e peças do vestuário',
	},
	{
		value: '1351100',
		label: '1351-1/00 - Fabricação de artefatos têxteis para uso doméstico',
	},
	{
		value: '1352900',
		label: '1352-9/00 - Fabricação de artefatos de tapeçaria',
	},
	{
		value: '1353700',
		label: '1353-7/00 - Fabricação de artefatos de cordoaria',
	},
	{
		value: '1354500',
		label: '1354-5/00 - Fabricação de tecidos especiais, inclusive artefatos',
	},
	{
		value: '1359600',
		label:
			'1359-6/00 - Fabricação de outros produtos têxteis não especificados anteriormente',
	},
	{ value: '1411801', label: '1411-8/01 - Confecção de roupas íntimas' },
	{ value: '1411802', label: '1411-8/02 - Facção de roupas íntimas' },
	{
		value: '1412601',
		label:
			'1412-6/01 - Confecção de peças do vestuário, exceto roupas íntimas e as confeccionadas sob medida',
	},
	{
		value: '1412602',
		label:
			'1412-6/02 - Confecção, sob medida, de peças do vestuário, exceto roupas íntimas',
	},
	{
		value: '1412603',
		label: '1412-6/03 - Facção de peças do vestuário, exceto roupas íntimas',
	},
	{
		value: '1413401',
		label: '1413-4/01 - Confecção de roupas profissionais, exceto sob medida',
	},
	{
		value: '1413402',
		label: '1413-4/02 - Confecção, sob medida, de roupas profissionais',
	},
	{ value: '1413403', label: '1413-4/03 - Facção de roupas profissionais' },
	{
		value: '1414200',
		label:
			'1414-2/00 - Fabricação de acessórios do vestuário, exceto para segurança e proteção',
	},
	{ value: '1421500', label: '1421-5/00 - Fabricação de meias' },
	{
		value: '1422300',
		label:
			'1422-3/00 - Fabricação de artigos do vestuário, produzidos em malharias e tricotagens, exceto meias',
	},
	{
		value: '1510600',
		label: '1510-6/00 - Curtimento e outras preparações de couro',
	},
	{
		value: '1521100',
		label:
			'1521-1/00 - Fabricação de artigos para viagem, bolsas e semelhantes de qualquer material',
	},
	{
		value: '1529700',
		label:
			'1529-7/00 - Fabricação de artefatos de couro não especificados anteriormente',
	},
	{
		value: '1531901',
		label: '1531-9/01 - Fabricação de calçados de couro',
	},
	{
		value: '1531902',
		label: '1531-9/02 - Acabamento de calçados de couro sob contrato',
	},
	{
		value: '1532700',
		label: '1532-7/00 - Fabricação de tênis de qualquer material',
	},
	{
		value: '1533500',
		label: '1533-5/00 - Fabricação de calçados de material sintético',
	},
	{
		value: '1539400',
		label:
			'1539-4/00 - Fabricação de calçados de materiais não especificadosanteriormente',
	},
	{
		value: '1540800',
		label:
			'1540-8/00 - Fabricação de partes para calçados, de qualquer material',
	},
	{
		value: '1610203',
		label: '1610-2/03 - Serrarias com desdobramento de madeira em bruto',
	},
	{
		value: '1610204',
		label:
			'1610-2/04 - Serrarias sem desdobramento de madeira em bruto - Resseragem',
	},
	{
		value: '1610205',
		label:
			'1610-2/05 - Serviço de tratamento de madeira realizado sob contrato',
	},
	{
		value: '1621800',
		label:
			'1621-8/00 - Fabricação de madeira laminada e de chapas de madeira compensada, prensada e aglomerada',
	},
	{
		value: '1622601',
		label: '1622-6/01 - Fabricação de casas de madeira pré-fabricadas',
	},
	{
		value: '1622602',
		label:
			'1622-6/02 - Fabricação de esquadrias de madeira e de peças de madeira para instalações industriais e comerciais',
	},
	{
		value: '1622699',
		label:
			'1622-6/99 - Fabricação de outros artigos de carpintaria para construção',
	},
	{
		value: '1623400',
		label:
			'1623-4/00 - Fabricação de artefatos de tanoaria e de embalagens de madeira',
	},
	{
		value: '1629301',
		label:
			'1629-3/01 - Fabricação de artefatos diversos de madeira, exceto móveis',
	},
	{
		value: '1629302',
		label:
			'1629-3/02 - Fabricação de artefatos diversos de cortiça, bambu, palha, vime e outros materiais trançados, exceto móveis',
	},
	{
		value: '1710900',
		label:
			'1710-9/00 - Fabricação de celulose e outras pastas para a fabricação de papel',
	},
	{ value: '1721400', label: '1721-4/00 - Fabricação de papel' },
	{
		value: '1722200',
		label: '1722-2/00 - Fabricação de cartolina e papel-cartão',
	},
	{
		value: '1731100',
		label: '1731-1/00 - Fabricação de embalagens de papel',
	},
	{
		value: '1732000',
		label: '1732-0/00 - Fabricação de embalagens de cartolina e papel-cartão',
	},
	{
		value: '1733800',
		label:
			'1733-8/00 - Fabricação de chapas e de embalagens de papelão ondulado',
	},
	{
		value: '1741901',
		label: '1741-9/01 - Fabricação de formulários contínuos',
	},
	{
		value: '1741902',
		label:
			'1741-9/02 - Fabricação de produtos de papel, cartolina, papel-cartão e papelão ondulado para uso comercial e de escritório',
	},
	{
		value: '1742701',
		label: '1742-7/01 - Fabricação de fraldas descartáveis',
	},
	{
		value: '1742702',
		label: '1742-7/02 - Fabricação de absorventes higiênicos',
	},
	{
		value: '1742799',
		label:
			'1742-7/99 - Fabricação de produtos de papel para uso doméstico e higiênico-sanitário não especificados anteriormente',
	},
	{
		value: '1749400',
		label:
			'1749-4/00 - Fabricação de produtos de pastas celulósicas, papel, cartolina, papel-cartão e papelão ondulado não especificados anteriormente',
	},
	{ value: '1811301', label: '1811-3/01 - Impressão de jornais' },
	{
		value: '1811302',
		label:
			'1811-3/02 - Impressão de livros, revistas e outras publicações periódicas',
	},
	{
		value: '1812100',
		label: '1812-1/00 - Impressão de material de segurança',
	},
	{
		value: '1813001',
		label: '1813-0/01 - Impressão de material para uso publicitário',
	},
	{
		value: '1813099',
		label: '1813-0/99 - Impressão de material para outros usos',
	},
	{ value: '1821100', label: '1821-1/00 - Serviços de pré-impressão' },
	{
		value: '1822901',
		label: '1822-9/01 - Serviços de encadernação e plastificação',
	},
	{
		value: '1822999',
		label:
			'1822-9/99 - Serviços de acabamentos gráficos, exceto encadernação e plastificação',
	},
	{
		value: '1830001',
		label: '1830-0/01 - Reprodução de som em qualquer suporte',
	},
	{
		value: '1830002',
		label: '1830-0/02 - Reprodução de vídeo em qualquer suporte',
	},
	{
		value: '1830003',
		label: '1830-0/03 - Reprodução de software em qualquer suporte',
	},
	{ value: '1910100', label: '1910-1/00 - Coquerias' },
	{
		value: '1921700',
		label: '1921-7/00 - Fabricação de produtos do refino de petróleo',
	},
	{ value: '1922501', label: '1922-5/01 - Formulação de combustíveis' },
	{
		value: '1922502',
		label: '1922-5/02 - Rerrefino de óleos lubrificantes',
	},
	{
		value: '1922599',
		label:
			'1922-5/99 - Fabricação de outros produtos derivados do petróleo, exceto produtos do refino',
	},
	{ value: '1931400', label: '1931-4/00 - Fabricação de álcool' },
	{
		value: '1932200',
		label: '1932-2/00 - Fabricação de biocombustíveis, exceto álcool',
	},
	{ value: '2011800', label: '2011-8/00 - Fabricação de cloro e álcalis' },
	{
		value: '2012600',
		label: '2012-6/00 - Fabricação de intermediários para fertilizantes',
	},
	{
		value: '2013401',
		label: '2013-4/01 - Fabricação de adubos e fertilizantes organo-minerais',
	},
	{
		value: '2013402',
		label:
			'2013-4/02 - Fabricação de adubos e fertilizantes, exceto organo-minerais',
	},
	{
		value: '2014200',
		label: '2014-2/00 - Fabricação de gases industriais',
	},
	{
		value: '2019301',
		label: '2019-3/01 - Elaboração de combustíveis nucleares',
	},
	{
		value: '2019399',
		label:
			'2019-3/99 - Fabricação de outros produtos químicos inorgânicos não especificados anteriormente',
	},
	{
		value: '2021500',
		label: '2021-5/00 - Fabricação de produtos petroquímicos básicos',
	},
	{
		value: '2022300',
		label:
			'2022-3/00 - Fabricação de intermediários para plastificantes, resinas e fibras',
	},
	{
		value: '2029100',
		label:
			'2029-1/00 - Fabricação de produtos químicos orgânicos não especificados anteriormente',
	},
	{
		value: '2031200',
		label: '2031-2/00 - Fabricação de resinas termoplásticas',
	},
	{
		value: '2032100',
		label: '2032-1/00 - Fabricação de resinas termofixas',
	},
	{ value: '2033900', label: '2033-9/00 - Fabricação de elastômeros' },
	{
		value: '2040100',
		label: '2040-1/00 - Fabricação de fibras artificiais e sintéticas',
	},
	{
		value: '2051700',
		label: '2051-7/00 - Fabricação de defensivos agrícolas',
	},
	{
		value: '2052500',
		label: '2052-5/00 - Fabricação de desinfestantes domissanitários',
	},
	{
		value: '2061400',
		label: '2061-4/00 - Fabricação de sabões e detergentes sintéticos',
	},
	{
		value: '2062200',
		label: '2062-2/00 - Fabricação de produtos de limpeza e polimento',
	},
	{
		value: '2063100',
		label:
			'2063-1/00 - Fabricação de cosméticos, produtos de perfumaria e de higiene pessoal',
	},
	{
		value: '2071100',
		label: '2071-1/00 - Fabricação de tintas, vernizes, esmaltes e lacas',
	},
	{
		value: '2072000',
		label: '2072-0/00 - Fabricação de tintas de impressão',
	},
	{
		value: '2073800',
		label:
			'2073-8/00 - Fabricação de impermeabilizantes, solventes e produtos afins',
	},
	{
		value: '2091600',
		label: '2091-6/00 - Fabricação de adesivos e selantes',
	},
	{
		value: '2092401',
		label: '2092-4/01 - Fabricação de pólvoras, explosivos e detonantes',
	},
	{
		value: '2092402',
		label: '2092-4/02 - Fabricação de artigos pirotécnicos',
	},
	{
		value: '2092403',
		label: '2092-4/03 - Fabricação de fósforos de segurança',
	},
	{
		value: '2093200',
		label: '2093-2/00 - Fabricação de aditivos de uso industrial',
	},
	{ value: '2094100', label: '2094-1/00 - Fabricação de catalisadores' },
	{
		value: '2099101',
		label:
			'2099-1/01 - Fabricação de chapas, filmes, papéis e outros materiais e produtos químicos para fotografia',
	},
	{
		value: '2099199',
		label:
			'2099-1/99 - Fabricação de outros produtos químicos não especificados anteriormente',
	},
	{
		value: '2110600',
		label: '2110-6/00 - Fabricação de produtos farmoquímicos',
	},
	{
		value: '2121101',
		label: '2121-1/01 - Fabricação de medicamentos alopáticos para uso humano',
	},
	{
		value: '2121102',
		label:
			'2121-1/02 - Fabricação de medicamentos homeopáticos para uso humano',
	},
	{
		value: '2121103',
		label:
			'2121-1/03 - Fabricação de medicamentos fitoterápicos para uso humano',
	},
	{
		value: '2122000',
		label: '2122-0/00 - Fabricação de medicamentos para uso veterinário',
	},
	{
		value: '2123800',
		label: '2123-8/00 - Fabricação de preparações farmacêuticas',
	},
	{
		value: '2211100',
		label: '2211-1/00 - Fabricação de pneumáticos e de câmaras-de-ar',
	},
	{ value: '2212900', label: '2212-9/00 - Reforma de pneumáticos usados' },
	{
		value: '2219600',
		label:
			'2219-6/00 - Fabricação de artefatos de borracha não especificados anteriormente',
	},
	{
		value: '2221800',
		label:
			'2221-8/00 - Fabricação de laminados planos e tubulares de material plástico',
	},
	{
		value: '2222600',
		label: '2222-6/00 - Fabricação de embalagens de material plástico',
	},
	{
		value: '2223400',
		label:
			'2223-4/00 - Fabricação de tubos e acessórios de material plástico para uso na construção',
	},
	{
		value: '2229301',
		label:
			'2229-3/01 - Fabricação de artefatos de material plástico para uso pessoal e doméstico',
	},
	{
		value: '2229302',
		label:
			'2229-3/02 - Fabricação de artefatos de material plástico para usos industriais',
	},
	{
		value: '2229303',
		label:
			'2229-3/03 - Fabricação de artefatos de material plástico para uso na construção, exceto tubos e acessórios',
	},
	{
		value: '2229399',
		label:
			'2229-3/99 - Fabricação de artefatos de material plástico para outros usos não especificados anteriormente',
	},
	{
		value: '2311700',
		label: '2311-7/00 - Fabricação de vidro plano e de segurança',
	},
	{
		value: '2312500',
		label: '2312-5/00 - Fabricação de embalagens de vidro',
	},
	{ value: '2319200', label: '2319-2/00 - Fabricação de artigos de vidro' },
	{ value: '2320600', label: '2320-6/00 - Fabricação de cimento' },
	{
		value: '2330301',
		label:
			'2330-3/01 - Fabricação de estruturas pré-moldadas de concreto armado, em série e sob encomenda',
	},
	{
		value: '2330302',
		label:
			'2330-3/02 - Fabricação de artefatos de cimento para uso na construção',
	},
	{
		value: '2330303',
		label:
			'2330-3/03 - Fabricação de artefatos de fibrocimento para uso na construção',
	},
	{
		value: '2330304',
		label: '2330-3/04 - Fabricação de casas pré-moldadas de concreto',
	},
	{
		value: '2330305',
		label:
			'2330-3/05 - Preparação de massa de concreto e argamassa para construção',
	},
	{
		value: '2330399',
		label:
			'2330-3/99 - Fabricação de outros artefatos e produtos de concreto, cimento, fibrocimento, gesso e materiais semelhantes',
	},
	{
		value: '2341900',
		label: '2341-9/00 - Fabricação de produtos cerâmicos refratários',
	},
	{ value: '2342701', label: '2342-7/01 - Fabricação de azulejos e pisos' },
	{
		value: '2342702',
		label:
			'2342-7/02 - Fabricação de artefatos de cerâmica e barro cozido para uso na construção, exceto azulejos e pisos',
	},
	{
		value: '2349401',
		label: '2349-4/01 - Fabricação de material sanitário de cerâmica',
	},
	{
		value: '2349499',
		label:
			'2349-4/99 - Fabricação de produtos cerâmicos não refratários não especificados anteriormente',
	},
	{
		value: '2391501',
		label: '2391-5/01 - Britamento de pedras, exceto associado à extração',
	},
	{
		value: '2391502',
		label:
			'2391-5/02 - Aparelhamento de pedras para construção, exceto associado à extração',
	},
	{
		value: '2391503',
		label:
			'2391-5/03 - Aparelhamento de placas e execução de trabalhos em mármore, granito, ardósia e outras pedras',
	},
	{ value: '2392300', label: '2392-3/00 - Fabricação de cal e gesso' },
	{
		value: '2399101',
		label:
			'2399-1/01 - Decoração, lapidação, gravação, vitrificação e outros trabalhos em cerâmica, louça, vidro e cristal',
	},
	{ value: '2399102', label: '2399-1/02 - Fabricação de abrasivos' },
	{
		value: '2399199',
		label:
			'2399-1/99 - Fabricação de outros produtos de minerais não metálicos não especificados anteriormente',
	},
	{ value: '2411300', label: '2411-3/00 - Produção de ferro-gusa' },
	{ value: '2412100', label: '2412-1/00 - Produção de ferroligas' },
	{
		value: '2421100',
		label: '2421-1/00 - Produção de semiacabados de aço',
	},
	{
		value: '2422901',
		label:
			'2422-9/01 - Produção de laminados planos de aço ao carbono, revestidos ou não',
	},
	{
		value: '2422902',
		label: '2422-9/02 - Produção de laminados planos de aços especiais',
	},
	{
		value: '2423701',
		label: '2423-7/01 - Produção de tubos de aço sem costura',
	},
	{
		value: '2423702',
		label: '2423-7/02 - Produção de laminados longos de aço, exceto tubos',
	},
	{ value: '2424501', label: '2424-5/01 - Produção de arames de aço' },
	{
		value: '2424502',
		label:
			'2424-5/02 - Produção de relaminados, trefilados e perfilados de aço, exceto arames',
	},
	{
		value: '2431800',
		label: '2431-8/00 - Produção de tubos de aço com costura',
	},
	{
		value: '2439300',
		label: '2439-3/00 - Produção de outros tubos de ferro e aço',
	},
	{
		value: '2441501',
		label: '2441-5/01 - Produção de alumínio e suas ligas em formas primárias',
	},
	{
		value: '2441502',
		label: '2441-5/02 - Produção de laminados de alumínio',
	},
	{
		value: '2442300',
		label: '2442-3/00 - Metalurgia dos metais preciosos',
	},
	{ value: '2443100', label: '2443-1/00 - Metalurgia do cobre' },
	{
		value: '2449101',
		label: '2449-1/01 - Produção de zinco em formas primárias',
	},
	{ value: '2449102', label: '2449-1/02 - Produção de laminados de zinco' },
	{
		value: '2449103',
		label: '2449-1/03 - Fabricação de ânodos para galvanoplastia',
	},
	{
		value: '2449199',
		label:
			'2449-1/99 - Metalurgia de outros metais não ferrosos e suas ligas não especificados anteriormente',
	},
	{ value: '2451200', label: '2451-2/00 - Fundição de ferro e aço' },
	{
		value: '2452100',
		label: '2452-1/00 - Fundição de metais não ferrosos e suas ligas',
	},
	{
		value: '2511000',
		label: '2511-0/00 - Fabricação de estruturas metálicas',
	},
	{
		value: '2512800',
		label: '2512-8/00 - Fabricação de esquadrias de metal',
	},
	{
		value: '2513600',
		label: '2513-6/00 - Fabricação de obras de caldeiraria pesada',
	},
	{
		value: '2521700',
		label:
			'2521-7/00 - Fabricação de tanques, reservatórios metálicos e caldeiras para aquecimento central',
	},
	{
		value: '2522500',
		label:
			'2522-5/00 - Fabricação de caldeiras geradoras de vapor, exceto para aquecimento central e para veículos',
	},
	{ value: '2531401', label: '2531-4/01 - Produção de forjados de aço' },
	{
		value: '2531402',
		label:
			'2531-4/02 - Produção de forjados de metais não ferrosos e suas ligas',
	},
	{
		value: '2532201',
		label: '2532-2/01 - Produção de artefatos estampados de metal',
	},
	{ value: '2532202', label: '2532-2/02 - Metalurgia do pó' },
	{
		value: '2539001',
		label: '2539-0/01 - Serviços de usinagem, torneiria e solda',
	},
	{
		value: '2539002',
		label: '2539-0/02 - Serviços de tratamento e revestimento em metais',
	},
	{
		value: '2541100',
		label: '2541-1/00 - Fabricação de artigos de cutelaria',
	},
	{
		value: '2542000',
		label:
			'2542-0/00 - Fabricação de artigos de serralheria, exceto esquadrias',
	},
	{ value: '2543800', label: '2543-8/00 - Fabricação de ferramentas' },
	{
		value: '2550101',
		label:
			'2550-1/01 - Fabricação de equipamento bélico pesado, exceto veículos militares de combate',
	},
	{
		value: '2550102',
		label: '2550-1/02 - Fabricação de armas de fogo, outras armas e munições',
	},
	{
		value: '2591800',
		label: '2591-8/00 - Fabricação de embalagens metálicas',
	},
	{
		value: '2592601',
		label:
			'2592-6/01 - Fabricação de produtos de trefilados de metal padronizados',
	},
	{
		value: '2592602',
		label:
			'2592-6/02 - Fabricação de produtos de trefilados de metal, exceto padronizados',
	},
	{
		value: '2593400',
		label:
			'2593-4/00 - Fabricação de artigos de metal para uso doméstico e pessoal',
	},
	{
		value: '2599301',
		label:
			'2599-3/01 - Serviços de confecção de armações metálicas para a construção',
	},
	{
		value: '2599302',
		label: '2599-3/02 - Serviço de corte e dobra de metais',
	},
	{
		value: '2599399',
		label:
			'2599-3/99 - Fabricação de outros produtos de metal não especificados anteriormente',
	},
	{
		value: '2610800',
		label: '2610-8/00 - Fabricação de componentes eletrônicos',
	},
	{
		value: '2621300',
		label: '2621-3/00 - Fabricação de equipamentos de informática',
	},
	{
		value: '2622100',
		label:
			'2622-1/00 - Fabricação de periféricos para equipamentos de informática',
	},
	{
		value: '2631100',
		label:
			'2631-1/00 - Fabricação de equipamentos transmissores de comunicação, peças e acessórios',
	},
	{
		value: '2632900',
		label:
			'2632-9/00 - Fabricação de aparelhos telefônicos e de outros equipamentos de comunicação, peças e acessórios',
	},
	{
		value: '2640000',
		label:
			'2640-0/00 - Fabricação de aparelhos de recepção, reprodução, gravação e amplificação de áudio e vídeo',
	},
	{
		value: '2651500',
		label:
			'2651-5/00 - Fabricação de aparelhos e equipamentos de medida, teste e controle',
	},
	{
		value: '2652300',
		label: '2652-3/00 - Fabricação de cronômetros e relógios',
	},
	{
		value: '2660400',
		label:
			'2660-4/00 - Fabricação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação',
	},
	{
		value: '2670101',
		label:
			'2670-1/01 - Fabricação de equipamentos e instrumentos ópticos, peças e acessórios',
	},
	{
		value: '2670102',
		label:
			'2670-1/02 - Fabricação de aparelhos fotográficos e cinematográficos, peças e acessórios',
	},
	{
		value: '2680900',
		label: '2680-9/00 - Fabricação de mídias virgens, magnéticas e ópticas',
	},
	{
		value: '2710401',
		label:
			'2710-4/01 - Fabricação de geradores de corrente contínua e alternada, peças e acessórios',
	},
	{
		value: '2710402',
		label:
			'2710-4/02 - Fabricação de transformadores, indutores, conversores, sincronizadores e semelhantes, peças e acessórios',
	},
	{
		value: '2710403',
		label: '2710-4/03 - Fabricação de motores elétricos, peças e acessórios',
	},
	{
		value: '2721000',
		label:
			'2721-0/00 - Fabricação de pilhas, baterias e acumuladores elétricos, exceto para veículos automotores',
	},
	{
		value: '2722801',
		label:
			'2722-8/01 - Fabricação de baterias e acumuladores para veículos automotores',
	},
	{
		value: '2722802',
		label:
			'2722-8/02 - Recondicionamento de baterias e acumuladores para veículos automotores',
	},
	{
		value: '2731700',
		label:
			'2731-7/00 - Fabricação de aparelhos e equipamentos para distribuição e controle de energia elétrica',
	},
	{
		value: '2732500',
		label:
			'2732-5/00 - Fabricação de material elétrico para instalações em circuito de consumo',
	},
	{
		value: '2733300',
		label:
			'2733-3/00 - Fabricação de fios, cabos e condutores elétricos isolados',
	},
	{ value: '2740601', label: '2740-6/01 - Fabricação de lâmpadas' },
	{
		value: '2740602',
		label:
			'2740-6/02 - Fabricação de luminárias e outros equipamentos de iluminação',
	},
	{
		value: '2751100',
		label:
			'2751-1/00 - Fabricação de fogões, refrigeradores e máquinas de lavar e secar para uso doméstico, peças e acessórios',
	},
	{
		value: '2759701',
		label:
			'2759-7/01 - Fabricação de aparelhos elétricos de uso pessoal, peças e acessórios',
	},
	{
		value: '2759799',
		label:
			'2759-7/99 - Fabricação de outros aparelhos eletrodomésticos não especificados anteriormente, peças e acessórios',
	},
	{
		value: '2790201',
		label:
			'2790-2/01 - Fabricação de eletrodos, contatos e outros artigos de carvão e grafita para uso elétrico, eletroímãs e isoladores',
	},
	{
		value: '2790202',
		label: '2790-2/02 - Fabricação de equipamentos para sinalização e alarme',
	},
	{
		value: '2790299',
		label:
			'2790-2/99 - Fabricação de outros equipamentos e aparelhos elétricos não especificados anteriormente',
	},
	{
		value: '2811900',
		label:
			'2811-9/00 - Fabricação de motores e turbinas, peças e acessórios, exceto para aviões e veículos rodoviários',
	},
	{
		value: '2812700',
		label:
			'2812-7/00 - Fabricação de equipamentos hidráulicos e pneumáticos, peças e acessórios, exceto válvulas',
	},
	{
		value: '2813500',
		label:
			'2813-5/00 - Fabricação de válvulas, registros e dispositivos semelhantes, peças e acessórios',
	},
	{
		value: '2814301',
		label:
			'2814-3/01 - Fabricação de compressores para uso industrial, peças e acessórios',
	},
	{
		value: '2814302',
		label:
			'2814-3/02 - Fabricação de compressores para uso não industrial, peças e acessórios',
	},
	{
		value: '2815101',
		label: '2815-1/01 - Fabricação de rolamentos para fins industriais',
	},
	{
		value: '2815102',
		label:
			'2815-1/02 - Fabricação de equipamentos de transmissão para fins industriais, exceto rolamentos',
	},
	{
		value: '2821601',
		label:
			'2821-6/01 - Fabricação de fornos industriais, aparelhos e equipamentos não elétricos para instalações térmicas, peças e acessórios',
	},
	{
		value: '2821602',
		label:
			'2821-6/02 - Fabricação de estufas e fornos elétricos para fins industriais, peças e acessórios',
	},
	{
		value: '2822401',
		label:
			'2822-4/01 - Fabricação de máquinas, equipamentos e aparelhos para transporte e elevação de pessoas, peças e acessórios',
	},
	{
		value: '2822402',
		label:
			'2822-4/02 - Fabricação de máquinas, equipamentos e aparelhos para transporte e elevação de cargas, peças e acessórios',
	},
	{
		value: '2823200',
		label:
			'2823-2/00 - Fabricação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial, peças e acessórios',
	},
	{
		value: '2824101',
		label:
			'2824-1/01 - Fabricação de aparelhos e equipamentos de ar condicionado para uso industrial',
	},
	{
		value: '2824102',
		label:
			'2824-1/02 - Fabricação de aparelhos e equipamentos de ar condicionado para uso não industrial',
	},
	{
		value: '2825900',
		label:
			'2825-9/00 - Fabricação de máquinas e equipamentos para saneamento básico e ambiental, peças e acessórios',
	},
	{
		value: '2829101',
		label:
			'2829-1/01 - Fabricação de máquinas de escrever, calcular e outros equipamentos não eletrônicos para escritório, peças e acessórios',
	},
	{
		value: '2829199',
		label:
			'2829-1/99 - Fabricação de outras máquinas e equipamentos de uso geral não especificados anteriormente, peças e acessórios',
	},
	{
		value: '2831300',
		label: '2831-3/00 - Fabricação de tratores agrícolas, peças e acessórios',
	},
	{
		value: '2832100',
		label:
			'2832-1/00 - Fabricação de equipamentos para irrigação agrícola, peças e acessórios',
	},
	{
		value: '2833000',
		label:
			'2833-0/00 - Fabricação de máquinas e equipamentos para a agricultura e pecuária, peças e acessórios, exceto para irrigação',
	},
	{
		value: '2840200',
		label: '2840-2/00 - Fabricação de máquinas-ferramenta, peças e acessórios',
	},
	{
		value: '2851800',
		label:
			'2851-8/00 - Fabricação de máquinas e equipamentos para a prospecção e extração de petróleo, peças e acessórios',
	},
	{
		value: '2852600',
		label:
			'2852-6/00 - Fabricação de outras máquinas e equipamentos para uso na extração mineral, peças e acessórios, exceto na extração de petróleo',
	},
	{
		value: '2853400',
		label:
			'2853-4/00 - Fabricação de tratores, peças e acessórios, exceto agrícolas',
	},
	{
		value: '2854200',
		label:
			'2854-2/00 - Fabricação de máquinas e equipamentos para terraplenagem, pavimentação e construção, peças e acessórios, exceto tratores',
	},
	{
		value: '2861500',
		label:
			'2861-5/00 - Fabricação de máquinas para a indústria metalúrgica, peças e acessórios, exceto máquinas-ferramenta',
	},
	{
		value: '2862300',
		label:
			'2862-3/00 - Fabricação de máquinas e equipamentos para as indústrias de alimentos, bebidas e fumo, peças e acessórios',
	},
	{
		value: '2863100',
		label:
			'2863-1/00 - Fabricação de máquinas e equipamentos para a indústria têxtil, peças e acessórios',
	},
	{
		value: '2864000',
		label:
			'2864-0/00 - Fabricação de máquinas e equipamentos para as indústrias do vestuário, do couro e de calçados, peças e acessórios',
	},
	{
		value: '2865800',
		label:
			'2865-8/00 - Fabricação de máquinas e equipamentos para as indústrias de celulose, papel e papelão e artefatos, peças e acessórios',
	},
	{
		value: '2866600',
		label:
			'2866-6/00 - Fabricação de máquinas e equipamentos para a indústria do plástico, peças e acessórios',
	},
	{
		value: '2869100',
		label:
			'2869-1/00 - Fabricação de máquinas e equipamentos para uso industrial específico não especificados anteriormente, peças e acessórios',
	},
	{
		value: '2910701',
		label: '2910-7/01 - Fabricação de automóveis, camionetas e utilitários',
	},
	{
		value: '2910702',
		label:
			'2910-7/02 - Fabricação de chassis com motor para automóveis, camionetas e utilitários',
	},
	{
		value: '2910703',
		label:
			'2910-7/03 - Fabricação de motores para automóveis, camionetas e utilitários',
	},
	{
		value: '2920401',
		label: '2920-4/01 - Fabricação de caminhões e ônibus',
	},
	{
		value: '2920402',
		label: '2920-4/02 - Fabricação de motores para caminhões e ônibus',
	},
	{
		value: '2930101',
		label:
			'2930-1/01 - Fabricação de cabines, carrocerias e reboques para caminhões',
	},
	{
		value: '2930102',
		label: '2930-1/02 - Fabricação de carrocerias para ônibus',
	},
	{
		value: '2930103',
		label:
			'2930-1/03 - Fabricação de cabines, carrocerias e reboques para outros veículos automotores, exceto caminhões e ônibus',
	},
	{
		value: '2941700',
		label:
			'2941-7/00 - Fabricação de peças e acessórios para o sistema motor de veículos automotores',
	},
	{
		value: '2942500',
		label:
			'2942-5/00 - Fabricação de peças e acessórios para os sistemas de marcha e transmissão de veículos automotores',
	},
	{
		value: '2943300',
		label:
			'2943-3/00 - Fabricação de peças e acessórios para o sistema de freios de veículos automotores',
	},
	{
		value: '2944100',
		label:
			'2944-1/00 - Fabricação de peças e acessórios para o sistema de direção e suspensão de veículos automotores',
	},
	{
		value: '2945000',
		label:
			'2945-0/00 - Fabricação de material elétrico e eletrônico para veículos automotores, exceto baterias',
	},
	{
		value: '2949201',
		label:
			'2949-2/01 - Fabricação de bancos e estofados para veículos automotores',
	},
	{
		value: '2949299',
		label:
			'2949-2/99 - Fabricação de outras peças e acessórios para veículos automotores não especificadas anteriormente',
	},
	{
		value: '2950600',
		label:
			'2950-6/00 - Recondicionamento e recuperação de motores para veículos automotores',
	},
	{
		value: '3011301',
		label: '3011-3/01 - Construção de embarcações de grande porte',
	},
	{
		value: '3011302',
		label:
			'3011-3/02 - Construção de embarcações para uso comercial e para usos especiais, exceto de grande porte',
	},
	{
		value: '3012100',
		label: '3012-1/00 - Construção de embarcações para esporte e lazer',
	},
	{
		value: '3031800',
		label:
			'3031-8/00 - Fabricação de locomotivas, vagões e outros materiais rodantes',
	},
	{
		value: '3032600',
		label:
			'3032-6/00 - Fabricação de peças e acessórios para veículos ferroviários',
	},
	{ value: '3041500', label: '3041-5/00 - Fabricação de aeronaves' },
	{
		value: '3042300',
		label:
			'3042-3/00 - Fabricação de turbinas, motores e outros componentes e peças para aeronaves',
	},
	{
		value: '3050400',
		label: '3050-4/00 - Fabricação de veículos militares de combate',
	},
	{ value: '3091101', label: '3091-1/01 - Fabricação de motocicletas' },
	{
		value: '3091102',
		label: '3091-1/02 - Fabricação de peças e acessórios para motocicletas',
	},
	{
		value: '3092000',
		label:
			'3092-0/00 - Fabricação de bicicletas e triciclos não motorizados, peças e acessórios',
	},
	{
		value: '3099700',
		label:
			'3099-7/00 - Fabricação de equipamentos de transporte não especificados anteriormente',
	},
	{
		value: '3101200',
		label: '3101-2/00 - Fabricação de móveis com predominância de madeira',
	},
	{
		value: '3102100',
		label: '3102-1/00 - Fabricação de móveis com predominância de metal',
	},
	{
		value: '3103900',
		label:
			'3103-9/00 - Fabricação de móveis de outros materiais, exceto madeira e metal',
	},
	{ value: '3104700', label: '3104-7/00 - Fabricação de colchões' },
	{ value: '3211601', label: '3211-6/01 - Lapidação de gemas' },
	{
		value: '3211602',
		label: '3211-6/02 - Fabricação de artefatos de joalheria e ourivesaria',
	},
	{ value: '3211603', label: '3211-6/03 - Cunhagem de moedas e medalhas' },
	{
		value: '3212400',
		label: '3212-4/00 - Fabricação de bijuterias e artefatos semelhantes',
	},
	{
		value: '3220500',
		label:
			'3220-5/00 - Fabricação de instrumentos musicais, peças e acessórios',
	},
	{
		value: '3230200',
		label: '3230-2/00 - Fabricação de artefatos para pesca e esporte',
	},
	{
		value: '3240001',
		label: '3240-0/01 - Fabricação de jogos eletrônicos',
	},
	{
		value: '3240002',
		label:
			'3240-0/02 - Fabricação de mesas de bilhar, de sinuca e acessórios não associada à locação',
	},
	{
		value: '3240003',
		label:
			'3240-0/03 - Fabricação de mesas de bilhar, de sinuca e acessórios associada à locação',
	},
	{
		value: '3240099',
		label:
			'3240-0/99 - Fabricação de outros brinquedos e jogos recreativos não especificados anteriormente',
	},
	{
		value: '3250701',
		label:
			'3250-7/01 - Fabricação de instrumentos não eletrônicos e utensílios para uso médico, cirúrgico, odontológico e de laboratório',
	},
	{
		value: '3250702',
		label:
			'3250-7/02 - Fabricação de mobiliário para uso médico, cirúrgico, odontológico e de laboratório',
	},
	{
		value: '3250703',
		label:
			'3250-7/03 - Fabricação de aparelhos e utensílios para correção de defeitos físicos e aparelhos ortopédicos em geral sob encomenda',
	},
	{
		value: '3250704',
		label:
			'3250-7/04 - Fabricação de aparelhos e utensílios para correção de defeitos físicos e aparelhos ortopédicos em geral, exceto sob encomenda',
	},
	{
		value: '3250705',
		label: '3250-7/05 - Fabricação de materiais para medicina e odontologia',
	},
	{ value: '3250706', label: '3250-7/06 - Serviços de prótese dentária' },
	{ value: '3250707', label: '3250-7/07 - Fabricação de artigos ópticos' },
	{ value: '3250709', label: '3250-7/09 - Serviço de laboratório óptico' },
	{
		value: '3291400',
		label: '3291-4/00 - Fabricação de escovas, pincéis e vassouras',
	},
	{
		value: '3292201',
		label:
			'3292-2/01 - Fabricação de roupas de proteção e segurança e resistentes a fogo',
	},
	{
		value: '3292202',
		label:
			'3292-2/02 - Fabricação de equipamentos e acessórios para segurança pessoal e profissional',
	},
	{
		value: '3299001',
		label: '3299-0/01 - Fabricação de guarda-chuvas e similares',
	},
	{
		value: '3299002',
		label:
			'3299-0/02 - Fabricação de canetas, lápis e outros artigos para escritório',
	},
	{
		value: '3299003',
		label:
			'3299-0/03 - Fabricação de letras, letreiros e placas de qualquer material, exceto luminosos',
	},
	{
		value: '3299004',
		label: '3299-0/04 - Fabricação de painéis e letreiros luminosos',
	},
	{
		value: '3299005',
		label: '3299-0/05 - Fabricação de aviamentos para costura',
	},
	{
		value: '3299006',
		label: '3299-0/06 - Fabricação de velas, inclusive decorativas',
	},
	{
		value: '3299099',
		label:
			'3299-0/99 - Fabricação de produtos diversos não especificados anteriormente',
	},
	{
		value: '3311200',
		label:
			'3311-2/00 - Manutenção e reparação de tanques, reservatórios metálicos e caldeiras, exceto para veículos',
	},
	{
		value: '3312102',
		label:
			'3312-1/02 - Manutenção e reparação de aparelhos e instrumentos de medida, teste e controle',
	},
	{
		value: '3312103',
		label:
			'3312-1/03 - Manutenção e reparação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação',
	},
	{
		value: '3312104',
		label:
			'3312-1/04 - Manutenção e reparação de equipamentos e instrumentos ópticos',
	},
	{
		value: '3313901',
		label:
			'3313-9/01 - Manutenção e reparação de geradores, transformadores e motores elétricos',
	},
	{
		value: '3313902',
		label:
			'3313-9/02 - Manutenção e reparação de baterias e acumuladores elétricos, exceto para veículos',
	},
	{
		value: '3313999',
		label:
			'3313-9/99 - Manutenção e reparação de máquinas, aparelhos e materiais elétricos não especificados anteriormente',
	},
	{
		value: '3314701',
		label:
			'3314-7/01 - Manutenção e reparação de máquinas motrizes não elétricas',
	},
	{
		value: '3314702',
		label:
			'3314-7/02 - Manutenção e reparação de equipamentos hidráulicos e pneumáticos, exceto válvulas',
	},
	{
		value: '3314703',
		label: '3314-7/03 - Manutenção e reparação de válvulas industriais',
	},
	{
		value: '3314704',
		label: '3314-7/04 - Manutenção e reparação de compressores',
	},
	{
		value: '3314705',
		label:
			'3314-7/05 - Manutenção e reparação de equipamentos de transmissão para fins industriais',
	},
	{
		value: '3314706',
		label:
			'3314-7/06 - Manutenção e reparação de máquinas, aparelhos e equipamentos para instalações térmicas',
	},
	{
		value: '3314707',
		label:
			'3314-7/07 - Manutenção e reparação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial',
	},
	{
		value: '3314708',
		label:
			'3314-7/08 - Manutenção e reparação de máquinas, equipamentos e aparelhos para transporte e elevação de cargas',
	},
	{
		value: '3314709',
		label:
			'3314-7/09 - Manutenção e reparação de máquinas de escrever, calcular e de outros equipamentos não eletrônicos para escritório',
	},
	{
		value: '3314710',
		label:
			'3314-7/10 - Manutenção e reparação de máquinas e equipamentos para uso geral não especificados anteriormente',
	},
	{
		value: '3314711',
		label:
			'3314-7/11 - Manutenção e reparação de máquinas e equipamentos para agricultura e pecuária',
	},
	{
		value: '3314712',
		label: '3314-7/12 - Manutenção e reparação de tratores agrícolas',
	},
	{
		value: '3314713',
		label: '3314-7/13 - Manutenção e reparação de máquinas-ferramenta',
	},
	{
		value: '3314714',
		label:
			'3314-7/14 - Manutenção e reparação de máquinas e equipamentos para a prospecção e extração de petróleo',
	},
	{
		value: '3314715',
		label:
			'3314-7/15 - Manutenção e reparação de máquinas e equipamentos para uso na extração mineral, exceto na extração de petróleo',
	},
	{
		value: '3314716',
		label: '3314-7/16 - Manutenção e reparação de tratores, exceto agrícolas',
	},
	{
		value: '3314717',
		label:
			'3314-7/17 - Manutenção e reparação de máquinas e equipamentos de terraplenagem, pavimentação e construção, exceto tratores',
	},
	{
		value: '3314718',
		label:
			'3314-7/18 - Manutenção e reparação de máquinas para a indústria metalúrgica, exceto máquinas-ferramenta',
	},
	{
		value: '3314719',
		label:
			'3314-7/19 - Manutenção e reparação de máquinas e equipamentos para as indústrias de alimentos, bebidas e fumo',
	},
	{
		value: '3314720',
		label:
			'3314-7/20 - Manutenção e reparação de máquinas e equipamentos para a indústria têxtil, do vestuário, do couro e calçados',
	},
	{
		value: '3314721',
		label:
			'3314-7/21 - Manutenção e reparação de máquinas e aparelhos para a indústria de celulose, papel e papelão e artefatos',
	},
	{
		value: '3314722',
		label:
			'3314-7/22 - Manutenção e reparação de máquinas e aparelhos para a indústria do plástico',
	},
	{
		value: '3314799',
		label:
			'3314-7/99 - Manutenção e reparação de outras máquinas e equipamentos para usos industriais não especificados anteriormente',
	},
	{
		value: '3315500',
		label: '3315-5/00 - Manutenção e reparação de veículos ferroviários',
	},
	{
		value: '3316301',
		label:
			'3316-3/01 - Manutenção e reparação de aeronaves, exceto a manutenção na pista',
	},
	{
		value: '3316302',
		label: '3316-3/02 - Manutenção de aeronaves na pista',
	},
	{
		value: '3317101',
		label:
			'3317-1/01 - Manutenção e reparação de embarcações e estruturas flutuantes',
	},
	{
		value: '3317102',
		label:
			'3317-1/02 - Manutenção e reparação de embarcações para esporte e lazer',
	},
	{
		value: '3319800',
		label:
			'3319-8/00 - Manutenção e reparação de equipamentos e produtos não especificados anteriormente',
	},
	{
		value: '3321000',
		label: '3321-0/00 - Instalação de máquinas e equipamentos industriais',
	},
	{
		value: '3329501',
		label: '3329-5/01 - Serviços de montagem de móveis de qualquer material',
	},
	{
		value: '3329599',
		label:
			'3329-5/99 - Instalação de outros equipamentos não especificados anteriormente',
	},
	{ value: '3511501', label: '3511-5/01 - Geração de energia elétrica' },
	{
		value: '3511502',
		label:
			'3511-5/02 - Atividades de coordenação e controle da operação da geração e transmissão de energia elétrica',
	},
	{
		value: '3512300',
		label: '3512-3/00 - Transmissão de energia elétrica',
	},
	{
		value: '3513100',
		label: '3513-1/00 - Comércio atacadista de energia elétrica',
	},
	{
		value: '3514000',
		label: '3514-0/00 - Distribuição de energia elétrica',
	},
	{
		value: '3520401',
		label: '3520-4/01 - Produção de gás, processamento de gás natural',
	},
	{
		value: '3520402',
		label: '3520-4/02 - Distribuição de combustíveis gasosos por redes urbanas',
	},
	{
		value: '3530100',
		label:
			'3530-1/00 - Produção e distribuição de vapor, água quente e ar condicionado',
	},
	{
		value: '3600601',
		label: '3600-6/01 - Captação, tratamento e distribuição de água',
	},
	{
		value: '3600602',
		label: '3600-6/02 - Distribuição de água por caminhões',
	},
	{ value: '3701100', label: '3701-1/00 - Gestão de redes de esgoto' },
	{
		value: '3702900',
		label:
			'3702-9/00 - Atividades relacionadas a esgoto, exceto a gestão de redes',
	},
	{
		value: '3811400',
		label: '3811-4/00 - Coleta de resíduos não perigosos',
	},
	{ value: '3812200', label: '3812-2/00 - Coleta de resíduos perigosos' },
	{
		value: '3821100',
		label: '3821-1/00 - Tratamento e disposição de resíduos não perigosos',
	},
	{
		value: '3822000',
		label: '3822-0/00 - Tratamento e disposição de resíduos perigosos',
	},
	{
		value: '3831901',
		label: '3831-9/01 - Recuperação de sucatas de alumínio',
	},
	{
		value: '3831999',
		label: '3831-9/99 - Recuperação de materiais metálicos, exceto alumínio',
	},
	{
		value: '3832700',
		label: '3832-7/00 - Recuperação de materiais plásticos',
	},
	{ value: '3839401', label: '3839-4/01 - Usinas de compostagem' },
	{
		value: '3839499',
		label:
			'3839-4/99 - Recuperação de materiais não especificados anteriormente',
	},
	{
		value: '3900500',
		label:
			'3900-5/00 - Descontaminação e outros serviços de gestão de resíduos',
	},
	{
		value: '4110700',
		label: '4110-7/00 - Incorporação de empreendimentos imobiliários',
	},
	{ value: '4120400', label: '4120-4/00 - Construção de edifícios' },
	{
		value: '4211101',
		label: '4211-1/01 - Construção de rodovias e ferrovias',
	},
	{
		value: '4211102',
		label:
			'4211-1/02 - Pintura para sinalização em pistas rodoviárias e aeroportos',
	},
	{
		value: '4212000',
		label: '4212-0/00 - Construção de obras de arte especiais',
	},
	{
		value: '4213800',
		label: '4213-8/00 - Obras de urbanização - ruas, praças e calçadas',
	},
	{
		value: '4221901',
		label:
			'4221-9/01 - Construção de barragens e represas para geração de energia elétrica',
	},
	{
		value: '4221902',
		label:
			'4221-9/02 - Construção de estações e redes de distribuição de energia elétrica',
	},
	{
		value: '4221903',
		label:
			'4221-9/03 - Manutenção de redes de distribuição de energia elétrica',
	},
	{
		value: '4221904',
		label: '4221-9/04 - Construção de estações e redes de telecomunicações',
	},
	{
		value: '4221905',
		label: '4221-9/05 - Manutenção de estações e redes de telecomunicações',
	},
	{
		value: '4222701',
		label:
			'4222-7/01 - Construção de redes de abastecimento de água, coleta de esgoto e construções correlatas, exceto obras de irrigação',
	},
	{ value: '4222702', label: '4222-7/02 - Obras de irrigação' },
	{
		value: '4223500',
		label:
			'4223-5/00 - Construção de redes de transportes por dutos, exceto para água e esgoto',
	},
	{
		value: '4291000',
		label: '4291-0/00 - Obras portuárias, marítimas e fluviais',
	},
	{
		value: '4292801',
		label: '4292-8/01 - Montagem de estruturas metálicas',
	},
	{ value: '4292802', label: '4292-8/02 - Obras de montagem industrial' },
	{
		value: '4299501',
		label: '4299-5/01 - Construção de instalações esportivas e recreativas',
	},
	{
		value: '4299599',
		label:
			'4299-5/99 - Outras obras de engenharia civil não especificadas anteriormente',
	},
	{
		value: '4311801',
		label: '4311-8/01 - Demolição de edifícios e outras estruturas',
	},
	{
		value: '4311802',
		label: '4311-8/02 - Preparação de canteiro e limpeza de terreno',
	},
	{ value: '4312600', label: '4312-6/00 - Perfurações e sondagens' },
	{ value: '4313400', label: '4313-4/00 - Obras de terraplenagem' },
	{
		value: '4319300',
		label:
			'4319-3/00 - Serviços de preparação do terreno não especificados anteriormente',
	},
	{
		value: '4321500',
		label: '4321-5/00 - Instalação e manutenção elétrica',
	},
	{
		value: '4322301',
		label: '4322-3/01 - Instalações hidráulicas, sanitárias e de gás',
	},
	{
		value: '4322302',
		label:
			'4322-3/02 - Instalação e manutenção de sistemas centrais de ar condicionado, de ventilação e refrigeração',
	},
	{
		value: '4322303',
		label: '4322-3/03 - Instalações de sistema de prevenção contra incêndio',
	},
	{
		value: '4329101',
		label: '4329-1/01 - Instalação de painéis publicitários',
	},
	{
		value: '4329102',
		label:
			'4329-1/02 - Instalação de equipamentos para orientação à navegação marítima, fluvial e lacustre',
	},
	{
		value: '4329103',
		label:
			'4329-1/03 - Instalação, manutenção e reparação de elevadores, escadas e esteiras rolantes',
	},
	{
		value: '4329104',
		label:
			'4329-1/04 - Montagem e instalação de sistemas e equipamentos de iluminação e sinalização em vias públicas, portos e aeroportos',
	},
	{
		value: '4329105',
		label: '4329-1/05 - Tratamentos térmicos, acústicos ou de vibração',
	},
	{
		value: '4329199',
		label:
			'4329-1/99 - Outras obras de instalações em construções não especificadas anteriormente',
	},
	{
		value: '4330401',
		label: '4330-4/01 - Impermeabilização em obras de engenharia civil',
	},
	{
		value: '4330402',
		label:
			'4330-4/02 - Instalação de portas, janelas, tetos, divisórias e armários embutidos de qualquer material',
	},
	{
		value: '4330403',
		label: '4330-4/03 - Obras de acabamento em gesso e estuque',
	},
	{
		value: '4330404',
		label: '4330-4/04 - Serviços de pintura de edifícios em geral',
	},
	{
		value: '4330405',
		label:
			'4330-4/05 - Aplicação de revestimentos e de resinas em interiores e exteriores',
	},
	{
		value: '4330499',
		label: '4330-4/99 - Outras obras de acabamento da construção',
	},
	{ value: '4391600', label: '4391-6/00 - Obras de fundações' },
	{ value: '4399101', label: '4399-1/01 - Administração de obras' },
	{
		value: '4399102',
		label:
			'4399-1/02 - Montagem e desmontagem de andaimes e outras estruturas temporárias',
	},
	{ value: '4399103', label: '4399-1/03 - Obras de alvenaria' },
	{
		value: '4399104',
		label:
			'4399-1/04 - Serviços de operação e fornecimento de equipamentos para transporte e elevação de cargas e pessoas para uso em obras',
	},
	{
		value: '4399105',
		label: '4399-1/05 - Perfuração e construção de poços de água',
	},
	{
		value: '4399199',
		label:
			'4399-1/99 - Serviços especializados para construção não especificados anteriormente',
	},
	{
		value: '4511101',
		label:
			'4511-1/01 - Comércio a varejo de automóveis, camionetas e utilitários novos',
	},
	{
		value: '4511102',
		label:
			'4511-1/02 - Comércio a varejo de automóveis, camionetas e utilitários usados',
	},
	{
		value: '4511103',
		label:
			'4511-1/03 - Comércio por atacado de automóveis, camionetas e utilitários novos e usados',
	},
	{
		value: '4511104',
		label: '4511-1/04 - Comércio por atacado de caminhões novos e usados',
	},
	{
		value: '4511105',
		label:
			'4511-1/05 - Comércio por atacado de reboques e semireboques novos e usados',
	},
	{
		value: '4511106',
		label:
			'4511-1/06 - Comércio por atacado de ônibus e micro-ônibus novos e usados',
	},
	{
		value: '4512901',
		label:
			'4512-9/01 - Representantes comerciais e agentes do comércio de veículos automotores',
	},
	{
		value: '4512902',
		label: '4512-9/02 - Comércio sob consignação de veículos automotores',
	},
	{
		value: '4520001',
		label:
			'4520-0/01 - Serviços de manutenção e reparação mecânica de veículos automotores',
	},
	{
		value: '4520002',
		label:
			'4520-0/02 - Serviços de lanternagem ou funilaria e pintura de veículos automotores',
	},
	{
		value: '4520003',
		label:
			'4520-0/03 - Serviços de manutenção e reparação elétrica de veículos automotores',
	},
	{
		value: '4520004',
		label:
			'4520-0/04 - Serviços de alinhamento e balanceamento de veículos automotores',
	},
	{
		value: '4520005',
		label:
			'4520-0/05 - Serviços de lavagem, lubrificação e polimento de veículos automotores',
	},
	{
		value: '4520006',
		label: '4520-0/06 - Serviços de borracharia para veículos automotores',
	},
	{
		value: '4520007',
		label:
			'4520-0/07 - Serviços de instalação, manutenção e reparação de acessórios para veículos automotores',
	},
	{ value: '4520008', label: '4520-0/08 - Serviços de capotaria' },
	{
		value: '4530701',
		label:
			'4530-7/01 - Comércio por atacado de peças e acessórios novos para veículos automotores',
	},
	{
		value: '4530702',
		label: '4530-7/02 - Comércio por atacado de pneumáticos e câmaras-de-ar',
	},
	{
		value: '4530703',
		label:
			'4530-7/03 - Comércio a varejo de peças e acessórios novos para veículos automotores',
	},
	{
		value: '4530704',
		label:
			'4530-7/04 - Comércio a varejo de peças e acessórios usados para veículos automotores',
	},
	{
		value: '4530705',
		label: '4530-7/05 - Comércio a varejo de pneumáticos e câmaras-de-ar',
	},
	{
		value: '4530706',
		label:
			'4530-7/06 - Representantes comerciais e agentes do comércio de peças e acessórios novos e usados para veículos automotores',
	},
	{
		value: '4541201',
		label: '4541-2/01 - Comércio por atacado de motocicletas e motonetas',
	},
	{
		value: '4541202',
		label:
			'4541-2/02 - Comércio por atacado de peças e acessórios para motocicletas e motonetas',
	},
	{
		value: '4541203',
		label: '4541-2/03 - Comércio a varejo de motocicletas e motonetas novas',
	},
	{
		value: '4541204',
		label: '4541-2/04 - Comércio a varejo de motocicletas e motonetas usadas',
	},
	{
		value: '4541206',
		label:
			'4541-2/06 - Comércio a varejo de peças e acessórios novos para motocicletas e motonetas',
	},
	{
		value: '4541207',
		label:
			'4541-2/07 - Comércio a varejo de peças e acessórios usados para motocicletas e motonetas',
	},
	{
		value: '4542101',
		label:
			'4542-1/01 - Representantes comerciais e agentes do comércio de motocicletas e motonetas, peças e acessórios',
	},
	{
		value: '4542102',
		label: '4542-1/02 - Comércio sob consignação de motocicletas e motonetas',
	},
	{
		value: '4543900',
		label: '4543-9/00 - Manutenção e reparação de motocicletas e motonetas',
	},
	{
		value: '4611700',
		label:
			'4611-7/00 - Representantes comerciais e agentes do comércio de matérias-primas agrícolas e animais vivos',
	},
	{
		value: '4612500',
		label:
			'4612-5/00 - Representantes comerciais e agentes do comércio de combustíveis, minerais, produtos siderúrgicos e químicos',
	},
	{
		value: '4613300',
		label:
			'4613-3/00 - Representantes comerciais e agentes do comércio de madeira, material de construção e ferragens',
	},
	{
		value: '4614100',
		label:
			'4614-1/00 - Representantes comerciais e agentes do comércio de máquinas, equipamentos, embarcações e aeronaves',
	},
	{
		value: '4615000',
		label:
			'4615-0/00 - Representantes comerciais e agentes do comércio de eletrodomésticos, móveis e artigos de uso doméstico',
	},
	{
		value: '4616800',
		label:
			'4616-8/00 - Representantes comerciais e agentes do comércio de têxteis, vestuário, calçados e artigos de viagem',
	},
	{
		value: '4617600',
		label:
			'4617-6/00 - Representantes comerciais e agentes do comércio de produtos alimentícios, bebidas e fumo',
	},
	{
		value: '4618401',
		label:
			'4618-4/01 - Representantes comerciais e agentes do comércio de medicamentos, cosméticos e produtos de perfumaria',
	},
	{
		value: '4618402',
		label:
			'4618-4/02 - Representantes comerciais e agentes do comércio de instrumentos e materiais odonto-médico-hospitalares',
	},
	{
		value: '4618403',
		label:
			'4618-4/03 - Representantes comerciais e agentes do comércio de jornais, revistas e outras publicações',
	},
	{
		value: '4618499',
		label:
			'4618-4/99 - Outros representantes comerciais e agentes do comércio especializado em produtos não especificados anteriormente',
	},
	{
		value: '4619200',
		label:
			'4619-2/00 - Representantes comerciais e agentes do comércio de mercadorias em geral não especializado',
	},
	{
		value: '4621400',
		label: '4621-4/00 - Comércio atacadista de café em grão',
	},
	{ value: '4622200', label: '4622-2/00 - Comércio atacadista de soja' },
	{
		value: '4623101',
		label: '4623-1/01 - Comércio atacadista de animais vivos',
	},
	{
		value: '4623102',
		label:
			'4623-1/02 - Comércio atacadista de couros, lãs, peles e outros subprodutos não comestíveis de origem animal',
	},
	{ value: '4623103', label: '4623-1/03 - Comércio atacadista de algodão' },
	{
		value: '4623104',
		label: '4623-1/04 - Comércio atacadista de fumo em folha não beneficiado',
	},
	{ value: '4623105', label: '4623-1/05 - Comércio atacadista de cacau' },
	{
		value: '4623106',
		label:
			'4623-1/06 - Comércio atacadista de sementes, flores, plantas e gramas',
	},
	{ value: '4623107', label: '4623-1/07 - Comércio atacadista de sisal' },
	{
		value: '4623108',
		label:
			'4623-1/08 - Comércio atacadista de matérias-primas agrícolas com atividade de fracionamento e acondicionamento associada',
	},
	{
		value: '4623109',
		label: '4623-1/09 - Comércio atacadista de alimentos para animais',
	},
	{
		value: '4623199',
		label:
			'4623-1/99 - Comércio atacadista de matérias-primas agrícolas não especificadas anteriormente',
	},
	{
		value: '4631100',
		label: '4631-1/00 - Comércio atacadista de leite e laticínios',
	},
	{
		value: '4632001',
		label:
			'4632-0/01 - Comércio atacadista de cereais e leguminosas beneficiados',
	},
	{
		value: '4632002',
		label: '4632-0/02 - Comércio atacadista de farinhas, amidos e féculas',
	},
	{
		value: '4632003',
		label:
			'4632-0/03 - Comércio atacadista de cereais e leguminosas beneficiados, farinhas, amidos e féculas, com atividade de fracionamento e acondicionamento associada',
	},
	{
		value: '4633801',
		label:
			'4633-8/01 - Comércio atacadista de frutas, verduras, raízes, tubérculos, hortaliças e legumes frescos',
	},
	{
		value: '4633802',
		label: '4633-8/02 - Comércio atacadista de aves vivas e ovos',
	},
	{
		value: '4633803',
		label:
			'4633-8/03 - Comércio atacadista de coelhos e outros pequenos animais vivos para alimentação',
	},
	{
		value: '4634601',
		label:
			'4634-6/01 - Comércio atacadista de carnes bovinas e suínas e derivados',
	},
	{
		value: '4634602',
		label: '4634-6/02 - Comércio atacadista de aves abatidas e derivados',
	},
	{
		value: '4634603',
		label: '4634-6/03 - Comércio atacadista de pescados e frutos do mar',
	},
	{
		value: '4634699',
		label:
			'4634-6/99 - Comércio atacadista de carnes e derivados de outros animais',
	},
	{
		value: '4635401',
		label: '4635-4/01 - Comércio atacadista de água mineral',
	},
	{
		value: '4635402',
		label: '4635-4/02 - Comércio atacadista de cerveja, chope e refrigerante',
	},
	{
		value: '4635403',
		label:
			'4635-4/03 - Comércio atacadista de bebidas com atividade de fracionamento e acondicionamento associada',
	},
	{
		value: '4635499',
		label:
			'4635-4/99 - Comércio atacadista de bebidas não especificadas anteriormente',
	},
	{
		value: '4636201',
		label: '4636-2/01 - Comércio atacadista de fumo beneficiado',
	},
	{
		value: '4636202',
		label:
			'4636-2/02 - Comércio atacadista de cigarros, cigarrilhas e charutos',
	},
	{
		value: '4637101',
		label: '4637-1/01 - Comércio atacadista de café torrado, moído e solúvel',
	},
	{ value: '4637102', label: '4637-1/02 - Comércio atacadista de açúcar' },
	{
		value: '4637103',
		label: '4637-1/03 - Comércio atacadista de óleos e gorduras',
	},
	{
		value: '4637104',
		label:
			'4637-1/04 - Comércio atacadista de pães, bolos, biscoitos e similares',
	},
	{
		value: '4637105',
		label: '4637-1/05 - Comércio atacadista de massas alimentícias',
	},
	{
		value: '4637106',
		label: '4637-1/06 - Comércio atacadista de sorvetes',
	},
	{
		value: '4637107',
		label:
			'4637-1/07 - Comércio atacadista de chocolates, confeitos, balas, bombons e semelhantes',
	},
	{
		value: '4637199',
		label:
			'4637-1/99 - Comércio atacadista especializado em outros produtos alimentícios não especificados anteriormente',
	},
	{
		value: '4639701',
		label: '4639-7/01 - Comércio atacadista de produtos alimentícios em geral',
	},
	{
		value: '4639702',
		label:
			'4639-7/02 - Comércio atacadista de produtos alimentícios em geral, com atividade de fracionamento e acondicionamento associada',
	},
	{ value: '4641901', label: '4641-9/01 - Comércio atacadista de tecidos' },
	{
		value: '4641902',
		label: '4641-9/02 - Comércio atacadista de artigos de cama, mesa e banho',
	},
	{
		value: '4641903',
		label: '4641-9/03 - Comércio atacadista de artigos de armarinho',
	},
	{
		value: '4642701',
		label:
			'4642-7/01 - Comércio atacadista de artigos do vestuário e acessórios, exceto profissionais e de segurança',
	},
	{
		value: '4642702',
		label:
			'4642-7/02 - Comércio atacadista de roupas e acessórios para uso profissional e de segurança do trabalho',
	},
	{
		value: '4643501',
		label: '4643-5/01 - Comércio atacadista de calçados',
	},
	{
		value: '4643502',
		label:
			'4643-5/02 - Comércio atacadista de bolsas, malas e artigos de viagem',
	},
	{
		value: '4644301',
		label:
			'4644-3/01 - Comércio atacadista de medicamentos e drogas de uso humano',
	},
	{
		value: '4644302',
		label:
			'4644-3/02 - Comércio atacadista de medicamentos e drogas de uso veterinário',
	},
	{
		value: '4645101',
		label:
			'4645-1/01 - Comércio atacadista de instrumentos e materiais para uso médico, cirúrgico, hospitalar e de laboratórios',
	},
	{
		value: '4645102',
		label: '4645-1/02 - Comércio atacadista de próteses e artigos de ortopedia',
	},
	{
		value: '4645103',
		label: '4645-1/03 - Comércio atacadista de produtos odontológicos',
	},
	{
		value: '4646001',
		label:
			'4646-0/01 - Comércio atacadista de cosméticos e produtos de perfumaria',
	},
	{
		value: '4646002',
		label: '4646-0/02 - Comércio atacadista de produtos de higiene pessoal',
	},
	{
		value: '4647801',
		label:
			'4647-8/01 - Comércio atacadista de artigos de escritório e de papelaria',
	},
	{
		value: '4647802',
		label:
			'4647-8/02 - Comércio atacadista de livros, jornais e outras publicações',
	},
	{
		value: '4649401',
		label:
			'4649-4/01 - Comércio atacadista de equipamentos elétricos de uso pessoal e doméstico',
	},
	{
		value: '4649402',
		label:
			'4649-4/02 - Comércio atacadista de aparelhos eletrônicos de uso pessoal e doméstico',
	},
	{
		value: '4649403',
		label:
			'4649-4/03 - Comércio atacadista de bicicletas, triciclos e outros veículos recreativos',
	},
	{
		value: '4649404',
		label: '4649-4/04 - Comércio atacadista de móveis e artigos de colchoaria',
	},
	{
		value: '4649405',
		label:
			'4649-4/05 - Comércio atacadista de artigos de tapeçaria, persianas e cortinas',
	},
	{
		value: '4649406',
		label: '4649-4/06 - Comércio atacadista de lustres, luminárias e abajures',
	},
	{
		value: '4649407',
		label:
			'4649-4/07 - Comércio atacadista de filmes, CDs, DVDs, fitas e discos',
	},
	{
		value: '4649408',
		label:
			'4649-4/08 - Comércio atacadista de produtos de higiene, limpeza e conservação domiciliar',
	},
	{
		value: '4649409',
		label:
			'4649-4/09 - Comércio atacadista de produtos de higiene, limpeza e conservação domiciliar, com atividade de fracionamento e acondicionamento associada',
	},
	{
		value: '4649410',
		label:
			'4649-4/10 - Comércio atacadista de jóias, relógios e bijuterias, inclusive pedras preciosas e semipreciosas lapidadas',
	},
	{
		value: '4649499',
		label:
			'4649-4/99 - Comércio atacadista de outros equipamentos e artigos de uso pessoal e doméstico não especificados anteriormente',
	},
	{
		value: '4651601',
		label: '4651-6/01 - Comércio atacadista de equipamentos de informática',
	},
	{
		value: '4651602',
		label: '4651-6/02 - Comércio atacadista de suprimentos para informática',
	},
	{
		value: '4652400',
		label:
			'4652-4/00 - Comércio atacadista de componentes eletrônicos e equipamentos de telefonia e comunicação',
	},
	{
		value: '4661300',
		label:
			'4661-3/00 - Comércio atacadista de máquinas, aparelhos e equipamentos para uso agropecuário, partes e peças',
	},
	{
		value: '4662100',
		label:
			'4662-1/00 - Comércio atacadista de máquinas, equipamentos para terraplenagem, mineração e construção, partes e peças',
	},
	{
		value: '4663000',
		label:
			'4663-0/00 - Comércio atacadista de máquinas e equipamentos para uso industrial, partes e peças',
	},
	{
		value: '4664800',
		label:
			'4664-8/00 - Comércio atacadista de máquinas, aparelhos e equipamentos para uso odonto-médico-hospitalar, partes e peças',
	},
	{
		value: '4665600',
		label:
			'4665-6/00 - Comércio atacadista de máquinas e equipamentos para uso comercial, partes e peças',
	},
	{
		value: '4669901',
		label:
			'4669-9/01 - Comércio atacadista de bombas e compressores, partes e peças',
	},
	{
		value: '4669999',
		label:
			'4669-9/99 - Comércio atacadista de outras máquinas e equipamentos não especificados anteriormente, partes e peças',
	},
	{
		value: '4671100',
		label: '4671-1/00 - Comércio atacadista de madeira e produtos derivados',
	},
	{
		value: '4672900',
		label: '4672-9/00 - Comércio atacadista de ferragens e ferramentas',
	},
	{
		value: '4673700',
		label: '4673-7/00 - Comércio atacadista de material elétrico',
	},
	{ value: '4674500', label: '4674-5/00 - Comércio atacadista de cimento' },
	{
		value: '4679601',
		label: '4679-6/01 - Comércio atacadista de tintas, vernizes e similares',
	},
	{
		value: '4679602',
		label: '4679-6/02 - Comércio atacadista de mármores e granitos',
	},
	{
		value: '4679603',
		label: '4679-6/03 - Comércio atacadista de vidros, espelhos e vitrais',
	},
	{
		value: '4679604',
		label:
			'4679-6/04 - Comércio atacadista especializado de materiais de construção não especificados anteriormente',
	},
	{
		value: '4679699',
		label:
			'4679-6/99 - Comércio atacadista de materiais de construção em geral',
	},
	{
		value: '4681801',
		label:
			'4681-8/01 - Comércio atacadista de álcool carburante, biodiesel, gasolina e demais derivados de petróleo, exceto lubrificantes, não realizado por transportador retalhista (TRR)',
	},
	{
		value: '4681802',
		label:
			'4681-8/02 - Comércio atacadista de combustíveis realizado por transportador retalhista (TRR)',
	},
	{
		value: '4681803',
		label:
			'4681-8/03 - Comércio atacadista de combustíveis de origem vegetal, exceto álcool carburante',
	},
	{
		value: '4681804',
		label:
			'4681-8/04 - Comércio atacadista de combustíveis de origem mineral em bruto',
	},
	{
		value: '4681805',
		label: '4681-8/05 - Comércio atacadista de lubrificantes',
	},
	{
		value: '4682600',
		label:
			'4682-6/00 - Comércio atacadista de gás liquefeito de petróleo (GLP)',
	},
	{
		value: '4683400',
		label:
			'4683-4/00 - Comércio atacadista de defensivos agrícolas, adubos, fertilizantes e corretivos do solo',
	},
	{
		value: '4684201',
		label: '4684-2/01 - Comércio atacadista de resinas e elastômeros',
	},
	{
		value: '4684202',
		label: '4684-2/02 - Comércio atacadista de solventes',
	},
	{
		value: '4684299',
		label:
			'4684-2/99 - Comércio atacadista de outros produtos químicos e petroquímicos não especificados anteriormente',
	},
	{
		value: '4685100',
		label:
			'4685-1/00 - Comércio atacadista de produtos siderúrgicos e metalúrgicos, exceto para construção',
	},
	{
		value: '4686901',
		label: '4686-9/01 - Comércio atacadista de papel e papelão em bruto',
	},
	{
		value: '4686902',
		label: '4686-9/02 - Comércio atacadista de embalagens',
	},
	{
		value: '4687701',
		label: '4687-7/01 - Comércio atacadista de resíduos de papel e papelão',
	},
	{
		value: '4687702',
		label:
			'4687-7/02 - Comércio atacadista de resíduos e sucatas não metálicos, exceto de papel e papelão',
	},
	{
		value: '4687703',
		label: '4687-7/03 - Comércio atacadista de resíduos e sucatas metálicos',
	},
	{
		value: '4689301',
		label:
			'4689-3/01 - Comércio atacadista de produtos da extração mineral, exceto combustíveis',
	},
	{
		value: '4689302',
		label: '4689-3/02 - Comércio atacadista de fios e fibras beneficiados',
	},
	{
		value: '4689399',
		label:
			'4689-3/99 - Comércio atacadista especializado em outros produtos intermediários não especificados anteriormente',
	},
	{
		value: '4691500',
		label:
			'4691-5/00 - Comércio atacadista de mercadorias em geral, com predominância de produtos alimentícios',
	},
	{
		value: '4692300',
		label:
			'4692-3/00 - Comércio atacadista de mercadorias em geral, com predominância de insumos agropecuários',
	},
	{
		value: '4693100',
		label:
			'4693-1/00 - Comércio atacadista de mercadorias em geral, sem predominância de alimentos ou de insumos agropecuários',
	},
	{
		value: '4711301',
		label:
			'4711-3/01 - Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - hipermercados',
	},
	{
		value: '4711302',
		label:
			'4711-3/02 - Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - supermercados',
	},
	{
		value: '4712100',
		label:
			'4712-1/00 - Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - minimercados, mercearias e armazéns',
	},
	{
		value: '4713002',
		label:
			'4713-0/02 - Lojas de variedades, exceto lojas de departamentos ou magazines',
	},
	{
		value: '4713004',
		label:
			'4713-0/04 - Lojas de departamentos ou magazines, exceto lojas francas (Duty free)',
	},
	{
		value: '4713005',
		label:
			'4713-0/05 - Lojas francas (Duty Free) de aeroportos, portos e em fronteiras terrestres',
	},
	{
		value: '4721102',
		label: '4721-1/02 - Padaria e confeitaria com predominância de revenda',
	},
	{
		value: '4721103',
		label: '4721-1/03 - Comércio varejista de laticínios e frios',
	},
	{
		value: '4721104',
		label:
			'4721-1/04 - Comércio varejista de doces, balas, bombons e semelhantes',
	},
	{
		value: '4722901',
		label: '4722-9/01 - Comércio varejista de carnes - açougues',
	},
	{ value: '4722902', label: '4722-9/02 - Peixaria' },
	{ value: '4723700', label: '4723-7/00 - Comércio varejista de bebidas' },
	{
		value: '4724500',
		label: '4724-5/00 - Comércio varejista de hortifrutigranjeiros',
	},
	{ value: '4729601', label: '4729-6/01 - Tabacaria' },
	{
		value: '4729602',
		label:
			'4729-6/02 - Comércio varejista de mercadorias em lojas de conveniência',
	},
	{
		value: '4729699',
		label:
			'4729-6/99 - Comércio varejista de produtos alimentícios em geral ou especializado em produtos alimentícios não especificados anteriormente',
	},
	{
		value: '4731800',
		label:
			'4731-8/00 - Comércio varejista de combustíveis para veículos automotores',
	},
	{
		value: '4732600',
		label: '4732-6/00 - Comércio varejista de lubrificantes',
	},
	{
		value: '4741500',
		label: '4741-5/00 - Comércio varejista de tintas e materiais para pintura',
	},
	{
		value: '4742300',
		label: '4742-3/00 - Comércio varejista de material elétrico',
	},
	{ value: '4743100', label: '4743-1/00 - Comércio varejista de vidros' },
	{
		value: '4744001',
		label: '4744-0/01 - Comércio varejista de ferragens e ferramentas',
	},
	{
		value: '4744002',
		label: '4744-0/02 - Comércio varejista de madeira e artefatos',
	},
	{
		value: '4744003',
		label: '4744-0/03 - Comércio varejista de materiais hidráulicos',
	},
	{
		value: '4744004',
		label:
			'4744-0/04 - Comércio varejista de cal, areia, pedra britada, tijolos e telhas',
	},
	{
		value: '4744005',
		label:
			'4744-0/05 - Comércio varejista de materiais de construção não especificados anteriormente',
	},
	{
		value: '4744006',
		label: '4744-0/06 - Comércio varejista de pedras para revestimento',
	},
	{
		value: '4744099',
		label: '4744-0/99 - Comércio varejista de materiais de construção em geral',
	},
	{
		value: '4751201',
		label:
			'4751-2/01 - Comércio varejista especializado de equipamentos e suprimentos de informática',
	},
	{
		value: '4751202',
		label: '4751-2/02 - Recarga de cartuchos para equipamentos de informática',
	},
	{
		value: '4752100',
		label:
			'4752-1/00 - Comércio varejista especializado de equipamentos de telefonia e comunicação',
	},
	{
		value: '4753900',
		label:
			'4753-9/00 - Comércio varejista especializado de eletrodomésticos e equipamentos de áudio e vídeo',
	},
	{ value: '4754701', label: '4754-7/01 - Comércio varejista de móveis' },
	{
		value: '4754702',
		label: '4754-7/02 - Comércio varejista de artigos de colchoaria',
	},
	{
		value: '4754703',
		label: '4754-7/03 - Comércio varejista de artigos de iluminação',
	},
	{ value: '4755501', label: '4755-5/01 - Comércio varejista de tecidos' },
	{
		value: '4755502',
		label: '4755-5/02 - Comercio varejista de artigos de armarinho',
	},
	{
		value: '4755503',
		label: '4755-5/03 - Comercio varejista de artigos de cama, mesa e banho',
	},
	{
		value: '4756300',
		label:
			'4756-3/00 - Comércio varejista especializado de instrumentos musicais e acessórios',
	},
	{
		value: '4757100',
		label:
			'4757-1/00 - Comércio varejista especializado de peças e acessórios para aparelhos eletroeletrônicos para uso doméstico, exceto informática e comunicação',
	},
	{
		value: '4759801',
		label:
			'4759-8/01 - Comércio varejista de artigos de tapeçaria, cortinas e persianas',
	},
	{
		value: '4759899',
		label:
			'4759-8/99 - Comércio varejista de outros artigos de uso doméstico não especificados anteriormente',
	},
	{ value: '4761001', label: '4761-0/01 - Comércio varejista de livros' },
	{
		value: '4761002',
		label: '4761-0/02 - Comércio varejista de jornais e revistas',
	},
	{
		value: '4761003',
		label: '4761-0/03 - Comércio varejista de artigos de papelaria',
	},
	{
		value: '4762800',
		label: '4762-8/00 - Comércio varejista de discos, CDs, DVDs e fitas',
	},
	{
		value: '4763601',
		label: '4763-6/01 - Comércio varejista de brinquedos e artigos recreativos',
	},
	{
		value: '4763602',
		label: '4763-6/02 - Comércio varejista de artigos esportivos',
	},
	{
		value: '4763603',
		label:
			'4763-6/03 - Comércio varejista de bicicletas e triciclos, peças e acessórios',
	},
	{
		value: '4763604',
		label: '4763-6/04 - Comércio varejista de artigos de caça, pesca e camping',
	},
	{
		value: '4763605',
		label:
			'4763-6/05 - Comércio varejista de embarcações e outros veículos recreativos, peças e acessórios',
	},
	{
		value: '4771701',
		label:
			'4771-7/01 - Comércio varejista de produtos farmacêuticos, sem manipulação de fórmulas',
	},
	{
		value: '4771702',
		label:
			'4771-7/02 - Comércio varejista de produtos farmacêuticos, com manipulação de fórmulas',
	},
	{
		value: '4771703',
		label:
			'4771-7/03 - Comércio varejista de produtos farmacêuticos homeopáticos',
	},
	{
		value: '4771704',
		label: '4771-7/04 - Comércio varejista de medicamentos veterinários',
	},
	{
		value: '4772500',
		label:
			'4772-5/00 - Comércio varejista de cosméticos, produtos de perfumaria e de higiene pessoal',
	},
	{
		value: '4773300',
		label: '4773-3/00 - Comércio varejista de artigos médicos e ortopédicos',
	},
	{
		value: '4774100',
		label: '4774-1/00 - Comércio varejista de artigos de óptica',
	},
	{
		value: '4781400',
		label:
			'4781-4/00 - Comércio varejista de artigos do vestuário e acessórios',
	},
	{ value: '4782201', label: '4782-2/01 - Comércio varejista de calçados' },
	{
		value: '4782202',
		label: '4782-2/02 - Comércio varejista de artigos de viagem',
	},
	{
		value: '4783101',
		label: '4783-1/01 - Comércio varejista de artigos de joalheria',
	},
	{
		value: '4783102',
		label: '4783-1/02 - Comércio varejista de artigos de relojoaria',
	},
	{
		value: '4784900',
		label: '4784-9/00 - Comércio varejista de gás liqüefeito de petróleo (GLP)',
	},
	{
		value: '4785701',
		label: '4785-7/01 - Comércio varejista de antiguidades',
	},
	{
		value: '4785799',
		label: '4785-7/99 - Comércio varejista de outros artigos usados',
	},
	{
		value: '4789001',
		label:
			'4789-0/01 - Comércio varejista de suvenires, bijuterias e artesanatos',
	},
	{
		value: '4789002',
		label: '4789-0/02 - Comércio varejista de plantas e flores naturais',
	},
	{
		value: '4789003',
		label: '4789-0/03 - Comércio varejista de objetos de arte',
	},
	{
		value: '4789004',
		label:
			'4789-0/04 - Comércio varejista de animais vivos e de artigos e alimentos para animais de estimação',
	},
	{
		value: '4789005',
		label:
			'4789-0/05 - Comércio varejista de produtos saneantes domissanitários',
	},
	{
		value: '4789006',
		label:
			'4789-0/06 - Comércio varejista de fogos de artifício e artigos pirotécnicos',
	},
	{
		value: '4789007',
		label: '4789-0/07 - Comércio varejista de equipamentos para escritório',
	},
	{
		value: '4789008',
		label:
			'4789-0/08 - Comércio varejista de artigos fotográficos e para filmagem',
	},
	{
		value: '4789009',
		label: '4789-0/09 - Comércio varejista de armas e munições',
	},
	{
		value: '4789099',
		label:
			'4789-0/99 - Comércio varejista de outros produtos não especificados anteriormente',
	},
	{
		value: '4911600',
		label: '4911-6/00 - Transporte ferroviário de carga',
	},
	{
		value: '4912401',
		label:
			'4912-4/01 - Transporte ferroviário de passageiros intermunicipal e interestadual',
	},
	{
		value: '4912402',
		label:
			'4912-4/02 - Transporte ferroviário de passageiros municipal e em região metropolitana',
	},
	{ value: '4912403', label: '4912-4/03 - Transporte metroviário' },
	{
		value: '4921301',
		label:
			'4921-3/01 - Transporte rodoviário coletivo de passageiros, com itinerário fixo, municipal',
	},
	{
		value: '4921302',
		label:
			'4921-3/02 - Transporte rodoviário coletivo de passageiros, com itinerário fixo, intermunicipal em região metropolitana',
	},
	{
		value: '4922101',
		label:
			'4922-1/01 - Transporte rodoviário coletivo de passageiros, com itinerário fixo, intermunicipal, exceto em região metropolitana',
	},
	{
		value: '4922102',
		label:
			'4922-1/02 - Transporte rodoviário coletivo de passageiros, com itinerário fixo, interestadual',
	},
	{
		value: '4922103',
		label:
			'4922-1/03 - Transporte rodoviário coletivo de passageiros, com itinerário fixo, internacional',
	},
	{ value: '4923001', label: '4923-0/01 - Serviço de táxi' },
	{
		value: '4923002',
		label:
			'4923-0/02 - Serviço de transporte de passageiros - locação de automóveis com motorista',
	},
	{ value: '4924800', label: '4924-8/00 - Transporte escolar' },
	{
		value: '4929901',
		label:
			'4929-9/01 - Transporte rodoviário coletivo de passageiros, sob regime de fretamento, municipal',
	},
	{
		value: '4929902',
		label:
			'4929-9/02 - Transporte rodoviário coletivo de passageiros, sob regime de fretamento, intermunicipal, interestadual e internacional',
	},
	{
		value: '4929903',
		label:
			'4929-9/03 - Organização de excursões em veículos rodoviários próprios, municipal',
	},
	{
		value: '4929904',
		label:
			'4929-9/04 - Organização de excursões em veículos rodoviários próprios, intermunicipal, interestadual e internacional',
	},
	{
		value: '4929999',
		label:
			'4929-9/99 - Outros transportes rodoviários de passageiros não especificados anteriormente',
	},
	{
		value: '4930201',
		label:
			'4930-2/01 - Transporte rodoviário de carga, exceto produtos perigosos e mudanças, municipal',
	},
	{
		value: '4930202',
		label:
			'4930-2/02 - Transporte rodoviário de carga, exceto produtos perigosos e mudanças, intermunicipal, interestadual e internacional',
	},
	{
		value: '4930203',
		label: '4930-2/03 - Transporte rodoviário de produtos perigosos',
	},
	{
		value: '4930204',
		label: '4930-2/04 - Transporte rodoviário de mudanças',
	},
	{ value: '4940000', label: '4940-0/00 - Transporte dutoviário' },
	{
		value: '4950700',
		label: '4950-7/00 - Trens turísticos, teleféricos e similares',
	},
	{
		value: '5011401',
		label: '5011-4/01 - Transporte marítimo de cabotagem - Carga',
	},
	{
		value: '5011402',
		label: '5011-4/02 - Transporte marítimo de cabotagem - Passageiros',
	},
	{
		value: '5012201',
		label: '5012-2/01 - Transporte marítimo de longo curso - Carga',
	},
	{
		value: '5012202',
		label: '5012-2/02 - Transporte marítimo de longo curso - Passageiros',
	},
	{
		value: '5021101',
		label:
			'5021-1/01 - Transporte por navegação interior de carga, municipal, exceto travessia',
	},
	{
		value: '5021102',
		label:
			'5021-1/02 - Transporte por navegação interior de carga, intermunicipal, interestadual e internacional, exceto travessia',
	},
	{
		value: '5022001',
		label:
			'5022-0/01 - Transporte por navegação interior de passageiros em linhas regulares, municipal, exceto travessia',
	},
	{
		value: '5022002',
		label:
			'5022-0/02 - Transporte por navegação interior de passageiros em linhas regulares, intermunicipal, interestadual e internacional, exceto travessia',
	},
	{ value: '5030101', label: '5030-1/01 - Navegação de apoio marítimo' },
	{ value: '5030102', label: '5030-1/02 - Navegação de apoio portuário' },
	{
		value: '5030103',
		label: '5030-1/03 - Serviço de rebocadores e empurradores',
	},
	{
		value: '5091201',
		label: '5091-2/01 - Transporte por navegação de travessia, municipal',
	},
	{
		value: '5091202',
		label:
			'5091-2/02 - Transporte por navegação de travessia, intermunicipal, interestadual e internacional',
	},
	{
		value: '5099801',
		label: '5099-8/01 - Transporte aquaviário para passeios turísticos',
	},
	{
		value: '5099899',
		label:
			'5099-8/99 - Outros transportes aquaviários não especificados anteriormente',
	},
	{
		value: '5111100',
		label: '5111-1/00 - Transporte aéreo de passageiros regular',
	},
	{
		value: '5112901',
		label:
			'5112-9/01 - Serviço de táxi aéreo e locação de aeronaves com tripulação',
	},
	{
		value: '5112999',
		label:
			'5112-9/99 - Outros serviços de transporte aéreo de passageiros não regular',
	},
	{ value: '5120000', label: '5120-0/00 - Transporte aéreo de carga' },
	{ value: '5130700', label: '5130-7/00 - Transporte espacial' },
	{
		value: '5211701',
		label: '5211-7/01 - Armazéns gerais - emissão de warrant',
	},
	{ value: '5211702', label: '5211-7/02 - Guarda-móveis' },
	{
		value: '5211799',
		label:
			'5211-7/99 - Depósitos de mercadorias para terceiros, exceto armazéns gerais e guarda-móveis',
	},
	{ value: '5212500', label: '5212-5/00 - Carga e descarga' },
	{
		value: '5221400',
		label:
			'5221-4/00 - Concessionárias de rodovias, pontes, túneis e serviços relacionados',
	},
	{
		value: '5222200',
		label: '5222-2/00 - Terminais rodoviários e ferroviários',
	},
	{ value: '5223100', label: '5223-1/00 - Estacionamento de veículos' },
	{
		value: '5229001',
		label:
			'5229-0/01 - Serviços de apoio ao transporte por táxi, inclusive centrais de chamada',
	},
	{
		value: '5229002',
		label: '5229-0/02 - Serviços de reboque de veículos',
	},
	{
		value: '5229099',
		label:
			'5229-0/99 - Outras atividades auxiliares dos transportes terrestres não especificadas anteriormente',
	},
	{
		value: '5231101',
		label: '5231-1/01 - Administração da infraestrutura portuária',
	},
	{
		value: '5231102',
		label: '5231-1/02 - Atividades do Operador Portuário',
	},
	{
		value: '5231103',
		label: '5231-1/03 - Gestão de terminais aquaviários',
	},
	{
		value: '5232000',
		label: '5232-0/00 - Atividades de agenciamento marítimo',
	},
	{ value: '5239701', label: '5239-7/01 - Serviços de praticagem' },
	{
		value: '5239799',
		label:
			'5239-7/99 - Atividades auxiliares dos transportes aquaviários não especificadas anteriormente',
	},
	{
		value: '5240101',
		label: '5240-1/01 - Operação dos aeroportos e campos de aterrissagem',
	},
	{
		value: '5240199',
		label:
			'5240-1/99 - Atividades auxiliares dos transportes aéreos, exceto operação dos aeroportos e campos de aterrissagem',
	},
	{ value: '5250801', label: '5250-8/01 - Comissaria de despachos' },
	{
		value: '5250802',
		label: '5250-8/02 - Atividades de despachantes aduaneiros',
	},
	{
		value: '5250803',
		label:
			'5250-8/03 - Agenciamento de cargas, exceto para o transporte marítimo',
	},
	{
		value: '5250804',
		label: '5250-8/04 - Organização logística do transporte de carga',
	},
	{
		value: '5250805',
		label: '5250-8/05 - Operador de transporte multimodal - OTM',
	},
	{ value: '5310501', label: '5310-5/01 - Atividades do Correio Nacional' },
	{
		value: '5310502',
		label:
			'5310-5/02 - Atividades de franqueadas e permissionárias do Correio Nacional',
	},
	{
		value: '5320201',
		label:
			'5320-2/01 - Serviços de malote não realizados pelo Correio Nacional',
	},
	{ value: '5320202', label: '5320-2/02 - Serviços de entrega rápida' },
	{ value: '5510801', label: '5510-8/01 - Hotéis' },
	{ value: '5510802', label: '5510-8/02 - Apart-hotéis' },
	{ value: '5510803', label: '5510-8/03 - Motéis' },
	{
		value: '5590601',
		label: '5590-6/01 - Albergues, exceto assistenciais',
	},
	{ value: '5590602', label: '5590-6/02 - Campings' },
	{ value: '5590603', label: '5590-6/03 - Pensões (alojamento)' },
	{
		value: '5590699',
		label: '5590-6/99 - Outros alojamentos não especificados anteriormente',
	},
	{ value: '5611201', label: '5611-2/01 - Restaurantes e similares' },
	{
		value: '5611203',
		label: '5611-2/03 - Lanchonetes, casas de chá, de sucos e similares',
	},
	{
		value: '5611204',
		label:
			'5611-2/04 - Bares e outros estabelecimentos especializados em servir bebidas, sem entretenimento',
	},
	{
		value: '5611205',
		label:
			'5611-2/05 - Bares e outros estabelecimentos especializados em servir bebidas, com entretenimento',
	},
	{
		value: '5612100',
		label: '5612-1/00 - Serviços ambulantes de alimentação',
	},
	{
		value: '5620101',
		label:
			'5620-1/01 - Fornecimento de alimentos preparados preponderantemente para empresas',
	},
	{
		value: '5620102',
		label:
			'5620-1/02 - Serviços de alimentação para eventos e recepções - bufê',
	},
	{
		value: '5620103',
		label: '5620-1/03 - Cantinas - serviços de alimentação privativos',
	},
	{
		value: '5620104',
		label:
			'5620-1/04 - Fornecimento de alimentos preparados preponderantemente para consumo domiciliar',
	},
	{ value: '5811500', label: '5811-5/00 - Edição de livros' },
	{ value: '5812301', label: '5812-3/01 - Edição de jornais diários' },
	{ value: '5812302', label: '5812-3/02 - Edição de jornais não diários' },
	{ value: '5813100', label: '5813-1/00 - Edição de revistas' },
	{
		value: '5819100',
		label: '5819-1/00 - Edição de cadastros, listas e outros produtos gráficos',
	},
	{
		value: '5821200',
		label: '5821-2/00 - Edição integrada à impressão de livros',
	},
	{
		value: '5822101',
		label: '5822-1/01 - Edição integrada à impressão de jornais diários',
	},
	{
		value: '5822102',
		label: '5822-1/02 - Edição integrada à impressão de jornais não diários',
	},
	{
		value: '5823900',
		label: '5823-9/00 - Edição integrada à impressão de revistas',
	},
	{
		value: '5829800',
		label:
			'5829-8/00 - Edição integrada à impressão de cadastros, listas e outros produtos gráficos',
	},
	{ value: '5911101', label: '5911-1/01 - Estúdios cinematográficos' },
	{
		value: '5911102',
		label: '5911-1/02 - Produção de filmes para publicidade',
	},
	{
		value: '5911199',
		label:
			'5911-1/99 - Atividades de produção cinematográfica, de vídeos e de programas de televisão não especificadas anteriormente',
	},
	{ value: '5912001', label: '5912-0/01 - Serviços de dublagem' },
	{
		value: '5912002',
		label: '5912-0/02 - Serviços de mixagem sonora em produção audiovisual',
	},
	{
		value: '5912099',
		label:
			'5912-0/99 - Atividades de pós-produção cinematográfica, de vídeos e de programas de televisão não especificadas anteriormente',
	},
	{
		value: '5913800',
		label:
			'5913-8/00 - Distribuição cinematográfica, de vídeo e de programas de televisão',
	},
	{
		value: '5914600',
		label: '5914-6/00 - Atividades de exibição cinematográfica',
	},
	{
		value: '5920100',
		label: '5920-1/00 - Atividades de gravação de som e de edição de música',
	},
	{ value: '6010100', label: '6010-1/00 - Atividades de rádio' },
	{ value: '6021700', label: '6021-7/00 - Atividades de televisão aberta' },
	{ value: '6022501', label: '6022-5/01 - Programadoras' },
	{
		value: '6022502',
		label:
			'6022-5/02 - Atividades relacionadas à televisão por assinatura, exceto programadoras',
	},
	{
		value: '6110801',
		label: '6110-8/01 - Serviços de telefonia fixa comutada - STFC',
	},
	{
		value: '6110802',
		label:
			'6110-8/02 - Serviços de redes de transporte de telecomunicações - SRTT',
	},
	{
		value: '6110803',
		label: '6110-8/03 - Serviços de comunicação multimídia - SCM',
	},
	{
		value: '6110899',
		label:
			'6110-8/99 - Serviços de telecomunicações por fio não especificados anteriormente',
	},
	{ value: '6120501', label: '6120-5/01 - Telefonia móvel celular' },
	{
		value: '6120502',
		label: '6120-5/02 - Serviço móvel especializado - SME',
	},
	{
		value: '6120599',
		label:
			'6120-5/99 - Serviços de telecomunicações sem fio não especificados anteriormente',
	},
	{ value: '6130200', label: '6130-2/00 - Telecomunicações por satélite' },
	{
		value: '6141800',
		label: '6141-8/00 - Operadoras de televisão por assinatura por cabo',
	},
	{
		value: '6142600',
		label: '6142-6/00 - Operadoras de televisão por assinatura por micro-ondas',
	},
	{
		value: '6143400',
		label: '6143-4/00 - Operadoras de televisão por assinatura por satélite',
	},
	{
		value: '6190601',
		label: '6190-6/01 - Provedores de acesso às redes de comunicações',
	},
	{
		value: '6190602',
		label: '6190-6/02 - Provedores de voz sobre protocolo Internet - VOIP',
	},
	{
		value: '6190699',
		label:
			'6190-6/99 - Outras atividades de telecomunicações não especificadas anteriormente',
	},
	{
		value: '6201501',
		label:
			'6201-5/01 - Desenvolvimento de programas de computador sob encomenda',
	},
	{ value: '6201502', label: '6201-5/02 - Web desing' },
	{
		value: '6202300',
		label:
			'6202-3/00 - Desenvolvimento e licenciamento de programas de computador customizáveis',
	},
	{
		value: '6203100',
		label:
			'6203-1/00 - Desenvolvimento e licenciamento de programas de computador não customizáveis',
	},
	{
		value: '6204000',
		label: '6204-0/00 - Consultoria em tecnologia da informação',
	},
	{
		value: '6209100',
		label:
			'6209-1/00 - Suporte técnico, manutenção e outros serviços em tecnologia da informação',
	},
	{
		value: '6311900',
		label:
			'6311-9/00 - Tratamento de dados, provedores de serviços de aplicação e serviços de hospedagem na Internet',
	},
	{
		value: '6319400',
		label:
			'6319-4/00 - Portais, provedores de conteúdo e outros serviços de informação na Internet',
	},
	{ value: '6391700', label: '6391-7/00 - Agências de notícias' },
	{
		value: '6399200',
		label:
			'6399-2/00 - Outras atividades de prestação de serviços de informação não especificadas anteriormente',
	},
	{ value: '6410700', label: '6410-7/00 - Banco Central' },
	{ value: '6421200', label: '6421-2/00 - Bancos comerciais' },
	{
		value: '6422100',
		label: '6422-1/00 - Bancos múltiplos, com carteira comercial',
	},
	{ value: '6423900', label: '6423-9/00 - Caixas econômicas' },
	{ value: '6424701', label: '6424-7/01 - Bancos cooperativos' },
	{
		value: '6424702',
		label: '6424-7/02 - Cooperativas centrais de crédito',
	},
	{ value: '6424703', label: '6424-7/03 - Cooperativas de crédito mútuo' },
	{ value: '6424704', label: '6424-7/04 - Cooperativas de crédito rural' },
	{
		value: '6431000',
		label: '6431-0/00 - Bancos múltiplos, sem carteira comercial',
	},
	{ value: '6432800', label: '6432-8/00 - Bancos de investimento' },
	{ value: '6433600', label: '6433-6/00 - Bancos de desenvolvimento' },
	{ value: '6434400', label: '6434-4/00 - Agências de fomento' },
	{
		value: '6435201',
		label: '6435-2/01 - Sociedades de crédito imobiliário',
	},
	{
		value: '6435202',
		label: '6435-2/02 - Associações de poupança e empréstimo',
	},
	{ value: '6435203', label: '6435-2/03 - Companhias hipotecárias' },
	{
		value: '6436100',
		label:
			'6436-1/00 - Sociedades de crédito, financiamento e investimento - financeiras',
	},
	{
		value: '6437900',
		label: '6437-9/00 - Sociedades de crédito ao microempreendedor',
	},
	{ value: '6438701', label: '6438-7/01 - Bancos de câmbio' },
	{
		value: '6438799',
		label:
			'6438-7/99 - Outras instituições de intermediação não monetária não especificadas anteriormente',
	},
	{ value: '6440900', label: '6440-9/00 - Arrendamento mercantil' },
	{ value: '6450600', label: '6450-6/00 - Sociedades de capitalização' },
	{
		value: '6461100',
		label: '6461-1/00 - Holdings de instituições financeiras',
	},
	{
		value: '6462000',
		label: '6462-0/00 - Holdings de instituições não financeiras',
	},
	{
		value: '6463800',
		label: '6463-8/00 - Outras sociedades de participação, exceto holdings',
	},
	{
		value: '6470101',
		label:
			'6470-1/01 - Fundos de investimento, exceto previdenciários e imobiliários',
	},
	{
		value: '6470102',
		label: '6470-1/02 - Fundos de investimento previdenciários',
	},
	{
		value: '6470103',
		label: '6470-1/03 - Fundos de investimento imobiliários',
	},
	{
		value: '6491300',
		label: '6491-3/00 - Sociedades de fomento mercantil - factoring',
	},
	{ value: '6492100', label: '6492-1/00 - Securitização de créditos' },
	{
		value: '6493000',
		label:
			'6493-0/00 - Administração de consórcios para aquisição de bens e direitos',
	},
	{ value: '6499901', label: '6499-9/01 - Clubes de investimento' },
	{ value: '6499902', label: '6499-9/02 - Sociedades de investimento' },
	{ value: '6499903', label: '6499-9/03 - Fundo garantidor de crédito' },
	{
		value: '6499904',
		label: '6499-9/04 - Caixas de financiamento de corporações',
	},
	{
		value: '6499905',
		label: '6499-9/05 - Concessão de crédito pelas OSCIP',
	},
	{
		value: '6499999',
		label:
			'6499-9/99 - Outras atividades de serviços financeiros não especificadas anteriormente',
	},
	{
		value: '6511101',
		label: '6511-1/01 - Sociedade seguradora de seguros vida',
	},
	{ value: '6511102', label: '6511-1/02 - Planos de auxílio-funeral' },
	{
		value: '6512000',
		label: '6512-0/00 - Sociedade seguradora de seguros não vida',
	},
	{
		value: '6520100',
		label: '6520-1/00 - Sociedade seguradora de seguros-saúde',
	},
	{ value: '6530800', label: '6530-8/00 - Resseguros' },
	{
		value: '6541300',
		label: '6541-3/00 - Previdência complementar fechada',
	},
	{
		value: '6542100',
		label: '6542-1/00 - Previdência complementar aberta',
	},
	{ value: '6550200', label: '6550-2/00 - Planos de saúde' },
	{ value: '6611801', label: '6611-8/01 - Bolsa de valores' },
	{ value: '6611802', label: '6611-8/02 - Bolsa de mercadorias' },
	{ value: '6611803', label: '6611-8/03 - Bolsa de mercadorias e futuros' },
	{
		value: '6611804',
		label: '6611-8/04 - Administração de mercados de balcão organizados',
	},
	{
		value: '6612601',
		label: '6612-6/01 - Corretoras de títulos e valores mobiliários',
	},
	{
		value: '6612602',
		label: '6612-6/02 - Distribuidoras de títulos e valores mobiliários',
	},
	{ value: '6612603', label: '6612-6/03 - Corretoras de câmbio' },
	{
		value: '6612604',
		label: '6612-6/04 - Corretoras de contratos de mercadorias',
	},
	{
		value: '6612605',
		label: '6612-6/05 - Agentes de investimentos em aplicações financeiras',
	},
	{
		value: '6613400',
		label: '6613-4/00 - Administração de cartões de crédito',
	},
	{
		value: '6619301',
		label: '6619-3/01 - Serviços de liquidação e custódia',
	},
	{
		value: '6619302',
		label: '6619-3/02 - Correspondentes de instituições financeiras',
	},
	{
		value: '6619303',
		label: '6619-3/03 - Representações de bancos estrangeiros',
	},
	{ value: '6619304', label: '6619-3/04 - Caixas eletrônicos' },
	{
		value: '6619305',
		label: '6619-3/05 - Operadoras de cartões de débito',
	},
	{
		value: '6619399',
		label:
			'6619-3/99 - Outras atividades auxiliares dos serviços financeiros não especificadas anteriormente',
	},
	{
		value: '6621501',
		label: '6621-5/01 - Peritos e avaliadores de seguros',
	},
	{
		value: '6621502',
		label: '6621-5/02 - Auditoria e consultoria atuarial',
	},
	{
		value: '6622300',
		label:
			'6622-3/00 - Corretores e agentes de seguros, de planos de previdência complementar e de saúde',
	},
	{
		value: '6629100',
		label:
			'6629-1/00 - Atividades auxiliares dos seguros, da previdência complementar e dos planos de saúde não especificadas anteriormente',
	},
	{
		value: '6630400',
		label:
			'6630-4/00 - Atividades de administração de fundos por contrato ou comissão',
	},
	{
		value: '6810201',
		label: '6810-2/01 - Compra e venda de imóveis próprios',
	},
	{ value: '6810202', label: '6810-2/02 - Aluguel de imóveis próprios' },
	{ value: '6810203', label: '6810-2/03 - Loteamento de imóveis próprios' },
	{
		value: '6821801',
		label: '6821-8/01 - Corretagem na compra e venda e avaliação de imóveis',
	},
	{
		value: '6821802',
		label: '6821-8/02 - Corretagem no aluguel de imóveis',
	},
	{
		value: '6822600',
		label: '6822-6/00 - Gestão e administração da propriedade imobiliária',
	},
	{ value: '6911701', label: '6911-7/01 - Serviços advocatícios' },
	{
		value: '6911702',
		label: '6911-7/02 - Atividades auxiliares da justiça',
	},
	{
		value: '6911703',
		label: '6911-7/03 - Agente de propriedade industrial',
	},
	{ value: '6912500', label: '6912-5/00 - Cartórios' },
	{ value: '6920601', label: '6920-6/01 - Atividades de contabilidade' },
	{
		value: '6920602',
		label:
			'6920-6/02 - Atividades de consultoria e auditoria contábil e tributária',
	},
	{
		value: '7020400',
		label:
			'7020-4/00 - Atividades de consultoria em gestão empresarial, exceto consultoria técnica específica',
	},
	{ value: '7111100', label: '7111-1/00 - Serviços de arquitetura' },
	{ value: '7112000', label: '7112-0/00 - Serviços de engenharia' },
	{
		value: '7119701',
		label: '7119-7/01 - Serviços de cartografia, topografia e geodésia',
	},
	{
		value: '7119702',
		label: '7119-7/02 - Atividades de estudos geológicos',
	},
	{
		value: '7119703',
		label:
			'7119-7/03 - Serviços de desenho técnico relacionados à arquitetura e engenharia',
	},
	{
		value: '7119704',
		label:
			'7119-7/04 - Serviços de perícia técnica relacionados à segurança do trabalho',
	},
	{
		value: '7119799',
		label:
			'7119-7/99 - Atividades técnicas relacionadas à engenharia e arquitetura não especificadas anteriormente',
	},
	{ value: '7120100', label: '7120-1/00 - Testes e análises técnicas' },
	{
		value: '7210000',
		label:
			'7210-0/00 - Pesquisa e desenvolvimento experimental em ciências físicas e naturais',
	},
	{
		value: '7220700',
		label:
			'7220-7/00 - Pesquisa e desenvolvimento experimental em ciências sociais e humanas',
	},
	{ value: '7311400', label: '7311-4/00 - Agências de publicidade' },
	{
		value: '7312200',
		label:
			'7312-2/00 - Agenciamento de espaços para publicidade, exceto em veículos de comunicação',
	},
	{
		value: '7319001',
		label: '7319-0/01 - Criação de estandes para feiras e exposições',
	},
	{ value: '7319002', label: '7319-0/02 - Promoção de vendas' },
	{ value: '7319003', label: '7319-0/03 - Marketing direto' },
	{ value: '7319004', label: '7319-0/04 - Consultoria em publicidade' },
	{
		value: '7319099',
		label:
			'7319-0/99 - Outras atividades de publicidade não especificadas anteriormente',
	},
	{
		value: '7320300',
		label: '7320-3/00 - Pesquisas de mercado e de opinião pública',
	},
	{ value: '7410202', label: '7410-2/02 - Design de interiores' },
	{ value: '7410203', label: '7410-2/03 - Desing de produto' },
	{
		value: '7410299',
		label: '7410-2/99 - Atividades de desing não especificadas anteriormente',
	},
	{
		value: '7420001',
		label:
			'7420-0/01 - Atividades de produção de fotografias, exceto aérea e submarina',
	},
	{
		value: '7420002',
		label:
			'7420-0/02 - Atividades de produção de fotografias aéreas e submarinas',
	},
	{ value: '7420003', label: '7420-0/03 - Laboratórios fotográficos' },
	{ value: '7420004', label: '7420-0/04 - Filmagem de festas e eventos' },
	{ value: '7420005', label: '7420-0/05 - Serviços de microfilmagem' },
	{
		value: '7490101',
		label: '7490-1/01 - Serviços de tradução, interpretação e similares',
	},
	{ value: '7490102', label: '7490-1/02 - Escafandria e mergulho' },
	{
		value: '7490103',
		label:
			'7490-1/03 - Serviços de agronomia e de consultoria às atividades agrícolas e pecuárias',
	},
	{
		value: '7490104',
		label:
			'7490-1/04 - Atividades de intermediação e agenciamento de serviços e negócios em geral, exceto imobiliários',
	},
	{
		value: '7490105',
		label:
			'7490-1/05 - Agenciamento de profissionais para atividades esportivas, culturais e artísticas',
	},
	{
		value: '7490199',
		label:
			'7490-1/99 - Outras atividades profissionais, científicas e técnicas não especificadas anteriormente',
	},
	{ value: '7500100', label: '7500-1/00 - Atividades veterinárias' },
	{
		value: '7711000',
		label: '7711-0/00 - Locação de automóveis sem condutor',
	},
	{
		value: '7719501',
		label:
			'7719-5/01 - Locação de embarcações sem tripulação, exceto para finsrecreativos',
	},
	{
		value: '7719502',
		label: '7719-5/02 - Locação de aeronaves sem tripulação',
	},
	{
		value: '7719599',
		label:
			'7719-5/99 - Locação de outros meios de transporte não especificados anteriormente, sem condutor',
	},
	{
		value: '7721700',
		label: '7721-7/00 - Aluguel de equipamentos recreativos e esportivos',
	},
	{
		value: '7722500',
		label: '7722-5/00 - Aluguel de fitas de vídeo, DVDs e similares',
	},
	{
		value: '7723300',
		label: '7723-3/00 - Aluguel de objetos do vestuário, jóias e acessórios',
	},
	{
		value: '7729201',
		label: '7729-2/01 - Aluguel de aparelhos de jogos eletrônicos',
	},
	{
		value: '7729202',
		label:
			'7729-2/02 - Aluguel de móveis, utensílios e aparelhos de uso doméstico e pessoal, instrumentos musicais',
	},
	{ value: '7729203', label: '7729-2/03 - Aluguel de material médico' },
	{
		value: '7729299',
		label:
			'7729-2/99 - Aluguel de outros objetos pessoais e domésticos não especificados anteriormente',
	},
	{
		value: '7731400',
		label:
			'7731-4/00 - Aluguel de máquinas e equipamentos agrícolas sem operador',
	},
	{
		value: '7732201',
		label:
			'7732-2/01 - Aluguel de máquinas e equipamentos para construção sem operador, exceto andaimes',
	},
	{ value: '7732202', label: '7732-2/02 - Aluguel de andaimes' },
	{
		value: '7733100',
		label: '7733-1/00 - Aluguel de máquinas e equipamentos para escritório',
	},
	{
		value: '7739001',
		label:
			'7739-0/01 - Aluguel de máquinas e equipamentos para extração de minérios e petróleo, sem operador',
	},
	{
		value: '7739002',
		label:
			'7739-0/02 - Aluguel de equipamentos científicos, médicos e hospitalares, sem operador',
	},
	{
		value: '7739003',
		label:
			'7739-0/03 - Aluguel de palcos, coberturas e outras estruturas de uso temporário, exceto andaimes',
	},
	{
		value: '7739099',
		label:
			'7739-0/99 - Aluguel de outras máquinas e equipamentos comerciais e industriais não especificados anteriormente, sem operador',
	},
	{
		value: '7740300',
		label: '7740-3/00 - Gestão de ativos intangíveis não financeiros',
	},
	{
		value: '7810800',
		label: '7810-8/00 - Seleção e agenciamento de mão de obra',
	},
	{
		value: '7820500',
		label: '7820-5/00 - Locação de mão de obra temporária',
	},
	{
		value: '7830200',
		label:
			'7830-2/00 - Fornecimento e gestão de recursos humanos para terceiros',
	},
	{ value: '7911200', label: '7911-2/00 - Agências de viagens' },
	{ value: '7912100', label: '7912-1/00 - Operadores turísticos' },
	{
		value: '7990200',
		label:
			'7990-2/00 - Serviços de reservas e outros serviços de turismo não especificados anteriormente',
	},
	{
		value: '8011101',
		label: '8011-1/01 - Atividades de vigilância e segurança privada',
	},
	{
		value: '8011102',
		label: '8011-1/02 - Serviços de adestramento de cães de guarda',
	},
	{
		value: '8012900',
		label: '8012-9/00 - Atividades de transporte de valores',
	},
	{
		value: '8020001',
		label:
			'8020-0/01 - Atividades de monitoramento de sistemas de segurança eletrônico',
	},
	{
		value: '8020002',
		label: '8020-0/02 - Outras atividades de serviços de segurança',
	},
	{
		value: '8030700',
		label: '8030-7/00 - Atividades de investigação particular',
	},
	{
		value: '8111700',
		label:
			'8111-7/00 - Serviços combinados para apoio a edifícios, exceto condomínios prediais',
	},
	{ value: '8112500', label: '8112-5/00 - Condomínios prediais' },
	{
		value: '8121400',
		label: '8121-4/00 - Limpeza em prédios e em domicílios',
	},
	{
		value: '8122200',
		label: '8122-2/00 - Imunização e controle de pragas urbanas',
	},
	{
		value: '8129000',
		label: '8129-0/00 - Atividades de limpeza não especificadas anteriormente',
	},
	{ value: '8130300', label: '8130-3/00 - Atividades paisagísticas' },
	{
		value: '8211300',
		label:
			'8211-3/00 - Serviços combinados de escritório e apoio administrativo',
	},
	{ value: '8219901', label: '8219-9/01 - Fotocópias' },
	{
		value: '8219999',
		label:
			'8219-9/99 - Preparação de documentos e serviços especializados de apoio administrativo não especificados anteriormente',
	},
	{ value: '8220200', label: '8220-2/00 - Atividades de teleatendimento' },
	{
		value: '8230001',
		label:
			'8230-0/01 - Serviços de organização de feiras, congressos, exposições e festas',
	},
	{ value: '8230002', label: '8230-0/02 - Casas de festas e eventos' },
	{
		value: '8291100',
		label: '8291-1/00 - Atividades de cobrança e informações cadastrais',
	},
	{
		value: '8292000',
		label: '8292-0/00 - Envasamento e empacotamento sob contrato',
	},
	{
		value: '8299701',
		label: '8299-7/01 - Medição de consumo de energia elétrica, gás e água',
	},
	{
		value: '8299702',
		label:
			'8299-7/02 - Emissão de vales-alimentação, vales-transporte e similares',
	},
	{
		value: '8299703',
		label: '8299-7/03 - Serviços de gravação de carimbos, exceto confecção',
	},
	{ value: '8299704', label: '8299-7/04 - Leiloeiros independentes' },
	{
		value: '8299705',
		label: '8299-7/05 - Serviços de levantamento de fundos sob contrato',
	},
	{ value: '8299706', label: '8299-7/06 - Casas lotéricas' },
	{ value: '8299707', label: '8299-7/07 - Salas de acesso à Internet' },
	{
		value: '8299799',
		label:
			'8299-7/99 - Outras atividades de serviços prestados principalmente às empresas não especificadas anteriormente',
	},
	{ value: '8411600', label: '8411-6/00 - Administração pública em geral' },
	{
		value: '8412400',
		label:
			'8412-4/00 - Regulação das atividades de saúde, educação, serviços culturais e outros serviços sociais',
	},
	{
		value: '8413200',
		label: '8413-2/00 - Regulação das atividades econômicas',
	},
	{ value: '8421300', label: '8421-3/00 - Relações exteriores' },
	{ value: '8422100', label: '8422-1/00 - Defesa' },
	{ value: '8423000', label: '8423-0/00 - Justiça' },
	{ value: '8424800', label: '8424-8/00 - Segurança e ordem pública' },
	{ value: '8425600', label: '8425-6/00 - Defesa Civil' },
	{ value: '8430200', label: '8430-2/00 - Seguridade social obrigatória' },
	{ value: '8511200', label: '8511-2/00 - Educação infantil - creche' },
	{ value: '8512100', label: '8512-1/00 - Educação infantil - pré-escola' },
	{ value: '8513900', label: '8513-9/00 - Ensino fundamental' },
	{ value: '8520100', label: '8520-1/00 - Ensino médio' },
	{ value: '8531700', label: '8531-7/00 - Educação superior - graduação' },
	{
		value: '8532500',
		label: '8532-5/00 - Educação superior - graduação e pós-graduação',
	},
	{
		value: '8533300',
		label: '8533-3/00 - Educação superior - pós-graduação e extensão',
	},
	{
		value: '8541400',
		label: '8541-4/00 - Educação profissional de nível técnico',
	},
	{
		value: '8542200',
		label: '8542-2/00 - Educação profissional de nível tecnológico',
	},
	{
		value: '8550301',
		label: '8550-3/01 - Administração de caixas escolares',
	},
	{
		value: '8550302',
		label:
			'8550-3/02 - Atividades de apoio à educação, exceto caixas escolares',
	},
	{ value: '8591100', label: '8591-1/00 - Ensino de esportes' },
	{ value: '8592901', label: '8592-9/01 - Ensino de dança' },
	{
		value: '8592902',
		label: '8592-9/02 - Ensino de artes cênicas, exceto dança',
	},
	{ value: '8592903', label: '8592-9/03 - Ensino de música' },
	{
		value: '8592999',
		label:
			'8592-9/99 - Ensino de arte e cultura não especificado anteriormente',
	},
	{ value: '8593700', label: '8593-7/00 - Ensino de idiomas' },
	{ value: '8599601', label: '8599-6/01 - Formação de condutores' },
	{ value: '8599602', label: '8599-6/02 - Cursos de pilotagem' },
	{ value: '8599603', label: '8599-6/03 - Treinamento em informática' },
	{
		value: '8599604',
		label:
			'8599-6/04 - Treinamento em desenvolvimento profissional e gerencial',
	},
	{
		value: '8599605',
		label: '8599-6/05 - Cursos preparatórios para concursos',
	},
	{
		value: '8599699',
		label:
			'8599-6/99 - Outras atividades de ensino não especificadas anteriormente',
	},
	{
		value: '8610101',
		label:
			'8610-1/01 - Atividades de atendimento hospitalar, exceto pronto-socorro e unidades para atendimento a urgências',
	},
	{
		value: '8610102',
		label:
			'8610-1/02 - Atividades de atendimento em pronto-socorro e unidades hospitalares para atendimento a urgências',
	},
	{ value: '8621601', label: '8621-6/01 - UTI móvel' },
	{
		value: '8621602',
		label:
			'8621-6/02 - Serviços móveis de atendimento a urgências, exceto por UTI móvel',
	},
	{
		value: '8622400',
		label:
			'8622-4/00 - Serviços de remoção de pacientes, exceto os serviços móveis de atendimento a urgências',
	},
	{
		value: '8630501',
		label:
			'8630-5/01 - Atividade médica ambulatorial com recursos para realização de procedimentos cirúrgicos',
	},
	{
		value: '8630502',
		label:
			'8630-5/02 - Atividade médica ambulatorial com recursos para realização de exames complementares',
	},
	{
		value: '8630503',
		label: '8630-5/03 - Atividade médica ambulatorial restrita a consultas',
	},
	{ value: '8630504', label: '8630-5/04 - Atividade odontológica' },
	{
		value: '8630506',
		label: '8630-5/06 - Serviços de vacinação e imunização humana',
	},
	{
		value: '8630507',
		label: '8630-5/07 - Atividades de reprodução humana assistida',
	},
	{
		value: '8630599',
		label:
			'8630-5/99 - Atividades de atenção ambulatorial não especificadas anteriormente',
	},
	{
		value: '8640201',
		label: '8640-2/01 - Laboratórios de anatomia patológica e citológica',
	},
	{ value: '8640202', label: '8640-2/02 - Laboratórios clínicos' },
	{
		value: '8640203',
		label: '8640-2/03 - Serviços de diálise e nefrologia',
	},
	{ value: '8640204', label: '8640-2/04 - Serviços de tomografia' },
	{
		value: '8640205',
		label:
			'8640-2/05 - Serviços de diagnóstico por imagem com uso de radiação ionizante, exceto tomografia',
	},
	{
		value: '8640206',
		label: '8640-2/06 - Serviços de ressonância magnética',
	},
	{
		value: '8640207',
		label:
			'8640-2/07 - Serviços de diagnóstico por imagem sem uso de radiação ionizante, exceto ressonância magnética',
	},
	{
		value: '8640208',
		label:
			'8640-2/08 - Serviços de diagnóstico por registro gráfico - ECG, EEG e outros exames análogos',
	},
	{
		value: '8640209',
		label:
			'8640-2/09 - Serviços de diagnóstico por métodos ópticos - endoscopia e outros exames análogos',
	},
	{ value: '8640210', label: '8640-2/10 - Serviços de quimioterapia' },
	{ value: '8640211', label: '8640-2/11 - Serviços de radioterapia' },
	{ value: '8640212', label: '8640-2/12 - Serviços de hemoterapia' },
	{ value: '8640213', label: '8640-2/13 - Serviços de litotripsia' },
	{
		value: '8640214',
		label: '8640-2/14 - Serviços de bancos de células e tecidos humanos',
	},
	{
		value: '8640299',
		label:
			'8640-2/99 - Atividades de serviços de complementação diagnóstica e terapêutica não especificadas anteriormente',
	},
	{ value: '8650001', label: '8650-0/01 - Atividades de enfermagem' },
	{
		value: '8650002',
		label: '8650-0/02 - Atividades de profissionais da nutrição',
	},
	{
		value: '8650003',
		label: '8650-0/03 - Atividades de psicologia e psicanálise',
	},
	{ value: '8650004', label: '8650-0/04 - Atividades de fisioterapia' },
	{
		value: '8650005',
		label: '8650-0/05 - Atividades de terapia ocupacional',
	},
	{ value: '8650006', label: '8650-0/06 - Atividades de fonoaudiologia' },
	{
		value: '8650007',
		label: '8650-0/07 - Atividades de terapia de nutrição enteral e parenteral',
	},
	{
		value: '8650099',
		label:
			'8650-0/99 - Atividades de profissionais da área de saúde não especificadas anteriormente',
	},
	{
		value: '8660700',
		label: '8660-7/00 - Atividades de apoio à gestão de saúde',
	},
	{
		value: '8690901',
		label:
			'8690-9/01 - Atividades de práticas integrativas e complementares em saúde humana',
	},
	{
		value: '8690902',
		label: '8690-9/02 - Atividades de bancos de leite humano',
	},
	{ value: '8690903', label: '8690-9/03 - Atividades de acupuntura' },
	{ value: '8690904', label: '8690-9/04 - Atividades de podologia' },
	{
		value: '8690999',
		label:
			'8690-9/99 - Outras atividades de atenção à saúde humana não especificadas anteriormente',
	},
	{
		value: '8711501',
		label: '8711-5/01 - Clínicas e residências geriátricas',
	},
	{
		value: '8711502',
		label: '8711-5/02 - Instituições de longa permanência para idosos',
	},
	{
		value: '8711503',
		label:
			'8711-5/03 - Atividades de assistência a deficientes físicos, imunodeprimidos e convalescentes',
	},
	{
		value: '8711504',
		label: '8711-5/04 - Centros de apoio a pacientes com câncer e com AIDS',
	},
	{
		value: '8711505',
		label: '8711-5/05 - Condomínios residenciais para idosos',
	},
	{
		value: '8712300',
		label:
			'8712-3/00 - Atividades de fornecimento de infraestrutura de apoio e assistência a paciente no domicílio',
	},
	{
		value: '8720401',
		label: '8720-4/01 - Atividades de centros de assistência psicossocial',
	},
	{
		value: '8720499',
		label:
			'8720-4/99 - Atividades de assistência psicossocial e à saúde a portadores de distúrbios psíquicos, deficiência mental e dependência química e grupos similares não especificadas anteriormente',
	},
	{ value: '8730101', label: '8730-1/01 - Orfanatos' },
	{ value: '8730102', label: '8730-1/02 - Albergues assistenciais' },
	{
		value: '8730199',
		label:
			'8730-1/99 - Atividades de assistência social prestadas em residências coletivas e particulares não especificadas anteriormente',
	},
	{
		value: '8800600',
		label: '8800-6/00 - Serviços de assistência social sem alojamento',
	},
	{ value: '9001901', label: '9001-9/01 - Produção teatral' },
	{ value: '9001902', label: '9001-9/02 - Produção musical' },
	{
		value: '9001903',
		label: '9001-9/03 - Produção de espetáculos de dança',
	},
	{
		value: '9001904',
		label:
			'9001-9/04 - Produção de espetáculos circenses, de marionetes e similares',
	},
	{
		value: '9001905',
		label:
			'9001-9/05 - Produção de espetáculos de rodeios, vaquejadas e similares',
	},
	{
		value: '9001906',
		label: '9001-9/06 - Atividades de sonorização e de iluminação',
	},
	{
		value: '9001999',
		label:
			'9001-9/99 - Artes cênicas, espetáculos e atividades complementares não especificados anteriormente',
	},
	{
		value: '9002701',
		label:
			'9002-7/01 - Atividades de artistas plásticos, jornalistas independentes eescritores',
	},
	{ value: '9002702', label: '9002-7/02 - Restauração de obras de arte' },
	{
		value: '9003500',
		label:
			'9003-5/00 - Gestão de espaços para artes cênicas, espetáculos e outras atividades artísticas',
	},
	{
		value: '9101500',
		label: '9101-5/00 - Atividades de bibliotecas e arquivos',
	},
	{
		value: '9102301',
		label:
			'9102-3/01 - Atividades de museus e de exploração de lugares e prédios históricos e atrações similares',
	},
	{
		value: '9102302',
		label:
			'9102-3/02 - Restauração e conservação de lugares e prédios históricos',
	},
	{
		value: '9103100',
		label:
			'9103-1/00 - Atividades de jardins botânicos, zoológicos, parques nacionais, reservas ecológicas e áreas de proteção ambiental',
	},
	{ value: '9200301', label: '9200-3/01 - Casas de bingo' },
	{
		value: '9200302',
		label: '9200-3/02 - Exploração de apostas em corridas de cavalos',
	},
	{
		value: '9200399',
		label:
			'9200-3/99 - Exploração de jogos de azar e apostas não especificados anteriormente',
	},
	{
		value: '9311500',
		label: '9311-5/00 - Gestão de instalações de esportes',
	},
	{
		value: '9312300',
		label: '9312-3/00 - Clubes sociais, esportivos e similares',
	},
	{
		value: '9313100',
		label: '9313-1/00 - Atividades de condicionamento físico',
	},
	{
		value: '9319101',
		label: '9319-1/01 - Produção e promoção de eventos esportivos',
	},
	{
		value: '9319199',
		label:
			'9319-1/99 - Outras atividades esportivas não especificadas anteriormente',
	},
	{
		value: '9321200',
		label: '9321-2/00 - Parques de diversão e parques temáticos',
	},
	{
		value: '9329801',
		label: '9329-8/01 - Discotecas, danceterias, salões de dança e similares',
	},
	{ value: '9329802', label: '9329-8/02 - Exploração de boliches' },
	{
		value: '9329803',
		label: '9329-8/03 - Exploração de jogos de sinuca, bilhar e similares',
	},
	{
		value: '9329804',
		label: '9329-8/04 - Exploração de jogos eletrônicos recreativos',
	},
	{
		value: '9329899',
		label:
			'9329-8/99 - Outras atividades de recreação e lazer não especificadas anteriormente',
	},
	{
		value: '9411100',
		label:
			'9411-1/00 - Atividades de organizações associativas patronais e empresariais',
	},
	{
		value: '9412001',
		label: '9412-0/01 - Atividades de fiscalização profissional',
	},
	{
		value: '9412099',
		label: '9412-0/99 - Outras atividades associativas profissionais',
	},
	{
		value: '9420100',
		label: '9420-1/00 - Atividades de organizações sindicais',
	},
	{
		value: '9430800',
		label:
			'9430-8/00 - Atividades de associações de defesa de direitos sociais',
	},
	{
		value: '9491000',
		label: '9491-0/00 - Atividades de organizações religiosas ou filosóficas',
	},
	{
		value: '9492800',
		label: '9492-8/00 - Atividades de organizações políticas',
	},
	{
		value: '9493600',
		label:
			'9493-6/00 - Atividades de organizações associativas ligadas à cultura e à arte',
	},
	{
		value: '9499500',
		label:
			'9499-5/00 - Atividades associativas não especificadas anteriormente',
	},
	{
		value: '9511800',
		label:
			'9511-8/00 - Reparação e manutenção de computadores e de equipamentos periféricos',
	},
	{
		value: '9512600',
		label: '9512-6/00 - Reparação e manutenção de equipamentos de comunicação',
	},
	{
		value: '9521500',
		label:
			'9521-5/00 - Reparação e manutenção de equipamentos eletroeletrônicos de uso pessoal e doméstico',
	},
	{
		value: '9529101',
		label: '9529-1/01 - Reparação de calçados, bolsas e artigos de viagem',
	},
	{ value: '9529102', label: '9529-1/02 - Chaveiros' },
	{ value: '9529103', label: '9529-1/03 - Reparação de relógios' },
	{
		value: '9529104',
		label:
			'9529-1/04 - Reparação de bicicletas, triciclos e outros veículos não motorizados',
	},
	{
		value: '9529105',
		label: '9529-1/05 - Reparação de artigos do mobiliário',
	},
	{ value: '9529106', label: '9529-1/06 - Reparação de jóias' },
	{
		value: '9529199',
		label:
			'9529-1/99 - Reparação e manutenção de outros objetos e equipamentos pessoais e domésticos não especificados anteriormente',
	},
	{ value: '9601701', label: '9601-7/01 - Lavanderias' },
	{ value: '9601702', label: '9601-7/02 - Tinturarias' },
	{ value: '9601703', label: '9601-7/03 - Toalheiros' },
	{
		value: '9602501',
		label: '9602-5/01 - Cabeleireiros, manicure e pedicure',
	},
	{
		value: '9602502',
		label:
			'9602-5/02 - Atividades de estética e outros serviços de cuidados com a beleza',
	},
	{
		value: '9603301',
		label: '9603-3/01 - Gestão e manutenção de cemitérios',
	},
	{ value: '9603302', label: '9603-3/02 - Serviços de cremação' },
	{ value: '9603303', label: '9603-3/03 - Serviços de sepultamento' },
	{ value: '9603304', label: '9603-3/04 - Serviços de funerárias' },
	{ value: '9603305', label: '9603-3/05 - Serviços de somatoconservação' },
	{
		value: '9603399',
		label:
			'9603-3/99 - Atividades funerárias e serviços relacionados não especificados anteriormente',
	},
	{ value: '9609202', label: '9609-2/02 - Agências matrimoniais' },
	{
		value: '9609204',
		label:
			'9609-2/04 - Exploração de máquinas de serviços pessoais acionadas por moeda',
	},
	{ value: '9609205', label: '9609-2/05 - Atividades de sauna e banhos' },
	{
		value: '9609206',
		label: '9609-2/06 - Serviços de tatuagem e colocação de piercing',
	},
	{
		value: '9609207',
		label: '9609-2/07 - Alojamento de animais domésticos',
	},
	{
		value: '9609208',
		label: '9609-2/08 - Higiene e embelezamento de animais domésticos',
	},
	{
		value: '9609299',
		label:
			'9609-2/99 - Outras atividades de serviços pessoais não especificadas anteriormente',
	},
	{ value: '9700500', label: '9700-5/00 - Serviços domésticos' },
];
