import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";

import { sendAnalytics } from "../../../utils/analytics";

const SaveLayerModal = ({ open, onClose, submitLayer }) => {
  const [layerName, setLayerName] = useState("");

  useEffect(() => {
    if (!open) setLayerName("");
  }, [open]);

  const _submitLayer = () => {
    submitLayer(layerName);
    sendAnalytics({ event: "continuar-button", page: "novaCamadaModal" });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Nova camada</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Defina um nome para a nova camada
        </Typography>
        <TextField
          id="novaCamada-nome-input"
          margin="dense"
          variant="outlined"
          name="layerName"
          value={layerName}
          onChange={(evt) => setLayerName(evt.target.value)}
          placeholder="Digite aqui o nome da camada"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          id="novaCamada-cancelar-button"
          onClick={() => {
            onClose();
            sendAnalytics({
              event: "cancelar-button",
              page: "novaCamadaModal",
            });
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button
          id="novaCamada-continuar-button"
          variant="contained"
          onClick={_submitLayer}
          color="primary"
          disabled={!(layerName?.length)}
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveLayerModal;
