import axios from 'axios';
import { createAxiosInstance, AuthUtils } from '@assertiva/assertiva-ui';

export const BASE_URL = process.env.REACT_APP_API_URL;
export const API_CADASTRO = process.env.REACT_APP_AUTH_API_URL;
export const S3_URL = process.env.REACT_APP_S3_URL;

const _axios = createAxiosInstance({
  timeout: 60000,
  onNotAuthenticate: () => {
    AuthUtils.redirectWhenNotAuthenticated();
  },
});

export const cancelTokenSource = axios.CancelToken;

export default _axios;
