import React from "react";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

import { ellipsisString } from "../../../utils/general";
import Constants from "../constants";

import "./style.css";

const FilterChip = ({
  filterChip,
  setFilterChip,
  fetchApi,
  filterData,
  setFilterData,
}) => {
  const onDelete = (name) => {
    let requestObj = {};

    const newFilterChip = filterChip.filter((filter) => filter.name !== name);
    const removeFilterData = Constants.defaultFilterData[name];
    const newFilterData = {
      ...filterData,
      [name]: removeFilterData,
    };

    setFilterChip(newFilterChip);
    setFilterData(newFilterData);

    if (!newFilterChip.length) {
      return fetchApi();
    }

    newFilterChip.forEach((filter) => {
      requestObj[filter.name] = filter.value;
    });

    fetchApi(requestObj);
  };

  const label = {
    listName: (value) => (
      <div className="filter-chip__label_div">
        <Typography className="filter-chip__label_bold">Nome: </Typography>
        <Typography className="filter-chip__label">
          &nbsp;{ellipsisString(value, 25)}
        </Typography>
      </div>
    ),
    dates: (value) => (
      <div className="filter-chip__label_div">
        {value.dateIni.value && (
          <>
            <Typography className="filter-chip__label_bold">De: </Typography>
            <Typography className="filter-chip__label">
              &nbsp;{value.dateIni.value}
            </Typography>
          </>
        )}
        {value.dateFin.value && (
          <>
            <Typography className="filter-chip__label_bold">
              &nbsp;Até:
            </Typography>
            <Typography className="filter-chip__label">
              &nbsp;{value.dateFin.value}
            </Typography>
          </>
        )}
      </div>
    ),
  };

  return (
    <>
      {filterChip.map((chip, index) => {
        return (
          label[chip.name] && (
            <Chip
              key={index}
              size="small"
              className="filter-chip"
              label={label[chip.name](chip.value)}
              onDelete={() => onDelete(chip.name)}
            />
          )
        );
      })}
    </>
  );
};

export default FilterChip;
