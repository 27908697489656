import React from 'react';

import BarChartIcon from '@material-ui/icons/BarChart';
import SettingsIcon from '@material-ui/icons/Settings';

import { AUClientLogin, PRODUCT_IDS } from '@assertiva/assertiva-ui';
import Dashboard from './components/dashboard/Dashboard';
import Settings from './components/settings/Settings';

const routes = [
  {
    id: 0,
    exact: true,
    path: '/',
    label: 'Login',
    redirect: '/dashboard',
    isPrivate: false,
    component: (props) => <AUClientLogin {...props} />,
  },
  {
    id: 2,
    exact: true,
    path: '/dashboard',
    label: 'Geotimize',
    isPrivate: true,
    showInMenu: true,
    startOpen: true,
    productId: PRODUCT_IDS.GEOTIMIZE,
    nested: [
      {
        id: 3,
        exact: true,
        path: '/dashboard',
        icon: <BarChartIcon size='small' />,
        label: 'Novo Estudo',
        isPrivate: true,
        showInMenu: true,
        component: (props) => <Dashboard {...props} />,
      },
      {
        id: 3,
        exact: true,
        path: '/configuracoes',
        icon: <SettingsIcon size='small' />,
        label: 'Configurações',
        isPrivate: true,
        showInMenu: true,
        component: (props) => <Settings {...props} />,
      },
    ],
  },
];

export default routes;
