import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import {
  AUNotifier,
  AUPurposeService,
  PRODUCT_IDS,
} from '@assertiva/assertiva-ui';

import ActionCard from './components/actionCard/ActionCard';
import ListSelect from './components/listSelect/listSelect';
import ListSelectHeader from './components/listSelectHeader/listSelectHeader';
import Drawer from '../../components/customDrawer/CustomDrawer';
import Pagination from '../../components/Pagination/Pagination';

import * as _actions from './actions';
import { openDrawer as globalOpenDrawer } from '../../actions';

import {
  getLayerActions,
  deleteLayerAction,
  getLayerActionExportLimit,
} from '../../services/layerActions';

import { maybePluralize } from '../../utils/general';
import { sendAnalytics } from '../../utils/analytics';

import './style.css';

const ActionsDrawer = ({
  showDrawerLoading,
  hideDrawerLoading,
  isOpen,
  openDrawer,
  closeDrawer,
  changeActions,
  globalOpenDrawer,
  loading,
  exportLimits,
  changeExportLimits,
  pagination,
  setPagination,
  changeFinality,
  finality,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [childAnchorEl, setChildAnchorEl] = useState(null);
  const [expandedLists, setExpandedLists] = useState({});
  const [onSelectMode, setOnSelectMode] = useState(false);
  const [listsSelected, setListsSelect] = useState([]);
  const { actions, actionsOrder, count, totalPages } = useSelector((state) => ({
    actions: state.actionsDrawer.actions,
    actionsOrder: state.actionsDrawer.actionsOrder,
    count: state.actionsDrawer.count,
    totalPages: state.actionsDrawer.totalPages,
  }));

  const initActionsDrawer = useCallback(async () => {
    try {
      showDrawerLoading();

      const [{ data: layersActions }, { data: finalities }] = await Promise.all(
        [
          getLayerActions({
            page: pagination.page,
            limit: pagination.rowsPerPage,
          }),
          AUPurposeService.getPurposes(PRODUCT_IDS.GEOTIMIZE),
        ]
      );

      const { count, totalPages, items } = layersActions.data;
      const { layerActionsById, layerActionsOrder } = items;

      changeFinality({
        finality: finalities.map((f) => ({
          id: f.id,
          label: f.text,
          description: f.description,
        })),
      });
      changeActions({ layerActionsById, layerActionsOrder, count, totalPages });
    } catch (error) {
      AUNotifier.error('Ocorreu um erro, tente novamente mais tarde.');
      closeDrawer();
    } finally {
      hideDrawerLoading();
    }
  }, [
    pagination,
    changeActions,
    changeFinality,
    showDrawerLoading,
    hideDrawerLoading,
    closeDrawer,
  ]);

  useEffect(() => {
    isOpen && initActionsDrawer();
  }, [isOpen, initActionsDrawer]);

  useEffect(() => {
    if (!actionsOrder.length && isOpen && !loading) {
      closeDrawer();
    }
  }, [actionsOrder, loading]);

  useEffect(() => {
    const escapeFunction = (ev) => {
      if (ev.keyCode === 27) {
        setOnSelectMode(false);
        setListsSelect([]);
      }
    };

    if (onSelectMode) {
      document.addEventListener('keydown', escapeFunction, false);
    } else {
      document.removeEventListener('keydown', escapeFunction, false);
    }
  }, [onSelectMode, setOnSelectMode, setListsSelect]);

  const expandList = (actionId) => {
    setExpandedLists({
      ...expandedLists,
      [actionId]: !expandedLists[actionId],
    });
  };

  const expandAllLists = () =>
    setExpandedLists(
      actionsOrder.reduce((acc, cur) => ({ ...acc, [cur]: true }), {})
    );

  const collapseAllLists = () =>
    setExpandedLists(
      actionsOrder.reduce((acc, cur) => ({ ...acc, [cur]: false }), {})
    );

  const updateLayerActions = () => {
    showDrawerLoading();

    return getLayerActions({ page: 0, limit: 5 })
      .then(({ data: layerActions }) => {
        const { count, totalPages, items } = layerActions.data;
        const { layerActionsById, layerActionsOrder } = items;

        changeActions({
          layerActionsById,
          layerActionsOrder,
          count,
          totalPages,
        });
        getLayerActionExportLimit().then((limits) => {
          changeExportLimits({
            standard: limits.standard,
            score: limits.score,
          });
        });
      })
      .finally(() => hideDrawerLoading());
  };

  const onDeleteList = ({ listsIds }) => {
    showDrawerLoading();

    const actionsCopy = { ...actions };
    const actionsOrderCopy = [...actionsOrder];
    const newCount = count - 1;
    const newTotalPages = Math.ceil(newCount / pagination.rowsPerPage);

    if (Array.isArray(listsIds) && listsIds.length) {
      listsIds.forEach((id) => {
        if (actionsCopy[id]) {
          const deleteIndex = actionsOrderCopy.indexOf(id.toString());

          if (deleteIndex >= 0) {
            actionsOrderCopy.splice(deleteIndex, 1);
            delete actionsCopy[id];
          }
        }
      });
    }

    changeActions({
      layerActionsById: actionsCopy,
      layerActionsOrder: actionsOrderCopy,
      count: newCount,
      totalPages: newTotalPages,
    });

    let message;
    if (listsIds.length === Object.keys(actionsOrder).length) {
      message = 'Todas as listas foram excluídas com sucesso.';
    } else {
      message = `${
        Array.isArray(listsIds) ? listsIds.length : '1'
      } ${maybePluralize(listsIds.length, 'lista')} ${
        listsIds.length > 1 ? 'foram' : 'foi'
      } ${maybePluralize(listsIds.length, 'excluída')} com sucesso.`;
    }

    if (onSelectMode) {
      setOnSelectMode(false);
      setListsSelect([]);
    }

    deleteLayerAction({ layersActionsIds: listsIds })
      .then(() => {
        AUNotifier.notify({
          message,
          options: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          },
        });
      })
      .catch(() => updateLayerActions())
      .finally(() => hideDrawerLoading());
  };

  const handlePagination = (key, value) => {
    if (key === 'rowsPerPage') {
      const newTotalPages = Math.ceil(count / value);

      return setPagination({
        ...pagination,
        [key]: value,
        page: newTotalPages - 1,
      });
    }

    return setPagination((oldPagination) => ({
      ...oldPagination,
      [key]: value,
    }));
  };

  return (
    <>
      <Drawer
        title='Listas'
        variant='persistent'
        anchor='right'
        open={isOpen}
        moreOptions={
          <>
            <IconButton
              size='small'
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <MoreHorizIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {onSelectMode ? (
                <MenuItem
                  onClick={() => {
                    setOnSelectMode(false);
                    setListsSelect([]);
                    setAnchorEl(null);
                  }}
                >
                  <Typography variant='inherit'>Cancelar</Typography>
                </MenuItem>
              ) : (
                <div>
                  <MenuItem
                    onClick={() =>
                      globalOpenDrawer({
                        drawerName: 'ListHistory',
                        data: {
                          listHistory: [],
                          showDrawerLoading,
                          hideDrawerLoading,
                        },
                      })
                    }
                  >
                    <Typography variant='inherit'>
                      Histórico de listas exportadas
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOnSelectMode(true);
                      setAnchorEl(null);
                    }}
                  >
                    <Typography variant='inherit'>
                      Selecionar várias listas...
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => setChildAnchorEl(event.currentTarget)}
                  >
                    <Typography variant='inherit'>Visualização</Typography>
                    <ArrowRightIcon />
                  </MenuItem>
                </div>
              )}
            </Menu>

            <Menu
              anchorEl={childAnchorEl}
              keepMounted
              open={Boolean(childAnchorEl)}
              onClose={() => {
                setChildAnchorEl(null);
                setAnchorEl(null);
              }}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={expandAllLists}>
                <Typography variant='inherit'>Expandir todas</Typography>
              </MenuItem>
              <MenuItem onClick={collapseAllLists}>
                <Typography variant='inherit'>Resumir todas</Typography>
              </MenuItem>
            </Menu>
          </>
        }
        onClose={() => {
          setOnSelectMode(false);
          closeDrawer();
        }}
        style={{ width: '350px' }}
        classes={{
          root: 'actions-drawer',
          paper: 'actions-drawer__paper',
        }}
        PaperProps={{ elevation: 4 }}
      >
        {loading && (
          <div className='actions-drawer__loading'>
            <CircularProgress />
          </div>
        )}
        <div
          style={{ overflow: loading ? 'hidden' : 'unset' }}
          className='actions-drawer__content_div'
        >
          {onSelectMode && (
            <ListSelectHeader
              listsSelected={listsSelected}
              setListsSelect={setListsSelect}
              lists={actionsOrder.map((action) => Number(action))}
              setOnSelectMode={setOnSelectMode}
              deleteList={onDeleteList}
            />
          )}

          {actionsOrder.map((actionId) => {
            const {
              id,
              layerId,
              layerName,
              name,
              summary,
              rowsexported,
              list,
              isCompact,
              scoreChart,
            } = actions[actionId];

            return (
              <React.Fragment key={`list-${id}`}>
                {onSelectMode ? (
                  <ListSelect
                    key={`select-${id}`}
                    listId={id}
                    layerName={layerName}
                    name={name}
                    summary={summary}
                    setListsSelect={setListsSelect}
                    listsSelected={listsSelected}
                  />
                ) : (
                  <ActionCard
                    key={`ActionCard-${actionId}`}
                    listIsExpanded={expandedLists[actionId]}
                    expandList={() => expandList(actionId)}
                    layerId={layerId}
                    layerActionId={id}
                    layerName={layerName}
                    name={name}
                    scoreChart={scoreChart}
                    summary={summary}
                    rowsExported={Number(rowsexported)}
                    exportLimits={exportLimits}
                    list={list}
                    isCompact={isCompact}
                    onDelete={() => onDeleteList({ listsIds: [id] })}
                    openDrawer={globalOpenDrawer}
                    updateLayerActions={updateLayerActions}
                  />
                )}
              </React.Fragment>
            );
          })}

          {!loading && (
            <Pagination
              labelRowsPerPage='Listas'
              count={count}
              page={pagination?.page}
              rowsPerPageOptions={[5, 10]}
              rowsPerPage={pagination?.rowsPerPage}
              totalPages={totalPages}
              onChangePage={(newPage) => handlePagination('page', newPage)}
              onChangeRowsPerPage={(newRowsPerPage) =>
                handlePagination('rowsPerPage', newRowsPerPage)
              }
            />
          )}
        </div>
      </Drawer>

      <Paper
        id='listaDrawer-button'
        classes={{
          root: `actions-drawer__trigger ${isOpen ? 'open' : ''}`,
        }}
        elevation={4}
        onClick={() => {
          if (isOpen) closeDrawer();
          else openDrawer();

          sendAnalytics({ event: 'listaDrawer-button', page: 'listas' });
        }}
      >
        {isOpen ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </Paper>
    </>
  );
};

const mapStateToProps = ({ actionsDrawer }) => ({
  loading: actionsDrawer.loading.isShowing,
  isOpen: actionsDrawer.isOpen,
  exportLimits: actionsDrawer.exportLimits,
  finality: actionsDrawer.finality,
});

export default connect(mapStateToProps, {
  ..._actions,
  globalOpenDrawer,
})(ActionsDrawer);
