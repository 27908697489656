import defaultAxios from 'axios';
import queryString from 'query-string';
import axios, { BASE_URL } from './base';

export const postExportList = ({
  name,
  layerId,
  params,
  fileExtension,
  finality,
  layerActionId,
  rowsToExport,
  continueFromLastExport,
  insertOnBlacklist,
}) => {
  return axios.post(`${BASE_URL}/maps/export`, {
    name,
    layerId,
    layerActionId,
    columnParams: params,
    fileExtension,
    finality,
    rowsToExport,
    continueFromLastExport,
    insertOnBlacklist,
  });
};

export const getExportList = ({ listId }) => {
  return axios.get(`${BASE_URL}/maps/export/${listId}`);
};

export const getExportListStatus = ({ listId }) => {
  return axios.get(`${BASE_URL}/maps/export/${listId}/status`);
};

export const getExportListHistory = (filters = null) => {
  const {
    listName = null,
    dateIni = null,
    dateFin = null,
    showExpired = false,
  } = filters || {};

  return axios.get(`${BASE_URL}/maps/export/history`, {
    params: {
      ...(listName ? { listName } : {}),
      ...(dateIni ? { dateIni } : {}),
      ...(dateFin ? { dateFin } : {}),
      ...(listName || dateIni || dateFin ? { showExpired } : {}),
    },
    paramsSerializer: queryString.stringify,
  });
};

export const getExportFileSize = (url) => {
  return defaultAxios.head(url);
};
