export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const CHANGE_ACTIONS = 'CHANGE_ACTIONS';
export const SHOW_DRAWER_LOADING = 'SHOW_DRAWER_LOADING';
export const HIDE_DRAWER_LOADING = 'HIDE_DRAWER_LOADING';
export const CHANGE_EXPORT_LIMITS = 'CHANGE_EXPORT_LIMITS';
export const CHANGE_FINALITY = 'CHANGE_FINALITY';

export const openDrawer = () => ({
  type: OPEN_DRAWER,
});

export const closeDrawer = () => ({
  type: CLOSE_DRAWER,
});

export const changeActions = ({
  layerActionsById,
  layerActionsOrder,
  count,
  totalPages,
}) => ({
  type: CHANGE_ACTIONS,
  payload: { layerActionsById, layerActionsOrder, count, totalPages },
});

export const showDrawerLoading = () => ({
  type: SHOW_DRAWER_LOADING,
});

export const hideDrawerLoading = () => ({
  type: HIDE_DRAWER_LOADING,
});

export const changeExportLimits = ({ standard, score }) => ({
  type: CHANGE_EXPORT_LIMITS,
  payload: { standard, score },
});

export const changeFinality = ({ finality }) => ({
  type: CHANGE_FINALITY,
  payload: { finality },
});
