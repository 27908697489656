import axios, { BASE_URL } from './base';
import queryString from 'query-string';
import GeneralConstants from '../constants/general';

export const getFilter = (filterParams) => {
  return axios.get(`${BASE_URL}/filter/pj/dash`, {
    params: {
      params: Object.keys(filterParams),
      ...filterParams,
    },
    paramsSerializer: queryString.stringify,
  });
};

export const getFilterMarkers = ({
  points = GeneralConstants.defaultMapPoints,
  zoom = GeneralConstants.defaultZoom,
  ...filterParams
}) => {
  return axios.get(`${BASE_URL}/maps/filter/pj/markers`, {
    params: {
      points,
      zoom,
      params: Object.keys(filterParams),
      ...filterParams,
    },
    paramsSerializer: queryString.stringify,
  });
};

export const getFilterLayers = ({
  points = GeneralConstants.defaultMapPoints,
  zoom = GeneralConstants.defaultZoom,
  layers,
}) => {
  return axios.get(`${BASE_URL}/maps/layer/pj/markers`, {
    params: { points, zoom, layers },
    paramsSerializer: queryString.stringify,
  });
};

export const getFieldParams = () => {
  return axios.get(`${BASE_URL}/maps/filter/field-params`);
};
