import React from 'react';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import { Divider } from '@material-ui/core';

import GeneralInfo from '../../containers/generalInfo/GeneralInfo';
import ZoomSettings from './components/zoomSettings/ZoomSettings';

import './HUD.css';

const HUD = ({
  switchTraffic,
  trafficEnabled,
  drawingMode,
  changeDrawingMode,
  changeMapType,
  mapType,
  changeZoom,
}) => {
  return (
    <div className='hud'>
      <div className='hud__top hud__left'>
        <div>
          <Paper elevation={4} className='hud__map-types'>
            <TextField
              className='hud__map-types-textfield'
              select
              variant='outlined'
              value={[mapType]}
              InputLabelProps={{ margin: 'dense' }}
              InputProps={{ margin: 'dense' }}
              SelectProps={{
                multiple: true,
                onChange: (event, child) =>
                  child.props.value && changeMapType(child.props.value),
              }}
              fullWidth
            >
              <MenuItem key='roadmap' value='roadmap'>
                Mapa
              </MenuItem>
              <MenuItem key='satellite' value='satellite'>
                Satélite
              </MenuItem>
              <Divider />
              <MenuItem disabled={mapType === 'satellite'}>
                <span>Exibir trânsito</span>
                <Switch
                  color='primary'
                  onChange={switchTraffic}
                  checked={trafficEnabled}
                  disabled={mapType === 'satellite'}
                />
              </MenuItem>
            </TextField>
          </Paper>
        </div>
        {/* <div>
					<DrawingToolbar
						drawingMode={drawingMode}
						changeDrawingMode={changeDrawingMode}
					/>
				</div> */}
      </div>
      <div className='hud__top hud__center' />
      <div className='hud__top hud__right' />
      <div className='hud__bottom hud__left'>
        <ZoomSettings changeZoom={changeZoom} />
      </div>
      <div className='hud__bottom hud__center' />
      <div className='hud__bottom hud__right'>
        <GeneralInfo />
      </div>
    </div>
  );
};

export default HUD;
