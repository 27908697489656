import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import InfoIcon from '@material-ui/icons/Info';

import './style.css';

const LayerActionsModal = ({ open, onClose, submitLayer }) => {
  const [listName, setListName] = useState('');
  const [nameError, setNameError] = useState(false);

  useEffect(() => {
    if (!open) setListName('');
  }, [open]);

  useEffect(() => {
    if (listName.match(/[^a-zA-Z0-9 ]/g)) {
      setNameError(true);
    } else if (nameError) {
      setNameError(false);
    }
  }, [listName, nameError]);

  const _submitLayer = () => {
    submitLayer(listName);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className='layer-actions-modal'
      fullWidth
    >
      <DialogTitle disableTypography>
        <Typography variant='h6'>Listar empresas</Typography>
        <div className='layer-actions-modal__action-icons'>
          <Tooltip
            title='Esta ação irá listar todas as empresas que foram encontradas na camada através dos filtros.'
            arrow
          >
            <IconButton aria-label='info'>
              <InfoIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1'>Nome da lista</Typography>
        <TextField
          error={nameError}
          helperText={
            nameError && 'O nome da lista não deve conter caracteres especiais'
          }
          margin='dense'
          variant='outlined'
          name='listName'
          value={listName}
          onChange={(evt) => setListName(evt.target.value)}
          placeholder='Digite aqui o nome da lista'
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancelar
        </Button>
        <Button
          disabled={nameError || !(listName?.length)}
          onClick={_submitLayer}
          color='primary'
          variant="contained"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LayerActionsModal;
