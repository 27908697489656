import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FunctionUtils, AUEmptyData } from '@assertiva/assertiva-ui';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getExportListHistory } from '../../services/exportList';
import Drawer from '../customDrawer/CustomDrawer';
import HistoryFilter from './components/HistoryFilter';
import FilterChip from './components/FilterChip';
import { ellipsisString } from '../../utils/general';
import Constants from './constants';

import './style.css';

const ListHistory = ({
  open,
  onClose,
  showDrawerLoading,
  hideDrawerLoading,
}) => {
  const { loading } = useSelector((state) => ({
    loading: state.actionsDrawer.loading.isShowing,
  }));
  const [listHistory, setListHistory] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterData, setFilterData] = useState(Constants.defaultFilterData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterChip, setFilterChip] = useState();

  const mountChip = (options) =>
    options
      ? Object.keys(options).map((op) => {
          return {
            name: op,
            value: options[op],
          };
        })
      : [];

  const fetchApi = (options = null) => {
    let requestObj = null;

    showDrawerLoading();
    setOpenFilter(false);
    setPage(0);
    setRowsPerPage(10);
    setFilterChip([]);

    if (options && Object.keys(options).length) {
      requestObj = {
        ...options,
        dateIni: options.dates?.dateIni.value,
        dateFin: options.dates?.dateFin.value,
      };

      setFilterChip(mountChip(options));
    }

    getExportListHistory(requestObj)
      .then(({ data: list }) => {
        setListHistory(list.data);
      })
      .catch(() => {
        setListHistory([]);
        setFilterChip([]);
      })
      .finally(() => {
        hideDrawerLoading();
      });
  };

  useEffect(() => {
    if (open) {
      fetchApi();
    } else {
      setFilterData(Constants.defaultFilterData);
      setFilterChip([]);
    }
  }, [open]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {Constants.listHeader.map((headCell) => (
          <TableCell key={headCell.id} align='center'>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      classes={{ paper: 'list-history-drawer' }}
      id='historicoLista-drawer'
      anchor='right'
      title='Histórico de listas'
    >
      {loading && (
        <div className='actions-drawer__loading'>
          <CircularProgress />
        </div>
      )}
      <Paper variant='outlined' className='list-history-drawer__paper'>
        <TableContainer>
          <Toolbar className='list-history-drawer__toolbar'>
            <Typography className='list-history-drawer__toolbar_title'>
              Listas geradas
            </Typography>
            <div className='list-history-drawer__toolbar_action'>
              <Tooltip title='Filtrar listas'>
                <IconButton onClick={() => setOpenFilter(!openFilter)}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
              <FilterChip
                filterChip={filterChip}
                setFilterChip={setFilterChip}
                fetchApi={fetchApi}
                filterData={filterData}
                setFilterData={setFilterData}
              />
            </div>
          </Toolbar>
          <HistoryFilter
            open={openFilter}
            setOpenFilter={setOpenFilter}
            setFilterData={setFilterData}
            filterData={filterData}
            fetchApi={fetchApi}
          />
          <Table className='list-history-drawer__table'>
            <TableHeader />
            <TableBody>
              {listHistory &&
                FunctionUtils.sliceArrayPerPage(
                  listHistory,
                  page,
                  rowsPerPage
                ).map((row, index) => {
                  return (
                    <TableRow
                      hover
                      onClick={() => null}
                      key={`${row.name}-${index}`}
                    >
                      <Tooltip
                        title={row.name.length > 25 ? row.name : ''}
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 125 }}
                        placement='bottom'
                      >
                        <TableCell align='center'>
                          {ellipsisString(row.name)}
                        </TableCell>
                      </Tooltip>
                      <TableCell align='center'>{row.createdat}</TableCell>
                      <TableCell align='center'>{row.rowcount}</TableCell>
                      <TableCell align='center'>
                        {!row.expired
                          ? `${row.downloadLimitDate} dias`
                          : 'Expirado'}
                      </TableCell>
                      <TableCell
                        className='list-history-drawer_action-cell'
                        align='center'
                      >
                        <IconButton
                          onClick={() =>
                            row.s3key && window.open(row.s3key, '_blank')
                          }
                          disabled={row.expired || !row.s3key.length}
                        >
                          <KeyboardTabIcon
                            classes={{
                              root: `list-history-drawer__download_icon${
                                row.expired || !row.s3key.length
                                  ? '_disabled'
                                  : ''
                              }`,
                            }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {!listHistory.length && (
          <AUEmptyData
            boxProps={{
              p: 2,
            }}
            subtitle='Nenhuma lista encontrada.'
          />
        )}
        <TablePagination
          rowsPerPageOptions={Constants.rowsPerPage}
          component='div'
          count={listHistory?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Linhas por página'
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
        />
      </Paper>
    </Drawer>
  );
};

export default ListHistory;
