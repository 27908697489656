import React, { useState, useEffect, memo } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { ChromePicker } from 'react-color';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import Divider from '@material-ui/core/Divider';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import LayerFilterItem from '../../components/layerFilterItem/LayerFilterItem';

import { ellipsisString } from '../../../../utils/general';
import { sendAnalytics } from '../../../../utils/analytics';

import './style.css';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
  },
  notAllowed: {
    cursor: 'not-allowed',
  },
});

const LayerItem = ({
  layerId,
  layerName,
  layerColor,
  selectedFilters,
  layerData,
  filters,
  editLayer,
  deleteLayer,
  changeLayerColor,
  changeLayerName,
  duplicateLayer,
  openLayerActionsModal,
  setOnSelectMode,
  selectLayer,
  editingLayerId,
  isEditing,
}) => {
  const classes = useStyles();

  const [activeFilter, setActiveFilter] = useState('');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [colorPicker, setColorPicker] = useState(layerColor);
  const [onRenameLayer, setOnRenameLayer] = useState(false);

  const [newLayerName, setNewLayerName] = useState(null);

  const [anchorElLayerOptions, setAnchorElLayerOptions] = React.useState(null);
  const openLayerOptions = Boolean(anchorElLayerOptions);

  const _selectedFilters = selectedFilters.filter(
    (selectedFilter, index) =>
      !selectedFilters.some(
        (_selectedFilter, _index) =>
          _index > index &&
          selectedFilter.filterId === _selectedFilter.filterId &&
          selectedFilter.categoryId === _selectedFilter.categoryId
      )
  );

  useEffect(() => {
    if (!onRenameLayer) setNewLayerName(null);
  }, [onRenameLayer]);

  const closeRenameLayer = () => {
    setOnRenameLayer(false);
    setAnchorElLayerOptions(false);
  };

  const handleDuplicateLayer = (layerName, selectedFilters, layerData) => {
    duplicateLayer({ layerName, selectedFilters, layerData });
    setAnchorElLayerOptions(false);
  };

  const handlekeyDown = (event) => {
    if (event.key === 'Enter') {
      if (newLayerName) {
        changeLayerName({ layerId, newLayerName, layerName });
        selectLayer({ layerData, layerId, layerName: newLayerName });
      }
      closeRenameLayer();
    } else if (event.key === 'Escape') {
      closeRenameLayer();
    }
  };

  const handleClickLayerOptions = (event) => {
    event.stopPropagation();
    setAnchorElLayerOptions(event.currentTarget);
  };

  const handleCloseLayerOptions = () => {
    setAnchorElLayerOptions(null);
  };

  return (
    <div className='layers__item'>
      <Paper
        elevation={editingLayerId === layerId ? 8 : 0}
        onClick={(e) => !isEditing && selectLayer({ layerData, layerId, layerName })}
        className={isEditing ? classes.notAllowed : classes.root}
      >
        <div
          className='layers__color_indicator'
          style={{ backgroundColor: layerColor }}
        />
        {showColorPicker && (
          <div
            className='layers__item__color_picker_div'
            onClick={(event) => event.stopPropagation()}
          >
            <div
              className='layers__item__color_picker_exit'
              onClick={(event) => {
                event.stopPropagation();
                setShowColorPicker(false);
              }}
            />
            <ChromePicker
              disableAlpha
              color={colorPicker}
              onChange={({ hex }) => setColorPicker(hex)}
              onChangeComplete={({ hex }) =>
                changeLayerColor({
                  layerId,
                  color: hex,
                })
              }
            />
          </div>
        )}
        <div className='layers__item__title'>
          {!onRenameLayer ? (
            <>
              <div>
                <LightTooltip
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 125 }}
                  title={layerName?.length > 20 ? layerName : ''}
                  placement='right-end'
                >
                  <Typography
                    variant='body1'
                    display='inline'
                    className='layers__layer_name'
                  >
                    {ellipsisString(layerName, 20)}&nbsp;
                  </Typography>
                </LightTooltip>
              </div>
              <div>
                <IconButton
                  className='layer-dropdown'
                  aria-label='more'
                  aria-controls={`layerDropdown-${layerId}`}
                  aria-haspopup='true'
                  onClick={handleClickLayerOptions}
                  disabled={isEditing}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id={`layerDropdown-${layerId}`}
                  anchorEl={anchorElLayerOptions}
                  keepMounted
                  open={openLayerOptions}
                  onClose={(event) => {
                    event.stopPropagation();
                    handleCloseLayerOptions();
                  }}
                >
                  <MenuItem
                    key='renameLayer'
                    value='renameLayer'
                    onClick={(event) => {
                      event.stopPropagation();
                      setOnRenameLayer(true);
                    }}
                  >
                    Renomear
                  </MenuItem>
                  <MenuItem
                    key='editLayer'
                    value='editLayer'
                    onClick={(event) => {
                      editLayer(layerId);
                      handleCloseLayerOptions();
                    }}
                  >
                    Editar
                  </MenuItem>
                  <MenuItem
                    key='changeColor'
                    value='changeColor'
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowColorPicker(true);
                      sendAnalytics({
                        event: 'alterarCor-button',
                        page: 'camada',
                      });
                      handleCloseLayerOptions();
                    }}
                  >
                    Alterar cor
                  </MenuItem>
                  <MenuItem
                    key='createLayerAction'
                    value='createLayerAction'
                    onClick={(event) => {
                      event.stopPropagation();
                      openLayerActionsModal({ layerId });
                      sendAnalytics({
                        event: 'criarLista-button',
                        page: 'camada',
                      });
                    }}
                  >
                    Criar lista
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    key='selectLayers'
                    value='selectLayers'
                    onClick={(event) => {
                      event.stopPropagation();
                      setOnSelectMode(true);
                    }}
                  >
                    Selecionar...
                  </MenuItem>
                  <MenuItem
                    key='duplicateLayer'
                    value='duplicateLayer'
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDuplicateLayer(
                        layerName,
                        selectedFilters,
                        layerData
                      );
                    }}
                  >
                    Duplicar
                  </MenuItem>
                  <MenuItem
                    key='deleteLayer'
                    value='deleteLayer'
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteLayer({ layersId: [layerId] });
                    }}
                  >
                    Deletar
                  </MenuItem>
                </Menu>
              </div>
            </>
          ) : (
            <div
              style={{ width: '100%' }}
              onClick={(event) => event.stopPropagation()}
            >
              <TextField
                id={`renomearCamada-nome-input-${layerId}`}
                className='rename-layer-input'
                margin='dense'
                variant='outlined'
                name='layerName'
                value={newLayerName == null ? layerName : newLayerName}
                onChange={(evt) => setNewLayerName(evt.target.value)}
                onKeyDown={handlekeyDown}
                onBlur={closeRenameLayer}
                autoFocus={true}
                fullWidth
              />
              <Typography
                variant='body1'
                color='textSecondary'
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  margin: '0px 10px',
                }}
              >
                Pressione Enter para salvar ou Esc para cancelar
              </Typography>
            </div>
          )}
        </div>
        <div>
          <Divider />
          {_selectedFilters.map(({ categoryId, filterId }, index) => {
            const { label, attributes } =
              filters[categoryId].filtersById[filterId];

            return (
              <LayerFilterItem
                key={`layerItem-${index}`}
                LightTooltip={LightTooltip}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
                layerId={layerId}
                categoryId={categoryId}
                filterId={filterId}
                label={label}
                attributes={attributes}
                layerData={layerData}
              />
            );
          })}
        </div>
      </Paper>
    </div>
  );
};

export default memo(LayerItem);
