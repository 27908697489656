import { styled, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const Styles = makeStyles(() => ({
  paginationButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '10px',
  },
}));

export const IconButton = styled(Button)({
  minWidth: '24px',
  padding: '0px',
});
