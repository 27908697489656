import {
  INIT_MAP,
  SWITCH_TRAFFIC,
  CHANGE_DRAWING_MODE,
  ADD_DRAWING,
  CHANGE_MAP_TYPE,
  SWITCH_DATA_TYPE,
  SHOW_MAP_LOADING,
  HIDE_MAP_LOADING,
} from './actions';

const initialState = {
  bounds: {},
  heatmap: [],
  dataType: 'marker',
  trafficEnabled: false,
  drawingMode: null,
  drawings: [],
  mapType: 'roadmap',
  mapLoading: { count: 0, isShowing: false },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT_MAP: {
      const { map } = payload;

      window.map = map;
      window.trafficLayer = new window.google.maps.TrafficLayer();

      // TODO Enable drawing Google API
      // window.drawingManager = new window.google.maps.drawing.DrawingManager({
      //   drawingControl: false,
      //   drawingControlOptions: {
      //     drawingModes: ['circle', 'polygon', 'rectangle'],
      //   },
      //   circleOptions: {
      //     draggable: true,
      //     editable: true,
      //     fillColor: '#8A00C0',
      //   },
      //   polygonOptions: {
      //     draggable: true,
      //     editable: true,
      //     fillColor: '#8A00C0',
      //   },
      //   rectangleOptions: {
      //     draggable: true,
      //     editable: true,
      //     fillColor: '#8A00C0',
      //   },
      // });

      window.trafficLayer.setMap(null);
      // window.drawingManager.setMap(window.map);

      return { ...state };
    }

    case SWITCH_TRAFFIC: {
      if (!state.trafficEnabled) window.trafficLayer.setMap(window.map);
      else window.trafficLayer.setMap(null);

      return { ...state, trafficEnabled: !state.trafficEnabled };
    }

    case CHANGE_DRAWING_MODE: {
      let _drawingMode = payload;

      if (window.drawingManager.getDrawingMode() === _drawingMode)
        _drawingMode = null;

      window.drawingManager.setDrawingMode(_drawingMode);

      return { ...state, drawingMode: _drawingMode };
    }

    case ADD_DRAWING: {
      const { drawingMode, drawingData } = payload;

      return { ...state, drawings: [{ drawingMode, drawingData }] };
    }

    case CHANGE_MAP_TYPE: {
      window.map.setMapTypeId(payload);
      window.trafficLayer.setMap(null);

      return { ...state, mapType: payload, trafficEnabled: false };
    }

    case SWITCH_DATA_TYPE: {
      return {
        ...state,
        dataType: state.dataType === 'marker' ? 'heatmap' : 'marker',
      };
    }

    case SHOW_MAP_LOADING: {
      return {
        ...state,
        mapLoading: {
          count: state.mapLoading.count + 1,
          isShowing: true,
        },
      };
    }

    case HIDE_MAP_LOADING: {
      return {
        ...state,
        mapLoading: {
          count: state.mapLoading.count > 0 ? state.mapLoading.count - 1 : 0,
          isShowing: state.mapLoading.count - 1 > 0,
        },
      };
    }

    default:
      return state;
  }
};
