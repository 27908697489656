import React, { useCallback, useEffect } from 'react';
import * as DateFns from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import EventIcon from '@material-ui/icons/Event';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Constants from '../constants';

import './style.css';

const HistoryFilter = ({
  open,
  setOpenFilter,
  filterData,
  setFilterData,
  fetchApi,
}) => {
  const escapeFunction = useCallback(
    (ev) => {
      if (ev.keyCode === 27) {
        setOpenFilter(false);
        setFilterData(Constants.defaultFilterData);
      }
    },
    [setOpenFilter, setFilterData]
  );

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', escapeFunction, false);
    } else {
      document.removeEventListener('keydown', escapeFunction, false);
    }
  }, [open, escapeFunction]);

  const handleDateChange = (name) => (date) => {
    setFilterData({
      ...filterData,
      dates: {
        ...filterData.dates,
        [name]: {
          date,
          value: date && DateFns.format(date, 'dd/MM/yyyy'),
        },
      },
    });
  };

  const handleDisabled =
    !filterData.listName &&
    !filterData.dates.dateIni.value &&
    !filterData.dates.dateFin.value;

  return (
    open && (
      <Paper elevation={4} classes={{ root: 'history-filter' }}>
        <div className='history-filter__header'>
          <Typography className='history-filter__header_title'>
            Filtros
          </Typography>
          <Typography className='history-filter__header_subtitle'>
            Utilize as opções abaixo para exibir apenas as listas desejadas.
          </Typography>
        </div>
        <div className='history-filter__body'>
          <Typography className='history-filter__body_title'>Opções</Typography>
          <TextField
            id='list-name'
            size='small'
            className='history-filter__body_field'
            label='Nome da lista'
            fullWidth
            value={filterData.listName}
            onChange={(event) => {
              setFilterData({
                ...filterData,
                listName: event.target.value,
              });
            }}
            variant='outlined'
          />
          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <DatePicker
              id='date-initial'
              className='history-filter__body_field'
              size='small'
              fullWidth
              clearable
              cancelLabel='Cancelar'
              clearLabel='Limpar'
              inputVariant='outlined'
              format='dd/MM/yyyy'
              label='De'
              value={filterData.dates.dateIni.date}
              maxDate={filterData.dates.dateFin.date || new Date()}
              onChange={handleDateChange('dateIni')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <EventIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <DatePicker
              id='date-final'
              className='history-filter__body_field'
              size='small'
              fullWidth
              clearable
              cancelLabel='Cancelar'
              clearLabel='Limpar'
              inputVariant='outlined'
              format='dd/MM/yyyy'
              label='Até'
              value={filterData.dates.dateFin.date}
              maxDate={new Date()}
              minDate={filterData.dates.dateIni.date || new Date('01-01-1970')}
              minDateMessage='Data final deve ser maior que a inicial'
              onChange={handleDateChange('dateFin')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <EventIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </MuiPickersUtilsProvider>
          <FormControlLabel
            className='history-filter__checkbox'
            control={
              <Checkbox
                checked={filterData.showExpired}
                onChange={(event) =>
                  setFilterData({
                    ...filterData,
                    showExpired: event.target.checked,
                  })
                }
                name='showExpired'
                color='primary'
              />
            }
            label={
              <Typography className='history-filter__checkbox_label'>
                Mostrar listas expiradas
              </Typography>
            }
          />
        </div>
        <div className='history-filter__footer'>
          <Button
            className='history-filter__footer_button'
            size='medium'
            color='primary'
            variant='text'
            onClick={() => setOpenFilter(false)}
          >
            Fechar
          </Button>
          <Button
            className='history-filter__footer_button'
            size='medium'
            color='primary'
            variant='contained'
            disabled={handleDisabled}
            onClick={() => {
              const requestObj = {
                showExpired: filterData.showExpired,
                ...(filterData.listName
                  ? { listName: filterData.listName }
                  : {}),
                ...(filterData.dates?.dateIni.value ||
                filterData.dates?.dateFin.value
                  ? { dates: filterData.dates }
                  : {}),
              };

              fetchApi(requestObj);
            }}
          >
            Aplicar
          </Button>
        </div>
      </Paper>
    )
  );
};

export default HistoryFilter;
