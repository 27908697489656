import defaultAxios from 'axios';
import queryString from 'query-string';
import axios, { BASE_URL, cancelTokenSource } from './base';

let cancelFn = null;

export const getBlacklist = (params) => {
  const { filter = null, pagination = { page: 1, rowsPerPage: 5 } } = params;
  const { cnpj = null, dates = { dateIni: null, dateFin: null } } =
    filter || {};

  return axios.get(`${BASE_URL}/config/blacklist/pj`, {
    params: {
      ...(cnpj.value ? { cnpj: cnpj?.value } : {}),
      ...(dates?.dateIni.value ? { dateIni: dates.dateIni.value } : {}),
      ...(dates?.dateFin.value ? { dateFin: dates.dateFin.value } : {}),
      page: pagination.page,
      limit: pagination.rowsPerPage,
    },
    paramsSerializer: queryString.stringify,
  });
};

export const getPresignedUrl = ({ name: fileName, type: fileType }) => {
  return axios.post(`${BASE_URL}/config/blacklist/pj/upload-url`, {
    fileName,
    fileType,
  });
};

export const uploadFileByPresignedUrl = (uploadUrl, file, callback) => {
  return defaultAxios.put(uploadUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress: (event) => {
      const progress = Math.round((event.loaded * 100) / event.total);
      callback(progress);
    },
    cancelToken: new cancelTokenSource((cancel) => {
      cancelFn = cancel;
    }),
  });
};

export const postSingleBlacklist = ({ cnpj, description = '' }) => {
  return axios.post(`${BASE_URL}/config/blacklist/pj/single`, {
    cnpj,
    description,
  });
};

export const cancelUploadProgress = () => cancelFn && cancelFn();

export const postFileBlacklist = ({ s3Input, blacklistFileId }) => {
  return axios.post(`${BASE_URL}/config/blacklist/pj/file`, {
    s3File: s3Input,
    blacklistFileId,
  });
};

export const getBlacklistFileStatus = (blacklistId) => {
  return axios.get(
    `${BASE_URL}/config/blacklist/pj/file/${blacklistId}/status`
  );
};

export const deleteBlacklistItems = (blacklistItems) => {
  return axios.delete(`${BASE_URL}/config/blacklist/pj`, {
    data: { blacklistIds: blacklistItems },
  });
};
