import { useState, useMemo } from 'react';
import filesize from 'filesize';

import {
  getPresignedUrl,
  uploadFileByPresignedUrl,
  postFileBlacklist,
  getBlacklistFileStatus,
} from 'services/blacklist';

import GeneralConstants from '../../../../constants/general';

const useUpload = ({ cleanFilterAndPagination, onClose, showNotify }) => {
  const [currentStep, setCurrentStep] = useState(
    GeneralConstants.uploadStep.EMPTY
  );
  const [processStep, setProcessStep] = useState(
    GeneralConstants.processStep.NOT_STARTED
  );
  const [barProgress, setBarProgress] = useState(0);
  const [file, setFile] = useState({ name: '', size: 0, type: '' });
  const [s3Input, setS3Input] = useState('');
  const [blacklistFileId, setBlacklistFileId] = useState(null);
  const [fileInfo, setFileInfo] = useState({
    total: 0,
    validItems: 0,
    invalidItems: 0,
  });

  const { isUploadEmpty, isUploadProgress, isUploadCompleted } = useMemo(
    () => ({
      isUploadEmpty: currentStep === GeneralConstants.uploadStep.EMPTY,
      isUploadProgress: currentStep === GeneralConstants.uploadStep.PROGRESS,
      isUploadCompleted: currentStep === GeneralConstants.uploadStep.COMPLETED,
    }),
    [currentStep]
  );

  const {
    isProcessNotStarted,
    isProcessInProgress,
  } = useMemo(
    () => ({
      isProcessNotStarted:
        processStep === GeneralConstants.processStep.NOT_STARTED,
      isProcessStarted: processStep === GeneralConstants.processStep.STARTED,
      isProcessValidating:
        processStep === GeneralConstants.processStep.VALIDATING,
      isProcessInProgress:
        processStep === GeneralConstants.processStep.PROCESSING,
      isProcessDone: processStep === GeneralConstants.processStep.DONE,
    }),
    [processStep]
  );

  const getProcessProgress = useMemo(() => {
    switch (processStep) {
      case GeneralConstants.processStep.STARTED:
        return { label: 'Leitura do arquivo iniciada...', bar: 25 };
      case GeneralConstants.processStep.VALIDATING:
        return { label: 'Validando...', bar: 50 };
      case GeneralConstants.processStep.PROCESSING:
        return { label: 'Processando os dados...', bar: 75 };
      case GeneralConstants.processStep.DONE:
        return { label: 'Processamento finalizado!', bar: 100 };
      default:
        return { label: 'Processo não iniciado.', bar: 0 };
    }
  }, [processStep]);

  const removeAfterQuery = (url) => url.replace(/\?.*$/, '');
  const getFileSizeString = (size) =>
    filesize(size, {
      round: 0,
    });

  const handleExtension = (fileUploaded, types = []) => {
    const extractExtension = fileUploaded?.name.split('.').pop();
    const fileSize = fileUploaded?.size;

    return {
      getType: `.${extractExtension}`,
      checkType: types.some((value) => value === `.${extractExtension}`),
      checkSize: fileSize > GeneralConstants.fileSizeBlacklist,
    };
  };

  const handleExtensionsForceCSV = ({
    name,
    type,
    size,
    lastModified,
    lastModifiedDate,
  }) => {
    const [, extension] = name.split('.');
    if (extension === 'csv') {
      return { name, size, type: 'text/csv', lastModified, lastModifiedDate };
    } else {
      return { name, type, size, lastModified, lastModifiedDate };
    }
  };

  const selectFileEvent = async (event) => {
    if (currentStep !== GeneralConstants.uploadStep.EMPTY) {
      cancelUpload();
      return;
    }

    const eventFile = event.target.files[0];
    setFile(handleExtensionsForceCSV(eventFile));

    if (
      !handleExtension(eventFile, GeneralConstants.fileTypesBlacklist).checkType
    ) {
      showNotify({
        message: `"${
          handleExtension(eventFile).getType
        }" - Tipo de arquivo não suportado.`,
        type: 'error',
      });
      cancelUpload();
      return;
    }

    if (handleExtension(eventFile).checkSize) {
      showNotify({
        message: 'Tamanho do arquivo não pode ser superior à 200 MB.',
        type: 'error',
      });
      cancelUpload();
      return;
    }

    setCurrentStep(GeneralConstants.uploadStep.PROGRESS);
    await uploadFile(
      new Blob([eventFile], { type: handleExtensionsForceCSV(eventFile).type }),
      handleExtensionsForceCSV(eventFile)
    );
  };

  const uploadFile = async (fileBlob, fileJSON) => {
    try {
      const { data: response } = await getPresignedUrl(fileJSON);
      const { url: presignedUrl, blacklistFileId: fileId } = response.data;

      const uploadedFile = await uploadFileByPresignedUrl(
        presignedUrl,
        fileBlob,
        (progress) => setBarProgress(progress)
      );

      setS3Input(String(removeAfterQuery(uploadedFile.config.url)));
      setBlacklistFileId(fileId);
      setCurrentStep(GeneralConstants.uploadStep.COMPLETED);
    } catch (err) {
      showNotify({
        message: 'Ocorreu um erro, Tente novamente mais tarde.',
        type: GeneralConstants.toastType.ERROR,
      });
      setCurrentStep(GeneralConstants.uploadStep.EMPTY);
      cancelUpload();
    }
  };

  const addFileBlacklist = async () => {
    try {
      setProcessStep(GeneralConstants.processStep.STARTED);
      const { data: response } = await postFileBlacklist({
        s3Input,
        blacklistFileId,
      });
      setFileInfo({
        total: response.data.total,
        validItems: response.data.validItems,
        invalidItems: response.data.invalidItems,
      });
      setProcessStep(GeneralConstants.processStep.VALIDATING);

      const interval = setInterval(async () => {
        try {
          const { data: fileStatus } = await getBlacklistFileStatus(
            blacklistFileId
          );
          const { status, message } = fileStatus.data;

          if (status !== GeneralConstants.processStep.STARTED) {
            switch (status) {
              case GeneralConstants.processStep.PROCESSING:
                setProcessStep(GeneralConstants.processStep.PROCESSING);
                break;
              case GeneralConstants.processStep.DONE:
                showNotify({
                  message: `O lote de ${response.data.validItems} CNPJs foram adicionados a blacklist.`,
                });

                setProcessStep(GeneralConstants.processStep.DONE);
                cleanFilterAndPagination();
                clearInterval(interval);
                onClose();
                break;
              case GeneralConstants.processStep.ERROR:
                showNotify({
                  message:
                    message === ''
                      ? 'Ocorreu um erro durante a requisição. Por favor tente novamente ou contate o suporte.'
                      : message,
                  type: GeneralConstants.toastType.ERROR,
                });
                clearInterval(interval);
                cancelUpload();
                break;
              default:
                clearInterval(interval);
            }
          }
        } catch (err) {
          if (err?.response && err.response?.status === 422) {
            showNotify({
              message: err.response.data.message,
              type: GeneralConstants.toastType.ERROR,
            });
          } else {
            showNotify({
              message: 'Ocorreu um erro, Tente novamente mais tarde.',
              type: GeneralConstants.toastType.ERROR,
            });
          }
          clearInterval(interval);
          cancelUpload();
        }
      }, 2500);
    } catch (err) {
      if (err?.response && err.response?.status === 422) {
        showNotify({
          message: err.response.data.message,
          type: GeneralConstants.toastType.ERROR,
        });
      } else {
        showNotify({
          message: 'Ocorreu um erro, Tente novamente mais tarde.',
          type: GeneralConstants.toastType.ERROR,
        });
      }
      cancelUpload();
    }
  };

  const cancelUpload = () => {
    setBarProgress(0);
    setFile({ name: '', size: 0, type: '' });
    setS3Input('');
    setCurrentStep(GeneralConstants.uploadStep.EMPTY);
    setProcessStep(GeneralConstants.processStep.NOT_STARTED);
    setBlacklistFileId(null);
    setFileInfo({ total: 0, validItems: 0, invalidItems: 0 });
  };

  return {
    barProgress,
    selectFileEvent,
    isUploadEmpty,
    isUploadProgress,
    isUploadCompleted,
    isProcessNotStarted,
    isProcessInProgress,
    file,
    cancelUpload,
    getFileSizeString,
    addFileBlacklist,
    fileInfo,
    getProcessProgress,
  };
};

export default useUpload;
