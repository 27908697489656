import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';

const CRMCampaignConfig = ({
	users,
	selectedUsers: _selectedUsers,
	origins,
	selectedOrigin: _selectedOrigin,
	suborigins,
	selectedSuborigin: _selectedSuborigin,
	nextStep,
	prevStep,
}) => {
	const [selectedUsers, setSelectedUsers] = useState(_selectedUsers);
	const [selectedOrigin, setSelectedOrigin] = useState(_selectedOrigin);
	const [selectedSuborigin, setSelectedSuborigin] =
		useState(_selectedSuborigin);

	const onChangeUsers = (selectedUsers) => {
		const isSelectedAll =
			selectedUsers.length > 0 &&
			selectedUsers[selectedUsers.length - 1].id === -1;

		if (isSelectedAll)
			setSelectedUsers([
				{
					id: -1,
					name: 'TODOS',
				},
			]);
		else setSelectedUsers(selectedUsers.filter(({ id }) => id !== -1));
	};

	return (
		<div className='crm-export__content__step'>
			<div class='crm-export__content__step__origin'>
				<Typography variant='h6'>Selecione a Origem</Typography>
				<Typography variant='body2' gutterBottom>
					Selecione a origem que as oportunidades irão mostrar.
				</Typography>
				<Autocomplete
					options={origins}
					getOptionLabel={(option) => option.name}
					noOptionsText='Nenhuma origem encontrada'
					renderInput={(params) => (
						<TextField
							{...params}
							variant='outlined'
							fullWidth
							margin='dense'
							placeholder='Selecione a origem'
						/>
					)}
					value={selectedOrigin}
					onChange={(event, newValue) => {
						setSelectedOrigin(newValue);
					}}
				/>
			</div>

			<div class='crm-export__content__step__suborigin'>
				<Typography variant='h6'>Selecione a Sub-origem</Typography>
				<Typography variant='body2' gutterBottom>
					Selecione a sub-origem que as oportunidades irão mostrar.
				</Typography>
				<Autocomplete
					options={
						(selectedOrigin && suborigins && suborigins[selectedOrigin.id]) ||
						[]
					}
					getOptionLabel={(option) => option.name}
					noOptionsText='Nenhuma sub-origem encontrada'
					renderInput={(params) => (
						<TextField
							{...params}
							variant='outlined'
							fullWidth
							margin='dense'
							placeholder='Selecione a sub-origem'
						/>
					)}
					value={selectedSuborigin}
					onChange={(event, newValue) => {
						setSelectedSuborigin(newValue);
					}}
					disabled={!selectedOrigin}
				/>
			</div>

			<div class='crm-export__content__step__users'>
				<Typography variant='h6'>
					Distribuir oportunidades para os consultores:
				</Typography>
				<Typography variant='body2' gutterBottom>
					Defina a distribuição das oportunidades criadas para os consultores.
				</Typography>
				<Autocomplete
					multiple
					options={[
						{
							id: -1,
							name: 'TODOS',
						},
						...users,
					]}
					getOptionLabel={(option) => option.name}
					noOptionsText='Nenhum consultor encontrado'
					defaultValue={selectedUsers}
					filterSelectedOptions
					renderInput={(params) => (
						<TextField
							{...params}
							variant='outlined'
							fullWidth
							margin='dense'
						/>
					)}
					ChipProps={{ size: 'small' }}
					value={selectedUsers}
					onChange={(event, newValue) => {
						onChangeUsers(newValue);
					}}
				/>
			</div>

			<div className='crm-export__content__step__footer'>
				<Grid container spacing={2}>
					<Grid item>
						<Button
							variant='contained'
							color='primary'
							onClick={() =>
								nextStep({
									selectedUsers,
									selectedOrigin,
									selectedSuborigin,
								})
							}
							disabled={!selectedOrigin || selectedUsers.length < 1}
						>
							Continuar
						</Button>
					</Grid>
					<Grid item>
						<Button
							variant='outlined'
							color='primary'
							onClick={() =>
								prevStep({
									selectedUsers,
									selectedOrigin,
									selectedSuborigin,
								})
							}
						>
							Voltar
						</Button>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

CRMCampaignConfig.defaultProps = {
	users: [],
	selectedUsers: [
		{
			id: -1,
			name: 'TODOS',
		},
	],
	origins: [],
	selectedOrigin: null,
	suborigins: {},
	selectedSuborigin: null,
};

export default CRMCampaignConfig;
