import React from 'react';
import _find from 'lodash/find';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import AutocompleteComponent from '../components/filter/components/autocomplete/Autocomplete';
import LocationAutocomplete from '../components/filter/components/locationAutocomplete/LocationAutocomplete';
import Range from '../components/filter/components/range/Range';
import ScoreSelect from '../components/filter/components/scoreSelect/ScoreSelect';

import { formatLabelString } from './general';

export const filterFormat = {
  select: ({ value, options }) => {
    const option = _find(options, ['value', value]);
    return option && option.label;
  },
  autocomplete: ({ value, options }) => {
    if (Array.isArray(value)) {
      const labelArray = [];

      value.forEach((v) => {
        const opt = _find(options, ['value', v]);
        if (opt) {
          labelArray.push(opt.label);
        }
      });

      return formatLabelString(labelArray);
    }

    const option = _find(options, ['value', value]);
    return option && option.label;
  },
  locationAutocomplete: ({ value }) => {
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    return '';
  },
  input: ({ value }) => value,
  range: ({ value }) => `${value[0]} - ${value[1]}`,
  drawing: ({ value }) => value.join(', '),
  locality: ({ value }) => value[0],
  scoreSelect: ({ value, marks }) => {
    return value
      .map((e) => {
        const find = marks.find((mark) => mark.value === e);

        if (find) {
          return find.letter;
        }
        return '';
      })
      .join(' - ');
  },
};

export const filterFields = (
  handleChange,
  value,
  currentFilter,
  setCurrentFilter,
  addFilter
) => ({
  select: ({ id, label, name, options }) => {
    return (
      <FormControl variant='outlined' fullWidth>
        <Select
          id={`select-${id}`}
          value={JSON.stringify(value) || ''}
          placeholder={label}
          name={name}
          onChange={(event) =>
            handleChange({
              ...event,
              target: {
                ...event.target,
                value: JSON.parse(event.target.value),
              },
            })
          }
          margin='dense'
        >
          {options.map(({ label, value }, index) => (
            <MenuItem key={index} value={JSON.stringify(value)}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  },
  autocomplete: ({ id, name, options, ...rest }) => (
    <>
      <AutocompleteComponent
        id={id}
        name={name}
        options={options}
        rest={rest}
        handleChange={handleChange}
        value={value}
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
      />
    </>
  ),
  locationAutocomplete: ({ id, name, label, placesType, ...rest }) => (
    <>
      <LocationAutocomplete
        id={id}
        name={name}
        label={label}
        placesType={placesType}
        rest={rest}
        handleChange={handleChange}
        value={value}
      />
    </>
  ),
  input: ({ id, label, name }) => {
    return (
      <TextField
        id={`input-${id}`}
        placeholder={label}
        value={value}
        name={name}
        onChange={handleChange}
        variant='outlined'
        margin='dense'
        fullWidth
      />
    );
  },
  switch: ({ id, options }) => (
    <ToggleButtonGroup
      value='marker'
      exclusive
      // onChange={handleAlignment}
      id={`switch-${id}`}
      size='small'
      fullWidth
    >
      <ToggleButton value={options[0].value}>{options[0].label}</ToggleButton>
      <ToggleButton value={options[1].value}>{options[1].label}</ToggleButton>
    </ToggleButtonGroup>
  ),
  range: ({ id, categoryId, name, fieldId, min, max, isAge, isCurrency }) => {
    return (
      <Range
        id={id}
        categoryId={categoryId}
        name={name}
        value={value}
        handleChange={handleChange}
        fieldId={fieldId}
        min={min}
        max={max}
        isAge={isAge}
        isCurrency={isCurrency}
        addFilter={addFilter}
      />
    );
  },
  drawing: () => {
    return <span>{value.join(', ')}</span>;
  },
  locality: () => {
    return <span>{value[0]}</span>;
  },
  scoreSelect: ({ id, name, ...rest }) => {
    return (
      <ScoreSelect
        id={id}
        name={name}
        rest={rest}
        handleChange={handleChange}
        value={value}
        addFilter={addFilter}
      />
    );
  },
});
