import React from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';

import { StylesProvider } from '@material-ui/core/styles';
import { AURoutesGenerator, AUProvider } from '@assertiva/assertiva-ui';

import MainLayout from 'components/mainLayout/MainLayout';
import history from './history';
import store from './store';
import routes from './routes';

import './style.css';

ReactDOM.render(
  <AUProvider>
    <Provider store={store}>
      <StylesProvider injectFirst>
        <AURoutesGenerator
          history={history}
          routes={routes}
          defaultPrivateRedirect='/login'
          defaultPublicRedirect='/'
          NotFoundComponent={() => <Redirect to='/' />}
          PrivateLayout={(props) => <MainLayout {...props} />}
        />
      </StylesProvider>
    </Provider>
  </AUProvider>,
  document.getElementById('root')
);
