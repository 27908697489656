import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import './style.css';
import Typography from "@material-ui/core/Typography";

const getWarningContent = (type, data) => {
  switch(type) {
    case 'inactiveCompanies':
      const { inactiveCompanies } = data.summary;
      return (
        <Typography key={0} variant='body1' classes={{ root: 'export-list-warning-modal__content_text' }}>
            Sua lista contém {inactiveCompanies} empresa{inactiveCompanies > 1 ? 's' : ''} com Situação Cadastral Inativa.
            Ela{inactiveCompanies > 1 ? 's' : ''} ser{inactiveCompanies > 1 ? 'ão' : 'á'} tarifada{inactiveCompanies > 1 ? 's' : ''} da mesma forma que as empresas Ativas.
          </Typography>
      );
    case 'scoreRecuperePJ':
      return (
        <Typography key={1} variant='body1' classes={{ root: 'export-list-warning-modal__content_text' }}>
            Você utilizou o "Assertiva Score Recupere".
            Portanto é gerado uma cobrança extra no valor de cada lead.
        </Typography>
      );
    default:
      return '';
  }
}

const ExportListWaningModal = ({ open, onClose, summary = {}, onContinue, type }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className='export-list-warning-modal'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle classes={{ root: 'export-list-warning-modal__title' }}>Atenção!</DialogTitle>
      <DialogContent>
        <div className='export-list-warning-modal__content'>
          {getWarningContent(type, { summary })}
          <Typography key={2} variant='body1' classes={{ root: 'export-list-warning-modal__content_text' }}>
            Deseja continuar?
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onContinue} color='primary'>
          Continuar
        </Button>
        <Button onClick={onClose} color='primary'>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportListWaningModal;
