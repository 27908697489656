import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import Divider from '@material-ui/core/Divider';

import { ellipsisString } from '../../../../utils/general';
import LayerFilterItem from '../../components/layerFilterItem/LayerFilterItem';

import './style.css';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const LayerSelect = ({
  layerId,
  layerName,
  selectedFilters,
  layersSelected,
  setLayersSelect,
  layerData,
  filters,
}) => {
  const [activeFilter, setActiveFilter] = useState('');
  const [checked, setChecked] = useState(false);

  const onClickPaper = () => {
    setLayersSelect(
      !checked
        ? Array.from(new Set([...layersSelected, layerId]))
        : layersSelected.filter((elem) => elem !== layerId)
    );

    setChecked(!checked);
  };

  useEffect(() => {
    setChecked(layersSelected.includes(layerId));
  }, [layersSelected, layerId]);

  return (
    <div className='layer-select'>
      <Paper
        variant='outlined'
        elevation={7}
        onClick={onClickPaper}
        classes={{
          root: checked
            ? 'layer-select__paper_button_active'
            : 'layer-select__paper_button',
        }}
      >
        <div className='layer-select__item__title'>
          <div className='layer-select__header_paper_div'>
            <LightTooltip
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 125 }}
              title={layerName?.length > 20 ? layerName : ''}
              placement='right-end'
            >
              <Typography
                variant='body1'
                display='inline'
                style={{ fontWeight: '500' }}
              >
                {ellipsisString(layerName, 20)}&nbsp;
              </Typography>
            </LightTooltip>
            <FormControl classes={{ root: 'layer-select__form_control' }}>
              <FormControlLabel
                classes={{ root: 'layer-select__form_control_label' }}
                control={
                  <Checkbox
                    checked={layersSelected.includes(layerId)}
                    color='primary'
                    id='layerSelect-checkbox'
                  />
                }
              />
            </FormControl>
          </div>
        </div>
        <div>
          <Divider />
          {selectedFilters.map(({ categoryId, filterId }, index) => {
            const { label, attributes } =
              filters[categoryId].filtersById[filterId];

            return (
              <LayerFilterItem
                key={`layerFilterItem-${index}`}
                LightTooltip={LightTooltip}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
                layerId={layerId}
                categoryId={categoryId}
                filterId={filterId}
                label={label}
                attributes={attributes}
                layerData={layerData}
              />
            );
          })}
        </div>
      </Paper>
    </div>
  );
};

export default LayerSelect;
