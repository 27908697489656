import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';

import { filterFormat } from '../../../../utils/filter';
import { ellipsisString } from '../../../../utils/general';

import './style.css';

const LayerFilterItem = ({
  activeFilter,
  setActiveFilter,
  categoryId,
  layerId,
  filterId,
  label,
  attributes,
  layerData,
  LightTooltip,
}) => {
  const htmlId = `${categoryId}-${layerId}-${filterId}`;
  const isActive = activeFilter === htmlId;
  const { fieldParams } = useSelector((state) => ({
    fieldParams: state.dashboard.fieldParams,
  }));

  return (
    <div
      className={`layers__item__filter ${isActive ? 'active' : ''}`}
      onMouseOver={() => setActiveFilter(htmlId)}
      onMouseLeave={() => setActiveFilter('')}
    >
      <div>
        <Typography variant='body2' className='layers__item__filter_label'>
          {label}
        </Typography>
        {attributes.map(({ type, name, ...rest }, index) => {
          const params = { value: layerData[name], ...rest };

          if (fieldParams[name]) params.options = fieldParams[name];

          return (
            <LightTooltip
              key={`tooltip-${index}`}
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 125 }}
              title={type !== 'autocomplete' ? '' : filterFormat[type](params)}
              placement='right-end'
            >
              <Typography variant='body2'>
                {ellipsisString(filterFormat[type](params), 28)}
              </Typography>
            </LightTooltip>
          );
        })}
      </div>
    </div>
  );
};

export default LayerFilterItem;
