import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CheckIcon from "@material-ui/icons/Check";

import "./style.css";
import Typography from "@material-ui/core/Typography";

const contentTexts = [
  'O Score Recupere da Assertiva é ideal para ser utilizado como apoio na',
  'recuperação de créditos e na evolução dos seguintes processos: gestão de',
  'cobrança, análise de clientes, identificação de alto potencial de',
  'pagamento, diminuição de custos operacionais, priorização de recuperação',
  'de dívida e melhora na eficiência de cobrança.'
];

const topicsTexts = [
  'Dados sociodemográficos',
  'Análise de perfil e histórico',
  'Renda Mensal Presumida',
  'Informações cadastrais'
]

const ExportListWaningModal = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="export-list-score-recupere-detail-modal"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Saiba mais</DialogTitle>
      <DialogContent>
        <div className="export-list-score-recupere-detail-modal__content">
          {contentTexts.map(text => <Typography
            variant="body1"
            classes={{
              root: "export-list-score-recupere-detail-modal__content_text",
            }}
          >
            {text}
          </Typography>)}
        </div>

        <div className="export-list-score-recupere-detail-modal__content2">
          <Typography
            color="primary"
            variant="subtitle1"
            classes={{
              root: "export-list-score-recupere-detail-modal__subtitle",
            }}
          >
            O que utilizamos para gerar esse Score?
          </Typography>
          {topicsTexts.map(text => 
            <div className="export-list-score-recupere-detail-modal__subcontent">
              <CheckIcon classes={{ root: "export-list-score-recupere-detail-modal__check_icon" }}/>
                <Typography classes={{ root: "export-list-score-recupere-detail-modal__subcontent_text" }}>
                  {text}
              </Typography>
            </div>)
          }
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportListWaningModal;
