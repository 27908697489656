import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import WarningIcon from '@material-ui/icons/Warning';

import listColumns from '../../../constants/exportListColumns';

const ConfirmExport = ({
  listQtdLines,
  allowExport,
  setAllowExport,
  config,
  newField,
}) => {
  const [showAllChipsParams, setShowAllChipsParams] = useState(
    config.params.length <= 11
  );
  return (
    <div className='export-list-modal__confirm-export'>
      <section>
        <Typography variant='h6' gutterBottom>
          Atenção!
        </Typography>
        <Typography
          variant='body1'
          className='export-list-modal__confirm-export__subtitle-utilization-term'
        >
          Este produto é destinado exclusivamente à busca de relacionamento com
          empresas. A utilização do dado pessoal do representante legal da
          Pessoa Jurídica eventualmente fornecido neste produto, possui o
          caráter exclusivo de localização/contato da pessoa jurídica. Os dados
          pessoais dos sócios não devem, sob nenhuma hipótese, ser utilizados
          para outra finalidade, senão, como representante legal da pessoa
          jurídica.
        </Typography>
      </section>

      <Paper
        elevation={0}
        classes={{ root: 'export-list-modal__confirm-export__warning' }}
      >
        <WarningIcon />
        <section>
          <Typography variant='body1' gutterBottom>
            {`Você está prestes a exportar um total de: ${listQtdLines} empresas.`}
          </Typography>
          <Typography className='export-list-modal__confirm-export__subtitle-confirm'>
            Confirmar esta operação significa que será cobrado um custo
            específico conforme expressado em seu contrato de serviço com a
            Assertiva.
          </Typography>
          <Typography variant='body1' gutterBottom>
            {`Dados selecionados: ${config.params.length}`}
          </Typography>
          <div className='export-list-modal__confirm-export__params-chips-container'>
            {[newField, ...listColumns].map(({ label, name }) => {
              if (config.params.slice(0, 11).includes(name)) {
                return (
                  <Chip
                    size='small'
                    label={label}
                    className='export-list-modal__confirm-export__params-chip'
                  />
                );
              }
            })}
            <Collapse in={showAllChipsParams}>
              {[newField, ...listColumns].map(({ label, name }) => {
                if (config.params.slice(11).includes(name)) {
                  return (
                    <Chip
                      size='small'
                      label={label}
                      className='export-list-modal__confirm-export__params-chip'
                    />
                  );
                }
              })}
            </Collapse>
            {!showAllChipsParams && '...'}
          </div>
          {config.params.length > 11 && (
            <Grid
              item
              xs={12}
              className='export-list-view-more-params-container'
            >
              <Button
                className='export-list-view-more-params-chips'
                color='primary'
                startIcon={
                  !showAllChipsParams ? <ExpandMoreIcon /> : <ExpandLessIcon />
                }
                onClick={() => setShowAllChipsParams(!showAllChipsParams)}
              >
                {!showAllChipsParams
                  ? 'Exibir todos dados selecionados'
                  : 'Exibir alguns dados selecionados'}
              </Button>
            </Grid>
          )}
        </section>
      </Paper>

      <FormControlLabel
        control={
          <Checkbox
            checked={allowExport}
            onChange={(evt, checked) => setAllowExport(checked)}
            color='primary'
          />
        }
        label='Declaro que li e concordo com as afirmações acima.'
      />
    </div>
  );
};

ConfirmExport.defaultProps = {
  allowExport: false,
};

export default ConfirmExport;
