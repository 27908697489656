import { GLOBAL_OPEN_DRAWER, GLOBAL_CLOSE_DRAWER } from './actions';

const initialState = {
  drawers: {
    CRMExport: { isOpen: false, data: {} },
    ListExport: { isOpen: false, data: {} },
    ListHistory: { isOpen: false, data: {} },
    AddBlacklist: { isOpen: false, data: {} },
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GLOBAL_OPEN_DRAWER: {
      const { drawerName, data } = payload;
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [drawerName]: { isOpen: true, data },
        },
      };
    }

    case GLOBAL_CLOSE_DRAWER: {
      const { drawerName } = payload;
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [drawerName]: { isOpen: false, data: {} },
        },
      };
    }

    default:
      return state;
  }
};
