import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import WarningIcon from '@material-ui/icons/Warning';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import Done from '@material-ui/icons/Done';

import { sendAnalytics } from '../../../utils/analytics';

const ListFileConfig = ({
  config,
  changeFileExtension,
  totalRows,
  wasExported,
  rowsExported,
  continueFromLastExport,
  setContinueFromLastExport,
  extractAll,
  setExtractAll,
  insertOnBlacklist,
  setInsertOnBlacklist,
  rowsToExport,
  setRowsToExport,
  exportLimit,
}) => {
  const remainingRows = totalRows - rowsExported;
  const checkLimit = () => {
    return totalRows > exportLimit || remainingRows > exportLimit;
  };
  const rowsToExportProps = {
    min: 1,
    max: checkLimit()
      ? exportLimit
      : extractAll || !continueFromLastExport
      ? totalRows
      : remainingRows,
    step: 1,
  };
  return (
    <div className='export-list-modal__step-2'>
      <Typography variant='body1'>
        Alguns sistemas e demandas exigem extensões específicas para funcionar
        corretamente, escolha entre:
      </Typography>

      <span className='export-list-modal__step-2__details'>
        <Done htmlColor='#51C256' />
        <Typography variant='body1'>
          .csv - Arquivo de texto separado por virgulas
        </Typography>
      </span>
      <span className='export-list-modal__step-2__details'>
        <Done htmlColor='#51C256' />
        <Typography variant='body1'>
          .txt - Arquivo de texto separado por ponto e virgula
        </Typography>
      </span>
      <span className='export-list-modal__step-2__details'>
        <Done htmlColor='#51C256' />
        <Typography variant='body1'>
          .xlsx - Planilha do Microsoft Excel
        </Typography>
      </span>

      <Typography variant='h6' className='export-list-modal__step-2__header'>
        Escolha a extensão do arquivo
      </Typography>

      <Typography variant='body1' gutterBottom>
        Escolha a extensão de arquivo que atende a sua necessidade.
      </Typography>

      <FormControl
        variant='outlined'
        margin='dense'
        style={{ minWidth: '210px' }}
      >
        <InputLabel id='exportarLista-extensao-select-label'>
          Selecione uma finalidade
        </InputLabel>
        <Select
          labelId='exportarLista-extensao-select-label'
          id='exportarLista-extensao-select'
          value={config.fileExtension}
          onChange={changeFileExtension}
          // autoWidth
        >
          <MenuItem
            id='extensao-csv-button'
            value='csv'
            onClick={sendAnalytics({
              event: 'csv-button',
              page: 'exportarLista',
            })}
          >
            CSV
          </MenuItem>
          <MenuItem
            id='extensao-txt-button'
            value='txt'
            onClick={sendAnalytics({
              event: 'txt-button',
              page: 'exportarLista',
            })}
          >
            TXT
          </MenuItem>
          <MenuItem
            id='extensao-xlsx-button'
            value='xlsx'
            onClick={sendAnalytics({
              event: 'xlsx-button',
              page: 'exportarLista',
            })}
          >
            XLSX
          </MenuItem>
        </Select>
      </FormControl>

      <Typography variant='h6' className='export-list-modal__step-2__header'>
        Defina a quantidade de linhas do arquivo
      </Typography>

      <Typography variant='body1' gutterBottom>
        Insira a quantidade de linhas que serão exportadas.
      </Typography>

      {wasExported && remainingRows > 0 && (
        <Paper
          variant='outlined'
          elevation={7}
          className='export-list-modal__step2__exportWarningPaper'
        >
          <WarningIcon style={{ fontSize: '3rem', paddingTop: 15 }} />

          <div className='export-list-modal__step2__exportWarningSwitch'>
            <Typography
              variant='caption'
              style={{ fontSize: 12, padding: '15px 0 15px 15px' }}
            >
              Essa lista já teve linhas exportadas anteriormente, você pode
              continuar a extração de onde parou ou exportar a totalidade da
              lista.
            </Typography>

            <FormControlLabel
              control={
                <Switch
                  color='primary'
                  onChange={(evt, checked) => {
                    setContinueFromLastExport(checked);
                    if (checked) {
                      setExtractAll(false);
                      setRowsToExport(
                        checkLimit() ? exportLimit : remainingRows
                      );
                    }
                  }}
                  checked={continueFromLastExport}
                />
              }
              label='Continuar extração da última linha exportada'
              className='exportarLista-extractAllRows-formLabel'
            />
          </div>
        </Paper>
      )}
      <Grid item xs={12} className='export-list-modal__step-2__rowsToExport'>
        <TextField
          id='exportarLista-rowsToExport-input'
          type='number'
          inputProps={rowsToExportProps}
          value={rowsToExport}
          onChange={(evt) => {
            if (evt.target.value > rowsToExportProps.max) {
              setRowsToExport(rowsToExportProps.max);
            } else {
              setRowsToExport(evt.target.value);
            }
          }}
          variant='outlined'
          label='Quantidade de linhas'
          margin='dense'
          disabled={extractAll && totalRows < exportLimit ? true : false}
          helperText={rowsToExport > exportLimit ? 'Saldo insuficiente' : ''}
          error={rowsToExport > exportLimit ? true : false}
          style={{ minWidth: '210px' }}
          autoWidth
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={extractAll && totalRows < exportLimit}
              onChange={(evt, checked) => {
                setExtractAll(checked);
                setRowsToExport(
                  checkLimit()
                    ? exportLimit
                    : checked || !continueFromLastExport
                    ? totalRows
                    : remainingRows
                );
              }}
              id='exportarLista-extractAllRows-checkbox'
              name='all'
              color='primary'
            />
          }
          label='Todas as linhas'
          className='exportarLista-extractAllRows-formLabel'
          disabled={
            continueFromLastExport || totalRows > exportLimit ? true : false
          }
        />
      </Grid>

      {checkLimit() && (
        <Alert
          className='export-list-billing-limit-alert'
          severity='error'
          variant='filled'
        >
          Seu saldo permite exportar somente {exportLimit} linhas.
        </Alert>
      )}

      <Typography variant='h6' className='export-list-modal__step-2__header'>
        Deseja inserir os registros a serem exportados na blacklist?
      </Typography>

      <Typography variant='body1' gutterBottom>
        A blacklist é responsável por manter empresas que não serão consideradas nos próximos estudos e por consequência não irão constar em futuras listas.
      </Typography>

      <FormControlLabel
          control={
            <Checkbox
              checked={insertOnBlacklist}
              onChange={(evt, checked) => {
                setInsertOnBlacklist(checked);
              }}
              id='exportarLista-insertOnBlacklist-checkbox'
              name='insertOnBlacklist'
              color='primary'
            />
          }
          label='Sim, desejo que esta lista não conste nas próximas extrações.'
          className='exportarLista-insertOnBlacklist-formLabel'
        />
    </div>
  );
};

ListFileConfig.defaultProps = {
  config: {
    params: [],
    fileExtension: 'csv',
    finality: '',
  },
};

export default ListFileConfig;
