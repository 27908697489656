import React, { useState, useEffect } from 'react';

import filesize from 'filesize';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { AUNotifier, ErrorUtils } from '@assertiva/assertiva-ui';

import Drawer from '../customDrawer/CustomDrawer';
import FinalityConfig from './components/FinalityConfig';
import ListParamsConfig from './components/ListParams';
import ListFileConfig from './components/ListFileConfig';
import ConfirmExport from './components/ConfirmExport';
import ListDownloadFile from './components/ListDownloadFile';

import ScoreRecupereDetailsModal from '../../containers/actionsDrawer/components/scoreRecupereDetailsModal/ScoreRecupereDetailsModal';
import ExportListWarningModal from '../../containers/actionsDrawer/components/exportListWarningModal/ExportListWarningModal';

import {
  postExportList,
  getExportListStatus,
  getExportList,
  getExportFileSize,
} from '../../services/exportList';

import listColumns from '../../constants/exportListColumns';

import { sendAnalytics } from '../../utils/analytics';

import './style.css';

const FINALITY_CONFIG_STEP = 0;
const PARAMS_CONFIG_STEP = 1;
const FILE_CONFIG_STEP = 2;
const CONFIRM_EXPORT_STEP = 3;
const DOWNLOAD_FILE_STEP = 4;

const ListExport = ({
  layerId,
  listName,
  layerActionId,
  listQtdLines,
  wasExported,
  rowsExported,
  exportLimits,
  open,
  onClose,
  updateLayerActions,
}) => {
  const [activeStep, setActiveStep] = useState(CONFIRM_EXPORT_STEP);
  const [config, setConfig] = useState({
    params: [],
    fileExtension: 'csv',
    finality: { id: null, label: '', description: '' },
  });
  const [allParamsSelected, setAllParamsSelected] = useState(false);
  const [showAllParams, setShowAllParams] = useState(false);
  const [allowExport, setAllowExport] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [listInfo, setListInfo] = useState({
    name: '',
    rowCount: 0,
    url: '',
    fileSize: '0 KB',
  });
  const [scoreDetailsModalOpen, setScoreDetailsModalOpen] = useState(false);
  const [scoreWarningModal, setScoreWarningModal] = useState(false);
  const [continueFromLastExport, setContinueFromLastExport] = useState(
    wasExported && rowsExported < listQtdLines
  );
  const [extractAll, setExtractAll] = useState(
    !(wasExported && rowsExported < listQtdLines)
  );
  const [rowsToExport, setRowsToExport] = useState(0);
  const [insertOnBlacklist, setInsertOnBlacklist] = useState(false);

  useEffect(() => {
    if (open) {
      setActiveStep(FINALITY_CONFIG_STEP);
      setConfig({
        params: [],
        fileExtension: 'csv',
        finality: { id: null, label: '', description: '' },
      });
      setAllowExport(false);
      setContinueFromLastExport(wasExported && rowsExported < listQtdLines);
      setExtractAll(!(wasExported && rowsExported < listQtdLines));
      setRowsToExport(
        listQtdLines > getExportBillingLimit() ||
          listQtdLines - rowsExported > getExportBillingLimit()
          ? getExportBillingLimit()
          : wasExported && rowsExported < listQtdLines
          ? listQtdLines - rowsExported
          : listQtdLines
      );
    }
  }, [open]);

  const newField = {
    label: 'Assertiva Score Recupere',
    name: 'scoreRecuperePJ',
  };

  const changeFinality = (evt) =>
    setConfig({ ...config, finality: evt.target.value });

  const changeFileExtension = (evt) =>
    setConfig({ ...config, fileExtension: evt.target.value });

  const changeParams = (evt, checked) => {
    setConfig({
      ...config,
      params: checked
        ? [...config.params, evt.target.name]
        : config.params.filter((elem) => elem !== evt.target.name),
    });
    setAllParamsSelected(false);
  };

  const selectAllParams = (evt, checked) => {
    setAllParamsSelected(checked);

    if (checked) {
      setConfig({
        ...config,
        params: [...listColumns.map((list) => list.name), newField.name],
      });
    } else {
      setConfig({
        ...config,
        params: [],
      });
    }
  };

  const getExportBillingLimit = () => {
    const { params } = config;
    const isScore = params.includes('scoreRecuperePJ') || allParamsSelected;

    return exportLimits
      ? isScore
        ? exportLimits.score
        : exportLimits.standard
      : null;
  };

  const checkExtraBillingField = () => {
    const { params } = config;

    if (params.includes('scoreRecuperePJ') || allParamsSelected) {
      setScoreWarningModal(true);
    } else {
      exportList();
    }
  };

  const exportList = async () => {
    const { params, fileExtension, finality } = config;

    setLoadingExport(true);
    selectAllParams(null, false);

    try {
      const { data: response } = await postExportList({
        name: listName,
        layerId,
        layerActionId,
        params,
        fileExtension,
        finality,
        rowsToExport,
        continueFromLastExport,
        insertOnBlacklist,
      });
      const { listId } = response.data;

      const interval = setInterval(async () => {
        try {
          const { data: response } = await getExportListStatus({
            listId,
          });
          const { status, message } = response.data;

          if (status !== 0) {
            clearInterval(interval);

            if (status === 1) {
              const { data: response } = await getExportList({
                listId,
              });
              const { name, rowCount, url } = response.data;

              const { headers: fileSizeResp } = await getExportFileSize(url);

              setListInfo({
                name,
                rowCount,
                url,
                fileSize: filesize(fileSizeResp['content-length'], {
                  round: 0,
                }),
              });
              setLoadingExport(false);
              setActiveStep(activeStep + 1);
              updateLayerActions();
            } else {
              AUNotifier.error(
                message === ''
                  ? 'Ocorreu um erro durante a requisição. Por favor tente novamente ou contate o suporte.'
                  : message
              );
              setLoadingExport(false);
              onClose();
            }
          }
        } catch (err) {
          AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
          clearInterval(interval);
          setLoadingExport(false);
          onClose();
        }
      }, 2500);
    } catch (err) {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(err));
      setLoadingExport(false);
      onClose();
    }
  };

  const steps = {
    [FINALITY_CONFIG_STEP]: {
      title: 'Finalidade de uso',
      description:
        'Selecione uma Finalidade de Uso, de acordo com a LGPD, declarando qual a finalidade de utilização dos dados.',
      children: () => (
        <FinalityConfig config={config} changeFinality={changeFinality} />
      ),
    },
    [PARAMS_CONFIG_STEP]: {
      title: 'Selecione as informações',
      description:
        'Selecione as informações que você quer inserir no seu arquivo exportado',
      children: () => (
        <ListParamsConfig
          allParamsSelected={allParamsSelected}
          selectAllParams={selectAllParams}
          showAllParams={showAllParams}
          setShowAllParams={setShowAllParams}
          config={config}
          newField={newField}
          changeParams={changeParams}
          setScoreDetailsModalOpen={setScoreDetailsModalOpen}
        />
      ),
    },
    [FILE_CONFIG_STEP]: {
      title: 'Arquivo exportado',
      description:
        'Selecione a extensão e a quantidade de linhas do arquivo de saída',
      children: () => (
        <ListFileConfig
          config={config}
          changeFileExtension={changeFileExtension}
          totalRows={listQtdLines}
          wasExported={wasExported}
          rowsExported={rowsExported}
          continueFromLastExport={continueFromLastExport}
          setContinueFromLastExport={setContinueFromLastExport}
          extractAll={extractAll}
          setExtractAll={setExtractAll}
          insertOnBlacklist={insertOnBlacklist}
          setInsertOnBlacklist={setInsertOnBlacklist}
          rowsToExport={rowsToExport}
          setRowsToExport={setRowsToExport}
          exportLimit={getExportBillingLimit()}
        />
      ),
    },
    [CONFIRM_EXPORT_STEP]: {
      title: 'Termo de utilização dos dados e confirmação',
      description: 'Leia o termo de utilização dos dados e confirme a operação',
      children: () => (
        <ConfirmExport
          listQtdLines={rowsToExport}
          allowExport={allowExport}
          setAllowExport={setAllowExport}
          config={config}
          newField={newField}
        />
      ),
    },
    [DOWNLOAD_FILE_STEP]: {
      title: 'Baixar arquivo',
      description: 'Faça o download do seu arquivo',
      children: () => <ListDownloadFile config={config} listInfo={listInfo} />,
    },
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      classes={{ paper: 'export-list-modal' }}
      id='exportarLista-modal'
      anchor={'right'}
      title='Exportar lista'
    >
      <Paper classes={{ root: 'listExport__container' }} elevation={0}>
        <Stepper
          classes={{ root: 'export-list-modal__content' }}
          activeStep={activeStep}
          orientation='vertical'
        >
          {loadingExport ? (
            <div className='export-list-modal__steppers__feedback'>
              Gerando o arquivo, isso pode levar algum tempo!
            </div>
          ) : (
            Object.keys(steps).map((step, index) => {
              const { title, description, children } = steps[step];

              return (
                <Step key={index}>
                  <StepLabel
                    optional={
                      <Typography
                        style={{
                          color: 'rgba(0, 0, 0, 0.54)',
                          fontSize: '10px',
                        }}
                      >
                        {description}
                      </Typography>
                    }
                  >
                    {title}
                  </StepLabel>
                  <StepContent>
                    {children()}

                    <div className='export-list-modal__content__step__footer'>
                      <Grid container spacing={2}>
                        <Grid item>
                          {activeStep === FINALITY_CONFIG_STEP && (
                            <Button
                              id='exportarLista-continuar-button'
                              className='exportaLista-upper-buttons'
                              onClick={() => setActiveStep(activeStep + 1)}
                              variant='contained'
                              color='primary'
                              disabled={!config.finality}
                            >
                              Continuar
                            </Button>
                          )}
                          {activeStep === PARAMS_CONFIG_STEP && (
                            <Button
                              id='exportarLista-continuar-button'
                              className='exportaLista-upper-buttons'
                              onClick={() => setActiveStep(activeStep + 1)}
                              variant='contained'
                              color='primary'
                              disabled={
                                !allParamsSelected && config.params.length === 0
                              }
                            >
                              Continuar
                            </Button>
                          )}
                          {activeStep === FILE_CONFIG_STEP && (
                            <Button
                              id='exportarLista-continuar-button'
                              className='exportaLista-upper-buttons'
                              onClick={() => setActiveStep(activeStep + 1)}
                              variant='contained'
                              color='primary'
                              disabled={
                                rowsToExport > getExportBillingLimit() ||
                                rowsToExport <= 0
                              }
                            >
                              Continuar
                            </Button>
                          )}
                          {activeStep === CONFIRM_EXPORT_STEP && (
                            <Button
                              id='exportarLista-exportar-button'
                              className='exportaLista-upper-buttons'
                              onClick={() => {
                                checkExtraBillingField();
                                sendAnalytics({
                                  event: 'exportar-button',
                                  page: 'exportarLista',
                                });
                              }}
                              variant='contained'
                              color='primary'
                              disabled={!allowExport}
                            >
                              Continuar
                            </Button>
                          )}
                          {activeStep === DOWNLOAD_FILE_STEP && (
                            <Button
                              id='exportarLista-concluir-button'
                              className='exportaLista-upper-buttons'
                              onClick={() => {
                                onClose();
                                sendAnalytics({
                                  event: 'concluir-button',
                                  page: 'exportarLista',
                                });
                              }}
                              variant='contained'
                              color='primary'
                            >
                              Finalizar
                            </Button>
                          )}
                        </Grid>
                        <Grid item>
                          {activeStep === FINALITY_CONFIG_STEP && (
                            <Button
                              id='exportarLista-cancelar-button'
                              className='exportaLista-upper-buttons'
                              color='primary'
                              onClick={() => {
                                onClose();
                                sendAnalytics({
                                  event: 'cancelar-button',
                                  page: 'exportarLista',
                                });
                              }}
                            >
                              Cancelar
                            </Button>
                          )}
                          {(activeStep === PARAMS_CONFIG_STEP ||
                            activeStep === FILE_CONFIG_STEP ||
                            activeStep === CONFIRM_EXPORT_STEP) && (
                            <Button
                              id='exportarLista-voltar-button'
                              className='exportaLista-upper-buttons'
                              color='primary'
                              onClick={() => setActiveStep(activeStep - 1)}
                            >
                              Voltar
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </StepContent>
                </Step>
              );
            })
          )}
        </Stepper>
      </Paper>
      <ScoreRecupereDetailsModal
        open={scoreDetailsModalOpen}
        onClose={() => setScoreDetailsModalOpen(false)}
      />

      <ExportListWarningModal
        open={scoreWarningModal}
        type='scoreRecuperePJ'
        onClose={() => setScoreWarningModal(false)}
        onContinue={() => {
          setScoreWarningModal(false);
          exportList();
        }}
      />
    </Drawer>
  );
};

export default ListExport;
