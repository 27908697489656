export const REFRESH_LAYERS = 'REFRESH_LAYERS';
export const REFRESH_MARKERS = 'REFRESH_MARKERS';
export const CHANGE_STATISTICS = 'CHANGE_STATISTICS';
export const REMOVE_LAYER = 'REMOVE_LAYER';
export const SWITCH_LAYER_VISIBILITY = 'SWITCH_LAYER_VISIBILITY';
export const SWITCH_HEATMAP = 'SWITCH_HEATMAP';
export const SHOW_LAYERS_LOADING = 'SHOW_LAYERS_LOADING';
export const HIDE_LAYERS_LOADING = 'HIDE_LAYERS_LOADING';

export const refreshLayers = ({ layers, layersOrder, count, totalPages }) => ({
  type: REFRESH_LAYERS,
  payload: { layers, layersOrder, count, totalPages },
});

export const refreshMarkers = (markers) => ({
  type: REFRESH_MARKERS,
  payload: { markers },
});

export const changeStatistics = (statistics) => ({
  type: CHANGE_STATISTICS,
  payload: { statistics },
});

export const removeLayer = ({ layerId }) => ({
  type: REMOVE_LAYER,
  payload: { layerId },
});

export const switchLayerVisibility = ({ layerId }) => ({
  type: SWITCH_LAYER_VISIBILITY,
  payload: { layerId },
});

export const switchHeatmap = ({ layerId }) => ({
  type: SWITCH_HEATMAP,
  payload: { layerId },
});

export const showLayersLoading = () => ({
  type: SHOW_LAYERS_LOADING,
});

export const hideLayersLoading = () => ({
  type: HIDE_LAYERS_LOADING,
});
