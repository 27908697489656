import React, { useState, useEffect } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { AUDocumentField } from '@assertiva/assertiva-ui';

import DownloadIcon from './assets/download-icon.svg';
import Drawer from '../customDrawer/CustomDrawer';
import Upload from './components/upload/Upload';
import FileExampleModal from './components/fileExampleModal/FileExampleModal';

import { postSingleBlacklist } from 'services/blacklist';

import { validateCnpj } from '../../utils/validation';
import { showNotify } from '../../utils/general';
import GeneralConstants from '../../constants/general';

import * as Styled from './styles';

const AddBlacklistDrawer = ({ open, onClose, cleanFilterAndPagination }) => {
  const classes = Styled.Styles();

  const [cnpj, setCnpj] = useState({
    value: '',
    hasError: false,
    errorMessage: '',
  });
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [openExModal, setOpenExModal] = useState(false);

  useEffect(() => {
    if (open) {
      setCnpj({ value: '', hasError: false, errorMessage: '' });
      setDescription('');
    }
  }, [open]);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await postSingleBlacklist({
        cnpj: cnpj.value,
        description,
      });

      showNotify({
        message: 'CNPJ adicionado ao blacklist.',
        type: GeneralConstants.toastType.NOTIFY,
      });

      cleanFilterAndPagination();
      onClose();
    } catch (error) {
      showNotify({
        message: error.response
          ? error.response.data.message
          : 'Erro ao adicionar blacklist.',
        type: GeneralConstants.toastType.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      id='addBlacklist-modal'
      anchor='right'
      title='Adicionar a Blacklist'
    >
      <Paper classes={{ root: classes.blacklistContainer }} elevation={3}>
        <Styled.Title variant='caption'>
          Inserir na blacklist - unitário
        </Styled.Title>

        <AUDocumentField
          id='add-blacklist-cnpj'
          type='cnpj'
          value={cnpj.value}
          onChangeEvent={(evt) => {
            const error = validateCnpj(evt.value);

            setDisabled(!!error);
            return setCnpj((prevState) => ({ ...prevState, value: evt.value }));
          }}
          onBlurEvent={() => {
            const error = validateCnpj(cnpj.value);
            if (!error) {
              return setCnpj((prevState) => ({
                ...prevState,
                hasError: false,
                errorMessage: '',
              }));
            }

            return setCnpj((prevState) => ({
              ...prevState,
              hasError: true,
              errorMessage: error,
            }));
          }}
          variant='outlined'
          label='CNPJ'
          margin='dense'
          placeholder='Digite o CNPJ...'
          classes={{ root: classes.textField }}
          error={cnpj.hasError}
          helperText={cnpj.errorMessage}
        />

        <TextField
          id='add-blacklist-description'
          type='text'
          value={description}
          onChange={(evt) => setDescription(evt.target.value)}
          variant='outlined'
          label='Descrição (opcional)'
          margin='dense'
          placeholder='Digite o motivo do blacklist'
          classes={{ root: classes.textField }}
          autoWidth
        />

        <Styled.AddButton
          variant='contained'
          color='primary'
          disabled={disabled || loading}
          onClick={onSubmit}
        >
          {loading ? <CircularProgress size={20} /> : 'Adicionar'}
        </Styled.AddButton>

        <Divider variant='fullWidth' style={{ marginBottom: '16px' }} />

        <Upload
          cleanFilterAndPagination={cleanFilterAndPagination}
          showNotify={showNotify}
          onClose={onClose}
        />

        <Styled.DownloadExampleButton
          color='primary'
          startIcon={
            <img
              alt='download_icon'
              src={DownloadIcon}
              height={24}
              width={24}
            />
          }
          onClick={() => setOpenExModal(!openExModal)}
        >
          baixar arquivo exemplo
        </Styled.DownloadExampleButton>

        <Styled.BlacklistWarning elevation={0}>
          <ErrorOutlineIcon classes={{ root: classes.warningIcon }} />
          <section>
            <Typography variant='body3' gutterBottom>
              Atenção: Adicionar registros na blacklist irá fazer com que as
              camadas e listas existentes também sejam atualizadas para
              desconsiderar os registros adicionados.
            </Typography>
          </section>
        </Styled.BlacklistWarning>
      </Paper>
      <FileExampleModal open={openExModal} setOpen={setOpenExModal} />
    </Drawer>
  );
};

export default AddBlacklistDrawer;
