import cnae from "./cnae";

export default {
  categories: [
    // { label: 'Pessoa Física', id: 0 },
    { label: 'Pessoa Jurídica', id: 1 },
    { label: 'Localização', id: 2, icon: 'map', hidden: false },
    // { label: 'Meus dados', id: 3 },
  ],
  filtersByCategories: {
    0: {
      category: 0,
      filtersById: {
        0: {
          label: 'Sexo',
          attributes: [
            {
              type: 'select',
              name: 'gender',
              options: [
                { label: 'Masculino', value: 'M' },
                { label: 'Feminino', value: 'F' },
              ],
            },
          ],
          id: 0,
        },
        1: {
          label: 'Faixa de idade',
          attributes: [
            {
              type: 'range',
              name: 'age',
              min: '18',
              max: '100',
            },
          ],
          id: 1,
        },
        2: {
          label: 'Faixa de renda',
          attributes: [
            {
              type: 'range',
              name: 'estimatedIncome',
              min: '0',
              max: '30000',
            },
          ],
          id: 2,
        },
      },
      filterOrder: [0, 1, 2],
    },
    1: {
      category: 1,
      filtersById: {
        0: {
          label: 'CNAE',
          attributes: [
            {
              type: 'autocomplete',
              name: 'cnae',
              options: cnae,
              multiple: true,
              required: true,
              noOptionsText: 'Nenhum CNAE encontrado',
            },
          ],
          id: 0,
        },
        1: {
          label: 'Idade da empresa',
          attributes: [
            {
              type: 'range',
              name: 'openingDate',
              fieldId: 'filtro-idadeDaEmpresa',
              min: '1',
              max: '20',
              isAge: true,
            },
          ],
          id: 1,
        },
        2: {
          label: 'Situação cadastral',
          attributes: [
            {
              type: 'select',
              name: 'registrationStatus',
              options: [
                { label: 'Todos', value: 'TODAS' },
                { label: 'Ativo', value: 'ATIVA' },
                { label: 'Inativo', value: 'INATIVA' },
              ],
            },
          ],
          id: 2,
        },
        3: {
          label: 'Faturamento presumido anual',
          attributes: [
            {
              type: 'range',
              name: 'companyIncome',
              fieldId: 'filtro-faturamentoPresumido',
              min: '0',
              max: '1000000',
              isCurrency: true,
            },
          ],
          id: 3,
        },
        4: {
          label: 'Quantidade de funcionários',
          attributes: [
            {
              type: 'range',
              name: 'numberOfEmployees',
              fieldId: 'filtro-quantidadeDeFuncionarios',
              min: '0',
              max: '1000',
            },
          ],
          id: 4,
        },
        5: {
          label: 'Porte',
          attributes: [
            {
              type: 'select',
              name: 'companySize',
              options: [
                { label: 'Micro-empresa', value: ['8', '9'] },
                { label: 'Pequeno Porte', value: ['5', '7'] },
                { label: 'Médio Porte', value: ['4', '4'] },
                { label: 'Grande Porte', value: ['1', '3'] },
              ],
            },
          ],
          id: 5,
        },
        6: {
          label: 'Nome fantasia',
          attributes: [
            {
              type: 'input',
              name: 'fantasyName',
            },
          ],
          id: 6,
        },
        7: {
          label: 'Razão social',
          attributes: [
            {
              type: 'input',
              name: 'corporateName',
            },
          ],
          id: 7,
        },
        8: {
          label: 'CNPJ',
          attributes: [
            {
              type: 'input',
              name: 'document',
            },
          ],
          id: 8,
        },
        9: {
          label: 'Matriz ou filial',
          attributes: [
            {
              type: 'select',
              name: 'companyType',
              options: [
                { label: 'Matriz', value: 'MATRIZ' },
                { label: 'Filial', value: 'FILIAL' },
              ],
            },
          ],
          id: 9,
        },
        10: {
          label: 'Setor público',
          attributes: [
            {
              type: 'select',
              name: 'publicSector',
              options: [
                { label: 'Sim', value: 'SIM' },
                { label: 'Não', value: 'NAO' },
              ],
            },
          ],
          id: 10,
        },
        11: {
          label: 'Empresa individual',
          attributes: [
            {
              type: 'select',
              name: 'individualCompany',
              options: [
                { label: 'Sim', value: 'SIM' },
                { label: 'Não', value: 'NAO' },
              ],
            },
          ],
          id: 11,
        },
        13: {
          label: 'Capital social',
          attributes: [
            {
              type: 'range',
              name: 'shareCapital',
              min: '0',
              max: '1000000',
              isCurrency: true,
            },
          ],
          id: 13,
        },
        14: {
          label: 'Quantidade de sócios',
          attributes: [
            {
              type: 'range',
              name: 'numberOfMembers',
              min: '0',
              max: '1000',
            },
          ],
          id: 14,
        },
        15: {
          label: 'Optante do simples nacional',
          attributes: [
            {
              type: 'select',
              name: 'simpleNational',
              options: [
                { label: 'Sim', value: 'SIM' },
                { label: 'Não', value: 'NAO' },
              ],
            },
          ],
          id: 15,
        },
        16: {
          label: 'Assertiva Score Recupere',
          attributes: [
            {
              type: 'scoreSelect',
              name: 'scoreRecuperePJ',
              marks: [
                { value: 1, letter: 'F' },
                { value: 2, letter: 'E' },
                { value: 3, letter: 'D' },
                { value: 4, letter: 'C' },
                { value: 5, letter: 'B' },
                { value: 6, letter: 'A' },
              ],
              defaultValue: [1, 6],
              defaultCheckedValue: [4],
            },
          ],
          id: 16,
        },
        17: {
          label: 'Redes',
          attributes: [
            {
              type: 'autocomplete',
              name: 'franchise',
              multiple: true,
              required: true,
              group: true,
              noOptionsText:
                'Não encontrou a rede que procura? Entre em contato com o nosso suporte.',
            },
          ],
          id: 17,
        },
      },
      filterOrder: [0, 17, 1, 2, 3, 4, 5, 9, 10, 11, 13, 14, 15, 16],
    },
    2: {
      category: 2,
      filtersById: {
        0: {
          label: 'Estado',
          attributes: [
            {
              type: 'select',
              name: 'uf',
              options: [
                { label: 'Acre', value: 'AC' },
                { label: 'Alagoas', value: 'AL' },
                { label: 'Amapá', value: 'AP' },
                { label: 'Amazonas', value: 'AM' },
                { label: 'Bahia', value: 'BA' },
                { label: 'Ceará', value: 'CE' },
                { label: 'Espírito Santo', value: 'ES' },
                { label: 'Goiás', value: 'GO' },
                { label: 'Maranhão', value: 'MA' },
                { label: 'Mato Grosso', value: 'MT' },
                { label: 'Mato Grosso do Sul', value: 'MS' },
                { label: 'Minas Gerais', value: 'MG' },
                { label: 'Pará', value: 'PA' },
                { label: 'Paraíba', value: 'PB' },
                { label: 'Paraná', value: 'PR' },
                { label: 'Pernambuco', value: 'PE' },
                { label: 'Piauí', value: 'PI' },
                { label: 'Rio de Janeiro', value: 'RJ' },
                { label: 'Rio Grande do Norte', value: 'RN' },
                { label: 'Rio Grande do Sul', value: 'RS' },
                { label: 'Rondônia', value: 'RO' },
                { label: 'Roraima', value: 'RR' },
                { label: 'Santa Catarina', value: 'SC' },
                { label: 'São Paulo', value: 'SP' },
                { label: 'Sergipe', value: 'SE' },
                { label: 'Tocantins', value: 'TO' },
                { label: 'Distrito Federal', value: 'DF' },
              ],
            },
          ],
          id: 0,
        },
        1: {
          label: 'Cidade',
          attributes: [
            {
              type: 'locationAutocomplete',
              name: 'city',
              placesType: '(cities)',
            },
          ],
          id: 1,
        },
        2: {
          label: 'Bairro',
          attributes: [
            {
              type: 'locationAutocomplete',
              name: 'neighborhood',
              placesType: '(regions)',
            },
          ],
          id: 2,
        },
        3: {
          label: 'Rua',
          attributes: [
            {
              type: 'locationAutocomplete',
              name: 'street',
              placesType: 'address',
            },
          ],
          id: 3,
        },
      },
      filterOrder: [0, 1, 2, 3],
    },
    3: {
      category: 3,
      filtersById: {},
      filterOrder: [],
    },
  },
};
