export default {
  listHeader: [
    { id: 'cnpj', label: 'CNPJ' },
    { id: 'description', label: 'Descrição' },
    { id: 'date', label: 'Data' },
  ],
  rowsPerPage: [5, 10, 25],
  pagination: {
    page: 0,
    rowsPerPage: 5,
  },
  defaultFilterData: {
    cnpj: {
      value: '',
      hasError: false,
      errorMessage: '',
    },
    dates: {
      dateIni: { value: null, date: null },
      dateFin: { value: null, date: null },
    },
  },
};
