import React from 'react';
import _chunk from 'lodash/chunk';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import listColumns from '../../../constants/exportListColumns';

const ListParamsConfig = ({
  allParamsSelected,
  selectAllParams,
  showAllParams,
  setShowAllParams,
  config,
  newField,
  changeParams,
  setScoreDetailsModalOpen,
}) => {
  return (
    <FormControl
      style={{
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        padding: '10px 20px',
      }}
      fullWidth
    >
      <Grid container spacing={2} className='export-list-modal__step-1'>
        <Grid item xs={12} className='export-list-modal__step-1__header'>
          <Typography style={{ color: '#000000', fontSize: '16px' }}>
            Dados a serem exportados
          </Typography>

          <Typography style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '14px' }}>
            {config.params.length} de {Object.keys(listColumns).length + 1}{' '}
            dados selecionados
          </Typography>

          <Button
            id='exportarLista-ver-mais-button'
            className='export-list-view-more-params'
            color='primary'
            variant='outlined'
            startIcon={!showAllParams ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            onClick={() => setShowAllParams(!showAllParams)}
          >
            {!showAllParams ? 'Ver mais dados' : 'Ver menos dados'}
          </Button>
        </Grid>

        <Grid item xs={12} className='export-list-modal__step-1__header'>
          <FormControlLabel
            control={
              <Checkbox
                checked={allParamsSelected}
                onChange={selectAllParams}
                id='exportarLista-all-checkbox'
                name='all'
                color='primary'
              />
            }
            label='Todos os dados'
          />
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            classes={{
              root: 'export-list-modal_new_field_grid',
            }}
          >
            <Grid item xs={9}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={config.params.includes(newField.name)}
                    onChange={changeParams}
                    id={`exportarLista-${newField.name}-checkbox`}
                    name={newField.name}
                    color='primary'
                  />
                }
                label={
                  <div className='export-list-modal_new_field_label_div'>
                    <Typography><b>{newField.label}</b></Typography>
                  </div>
                }
              />
              <Typography
                classes={{
                  root: 'export-list-modal_score_recupere_warning_text',
                }}
              >
                O uso do Score Recupere gera uma cobrança extra ao gerar uma lista.
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              className='export-list-modal_score_recupere_saiba-mais'
            >
              <Button
                className='exportaLista-upper-buttons'
                color='primary'
                onClick={() => setScoreDetailsModalOpen(true)}
              >
                Saiba mais
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {_chunk([...listColumns], 15).map((column, index) => (
          <Grid key={`grid-${index}`} item xs={4}>
            <FormGroup>
              {column.slice(0, 5).map(({ label, name }) => (
                <FormControlLabel
                  key={`form-${name}`}
                  control={
                    <Checkbox
                      checked={config.params.includes(name)}
                      onChange={changeParams}
                      id={`exportarLista-${name}-checkbox`}
                      name={name}
                      color='primary'
                    />
                  }
                  label={label}
                />
              ))}
            </FormGroup>

            <Collapse in={showAllParams}>
              <FormGroup>
                {column.slice(5, 15).map(({ label, name }) => (
                  <FormControlLabel
                    key={`form-${name}`}
                    control={
                      <Checkbox
                        checked={config.params.includes(name)}
                        onChange={changeParams}
                        id={`exportarLista-${name}-checkbox`}
                        name={name}
                        color='primary'
                      />
                    }
                    label={label}
                  />
                ))}{' '}
              </FormGroup>
            </Collapse>
          </Grid>
        ))}
      </Grid>
    </FormControl>
  );
};

ListParamsConfig.defaultProps = {
  config: {
    params: [],
    fileExtension: 'csv',
    finality: '',
  },
  allParamsSelected: false,
  showAllParams: false,
  newField: {},
};

export default ListParamsConfig;
