import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';

const PaperStyles = makeStyles(() => ({
  root: {
    width: '300px',
    padding: '16px',
    right: '38px',
    top: '60px',
    position: 'absolute',
    zIndex: 2,
  },
  textField: {
    marginTop: '24px',
    width: '100%',
    '&:last-of-type': {
      marginTop: '36px',
    },
  },
}));

const HeaderContainer = styled('div')({
  maxWidth: '275px',
});

const BodyContainer = styled('div')({
  marginTop: '24px',
});

const HeaderTitleText = styled(Typography)({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '19px',
  color: '#000000',
});

const HeaderSubtitleText = styled(Typography)({
  fontSize: '12px',
  lineHeight: '14px',
  color: '#00000099',
  marginTop: '6px',
});

const BodyTitleText = styled(Typography)({
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.4px',
  color: '#000000de',
});

const TextfieldStyled = withStyles({
  root: {
    marginTop: '16px',
  },
})(TextField);

const DatePickerStyled = withStyles({
  root: {
    marginTop: '16px',
  },
})(DatePicker);

const IconButtonStyled = styled(IconButton)({
  color: '#000000',
  right: '-10px',
});

const Footer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  marginTop: '20px',
});

const FooterButton = withStyles({
  root: {
    margin: '0 4px 0 8px',
    padding: '10px 16px',
    width: '97px',
    height: '36px',
    letterSpacing: '1.25px',
    textTransform: 'uppercase',
    fontSize: '14px',
    '&:disabled': {
      backgroundColor: '#9E9E9E',
      color: 'white',
      cursor: 'not-allowed',
    },
  },
})(Button);

export {
  PaperStyles,
  HeaderContainer,
  BodyContainer,
  TextfieldStyled,
  DatePickerStyled,
  HeaderTitleText,
  HeaderSubtitleText,
  BodyTitleText,
  Footer,
  FooterButton,
  IconButtonStyled,
};
