import { useEffect, useState, useCallback } from 'react';

import { getBlacklist, deleteBlacklistItems } from '../../services/blacklist';
import { showNotify } from '../../utils/general';

import Constants from './constants';
import GeneralConstants from '../../constants/general';

const useBlacklist = () => {
  const [loading, setLoading] = useState(false);
  const [blacklist, setBlacklist] = useState([]);
  const [pagination, setPagination] = useState(Constants.pagination);
  const [filter, setFilter] = useState(Constants.defaultFilterData);
  const [selected, setSelected] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterChip, setFilterChip] = useState([]);

  const listBlacklistItems = useCallback(async () => {
    setLoading(true);
    try {
      const { data: response } = await getBlacklist({ pagination, filter });
      setBlacklist(response.data);
    } catch (err) {
      showNotify({
        message: 'Ocorreu um erro. Tente novamente mais tarde.',
        type: GeneralConstants.toastType.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }, [pagination, filter]);

  const deleteBlacklist = async () => {
    try {
      setLoading(true);

      await deleteBlacklistItems(selected);

      showNotify({
        message: 'CNPJ(s) excluído(s) com sucesso.',
        type: GeneralConstants.toastType.NOTIFY,
      });
    } catch (err) {
      showNotify({
        message: 'Ocorreu um erro. Tente novamente mais tarde.',
        type: GeneralConstants.toastType.ERROR,
      });
    } finally {
      setLoading(false);
      cleanFilterAndPagination();
      setSelected([]);
    }
  };

  useEffect(() => {
    listBlacklistItems();
  }, [listBlacklistItems]);

  const handleChangePage = (_, newPage) => {
    setPagination((prevState) => {
      return { ...prevState, page: newPage };
    });
    setSelected([]);
  };
  const handleChangeRowsPerPage = (event) => {
    setPagination({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = blacklist.items.map((item) => item.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelectSingle = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    const selectedCopy = [...selected];
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else {
      selectedCopy.splice(selectedIndex, 1);
      newSelected = selectedCopy;
    }

    setSelected(newSelected);
  };

  const cleanFilterAndPagination = () => {
    const filters = { ...Constants.defaultFilterData };
    const pagination = { ...Constants.pagination };

    setFilter(filters);
    setPagination(pagination);
  };

  return {
    blacklist,
    loading,
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    isSelected,
    selected,
    handleSelectAllClick,
    handleSelectSingle,
    filter,
    setFilter,
    openFilter,
    setOpenFilter,
    cleanFilterAndPagination,
    deleteBlacklist,
    filterChip,
    setFilterChip,
    setPagination,
  };
};

export default useBlacklist;
