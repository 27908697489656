import React from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import LayersIcon from '@material-ui/icons/Layers';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';

import LayerItem from './components/layerItem/LayerItem';
import LayerActionsModal from './components/layerActionsModal/LayerActionsModal';
import LayerSelect from './components/layerSelect/LayerSelect';
import LayerSelectHeader from './components/layerSelectHeader/layerSelectHeader';
import Pagination from '../../components/Pagination/Pagination';

import * as actions from './actions';
import {
  changeSelectedFilters,
  changeFilterData,
  editLayerFilter,
  showFilterLoading,
  hideFilterLoading,
  changeFilterResult,
  changeSelectedLayer,
} from '../../components/dashboard/actions';
import {
  openDrawer,
  changeActions,
  showDrawerLoading,
  hideDrawerLoading,
  changeExportLimits,
} from '../actionsDrawer/actions';
import useLayers from './useLayers';

import './style.css';

export const Layers = ({
  filters,
  refreshLayers,
  layerStatistics,
  removeLayer,
  changeSelectedFilters,
  changeFilterData,
  changeFilterResult,
  editLayerFilter,
  showLayersLoading,
  hideLayersLoading,
  loading,
  openDrawer,
  changeActions,
  showDrawerLoading,
  hideDrawerLoading,
  showFilterLoading,
  hideFilterLoading,
  changeExportLimits,
  selectLayer,
  changeSelectedLayer,
  layerSelected,
  refreshMarkers,
  changeStatistics,
  isEditing,
  editingLayerId,
}) => {
  const {
    layerActionsModal,
    setLayerActionsModal,
    layers,
    layersOrder,
    onSelectMode,
    setOnSelectMode,
    pagination,
    setPagination,
    count,
    totalPages,
    layersSelected,
    setLayersSelect,
    editLayer,
    _deleteLayer,
    changeLayerName,
    duplicateLayer,
    changeLayerColor,
    saveLayerAction,
  } = useLayers({
    refreshLayers,
    removeLayer,
    changeSelectedFilters,
    changeFilterData,
    changeFilterResult,
    editLayerFilter,
    showLayersLoading,
    hideLayersLoading,
    openDrawer,
    changeActions,
    showDrawerLoading,
    hideDrawerLoading,
    showFilterLoading,
    hideFilterLoading,
    changeExportLimits,
    changeSelectedLayer,
    layerSelected,
    refreshMarkers,
    changeStatistics,
  });

  const handlePagination = (key, value) => {
    if (key === 'rowsPerPage') {
      const newTotalPages = Math.ceil(count / value);

      return setPagination({
        ...pagination,
        [key]: value,
        page: newTotalPages - 1,
      });
    }

    return setPagination((oldPagination) => ({
      ...oldPagination,
      [key]: value,
    }));
  };

  return (
    <>
      {loading && (
        <div className='layers__loading'>
          <CircularProgress />
        </div>
      )}
      {!!layersOrder.length && (
        <div className='layers'>
          {onSelectMode && (
            <LayerSelectHeader
              layersSelected={layersSelected}
              setLayersSelect={setLayersSelect}
              layers={layers}
              setOnSelectMode={setOnSelectMode}
              deleteLayer={_deleteLayer}
            />
          )}
          {layersOrder.map((layerId) => {
            let {
              name,
              color,
              data,
              selectedFilters,
              rowcount = null,
            } = layers[layerId];

            const markerCount =
              rowcount !== null
                ? rowcount
                : layerStatistics[layerId]
                ? layerStatistics[layerId][0].value
                : 0;

            return (
              <React.Fragment key={`layer-${layerId}`}>
                {onSelectMode ? (
                  <LayerSelect
                    key={`select-${layerId}`}
                    layerId={layerId}
                    layerName={name}
                    setLayersSelect={setLayersSelect}
                    layersSelected={layersSelected}
                    selectedFilters={selectedFilters}
                    layerData={data}
                    filters={filters}
                  />
                ) : (
                  <LayerItem
                    key={`item-${layerId}`}
                    layerId={layerId}
                    layerName={name}
                    layerColor={color}
                    selectedFilters={selectedFilters}
                    layerData={data}
                    filters={filters}
                    editLayer={editLayer}
                    deleteLayer={_deleteLayer}
                    changeLayerColor={changeLayerColor}
                    changeLayerName={changeLayerName}
                    duplicateLayer={duplicateLayer}
                    openLayerActionsModal={({ layerId }) =>
                      setLayerActionsModal({
                        isOpen: true,
                        layerId,
                        rowcount: markerCount,
                      })
                    }
                    setOnSelectMode={setOnSelectMode}
                    selectLayer={selectLayer}
                    editingLayerId={editingLayerId}
                    isEditing={isEditing}
                  />
                )}
              </React.Fragment>
            );
          })}

          <Pagination
            labelRowsPerPage='Camadas'
            count={count}
            page={pagination.page}
            rowsPerPageOptions={[5, 10]}
            rowsPerPage={pagination.rowsPerPage}
            totalPages={totalPages}
            onChangePage={(newPage) => handlePagination('page', newPage)}
            onChangeRowsPerPage={(newRowsPerPage) =>
              handlePagination('rowsPerPage', newRowsPerPage)
            }
          />
        </div>
      )}
      {!layersOrder.length && (
        <Paper classes={{ root: 'layers__empty-panel' }} elevation={0}>
          <Paper
            classes={{
              root: 'layers__empty-panel__icon',
            }}
            elevation={0}
          >
            <LayersIcon fontSize='inherit' />
          </Paper>
          {!loading && (
            <Typography variant='body2' className='layers__empty-panel__text'>
              Você ainda não criou nenhuma camada
            </Typography>
          )}
        </Paper>
      )}

      <LayerActionsModal
        open={layerActionsModal.isOpen}
        onClose={() =>
          setLayerActionsModal({
            isOpen: false,
            layerId: null,
            rowcount: 0,
          })
        }
        submitLayer={saveLayerAction}
      />
    </>
  );
};

const mapStateToProps = ({ dashboard, layers }) => ({
  filters: dashboard.filters,
  isEditing: dashboard.isEditing,
  editingLayerId: dashboard.layerId,
  layerSelected: dashboard.layerSelected,
  layerStatistics: layers.statistics,
  loading: layers.loading.isShowing,
});

export default connect(mapStateToProps, {
  ...actions,
  changeSelectedFilters,
  changeFilterData,
  changeFilterResult,
  editLayerFilter,
  openDrawer,
  changeActions,
  showDrawerLoading,
  hideDrawerLoading,
  showFilterLoading,
  hideFilterLoading,
  changeExportLimits,
  changeSelectedLayer,
})(Layers);
