import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import '../style.css';

const LabelCard = ({
    label,
    value
}) => {
    return (
        <Paper
            classes={{
                root: 'dashboard__study-area__label_card',
            }}
            elevation={0}
        >
            <Typography 
                classes={{
                    root: 'dashboard__study-area__label_card__title',
                }} 
                gutterBottom
            >
                {label}
            </Typography>
            <Typography 
                classes={{
                    root: 'dashboard__study-area__label_card__value',
                }}
            >
                {value}
            </Typography>
        </Paper>
    );
}

export default LabelCard;