import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import './style.css';

const CustomDrawer = ({
  drawerIndex = 0,
  onClose,
  title,
  moreOptions,
  classes = {},
  PaperProps = {},
  children,
  ...props
}) => {
  return (
    <Drawer
      {...props}
      onClose={onClose}
      classes={{
        ...classes,
        root: `custom-drawer__root ${classes.root || ''}`,
        paper: `custom-drawer__paper ${classes.paper || ''}`,
      }}
      PaperProps={{
        ...PaperProps,
        style: {
          right: drawerIndex * 200,
          ...PaperProps.style,
        },
      }}
    >
      {title && (
        <>
          <div className='custom-drawer__header'>
            <div className='custom-drawer__header__left'>
              <IconButton size='small' onClick={onClose}>
                <CloseIcon />
              </IconButton>

              <Typography className='custom-drawer__title' variant='h5'>
                {title}
              </Typography>
            </div>

            <div className='custom-drawer__header__right'>{moreOptions}</div>
          </div>

          <Divider />
        </>
      )}
      {children}
    </Drawer>
  );
};

export default CustomDrawer;
