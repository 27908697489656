import React, { useState } from 'react';
import { connect } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';

import MaximizeIcon from '@material-ui/icons/Crop32';
import MinimizeIcon from '@material-ui/icons/Minimize';
import PlaceIcon from '@material-ui/icons/Place';

import './style.css';

const Statistics = ({ id, name, color, value, isLastItem }) => (
  <>
    <div id={id} className='general-info__content__statistics'>
      <Typography variant='body1' noWrap>
        <span
          id={`${id}-nome`}
          className='general-info__content__statistics__title'
        >
          {name}
        </span>
      </Typography>
      <div className='general-info__content__statistics__info'>
        <span className='general-info__content__statistics__info__icon'>
          <PlaceIcon htmlColor={color} fontSize='inherit' />
        </span>
        <Typography variant='body1' noWrap>
          <span
            id={`${id}-numero`}
            className='general-info__content__statistics__info__data'
          >
            {value}
          </span>
        </Typography>
      </div>
    </div>
    {!isLastItem && <Divider />}
  </>
);

const GeneralInfo = ({
  layers,
  layerInfo,
  layerStatistics,
  filterStatistics,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Paper elevation={3} className='general-info'>
      <div
        className='general-info__header'
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Typography variant='body2'>Indicadores</Typography>
        {isCollapsed ? (
          <MaximizeIcon fontSize='small' />
        ) : (
          <MinimizeIcon fontSize='small' />
        )}
      </div>

      <Collapse in={!isCollapsed}>
        <div className='general-info__content'>
          {/* Current Filter */}
          <Statistics
            id='indicadores-filtrosAtuais'
            name='Filtros atuais'
            color='#008cff'
            value={filterStatistics[0] ? filterStatistics[0].value : 0}
            isLastItem={layers.length < 1}
          />

          {/* Layers */}
          {layers.map((layer, index) => {
            const { name, color, rowcount = null } = layerInfo[layer];

            let value;
            if (rowcount !== null) {
              value = rowcount;
            } else {
              value = layerStatistics[layer]
                ? layerStatistics[layer][0].value
                : 0;
            }

            return (
              <Statistics
                key={`statistic-${index}`}
                id='indicadores-camadas'
                name={name}
                color={color}
                value={value}
                isLastItem={index >= layers.length - 1}
              />
            );
          })}
        </div>
      </Collapse>
    </Paper>
  );
};

const mapStateToProps = ({ layers, dashboard }) => ({
  layers: layers.layersOrder,
  layerInfo: layers.layers,
  layerStatistics: layers.statistics,
  filterStatistics: dashboard.statistics,
});

export default connect(mapStateToProps, {})(GeneralInfo);
