
export const sendAnalytics = ({ event, page }) => {
    const product_name = process.env.REACT_APP_PRODUCT_NAME;

    try {
        window.dataLayer.push({ 
            'event': `${product_name}-${page}-${event}`
        });
    } catch (error) {
        console.log(error);
    }
}