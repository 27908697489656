import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';

import { ellipsisString } from '../../../../utils/general';
import './style.css';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const ListSelect = ({
  listId,
  layerName,
  name,
  summary,
  setListsSelect,
  listsSelected,
}) => {
  const [checked, setChecked] = useState(false);
  const onClickPaper = () => {
    setListsSelect(
      !checked
        ? Array.from(new Set([...listsSelected, listId]))
        : listsSelected.filter((elem) => elem !== listId)
    );

    setChecked(!checked);
  };

  useEffect(() => {
    setChecked(listsSelected.includes(listId));
  }, [listsSelected, listId]);

  return (
    <div className='list-select'>
      <Paper
        variant='outlined'
        elevation={7}
        onClick={onClickPaper}
        classes={{
          root: checked
            ? 'list-select__paper_button_active'
            : 'list-select__paper_button',
        }}
      >
        <div className='list-select__item__title'>
          <div className='list-select__header_paper_div'>
            <LightTooltip
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 125 }}
              title={layerName?.length > 25 ? layerName : ''}
              placement='right-end'
            >
              <Typography
                id='listaDeEmpresasEncontradas-label'
                variant='caption'
                color='textSecondary'
              >
                Lista de empresas encontradas - {ellipsisString(layerName, 25)}
              </Typography>
            </LightTooltip>
            <FormControl classes={{ root: 'list-select__form_control' }}>
              <FormControlLabel
                classes={{ root: 'list-select__form_control_label' }}
                control={
                  <Checkbox
                    checked={listsSelected.includes(listId)}
                    color='primary'
                    id='listSelect-checkbox'
                  />
                }
              />
            </FormControl>
          </div>
        </div>
        <div className='list-select__item__content'>
          <Typography
            variant='body1'
            display='inline'
            style={{ fontWeight: '500' }}
          >
            {name}
          </Typography>
        </div>
        <div className='list-select__item__content'>
          <div style={{ borderColor: '#8a00c1' }}>
            <Typography variant='body2' style={{ fontSize: 12 }}>
              Total de
              <br />
              Empresas:
            </Typography>
            <Typography variant='body2' style={{ fontWeight: 500 }}>
              {summary.total}
            </Typography>
          </div>

          <div style={{ borderColor: '#4DB546' }}>
            <Typography variant='body2' style={{ fontSize: 12 }}>
              Empresas
              <br />
              Ativas:
            </Typography>
            <Typography variant='body2' style={{ fontWeight: 500 }}>
              {summary.activeCompanies}
            </Typography>
          </div>

          <div style={{ borderColor: '#3333334C' }}>
            <Typography variant='body2' style={{ fontSize: 12 }}>
              Empresas
              <br />
              Inativas:
            </Typography>
            <Typography variant='body2' style={{ fontWeight: 500 }}>
              {summary.inactiveCompanies}
            </Typography>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default ListSelect;
