import React from 'react';

import Dialog from '@material-ui/core/Dialog';

import { S3_URL } from '../../../../services/base';

import CSVicon from '../../assets/extCSV.svg';
import XLSXicon from '../../assets/extXLSX.svg';
import TXTicon from '../../assets/extTXT.svg';

import * as Styled from './styles';

const FileExampleModal = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <Styled.Container>
        <Styled.ModalTitle>Baixar arquivo de exemplo</Styled.ModalTitle>
        <Styled.ModalBody>
          Escolha um tipo de arquivo para baixar o exemplo de um lote de CNPS
          para serem incluídos em lote na blacklist:
        </Styled.ModalBody>
        <Styled.ExamplesContainer>
          <a
            href={`${S3_URL}blacklist-file-examples/ExemploCSV.csv`}
            target='_blank'
            rel="noopener noreferrer"
          >
            <Styled.ExampleIcon src={CSVicon} alt='CSV' />
          </a>
          <a
            href={`${S3_URL}blacklist-file-examples/ExemploXLSX.xlsx`}
            target='_blank'
            rel="noopener noreferrer"
          >
            <Styled.ExampleIcon src={XLSXicon} alt='XLSX' />
          </a>
          <a
            href={`${S3_URL}blacklist-file-examples/ExemploTXT.txt`}
            target='_blank'
            rel="noopener noreferrer"
          >
            <Styled.ExampleIcon src={TXTicon} alt='TXT' />
          </a>
        </Styled.ExamplesContainer>
        <Styled.ModalButton
          variant='text'
          color='primary'
          onClick={handleClose}
        >
          Fechar
        </Styled.ModalButton>
      </Styled.Container>
    </Dialog>
  );
};

export default FileExampleModal;
