import React, { memo } from 'react';
import { connect } from 'react-redux';

import { AUHasPermission } from '@assertiva/assertiva-ui';
import { GEO_GENERAL } from '../../constants/permissions';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './components/TabPanel';

import { openDrawer, closeDrawer } from '../../actions';
import * as actions from './actions';

import './styles.css';

import Blacklist from '../../containers/blacklist/Blacklist';
import AddBlacklistDrawer from '../../components/addBlacklistDrawer/AddBlacklistDrawer';

const Settings = ({ openDrawer, closeDrawer, drawers }) => {
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  return (
    <AUHasPermission permissionKey={[...GEO_GENERAL]}>
      <Paper classes={{ root: 'configuration__container' }} elevation={4}>
        <Tabs
          value={currentTab}
          indicatorColor='primary'
          textColor='primary'
          onChange={handleTabChange}
        >
          <Tab label='Blacklist' />
        </Tabs>
        <TabPanel value={currentTab} index={0}>
          <Blacklist openDrawer={openDrawer} />
        </TabPanel>
        <AddBlacklistDrawer
          {...drawers['AddBlacklist'].data}
          open={drawers['AddBlacklist'].isOpen}
          onClose={() => closeDrawer({ drawerName: 'AddBlacklist' })}
        />
      </Paper>
    </AUHasPermission>
  );
};

const mapStateToProps = ({ global }) => ({
  drawers: global.drawers,
});

export default connect(mapStateToProps, {
  ...actions,
  openDrawer,
  closeDrawer,
})(memo(Settings));
