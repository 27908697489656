import React, { useCallback, useEffect, useState, useMemo } from 'react';
import * as DateFns from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';

import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import EventIcon from '@material-ui/icons/Event';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { AUDocumentField, ValidationUtils } from '@assertiva/assertiva-ui';

import * as Styled from './styles';

import ErrorMessages from '../../../../constants/errorMessages';
import Constants from '../../constants';

const BlacklistTableFilter = ({
  open,
  setOpenFilter,
  filterData,
  setFilterData,
  setFilterChip,
  setPagination,
}) => {
  const [value, setValue] = useState(filterData);
  const classes = Styled.PaperStyles();

  const escapeFunction = useCallback(
    (ev) => {
      if (ev.keyCode === 27) {
        setOpenFilter(false);
      }
    },
    [setOpenFilter]
  );

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', escapeFunction, false);
    } else {
      document.removeEventListener('keydown', escapeFunction, false);
    }
  }, [open, escapeFunction]);

  const handleDateChange = (name) => (date) => {
    setValue({
      ...value,
      dates: {
        ...value.dates,
        [name]: {
          date,
          value: date && DateFns.format(date, 'dd/MM/yyyy'),
        },
      },
    });
  };

  const handleDisabled = useMemo(() => {
    return (
      value.cnpj.hasError ||
      (!value.cnpj.value &&
        !value.dates.dateIni.value &&
        !value.dates.dateFin.value)
    );
  }, [value]);

  const onSearch = () => {
    const filterRequest = {
      ...(value.cnpj ? { cnpj: value.cnpj } : {}),
      ...(value.dates?.dateIni.value || value.dates?.dateFin.value
        ? { dates: filterData.dates }
        : {}),
    };

    const chips = Object.keys(filterRequest).map((filter) => ({
      name: filter,
      value: value[filter],
    }));

    setPagination(Constants.pagination);
    setFilterChip(chips);
    setFilterData(value);
    setOpenFilter(false);
  };

  return (
    open && (
      <Paper elevation={4} classes={{ root: classes.root }}>
        <Styled.HeaderContainer>
          <Styled.HeaderTitleText>Filtros</Styled.HeaderTitleText>
          <Styled.HeaderSubtitleText>
            Utilize as opções abaixo para exibir somente os CNPJs desejados.
          </Styled.HeaderSubtitleText>
        </Styled.HeaderContainer>
        <Styled.BodyContainer>
          <Styled.BodyTitleText>Opções</Styled.BodyTitleText>
          <AUDocumentField
            id='add-blacklist-cnpj'
            type='cnpj'
            value={value.cnpj?.value}
            onChangeEvent={(evt) => {
              const error =
                value.cnpj.value.length > 0 &&
                !ValidationUtils.isValidDocument(evt.value);

              return setValue((prevState) => ({
                ...prevState,
                cnpj: {
                  ...prevState.cnpj,
                  value: evt.value,
                  hasError: !!error,
                },
              }));
            }}
            onBlurEvent={() => {
              const error =
                value.cnpj.value.length > 0 &&
                !ValidationUtils.isValidDocument(value.cnpj.value);
              if (!error) {
                return setValue((prevState) => ({
                  ...prevState,
                  cnpj: {
                    ...prevState.cnpj,
                    hasError: false,
                    errorMessage: '',
                  },
                }));
              }

              return setValue((prevState) => ({
                ...prevState,
                cnpj: {
                  ...prevState.cnpj,
                  hasError: true,
                  errorMessage: ErrorMessages.FORM.INVALID_CNPJ,
                },
              }));
            }}
            variant='outlined'
            label='CNPJ'
            margin='dense'
            placeholder='Digite o CNPJ...'
            classes={{ root: classes.textField }}
            error={value.cnpj?.hasError}
            helperText={value.cnpj?.errorMessage}
          />
          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <Styled.DatePickerStyled
              id='date-initial'
              size='small'
              fullWidth
              clearable
              cancelLabel='Cancelar'
              clearLabel='Limpar'
              inputVariant='outlined'
              format='dd/MM/yyyy'
              label='De'
              value={value.dates.dateIni.date}
              maxDate={value.dates.dateFin.date || new Date()}
              onChange={handleDateChange('dateIni')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Styled.IconButtonStyled>
                      <EventIcon />
                    </Styled.IconButtonStyled>
                  </InputAdornment>
                ),
              }}
            />
            <Styled.DatePickerStyled
              id='date-final'
              size='small'
              fullWidth
              clearable
              cancelLabel='Cancelar'
              clearLabel='Limpar'
              inputVariant='outlined'
              format='dd/MM/yyyy'
              label='Até'
              value={value.dates.dateFin.date}
              maxDate={new Date()}
              minDate={value.dates.dateIni.date || new Date('01-01-1970')}
              minDateMessage='Data final deve ser maior que a inicial'
              onChange={handleDateChange('dateFin')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Styled.IconButtonStyled>
                      <EventIcon />
                    </Styled.IconButtonStyled>
                  </InputAdornment>
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        </Styled.BodyContainer>
        <Styled.Footer>
          <Styled.FooterButton
            size='medium'
            color='primary'
            variant='text'
            onClick={() => setOpenFilter(false)}
          >
            Fechar
          </Styled.FooterButton>
          <Styled.FooterButton
            size='medium'
            color='primary'
            variant='contained'
            disabled={handleDisabled}
            onClick={() => onSearch()}
          >
            Aplicar
          </Styled.FooterButton>
        </Styled.Footer>
      </Paper>
    )
  );
};

export default BlacklistTableFilter;
