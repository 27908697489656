import {
  REFRESH_LAYERS,
  REFRESH_MARKERS,
  CHANGE_STATISTICS,
  REMOVE_LAYER,
  SWITCH_LAYER_VISIBILITY,
  SWITCH_HEATMAP,
  SHOW_LAYERS_LOADING,
  HIDE_LAYERS_LOADING,
} from './actions';

const initialState = {
  layers: {},
  layersOrder: [],
  count: 0,
  totalPages: 0,
  markers: {},
  statistics: {},
  heatmapLayer: null,
  isLoaded: false,
  loading: { count: 0, isShowing: false },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case REFRESH_LAYERS: {
      const { layers, layersOrder, count, totalPages } = payload;

      return {
        ...state,
        layers,
        layersOrder,
        count,
        totalPages,
      };
    }

    case REFRESH_MARKERS: {
      const { markers } = payload;

      return {
        ...state,
        markers,
        isLoaded: true,
      };
    }

    case CHANGE_STATISTICS: {
      const { statistics } = payload;

      return {
        ...state,
        statistics,
      };
    }

    case REMOVE_LAYER: {
      const { layerId } = payload;

      return {
        ...state,
        layersOrder: state.layersOrder.filter((id) => id !== layerId),
      };
    }

    case SWITCH_LAYER_VISIBILITY: {
      const { layerId } = payload;

      return {
        ...state,
        layers: {
          ...state.layers,
          [layerId]: {
            ...state.layers[layerId],
            isEnabled: !state.layers[layerId].isEnabled,
          },
        },
      };
    }

    case SWITCH_HEATMAP: {
      const { layerId } = payload;

      return {
        ...state,
        heatmapLayer: layerId,
      };
    }

    case SHOW_LAYERS_LOADING: {
      return {
        ...state,
        loading: {
          count: state.loading.count + 1,
          isShowing: true,
        },
      };
    }

    case HIDE_LAYERS_LOADING: {
      return {
        ...state,
        loading: {
          count: state.loading.count > 0 ? state.loading.count - 1 : 0,
          isShowing: !(state.loading.count - 1 <= 0),
        },
      };
    }

    default:
      return state;
  }
};
