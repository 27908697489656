import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  contain: 'content',
});

const ToolBarStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 0,
    margin: '0 0 16px 2px',
  },
  column: {
    fontWeight: 'bold',
  },
}));

const Pagination = withStyles({
  caption: {
    fontSize: '14px',
    opacity: 0.5,
  },
})(TablePagination);

const ToolBarText = styled(Typography)({
  fontSize: '14px',
  lineWeight: '16px',
  fontWeight: (props) => (props.type === 'filterButton' ? 600 : 'normal'),
  color: (props) => (props.type === 'filterButton' ? '#8A00C1' : '#00000099'),
  margin: (props) => (props.type === 'filterButton' ? '0 8px' : 0),
});

const ToolBarActions = styled('div')({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
});

const BodyContainer = styled('div')({
  border: '1px solid #DCDBDC',
  borderRadius: '5px',
  minHeight: '400px',
});

const EmptyContainer = styled('div')({
  display: 'flex',
  width: '100%',
  height: '300px',
  alignItems: 'center',
  justifyContent: 'center',
});

const LoadingContainer = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  zIndex: 1,
});

const AddButtonContainer = styled('div')({
  display: 'flex',
  alignSelf: 'flex-start',
  marginTop: '16px',
});

export {
  Container,
  ToolBarStyles,
  Pagination,
  ToolBarText,
  ToolBarActions,
  BodyContainer,
  EmptyContainer,
  LoadingContainer,
  AddButtonContainer,
};
