import { AUNotifier } from '@assertiva/assertiva-ui';

const showNotify = ({ message, type = 'notify' }) => {
  switch (type) {
    case 'notify':
      return AUNotifier.notify({
        message,
        options: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        },
      });
    case 'error':
      return AUNotifier.error(message, {
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    default:
      return AUNotifier.info(message, {
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
  }
};

const ellipsisString = (text, maxSize = 25) =>
  text?.length > maxSize ? `${text.slice(0, maxSize)}...` : text;

const maybePluralize = (count, noun, suffix = 's') =>
  `${noun}${count > 1 ? suffix : ''}`;

const toArray = (value) => {
  if (value) {
    return Array.isArray(value) ? value : [value];
  }

  return [];
};

const formatLabelString = (labelArray) => {
  if (!labelArray || !labelArray.length) return '';

  const newArray = [...labelArray];

  if (newArray.length <= 1) return newArray.toString();

  const others = newArray.slice(0, newArray.length - 1);
  return `${others.join(', ')} e ${newArray.pop()}`;
};

export {
  ellipsisString,
  maybePluralize,
  toArray,
  formatLabelString,
  showNotify,
};
