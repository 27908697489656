import axios, { BASE_URL } from './base';

export const getToken = async ({ integrationType }) => {
	return await axios.get(`${BASE_URL}/maps/crm/${integrationType}/token`);
};

export const postToken = async ({ integrationType, token }) => {
	return await axios.post(`${BASE_URL}/maps/crm/${integrationType}/token`, {
		token,
	});
};

export const getUsers = async ({ integrationType }) => {
	return await axios.get(`${BASE_URL}/maps/crm/${integrationType}/users`);
};

export const getPipelines = async ({ integrationType }) => {
	return await axios.get(`${BASE_URL}/maps/crm/${integrationType}/pipelines`);
};

export const getOrigins = async ({ integrationType }) => {
	return await axios.get(`${BASE_URL}/maps/crm/${integrationType}/origins`);
};

export const postPiperunDeals = async ({
	integrationType,
	layerId,
	pipelineStageHash,
	userMails,
	originName,
	suboriginName,
	rowCount,
}) => {
	return await axios.post(`${BASE_URL}/maps/crm/${integrationType}/export`, {
		layerId,
		pipelineStageHash,
		userMails,
		originName,
		suboriginName,
		rowCount,
	});
};
