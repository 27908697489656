import React from 'react';
import { connect } from 'react-redux';

import { AUHasPermission } from '@assertiva/assertiva-ui';
import { GEO_GENERAL } from '../../constants/permissions';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Layers from 'containers/layers/Layers';
import TimelineIcon from '@material-ui/icons/Timeline';
import RoomIcon from '@material-ui/icons/Room';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MapIcon from '@material-ui/icons/Map';
import CircularProgress from '@material-ui/core/CircularProgress';

import FilterSearch from 'components/filter/components/filterSearch/FilterSearch';
import SaveLayerModal from './components/SaveLayerModal';
import LabelCard from './components/LabelCard';
import GraphCard from './components/GraphCard';
import ActionsDrawer from 'containers/actionsDrawer/ActionsDrawer';
import ListExport from '../ListExport/ListExport';
import CRMExport from '../CRMExport/CRMExport';
import ListHistory from '../ListHistory/ListHistory';
import Map from 'containers/map/Map';
import DashWelcomeModal from '../dashWelcomeModal/DashWelcomeModal';
import { filterFormat } from '../../utils/filter';

import { closeDrawer } from 'actions';
import * as actions from './actions';
import {
  showLayersLoading,
  hideLayersLoading,
  refreshLayers,
} from '../../containers/layers/actions';
import {
  changeActions,
  changeExportLimits,
  changeFinality,
} from 'containers/actionsDrawer/actions';
import { showMapLoading, hideMapLoading } from '../../containers/map/actions';

import useDashboard from './useDashboard';

import './style.css';

const Dashboard = ({
  filterIsEmpty,
  isEditing,
  selectedFilters,
  filterData,
  filterResult,
  changeFilterResult,
  addFilter,
  removeFilter,
  clearFilter,
  changeFilterData,
  changeFilterDataCookie,
  changeSelectedFilters,
  saveFieldParams,
  fieldParams,
  filters,
  categories,
  layerId,
  layerSelected,
  changeSelectedLayer,
  filterLoading,
  showFilterLoading,
  hideFilterLoading,
  showLayersLoading,
  hideLayersLoading,
  refreshLayers,
  closeDrawer,
  drawers,
  changeActions,
  changeExportLimits,
  showMapLoading,
  hideMapLoading,
  refreshFilterMarkers,
  changeFilterStatistics,
  setMapsChangeFilter,
  changeFinality,
}) => {
  const [open, setOpen] = React.useState(true);
  const {
    currentFilter,
    setCurrentFilter,
    _selectedFilters,
    _addFilter,
    _removeFilter,
    submitFilter,
    getFilterName,
    getFilterLabel,
    showCurrentFilter,
    formatNumber,
    openLayerNameModal,
    setOpenLayerNameModal,
    disableHandler,
    submitLayer,
    updateLayer,
    currentView,
    setCurrentView,
    selectLayer,
    pagination,
    setPagination,
  } = useDashboard({
    selectedFilters,
    addFilter,
    removeFilter,
    clearFilter,
    changeFilterData,
    changeFilterDataCookie,
    changeSelectedFilters,
    changeFinality,
    filterData,
    changeFilterResult,
    filters,
    fieldParams,
    filterIsEmpty,
    layerId,
    changeSelectedLayer,
    showFilterLoading,
    hideFilterLoading,
    showLayersLoading,
    hideLayersLoading,
    refreshLayers,
    saveFieldParams,
    changeActions,
    changeExportLimits,
    showMapLoading,
    hideMapLoading,
    refreshFilterMarkers,
    changeFilterStatistics,
    isEditing,
    setMapsChangeFilter,
  });
  const primary_color = '#CE8EE3';
  const secondary_color = '#77DFDC';

  return (
    <>
      <AUHasPermission permissionKey={[...GEO_GENERAL]}>
        <Paper classes={{ root: 'dashboard__container' }} elevation={4}>
          <Typography variant='h5' gutterBottom>
            Estudos
          </Typography>
          <div className='dashboard__filter'>
            <div className='dashboard__filter__search'>
              <Typography variant='body2'>Filtros</Typography>
              <FilterSearch
                filters={categories.reduce(
                  (acc, { id: categoryId, hidden }) =>
                    !hidden
                      ? [
                          ...acc,
                          ...filters[categoryId].filterOrder.map(
                            (filterId) => ({
                              ...filters[categoryId].filtersById[filterId],
                              category: categoryId,
                            })
                          ),
                        ]
                      : acc,
                  []
                )}
                addFilter={_addFilter}
                selectedFilters={_selectedFilters}
                setCurrentFilter={setCurrentFilter}
                currentFilter={currentFilter}
                currentFilterLabel={
                  currentFilter ? getFilterLabel(currentFilter) : null
                }
                categories={categories}
              />
            </div>

            {currentFilter && (
              <div
                className={`dashboard__filter__fields dashboard__filter__fields--${getFilterName(
                  currentFilter
                )}`}
              >
                {showCurrentFilter(currentFilter)}
              </div>
            )}

            <div className='dashboard__filter__divider'>
              <Divider orientation='vertical' />
            </div>

            <div className='dashboard__filter__buttons'>
              <Button
                id='filtrar-button'
                variant='contained'
                color='primary'
                onClick={submitFilter}
                disabled={disableHandler}
                style={{ marginRight: '8px' }}
              >
                FILTRAR
              </Button>
              {isEditing ? (
                <Button
                  id='salvarCamada-button'
                  variant='contained'
                  color='primary'
                  onClick={updateLayer}
                  disabled={disableHandler}
                >
                  SALVAR CAMADA
                </Button>
              ) : (
                <Button
                  id='criarCamada-button'
                  variant='contained'
                  color='primary'
                  onClick={() => setOpenLayerNameModal(true)}
                  disabled={disableHandler}
                >
                  CRIAR CAMADA
                </Button>
              )}
            </div>
          </div>
          {selectedFilters.length > 0 && (
            <div className='dashboard__filter-chips'>
              <Typography variant='body2'>
                {isEditing ? layerSelected.layerName : 'Nova Camada'}
              </Typography>
              <Paper
                classes={{ root: 'dashboard__filter-chips__container' }}
                elevation={0}
              >
                {selectedFilters.map(
                  ({ filterId, categoryId, position }, filterIndex) => {
                    const { label, attributes } =
                      filters[categoryId].filtersById[filterId];

                    return attributes.map(({ type, name, ...rest }) => {
                      const params = {
                        value: rest.multiple
                          ? [filterData[name][position]]
                          : filterData[name],
                        options: fieldParams[name],
                        ...rest,
                      };

                      return (
                        <Tooltip
                          title={filterFormat[type](params) || ''}
                          key={filterIndex}
                        >
                          <Chip
                            key={filterIndex}
                            size='small'
                            label={
                              <>
                                <strong>{label}</strong>{' '}
                                {filterFormat[type](params)}
                              </>
                            }
                            classes={{ root: 'dashboard__filter-chip' }}
                            onClick={() =>
                              setCurrentFilter({
                                filterId,
                                categoryId,
                                filterIndex,
                                editMode: true,
                              })
                            }
                            onDelete={() => {
                              setCurrentFilter();
                              _removeFilter({
                                filterIndex,
                                categoryId,
                                filterId,
                                position,
                              });
                            }}
                          />
                        </Tooltip>
                      );
                    });
                  }
                )}
              </Paper>
            </div>
          )}
          <div className='dashboard__study-area'>
            <div className='dashboard__study-area__layers'>
              <div className='dashboard__study-area__layers__header'>
                <Typography
                  variant='h6'
                  gutterBottom
                  className='dashboard__study-area__name'
                >
                  Camadas
                </Typography>
              </div>

              <Divider />

              <Layers selectLayer={selectLayer} />
            </div>
            <div className='dashboard__study-area__divider'>
              <Divider orientation='vertical' />
            </div>
            <div className='dashboard__study-area__viewport'>
              <div className='dashboard__study-area__viewport__header'>
                <div className='dashboard__study-area__name__container'>
                  <Typography
                    variant='h6'
                    gutterBottom
                    className='dashboard__study-area__name'
                  >
                    Características do estudo
                  </Typography>
                  {layerSelected.layerName && (
                    <Typography
                      variant='subtitle1'
                      gutterBottom
                      className='dashboard__study-area__layerName'
                    >
                      {layerSelected.layerName}
                    </Typography>
                  )}
                </div>
                <div className='dashboard__study-area__actions__container'>
                  {filterLoading && currentView !== 'dashboard' && (
                    <CircularProgress
                      style={{
                        width: '20px',
                        height: '20px',
                        margin: '0 8px 0 0',
                      }}
                    />
                  )}
                  <ToggleButtonGroup
                    value={currentView}
                    exclusive
                    onChange={(_, view) => view && setCurrentView(view)}
                    size='small'
                    color='primary'
                  >
                    <ToggleButton
                      value='dashboard'
                      className='dashboard__study-area__toggle__button'
                    >
                      <Tooltip
                        title='Visualizar dashboard'
                        className='dashboard__study-area__toggle__tooltip'
                      >
                        <DashboardIcon />
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton
                      value='map'
                      className='dashboard__study-area__toggle__button'
                    >
                      <Tooltip title='Visualizar mapa'>
                        <MapIcon />
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>

              <Divider />

              {filterLoading && currentView === 'dashboard' && (
                <div className='filter__loading'>
                  <CircularProgress />
                </div>
              )}
              {filterResult ? (
                <div className='dashboard__study-area__container'>
                  <div className='dashboard__study-area__row'>
                    <LabelCard
                      label='Total de empresas'
                      value={formatNumber(filterResult.total)}
                    />
                    <LabelCard
                      label='Faturamento total'
                      value={'R$ ' + formatNumber(filterResult.totalIncome)}
                    />
                    <LabelCard
                      label='Total de funcionários'
                      value={formatNumber(filterResult.totalEmployees)}
                    />
                  </div>
                  {currentView === 'dashboard' && (
                    <>
                      <div className='dashboard__study-area__row'>
                        <GraphCard
                          data={filterResult.companyPerIncomeRange}
                          title='Empresas por faixa de faturamento (anual)'
                          type='bar'
                          width='100%'
                          colors={[secondary_color]}
                        />
                      </div>
                      <div className='dashboard__study-area__row dashboard__study-area__row--two-cols'>
                        <GraphCard
                          data={filterResult.companyPerSegment}
                          title='Empresas por segmento'
                          type='pie'
                          width='100%'
                          colors={
                            filterResult.companyPerSegment.length === 1 && [
                              primary_color,
                            ]
                          }
                        />
                        <GraphCard
                          data={filterResult.companyPerSize}
                          title='Porte das empresas'
                          type='bar'
                          width='100%'
                          colors={[secondary_color]}
                        />
                      </div>
                      <div className='dashboard__study-area__row dashboard__study-area__row--two-cols'>
                        <GraphCard
                          data={filterResult.companyPerEmployeeRange}
                          title='Empresas por faixa de funcionários'
                          type='bar'
                          width='100%'
                          colors={[secondary_color]}
                        />
                        <GraphCard
                          data={filterResult.companyType}
                          title='Matriz/Filial'
                          type='pie'
                          width='100%'
                          colors={['#009BF7', '#8773D0']}
                        />
                      </div>
                    </>
                  )}
                  <Map
                    currentView={currentView}
                    layerSelected={layerSelected}
                  />
                </div>
              ) : (
                <Paper
                  classes={{ root: 'dashboard__study-area__empty-panel' }}
                  elevation={0}
                >
                  <Paper
                    classes={{
                      root: 'dashboard__study-area__empty-panel__icon',
                    }}
                    elevation={0}
                  >
                    {currentView === 'dashboard' ? (
                      <TimelineIcon fontSize='inherit' />
                    ) : (
                      <RoomIcon fontSize='inherit' />
                    )}
                  </Paper>
                  <Typography
                    variant='body2'
                    className='dashboard__study-area__empty-panel__text'
                  >
                    {!filterLoading && (
                      <>
                        {currentView === 'dashboard' ? (
                          <>Para realizar estudos, crie filtros e camadas</>
                        ) : (
                          <>Para visualizar o mapa, crie filtros e camadas</>
                        )}
                      </>
                    )}
                  </Typography>
                </Paper>
              )}
            </div>
          </div>
        </Paper>
        <div className='dashboard__actions'>
          <ActionsDrawer
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
        <SaveLayerModal
          open={openLayerNameModal}
          onClose={() => setOpenLayerNameModal(false)}
          submitLayer={submitLayer}
        />
        <ListExport
          {...drawers['ListExport'].data}
          open={drawers['ListExport'].isOpen}
          onClose={() => closeDrawer({ drawerName: 'ListExport' })}
        />
        <CRMExport
          {...drawers['CRMExport'].data}
          open={drawers['CRMExport'].isOpen}
          onClose={() => closeDrawer({ drawerName: 'CRMExport' })}
        />
        <ListHistory
          {...drawers['ListHistory'].data}
          open={drawers['ListHistory'].isOpen}
          onClose={() => closeDrawer({ drawerName: 'ListHistory' })}
        />
      </AUHasPermission>
    </>
  );
};

const mapStateToProps = ({ dashboard, global }) => ({
  filterIsEmpty: dashboard.isEmpty,
  isEditing: dashboard.isEditing,
  fieldParams: dashboard.fieldParams,
  selectedFilters: dashboard.selectedFilters,
  filterData: dashboard.filterData,
  filterResult: dashboard.filterResult,
  filters: dashboard.filters,
  categories: dashboard.categories,
  layerId: dashboard.layerId,
  layerSelected: dashboard.layerSelected,
  filterLoading: dashboard.filterLoading.isShowing,
  drawers: global.drawers,
});

export default connect(mapStateToProps, {
  ...actions,
  showLayersLoading,
  hideLayersLoading,
  refreshLayers,
  closeDrawer,
  changeActions,
  changeExportLimits,
  showMapLoading,
  hideMapLoading,
  changeFinality,
})(Dashboard);
