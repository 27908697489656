export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const CHANGE_SELECTED_FILTERS = 'CHANGE_SELECTED_FILTERS';
export const CHANGE_FILTER_DATA = 'CHANGE_FILTER_DATA';
export const REFRESH_FILTER_MARKERS = 'REFRESH_FILTER_MARKERS';
export const CHANGE_FILTER_STATISTICS = 'CHANGE_FILTER_STATISTICS';
export const CHANGE_FILTER_RESULT = 'CHANGE_FILTER_RESULT';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const EDIT_LAYER_FILTER = 'EDIT_LAYER_FILTER';
export const MAPS_CHANGE_FILTER = 'MAPS_CHANGE_FILTER';
export const SAVE_FIELD_PARAMS = 'SAVE_FIELD_PARAMS';
export const SHOW_FILTER_LOADING = 'SHOW_FILTER_LOADING';
export const HIDE_FILTER_LOADING = 'HIDE_FILTER_LOADING';
export const CHANGE_SELECTED_LAYER = 'CHANGE_SELECTED_LAYER';
export const CHANGE_FILTER_DATA_COOKIE = 'CHANGE_FILTER_DATA_COOKIE';

export const addFilter = ({ categoryId, filterId, position }) => ({
  type: ADD_FILTER,
  payload: { categoryId, filterId, position },
});

export const removeFilter = ({ selectedFilters, filterData }) => ({
  type: REMOVE_FILTER,
  payload: { selectedFilters, filterData },
});

export const changeSelectedFilters = ({ selectedFilters }) => ({
  type: CHANGE_SELECTED_FILTERS,
  payload: { selectedFilters },
});

export const changeFilterData = ({
  fieldName,
  fieldValue,
  filterData,
  currentFilter,
  selectedFilters,
}) => ({
  type: CHANGE_FILTER_DATA,
  payload: {
    fieldName,
    fieldValue,
    filterData,
    currentFilter,
    selectedFilters,
  },
});

export const changeFilterDataCookie = ({filterData}) => ({
  type: CHANGE_FILTER_DATA_COOKIE,
  payload: {filterData},
});

export const refreshFilterMarkers = (markers) => ({
  type: REFRESH_FILTER_MARKERS,
  payload: { markers },
});

export const changeFilterStatistics = (statistics) => ({
  type: CHANGE_FILTER_STATISTICS,
  payload: { statistics },
});

export const changeFilterResult = (result) => ({
  type: CHANGE_FILTER_RESULT,
  payload: { result },
});

export const clearFilter = () => ({
  type: CLEAR_FILTER,
});

export const editLayerFilter = ({ layerId }) => ({
  type: EDIT_LAYER_FILTER,
  payload: { layerId },
});

export const setMapsChangeFilter = (status) => ({
  type: MAPS_CHANGE_FILTER,
  payload: { status },
});

export const saveFieldParams = (fieldParams) => ({
  type: SAVE_FIELD_PARAMS,
  payload: { fieldParams },
});

export const showFilterLoading = () => ({
  type: SHOW_FILTER_LOADING,
});

export const hideFilterLoading = () => ({
  type: HIDE_FILTER_LOADING,
});

export const changeSelectedLayer = ({ layerId, layerName }) => ({
  type: CHANGE_SELECTED_LAYER,
  payload: { layerId, layerName },
});
