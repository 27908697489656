import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  contain: 'content',
  padding: '16px 24px 16px 24px',
});

const ModalTitle = styled(Typography)({
  fontSize: '20px',
  lineHeight: '23px',
  fontWeight: 'bold',
  color: 'rgba(0, 0, 0, 0.85)',
  marginBottom: '8px',
});

const ModalBody = styled(Typography)({
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.25px',
  color: 'rgba(0, 0, 0, 0.38);',
  marginBottom: '32px',
});

const ExamplesContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '42px',
});

const ExampleIcon = styled('img')({
  width: '40px',
  height: '40px',
  margin: '0px 24px',
});

const ModalButton = styled(Button)({
  width: '92px',
  height: '36px',
  padding: '10px 16px',
  alignSelf: 'flex-end',
  textTransform: 'uppercase',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '1.25px',
});

export {
  Container,
  ModalTitle,
  ModalBody,
  ExamplesContainer,
  ExampleIcon,
  ModalButton,
};
