export default {
  mapCenter: { lat: -15.7801, lng: -47.9292 },
  defaultZoom: 4,
  defaultMapPoints: '-22.897626,-47.087655,-22.8828,-47.066627',
  defaultMarkerColor: '#008cff',
  fileTypesBlacklist: ['.csv', '.txt', '.xlsx'],
  fileSizeBlacklist: 200000000,
  uploadStep: {
    EMPTY: 'EMPTY',
    PROGRESS: 'PROGRESS',
    COMPLETED: 'UPLOAD_COMPLETED',
  },
  processStep: {
    NOT_STARTED: -1,
    STARTED: 0,
    VALIDATING: 1,
    PROCESSING: 2,
    DONE: 3,
    ERROR: 4,
  },
  toastType: {
    ERROR: 'error',
    NOTIFY: 'notify',
    INFO: 'info',
  },
};
