import { createStore, combineReducers } from 'redux';

// Reducer imports
import global from './reducers';
import map from './containers/map/reducers';
import layers from './containers/layers/reducers';
import actionsDrawer from './containers/actionsDrawer/reducers';
import dashboard from './components/dashboard/reducers';

export default createStore(
  combineReducers({
    global,
    map,
    dashboard,
    layers,
    actionsDrawer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
