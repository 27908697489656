export const GLOBAL_OPEN_DRAWER = 'GLOBAL_OPEN_DRAWER';
export const GLOBAL_CLOSE_DRAWER = 'GLOBAL_CLOSE_DRAWER';

export const openDrawer = ({ drawerName, data }) => ({
	type: GLOBAL_OPEN_DRAWER,
	payload: { drawerName, data },
});

export const closeDrawer = ({ drawerName }) => ({
	type: GLOBAL_CLOSE_DRAWER,
	payload: { drawerName },
});
