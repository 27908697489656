export default {
  cnae: '',
  openingDate: ['0', '20'],
  registrationStatus: 'ATIVA',
  companyIncome: ['0', '10000'],
  numberOfEmployees: ['0', '1000'],
  companySize: ['8', '9'],
  fantasyName: '',
  corporateName: '',
  document: '',
  companyType: 'MATRIZ',
  publicSector: 'SIM',
  individualCompany: 'SIM',
  shareCapital: ['0', '10000'],
  numberOfMembers: ['0', '1000'],
  simpleNational: 'SIM',
  scoreRecuperePJ: [1, 6],
  cep: [],
  franchise: '',
};
