import React from 'react';
import Color from 'color';

const ClusterMarker = ({ color = '#008cff', size = 56, value }) => {
	return (
		<div
			style={{
				position: 'absolute',
				top: '0px',
				left: '0px',
			}}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				viewBox="0 0 203.999 200.897"
			>
				<g
					id="Grupo_7"
					data-name="Grupo 7"
					transform="translate(-255 -202)"
				>
					<path
						id="Subtração_1"
						data-name="Subtração 1"
						d="M149.83,200.9h0l-27.018-100.7.209.057-.11-.111,99.8-26.705A102.073,102.073,0,0,1,149.83,200.9ZM95.964,199.88h0A102.141,102.141,0,0,1,26.531,74.429l96.079,25.711-.11.111.208-.056L95.964,199.879Zm26.8-99.89h0l-70.1-70.141A102.164,102.164,0,0,1,85.076,8.008,102.113,102.113,0,0,1,194.829,27.875L122.761,99.989Z"
						transform="translate(232.239 202)"
						fill={Color(color).alpha(0.2)}
					/>
					<path
						id="Subtração_2"
						data-name="Subtração 2"
						d="M145.423,174.3,122.812,90.022l.209.056-.11-.11L206.275,67.66a85.057,85.057,0,0,1-60.85,106.64Zm-45.052-1.021h0A85.117,85.117,0,0,1,42.958,68.653L122.61,89.968l-.11.11.208-.056-22.336,83.256Zm22.389-83.461h0L64.68,31.7A85.129,85.129,0,0,1,91.69,13.5a85.1,85.1,0,0,1,91.115,16.232L122.761,89.818Z"
						transform="translate(232.239 212.171)"
						fill={Color(color).alpha(0.4)}
					/>
					<circle
						id="Elipse_3"
						data-name="Elipse 3"
						cx="62"
						cy="62"
						r="62"
						transform="translate(295 242)"
						fill={Color(color)}
					/>
				</g>
			</svg>
			<div
				style={{
					position: 'absolute',
					top: '0px',
					left: '0px',
					textAlign: 'center',
					width: '56px',
					lineHeight: '56px',
					fontWeight: 'bold',
					fontSize: '14px',
				}}
			>
				<span>{value}</span>
			</div>
		</div>
	);
};

export default ClusterMarker;
