import React from 'react';
import Chip from '@material-ui/core/Chip';

import * as Styled from './styles';
import Constants from '../../constants';

const FilterChip = ({
  filterChip,
  setFilterChip,
  filterData,
  setFilterData,
}) => {
  const classes = Styled.ChipStyle();

  const onDelete = (chipName) => {
    const newFilterChip = filterChip.filter(
      (filter) => filter.name !== chipName
    );
    const removeFilterData = Constants.defaultFilterData[chipName];
    const newFilterData = {
      ...filterData,
      [chipName]: removeFilterData,
    };

    setFilterChip(newFilterChip);
    setFilterData(newFilterData);
  };

  const label = {
    cnpj: (cnpj) => (
      <Styled.TextContainer>
        <Styled.Text bold>CNPJ: </Styled.Text>
        <Styled.Text>&nbsp;{cnpj.value}</Styled.Text>
      </Styled.TextContainer>
    ),
    dates: (value) => (
      <Styled.TextContainer>
        {value.dateIni.value && (
          <>
            <Styled.Text bold>De: </Styled.Text>
            <Styled.Text>&nbsp;{value.dateIni.value}</Styled.Text>
          </>
        )}
        {value.dateFin.value && (
          <>
            <Styled.Text bold>&nbsp;Até:</Styled.Text>
            <Styled.Text>&nbsp;{value.dateFin.value}</Styled.Text>
          </>
        )}
      </Styled.TextContainer>
    ),
  };

  return (
    <>
      {filterChip.map((chip, index) => {
        return (
          label[chip.name] && (
            <Chip
              key={index}
              size='small'
              classes={{ root: classes.root }}
              label={label[chip.name](chip.value)}
              onDelete={() => onDelete(chip.name)}
            />
          )
        );
      })}
    </>
  );
};

export default FilterChip;
