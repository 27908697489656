import axios, { BASE_URL } from './base';

export const getLayers = ({ page, limit }) => {
  return axios.get(`${BASE_URL}/maps/layer`, {
    params: {
      page,
      limit,
    },
  });
};

export const postLayer = ({
  name,
  selectedFilters,
  data: { points, zoom, ...filterParams },
}) => {
  return axios.post(`${BASE_URL}/maps/layer`, {
    name,
    selectedFilters,
    data: {
      points,
      zoom,
      params: Object.keys(filterParams),
      ...filterParams,
    },
  });
};

export const patchLayer = ({
  layerId,
  selectedFilters,
  data: { points, zoom, ...filterParams },
}) => {
  return axios.patch(`${BASE_URL}/maps/layer/${layerId}`, {
    selectedFilters,
    data: {
      points,
      zoom,
      params: Object.keys(filterParams),
      ...filterParams,
    },
  });
};

export const patchLayerName = ({ layerId, newLayerName }) => {
  return axios.patch(`${BASE_URL}/maps/layer/${layerId}`, {
    newLayerName,
  });
};

export const patchLayerColor = ({ layerId, color }) => {
  return axios.patch(`${BASE_URL}/maps/layer/${layerId}`, {
    color,
  });
};

export const deleteLayer = ({ layersId }) => {
  return axios.delete(`${BASE_URL}/maps/layer`, {
    data: { layersId },
  });
};
