import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

import './styles.css';

const ScoreSelect = ({ id, name, rest, handleChange, value, addFilter }) => {
  const [_value, setValue] = useState(value || rest.defaultValue);
  const [useInterval, setUseInterval] = useState(true);
  const selectedFilters = useSelector(
    (state) => state.dashboard.selectedFilters
  );

  const onSave = () => {
    handleChange({
      target: {
        type: 'scoreSelect',
        name: name,
        value: _value,
      },
    });

    if (
      selectedFilters.some(
        (selectedFilter) =>
          selectedFilter.filterId === id &&
          selectedFilter.categoryId === rest.categoryId
      )
    ) {
      return 0;
    }

    addFilter({ filterId: id, categoryId: rest.categoryId });
  };

  return (
    <Grid container spacing={2} className='score__select__container'>
      <Grid item xs={12} className='score__select__row'>
        <div
          className={`score__select__form_div${
            useInterval ? '--interval' : ''
          }`}
        >
          <FormControl variant='outlined' className='score__select__form'>
            <InputLabel>{useInterval ? 'De' : ''}</InputLabel>
            <Select
              id={`select-de-${id}`}
              value={_value[0]}
              placeholder={'De'}
              name={name}
              onChange={(event) =>
                setValue(
                  useInterval
                    ? [event.target.value, _value[1]]
                    : [event.target.value]
                )
              }
              margin='dense'
            >
              {rest.marks.map(({ letter, value: v }) => (
                <MenuItem
                  disabled={useInterval && v >= _value[1]}
                  key={v}
                  value={v}
                >
                  {letter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {useInterval && (
            <FormControl variant='outlined' className='score__select__form'>
              <InputLabel>Até</InputLabel>
              <Select
                id={`select-ate-${id}`}
                value={_value[1]}
                placeholder={'Até'}
                name={name}
                onChange={(event) => setValue([_value[0], event.target.value])}
                margin='dense'
              >
                {rest.marks.map(({ letter, value: v }) => (
                  <MenuItem disabled={v <= _value[0]} key={v} value={v}>
                    {letter}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        <FormControlLabel
          control={
            <Switch
              checked={_value?.length > 1}
              onChange={(event) => {
                setUseInterval(event.target.checked);
                setValue(event.target.checked ? [1, 6] : [3]);
              }}
              name='checkedB'
              color='primary'
            />
          }
          label={
            <Typography
              classes={{
                root: 'score__select__check_interval',
              }}
            >
              Usar o intervalo de Score
            </Typography>
          }
        />
        <IconButton
          className='score__select__add_icon'
          size='medium'
          onClick={onSave}
        >
          <AddIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ScoreSelect;
