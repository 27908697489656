import {
  OPEN_DRAWER,
  CLOSE_DRAWER,
  CHANGE_ACTIONS,
  SHOW_DRAWER_LOADING,
  HIDE_DRAWER_LOADING,
  CHANGE_EXPORT_LIMITS,
  CHANGE_FINALITY,
} from './actions';

const initialState = {
  isOpen: false,
  loading: { count: 0, isShowing: false },
  actions: {},
  actionsOrder: [],
  count: 0,
  totalPages: 0,
  exportLimits: { standard: 0, score: 0 },
  finality: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_DRAWER: {
      return {
        ...state,
        isOpen: true,
      };
    }

    case CLOSE_DRAWER: {
      return {
        ...state,
        isOpen: false,
      };
    }

    case CHANGE_ACTIONS: {
      const { layerActionsById, layerActionsOrder, count, totalPages } =
        payload;

      return {
        ...state,
        actions: layerActionsById,
        actionsOrder: layerActionsOrder,
        count,
        totalPages,
      };
    }

    case SHOW_DRAWER_LOADING: {
      return {
        ...state,
        loading: {
          count: state.loading.count + 1,
          isShowing: true,
        },
      };
    }

    case HIDE_DRAWER_LOADING: {
      return {
        ...state,
        loading: {
          count: state.loading.count > 0 ? state.loading.count - 1 : 0,
          isShowing: !(state.loading.count - 1 <= 0),
        },
      };
    }

    case CHANGE_EXPORT_LIMITS: {
      const { standard, score } = payload;

      return {
        ...state,
        exportLimits: {
          standard,
          score,
        },
      };
    }

    case CHANGE_FINALITY: {
      const { finality } = payload;

      return {
        ...state,
        finality: finality,
      };
    }

    default:
      return state;
  }
};
