import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const ProgressBar = withStyles(() => ({
  root: {
    height: 5,
    borderRadius: 2,
  },
  colorPrimary: {
    backgroundColor: '#CE8EE3',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#8A00C1',
  },
}))(LinearProgress);

const Progress = ({ progress }) => {
  return <ProgressBar variant='determinate' value={progress} />;
};

export default Progress;
