export default [
  { label: 'CNPJ', name: 'document' },
  { label: 'Razão social', name: 'corporateName' },
  { label: 'Nome fantasia', name: 'fantasyName' },
  { label: 'Tipo de empresa', name: 'companyType' },
  { label: 'Data de abertura', name: 'openingDate' },
  { label: 'Situação cadastral', name: 'registrationStatus' },
  { label: 'CNAE', name: 'cnae' },
  { label: 'CNAE descrição', name: 'descriptionCnae' },
  { label: 'CNAE grupo', name: 'groupCnae' },
  { label: 'Setor público', name: 'publicSector' },
  { label: 'Empresa individual', name: 'individualCompany' },
  { label: 'Quantidade de funcionários', name: 'numberOfEmployees' },
  { label: 'Porte da empresa', name: 'companySize' },
  { label: 'Capital social', name: 'shareCapital' },
  { label: 'Faturamento presumido anual', name: 'companyIncome' },
  { label: 'Logradouro', name: 'street' },
  { label: 'Número', name: 'number' },
  { label: 'Complemento', name: 'complement' },
  { label: 'Bairro', name: 'neighborhood' },
  { label: 'Cidade', name: 'city' },
  { label: 'UF', name: 'uf' },
  { label: 'CEP', name: 'zipCode' },
  { label: 'Site', name: 'site' },
  { label: 'E-mail 1', name: 'email1' },
  { label: 'E-mail 2', name: 'email2' },
  { label: 'Telefone 1', name: 'phone1' },
  { label: 'Telefone 2', name: 'phone2' },
  { label: 'Celular 1', name: 'mobilePhone1' },
  { label: 'Celular 2', name: 'mobilePhone2' },
  { label: 'Quantidade de sócios', name: 'numberOfMembers' },
  { label: 'Sócio 1 - Documento', name: 'member1document' },
  { label: 'Sócio 1 - Nome', name: 'member1name' },
  { label: 'Sócio 1 - Cargo', name: 'member1role' },
  { label: 'Sócio 1 - Participação', name: 'member1participation' },
  { label: 'Sócio 1 - Data de entrada', name: 'member1entryDate' },
  { label: 'Sócio 1 - Data de saída', name: 'member1departureDate' },
  { label: 'Sócio 2 - Documento', name: 'member2document' },
  { label: 'Sócio 2 - Nome', name: 'member2name' },
  { label: 'Sócio 2 - Cargo', name: 'member2role' },
  { label: 'Sócio 2 - Participação', name: 'member2participation' },
  { label: 'Sócio 2 - Data de entrada', name: 'member2entryDate' },
  { label: 'Sócio 2 - Data de saída', name: 'member2departureDate' },
];
