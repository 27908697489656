import React, { useState } from 'react';

import { AUNotifier } from '@assertiva/assertiva-ui';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import CachedIcon from '@material-ui/icons/Cached';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { postToken } from '../../../services/crm';

const CRMToken = ({
	getScreenFieldsData,
	token: _token,
	linesQtd: _linesQtd,
	maxLinesQtdSelected: _maxLinesQtdSelected,
	maxLinesQtd,
	tokenIsValid,
	nextStep,
	onClose,
}) => {
	const [token, setToken] = useState(_token);
	const [linesQtd, setLinesQtd] = useState(_linesQtd || maxLinesQtd);
	const [maxLinesQtdSelected, setMaxLinesQtdSelected] =
		useState(_maxLinesQtdSelected);
	const [loading, setLoading] = useState(false);
	const [tokenValidation, setTokenValidation] = useState({
		isValidated: tokenIsValid,
		isValid: tokenIsValid,
	});

	const validateToken = () => {
		setTokenValidation({ isValidated: false, isValid: false });
		setLoading(true);

		postToken({ integrationType: 1, token })
			.then(({ data: response }) => {
				if (response.success) {
					setTokenValidation({ isValidated: true, isValid: true });
					getScreenFieldsData();
				} else setTokenValidation({ isValidated: true, isValid: false });
			})
			.catch(() =>
				AUNotifier.error('Ocorreu um erro. Tente novamente mais tarde.'),
			)
			.finally(() => setLoading(false));
	};

	const getTokenValidationIcon = () => {
		if (!tokenValidation.isValidated) return <CachedIcon />;
		else if (tokenValidation.isValid)
			return (
				<span style={{ color: '#4caf50' }}>
					<DoneIcon />
				</span>
			);
		else return <ErrorOutlineIcon color='error' />;
	};

	const selectMaxLines = (checked) => {
		setMaxLinesQtdSelected(checked);

		if (checked) setLinesQtd(maxLinesQtd);
	};

	return (
		<div className='crm-export__content__step'>
			<Typography variant='h6'>Insira o seu token</Typography>
			<Grid container spacing={2}>
				<Grid item xs={9}>
					<TextField
						placeholder='Insira seu token aqui'
						variant='outlined'
						value={token}
						onChange={(evt) => setToken(evt.target.value)}
						InputLabelProps={{
							margin: 'dense',
						}}
						InputProps={{
							margin: 'dense',
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										className={`crm-export__content__step__loading ${
											loading && 'active'
										}`}
										size='small'
										disabled
									>
										{getTokenValidationIcon()}
									</IconButton>
								</InputAdornment>
							),
							fullWidth: true,
						}}
						error={tokenValidation.isValidated && !tokenValidation.isValid}
						helperText={
							tokenValidation.isValidated &&
							!tokenValidation.isValid &&
							'Token inválido'
						}
						fullWidth
					/>
				</Grid>
				<Grid item xs={3}>
					<Button
						variant='outlined'
						color='primary'
						onClick={validateToken}
						className='crm-export__content__step__btn'
						disabled={loading}
						fullWidth
					>
						Integrar
					</Button>
				</Grid>
			</Grid>

			<Typography
				className='crm-export__content__step__title'
				variant='body2'
				color='primary'
				gutterBottom
			>
				<Link
					target='_blank'
					href='https://suporte.crmpiperun.com/kb/article/112492/como-localizar-o-token-para-integracoes-com-o-piperun'
				>
					Veja como encontrar seu token clicando aqui!
				</Link>
			</Typography>
			<Typography
				className='crm-export__content__step__subtitle'
				variant='caption'
				color='textSecondary'
			>
				O Geotimize é um parceiro seguro do Piperun. Suas credenciais serão
				encriptadas e poderão ser removidas a qualquer momento.
			</Typography>

			<div class='crm-export__content__step__lines-qtd'>
				<Typography variant='h6'>
					Defina a quantidade de linhas do arquivo
				</Typography>
				<Typography variant='body2' gutterBottom>
					Insira a quantidade de linhas que serão exportadas
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField
							variant='outlined'
							type='number'
							value={linesQtd}
							onChange={(evt) => setLinesQtd(evt.target.value)}
							InputLabelProps={{
								margin: 'dense',
							}}
							InputProps={{
								margin: 'dense',
								fullWidth: true,
							}}
							inputProps={{ min: 1, max: maxLinesQtd }}
							fullWidth
							disabled={maxLinesQtdSelected}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormControlLabel
							control={
								<Checkbox
									checked={maxLinesQtdSelected}
									onChange={(evt, checked) => selectMaxLines(checked)}
									color='primary'
								/>
							}
							label='Todas as linhas'
						/>
					</Grid>
				</Grid>
			</div>

			<div className='crm-export__content__step__footer'>
				<Grid container spacing={2}>
					<Grid item>
						<Button
							variant='contained'
							color='primary'
							onClick={() =>
								nextStep({
									token,
									linesQtd,
									maxLinesQtdSelected,
									maxLinesQtd,
									tokenIsValid: tokenValidation.isValid,
								})
							}
							disabled={
								!tokenValidation.isValidated || !tokenValidation.isValid
							}
						>
							Continuar
						</Button>
					</Grid>
					<Grid item>
						<Button variant='outlined' color='primary' onClick={onClose}>
							Cancelar
						</Button>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

CRMToken.defaultProps = {
	token: '',
	linesQtd: 0,
	maxLinesQtd: 0,
	maxLinesQtdSelected: true,
	tokenIsValid: false,
};

export default CRMToken;
