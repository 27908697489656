import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ReactApexChart from 'react-apexcharts';

import { StringUtils } from '@assertiva/assertiva-ui'

import '../style.css';

const GraphCard = ({
    data,
    title,
    type,
    width,
    colors,
}) => {
    let series, options;
    const serires_data = data.map((item) => Number(item.value));

    if (type === 'pie') {
        series = serires_data
        options = {
            labels: data.map((item) => StringUtils.capitalize(item.label)),
            colors: colors ? colors : undefined
        };
    }
    else if (type === 'bar') {
        series = [{
            data: serires_data
        }];
        options = {
          colors: colors ? colors : undefined,
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: data.map((item) => {
              if (item.label.split(' ').length > 3) {
                const labels = item.label.split(' ');
                const mid = Math.floor(labels.length / 2);

                return [
                  labels.slice(0, mid).join(' '),
                  labels.slice(mid, labels.length).join(' '),
                ];
              }

              return item.label;
            }),
          },
        };
    }

    return (
        <Paper
            classes={{
                root: 'dashboard__study-area__graph_card',
            }}
            elevation={0}
        >    
            <Typography 
                classes={{
                    root: 'dashboard__study-area__graph_card__title',
                }} 
                gutterBottom
            >
                {title}
            </Typography>
            <div id="chart">
                <ReactApexChart 
                    options={options} 
                    series={series} 
                    type={type} 
                    width={width}
                    height="350"
                />
            </div>
        </Paper>
    )
}

export default GraphCard; 