import React from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

import DownloadIcon from '../assets/download-icon.svg';

import { sendAnalytics } from '../../../utils/analytics';

const ListDownloadFile = ({ config, listInfo }) => {
  return (
    <div className='export-list-modal__step-3'>
      <Typography variant='h6' gutterBottom>
        Seu arquivo foi gerado com sucesso!
      </Typography>

      <div className='export-list-modal__step-3__container'>
        <div className='export-list-modal__step-3__summary'>
          <div className='export-list-modal__step-3__summary__right'>
            <Chip
              id='exportarLista-nomeLista'
              size='small'
              label={`${listInfo.name}.${config.fileExtension}`}
            />
            <Typography
              variant='body1'
              className='export-list-modal__step-3__details'
            >
              Tamanho: {listInfo.fileSize}
            </Typography>
            <Typography
              variant='body1'
              className='export-list-modal__step-3__details'
            >
              Quantidade de entradas: {listInfo.rowCount}
            </Typography>
          </div>
        </div>
        <Button
          id='exportarLista-baixar-button'
          color='primary'
          variant='outlined'
          startIcon={<img src={DownloadIcon} height={24} width={24} />}
          className='export-list-modal__step-3__button'
          onClick={() => {
            window.open(listInfo.url, '_blank');
            sendAnalytics({ event: 'baixar-button', page: 'exportarLista' });
          }}
        >
          Baixar arquivo
        </Button>
      </div>
    </div>
  );
};

ListDownloadFile.defaultProps = {
  config: {
    params: [],
    fileExtension: 'csv',
    finality: '',
  },
  listInfo: {
    name: '',
    rowCount: 0,
    url: '',
  },
};

export default ListDownloadFile;
