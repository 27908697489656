import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Typography } from '@material-ui/core';

import './styles.css';

const options = {
  chart: {
    id: 'score-bar',
    toolbar: {
      show: false,
    },
    height: '100%',
  },
  tooltip: {
    custom: ({ series, seriesIndex, dataPointIndex }) => {
      return `<div class="chart__tooltip">
          <span> ${series[seriesIndex][dataPointIndex]} </span>
        </div>`;
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ['#8A00C1'],
  xaxis: {
    categories: ['A', 'B', 'C', 'D', 'E', 'F'],
  },
};

const ChartComponent = ({ scoreChart }) => {
  const [series, setSeries] = useState([{ data: [0, 0, 0, 0, 0, 0] }]);

  useEffect(() => {
    setSeries([{ data: scoreChart }]);
  }, [scoreChart]);

  return (
    <>
      <div className='chart'>
        <div className='chart__div_header'>
          <Typography classes={{ root: 'chart__header_title_text' }}>
            Score Recupere{' '}
          </Typography>
          <Typography classes={{ root: 'chart__header_subtitle_text' }}>
            Volume:
          </Typography>
        </div>
        <Chart type='bar' series={series} options={options} />
      </div>
      <div className='chart__div_footer'>
        <Typography classes={{ root: 'chart__footer_text' }}>
          Os dados das empresas ficam disponíveis ao exportar uma lista quando o
          filtro Score Recupere for aplicado.
        </Typography>
      </div>
    </>
  );
};

export default ChartComponent;
