import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { maybePluralize } from "../../../../utils/general";

import "./style.css";

const checkIfAllSelected = (listsSelected, lists) =>
  listsSelected.length === lists.length;
const ListSelectHeader = ({
  listsSelected,
  lists,
  setListsSelect,
  setOnSelectMode,
  deleteList,
}) => {
  const [allChecked, setAllChecked] = useState(false);
  const [anchorElListOptions, setAnchorElListOptions] = useState(null);
  const [anchorElCheckboxOptions, setAnchorElCheckboxOptions] = useState(null);
  const [warningModal, setWarningModal] = useState(false);
  const openListOptions = Boolean(anchorElListOptions);

  const changeSelected = () => {
    if (listsSelected.length >= 1) {
      setListsSelect([]);
      setAllChecked(false);
    } else {
      setListsSelect(lists);
      setAllChecked(true);
    }
  };

  const getSelectedText = () => {
    if (checkIfAllSelected(listsSelected, lists)) {
      return "Todas selecionadas";
    } else if (!listsSelected.length) {
      return "Nenhuma selecionada";
    }
    return `${listsSelected.length} ${maybePluralize(
      listsSelected.length,
      "selecionada"
    )}`;
  };

  const handleClickListOptions = (event) => {
    setAnchorElListOptions(event.currentTarget);
  };

  const handleCloseListOptions = () => {
    setAnchorElListOptions(null);
  };

  const onContinue = () => {
    deleteList({ listsIds: listsSelected });
  };

  const onClose = () => {
    setWarningModal(false);
  };

  const getConfirmationModal = () => (
    <Dialog
      open={warningModal}
      onClose={onClose}
      className="list-select-header__warning_delete_modal"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        classes={{ root: "list-select-header__warning_delete_modal_title" }}
      >
        Atenção!
      </DialogTitle>
      <DialogContent>
        <div className="list-select-header__warning_delete_modal_content">
          <Typography
            variant="body1"
            classes={{
              root: "list-select-header__warning_delete_modal_content_text",
            }}
          >
            Você está excluindo{" "}
            {`${listsSelected.length} ${maybePluralize(
              listsSelected.length,
              "lista"
            )}`}
            , essa operação não poderá ser desfeita. Deseja continuar?
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onContinue} color="primary">
          Continuar
        </Button>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );

  const markAllLists = () => {
    setListsSelect(lists);
    setAllChecked(true);
    setAnchorElCheckboxOptions(null);
  };

  const unmarkAllLists = () => {
    setListsSelect([]);
    setAllChecked(false);
    setAnchorElCheckboxOptions(null);
  };

  useEffect(() => {
    if (checkIfAllSelected(listsSelected, lists)) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [listsSelected, lists]);

  return (
    <div className="list-select-header">
      <Paper
        variant="outlined"
        elevation={7}
        classes={{ root: "list-select-header__paper" }}
      >
        <FormControl classes={{ root: "list-select-header__form_control" }}>
          <FormControlLabel
            classes={{ root: "list-select-header__form_control_label" }}
            control={
              <div className="list-checkbox-dropdown">
                {listsSelected.length >= 1 &&
                listsSelected.length !== lists.length ? (
                  <Checkbox
                    checked
                    color="primary"
                    id="listSelectHeader-checkbox"
                    onChange={changeSelected}
                    indeterminate
                  />
                ) : (
                  <Checkbox
                    checked={allChecked}
                    color="primary"
                    id="listSelectHeader-checkbox"
                    onChange={changeSelected}
                  />
                )}
                <IconButton
                  size="small"
                  onClick={(event) =>
                    setAnchorElCheckboxOptions(event.currentTarget)
                  }
                >
                  <ArrowDropDownIcon />
                </IconButton>

                <Menu
                  anchorEl={anchorElCheckboxOptions}
                  keepMounted
                  open={Boolean(anchorElCheckboxOptions)}
                  onClose={() => {
                    setAnchorElCheckboxOptions(null);
                  }}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem onClick={markAllLists}>
                    <Typography variant="inherit">Marcar todas</Typography>
                  </MenuItem>
                  <MenuItem onClick={unmarkAllLists}>
                    <Typography variant="inherit">Desmarcar todas</Typography>
                  </MenuItem>
                </Menu>
              </div>
            }
          />
        </FormControl>
        <Typography
          variant="body1"
          display="inline"
          color="primary"
          style={{ fontSize: 14, fontWeight: "700" }}
        >
          {getSelectedText()}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickListOptions}
        >
          Ações
        </Button>
        <Menu
          anchorEl={anchorElListOptions}
          keepMounted
          open={openListOptions}
          onClose={handleCloseListOptions}
        >
          <MenuItem
            key="deleteSelected"
            disabled={!listsSelected.length}
            value="deleteSelected"
            onClick={() => setWarningModal(true)}
          >
            Excluir selecionadas
          </MenuItem>
          <Divider />
          <MenuItem
            key="selectLists"
            value="selectLists"
            onClick={() => {
              setOnSelectMode(false);
              setListsSelect([]);
            }}
          >
            Cancelar
          </MenuItem>
        </Menu>
      </Paper>
      {getConfirmationModal()}
    </div>
  );
};

export default ListSelectHeader;
