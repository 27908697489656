import { useEffect, useState, useCallback, useMemo } from 'react';
import { groupBy as _groupBy } from 'lodash';
import Cookies from 'js-cookie';

import { AUNotifier, AULoadingManager } from '@assertiva/assertiva-ui';

import {
  getFilter,
  getFieldParams,
  getFilterMarkers,
} from '../../services/filter';
import { getLayers, postLayer, patchLayer } from '../../services/layer';
import { getLayerActionExportLimit } from '../../services/layerActions';

import { filterFields } from '../../utils/filter';
import FilterConstants from '../../components/filter/constants';

const useDashboard = ({
  selectedFilters,
  addFilter,
  removeFilter,
  clearFilter,
  changeFilterData,
  changeFilterDataCookie,
  changeSelectedFilters,
  filterData,
  changeFilterResult,
  filters,
  fieldParams,
  filterIsEmpty,
  layerId,
  changeSelectedLayer,
  showFilterLoading,
  hideFilterLoading,
  showLayersLoading,
  hideLayersLoading,
  refreshLayers,
  saveFieldParams,
  changeExportLimits,
  showMapLoading,
  hideMapLoading,
  refreshFilterMarkers,
  changeFilterStatistics,
  isEditing,
  setMapsChangeFilter,
}) => {
  const _selectedFilters = _groupBy(selectedFilters, 'categoryId');
  const [currentFilter, setCurrentFilter] = useState(null);
  const [isMultiple, setIsMultiple] = useState(false);
  const [openLayerNameModal, setOpenLayerNameModal] = useState(false);
  const [currentView, setCurrentView] = useState('dashboard');
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 5,
  });

  const { CNAESelected, FranchiseSelected } = useMemo(() => {
    return {
      CNAESelected:
        _selectedFilters[1] &&
        _selectedFilters[1].find((elem) => elem.filterId === 0),
      FranchiseSelected:
        _selectedFilters[1] &&
        _selectedFilters[1].find((elem) => elem.filterId === 17),
    };
  }, [_selectedFilters]);

  const disableHandler = useMemo(() => {
    return (
      filterIsEmpty ||
      !(CNAESelected || FranchiseSelected) ||
      !(
        (filterData.cnae && filterData.cnae.length) ||
        (filterData.franchise && filterData.franchise.length)
      )
    );
  }, [filterIsEmpty, CNAESelected, FranchiseSelected, filterData]);

  const initDashboard = useCallback(async () => {
    try {
      const [{ data: fieldParams }, limits] = await Promise.all([
        getFieldParams(),
        getLayerActionExportLimit(),
      ]);

      saveFieldParams(fieldParams.data);
      changeExportLimits({ standard: limits.standard, score: limits.score });
    } catch (err) {
      AUNotifier.error('Ocorreu um erro, tente novamente mais tarde.');
    }
  }, [changeExportLimits, saveFieldParams]);

  const getCookieValue = (name) => Cookies.get(name);

  const getSavedFilters = useCallback(() => {
    const cookie = getCookieValue('GeoSelectedLayers');

    if (cookie) {
      const filterDataCookieValue = JSON.parse(cookie).filterData;
      const selectedFiltersCookieValue = JSON.parse(cookie).selectedFilters;

      changeFilterDataCookie({
        filterData: filterDataCookieValue,
      });

      selectedFiltersCookieValue.forEach((filter) => {
        addFilter(filter);
      });
    }
  }, [addFilter, changeFilterDataCookie]);

  useEffect(() => {
    getSavedFilters();
    initDashboard();
  }, [initDashboard, getSavedFilters]);

  useEffect(() => {
    if (currentFilter) {
      const { categoryId, filterId } = currentFilter;

      filters[categoryId].filtersById[filterId].attributes.forEach(
        ({ multiple }) => {
          setIsMultiple(!!multiple);
        }
      );
    }
  }, [currentFilter, filters]);

  const saveFiltersCookie = useCallback(
    ({ hoursExpire }) => {
      let date = new Date();
      date.setTime(date.getTime() + hoursExpire * 60 * 60 * 1000);

      const data = {
        selectedFilters: selectedFilters,
        filterData: filterData,
      };

      document.cookie =
        `GeoSelectedLayers = ${JSON.stringify(data)}; expires = ` +
        date.toGMTString();
    },
    [filterData, selectedFilters]
  );

  useEffect(() => {
    saveFiltersCookie({
      hoursExpire: 24,
    });
  }, [filterData, saveFiltersCookie]);

  const isRemovingData = (value, data) => value.length < data?.length;

  const _addFilter = ({ filterId, categoryId }) => {
    let newSelectedFilters = [],
      position = 0;

    if (selectedFilters?.length) {
      newSelectedFilters = selectedFilters.filter(
        (elem) => elem.filterId === filterId && elem.categoryId === categoryId
      );
      position = newSelectedFilters.length;
    }

    addFilter({ filterId, categoryId, position });
    setCurrentFilter({ filterId, categoryId, position });
  };

  const _removeFilter = ({ filterIndex, categoryId, filterId, position }) => {
    let _selectedFilters = [...selectedFilters];
    _selectedFilters.splice(filterIndex, 1);

    if (_selectedFilters?.length) {
      for (let i = filterIndex; i < _selectedFilters.length; i++) {
        if (
          _selectedFilters[i].filterId === filterId &&
          _selectedFilters[i].categoryId === categoryId
        ) {
          _selectedFilters[i].position -= 1;
        }
      }
    }

    let _filterData = { ...filterData };
    filters[categoryId].filtersById[filterId].attributes.forEach(
      ({ name, multiple }) => {
        if (
          multiple &&
          _selectedFilters.some(
            (filter) =>
              filter.filterId === filterId && filter.categoryId === categoryId
          )
        ) {
          _filterData[name].splice(position, 1);
        } else delete _filterData[name];
      }
    );

    removeFilter({
      selectedFilters: _selectedFilters,
      filterData: _filterData,
    });
  };

  const handleChange = ({ target }) => {
    let value = target.type === 'checkbox' ? target.checked : target.value;

    const { categoryId, filterId, filterIndex, editMode } = currentFilter;

    // Handling when filter is multiple
    if (isMultiple) {
      if (editMode) {
        const index = selectedFilters[filterIndex].position;
        const data = value.pop();

        if (data === FilterConstants.ALL_SELECTED_OPTION) {
          const _selectedFilters = selectedFilters.filter(
            (elem) =>
              elem.filterId !== filterId || elem.categoryId !== categoryId
          );

          changeSelectedFilters({
            selectedFilters: [
              ..._selectedFilters,
              { filterId, categoryId, position: 0 },
            ],
          });

          value = [FilterConstants.ALL_SELECTED_OPTION];
        } else {
          value[index] = data;
        }
      } else {
        const { name: fieldName } =
          filters[categoryId].filtersById[filterId].attributes[0];
        const data = filterData[fieldName];

        if (isRemovingData(value, data)) {
          let selectedData = null;
          data.forEach((elem) => {
            if (!value.includes(elem)) {
              selectedData = elem;
            }
          });

          const position = data.indexOf(selectedData);
          const findSelectedFilter = selectedFilters.find(
            (elem) =>
              elem.filterId === filterId &&
              elem.categoryId === categoryId &&
              elem.position === position
          );

          return _removeFilter({
            filterIndex: selectedFilters.indexOf(findSelectedFilter),
            filterId,
            categoryId,
            position,
          });
        } else if (value.includes(FilterConstants.ALL_SELECTED_OPTION)) {
          const _selectedFilters = selectedFilters.filter(
            (elem) =>
              elem.filterId !== filterId || elem.categoryId !== categoryId
          );

          changeSelectedFilters({
            selectedFilters: [
              ..._selectedFilters,
              { filterId, categoryId, position: 0 },
            ],
          });

          value = [FilterConstants.ALL_SELECTED_OPTION];
        } else {
          _addFilter({ categoryId, filterId });
        }
      }
    }

    changeFilterData({
      fieldName: target.name,
      fieldValue: value,
    });
  };

  const submitFilter = async () => {
    AULoadingManager.show({ name: 'LINEAR' });
    showFilterLoading();
    showMapLoading();

    if (!isEditing) {
      setMapsChangeFilter(true);
    }

    try {
      const [{ data: response }, { data: filterMarkers }] = await Promise.all([
        getFilter({ ...filterData }).then((response) => {
          hideFilterLoading();
          return response;
        }),
        getFilterMarkers({
          ...(currentView === 'map'
            ? {
                points: window.map.getBounds().toUrlValue(),
                zoom: window.map.getZoom(),
              }
            : {}),
          ...filterData,
        }).then((response) => {
          hideMapLoading();
          return response;
        }),
      ]);

      changeFilterResult(response.data);
      changeSelectedLayer({ layerId: null, layerName: null });
      refreshFilterMarkers(filterMarkers.data.markers);
      changeFilterStatistics(filterMarkers.data.statistics);
      AULoadingManager.close({ name: 'LINEAR' });
    } catch (err) {
      AUNotifier.error('Ocorreu um erro, tente novamente mais tarde.');
      hideFilterLoading();
      hideMapLoading();
      AULoadingManager.close({ name: 'LINEAR' });
      setMapsChangeFilter(false);
    }
  };

  const submitLayer = async (layerName) => {
    showLayersLoading();

    try {
      await postLayer({
        name: layerName,
        selectedFilters,
        data: {
          ...filterData,
        },
      });

      const { data: response } = await getLayers({
        page: pagination.page,
        limit: pagination.rowsPerPage,
      });
      refreshLayers({
        layers: response.data.items.layersById,
        layersOrder: response.data.items.layersOrder,
        count: response.data.count,
        totalPages: response.data.totalPages,
      });
      // sendAnalytics({ event: "criarCamada-button", page: "filtros" });
    } catch (err) {
      AUNotifier.error('Ocorreu um erro, tente novamente mais tarde.');
    } finally {
      clearFilter();
      setCurrentFilter(null);
      hideLayersLoading();
    }
  };

  const selectLayer = async ({ layerData, layerId, layerName }) => {
    showFilterLoading();

    try {
      const { data: response } = await getFilter({ ...layerData });
      changeFilterResult(response.data);
      changeSelectedLayer({ layerId, layerName });
    } catch (err) {
      AUNotifier.error('Ocorreu um erro, tente novamente mais tarde.');
    } finally {
      hideFilterLoading();
    }
  };

  const updateLayer = async () => {
    showLayersLoading();

    try {
      await patchLayer({ layerId, selectedFilters, data: { ...filterData } });

      const { data: response } = await getLayers({
        page: pagination.page,
        limit: pagination.rowsPerPage,
      });
      refreshLayers({
        layers: response.data.items.layersById,
        layersOrder: response.data.items.layersOrder,
        count: response.data.count,
        totalPages: response.data.totalPages,
      });
      // sendAnalytics({ event: "salvarCamada-button", page: "filtros" });
    } catch (err) {
      AUNotifier.error('Ocorreu um erro, Tente novamente mais tarde.');
    } finally {
      clearFilter();
      setCurrentFilter();
      hideLayersLoading();
    }
  };

  const getFilterName = ({ filterId, categoryId }) => {
    return filters[categoryId].filtersById[filterId].attributes[0].name;
  };

  const getFilterLabel = ({ filterId, categoryId }) => {
    return filters[categoryId].filtersById[filterId].label;
  };

  const showCurrentFilter = ({ filterId, categoryId }) => {
    const { label, attributes } = filters[categoryId].filtersById[filterId];
    const { type, name, ...rest } = attributes[0];

    const params = {
      id: filterId,
      categoryId,
      label,
      name,
      ...rest,
    };

    if (fieldParams[name]) params.options = fieldParams[name];

    return filterFields(
      handleChange,
      filterData[name],
      currentFilter,
      setCurrentFilter,
      _addFilter
    )[type](params);
  };

  const formatNumber = (value) => Number(value).toLocaleString('pt-BR');

  return {
    currentFilter,
    setCurrentFilter,
    _selectedFilters,
    _addFilter,
    _removeFilter,
    submitFilter,
    getFilterName,
    getFilterLabel,
    showCurrentFilter,
    pagination,
    setPagination,
    formatNumber,
    openLayerNameModal,
    setOpenLayerNameModal,
    disableHandler,
    submitLayer,
    selectLayer,
    updateLayer,
    currentView,
    setCurrentView,
  };
};

export default useDashboard;
