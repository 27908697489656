import React from 'react';

import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const CRMReport = ({
	isOpen,
	onClose,
	exportData: {
		selectedOrigin,
		selectedSuborigin,
		selectedUsers,
		selectedPipeline,
		selectedPipelineStage,
	},
}) => {
	return (
		<Collapse in={isOpen}>
			<div className='crm-export__content__report'>
				<Typography variant='h5' gutterBottom>
					Tudo certo!
				</Typography>

				<Grid container spacing={2}>
					<Grid item>
						<Typography
							className='crm-export__content__report__summary'
							variant='body2'
							gutterBottom
						>
							Resumo da exportação:
						</Typography>

						<Typography variant='body1' gutterBottom>
							Origem: {selectedOrigin && selectedOrigin.name}
						</Typography>
						<Typography variant='body1' gutterBottom>
							Sub-origem: {selectedSuborigin && selectedSuborigin.name}
						</Typography>
						<Typography variant='body1' gutterBottom>
							Distribuir oportunidade para:{' '}
							{selectedUsers &&
								selectedUsers.map(({ name }) => name).join(', ')}
						</Typography>
						<Typography variant='body1' gutterBottom>
							Funil de Vendas: {selectedPipeline && selectedPipeline.name}
						</Typography>
						<Typography variant='body1' gutterBottom>
							Etapa do Funil:{' '}
							{selectedPipelineStage && selectedPipelineStage.name}
						</Typography>
					</Grid>
				</Grid>

				<div className='crm-export__content__step__footer'>
					<Grid container spacing={2}>
						<Grid item>
							<Button variant='contained' color='primary' onClick={onClose}>
								Finalizar
							</Button>
						</Grid>
					</Grid>
				</div>
			</div>
		</Collapse>
	);
};

CRMReport.defaultProps = {
	exportData: {},
};

export default CRMReport;
