import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import SearchIcon from '@material-ui/icons/Search';

const STATE_FILTER_ID = 0;
const CITY_FILTER_ID = 1;
const NEIGHBORHOOD_FILTER_ID = 2;
const STREET_FILTER_ID = 3;
const CNAE_FILTER_ID = 0;
const FRANCHISE_FILTER_ID = 17;
const COMPANY_CATEGORY = 1;
const LOCALIZATION_CATEGORY = 2;

const FilterSearch = ({
  filters,
  addFilter,
  selectedFilters,
  setCurrentFilter,
  currentFilter,
  currentFilterLabel,
  categories,
}) => {
  const [inputValue, setInputValue] = useState('');

  const getOptionsDisabled = (option) => {
    if (option.category === COMPANY_CATEGORY) {
      if (
        !selectedFilters[option.category] ||
        selectedFilters[option.category].every(
          (selectedFilter) =>
            selectedFilter.filterId !== CNAE_FILTER_ID &&
            selectedFilter.filterId !== FRANCHISE_FILTER_ID
        )
      ) {
        return (
          option.id !== FRANCHISE_FILTER_ID && option.id !== CNAE_FILTER_ID
        );
      }

      return selectedFilters[option.category].some(
        (selectedFilter) =>
          selectedFilter.filterId !== CNAE_FILTER_ID &&
          selectedFilter.filterId !== FRANCHISE_FILTER_ID &&
          selectedFilter.filterId === option.id
      );
    }

    if (option.category === LOCALIZATION_CATEGORY) {
      return (
        selectedFilters[option.category] &&
        selectedFilters[option.category].some(
          (selectedFilter) =>
            (option.id === CITY_FILTER_ID &&
              [
                STATE_FILTER_ID,
                NEIGHBORHOOD_FILTER_ID,
                STREET_FILTER_ID,
                option.id,
              ].includes(selectedFilter.filterId)) ||
            (option.id === STATE_FILTER_ID &&
              [
                CITY_FILTER_ID,
                NEIGHBORHOOD_FILTER_ID,
                STREET_FILTER_ID,
                option.id,
              ].includes(selectedFilter.filterId)) ||
            (option.id === NEIGHBORHOOD_FILTER_ID &&
              [
                CITY_FILTER_ID,
                STATE_FILTER_ID,
                STREET_FILTER_ID,
                option.id,
              ].includes(selectedFilter.filterId)) ||
            (option.id === STREET_FILTER_ID &&
              [
                CITY_FILTER_ID,
                STATE_FILTER_ID,
                NEIGHBORHOOD_FILTER_ID,
                option.id,
              ].includes(selectedFilter.filterId))
        )
      );
    }

    return (
      selectedFilters[option.category] &&
      selectedFilters[option.category].some(
        (selectedFilter) =>
          selectedFilter.filterId === option.id &&
          (!option.attributes[0]?.multiple ||
            (option.id === currentFilter?.filterId &&
              option.category === currentFilter?.categoryId))
      )
    );
  };

  return (
    <Autocomplete
      id='buscarFiltro-input'
      options={filters}
      groupBy={(option) =>
        categories.find((e) => e.id === option.category).label
      }
      getOptionLabel={(option) => option.label}
      getOptionDisabled={getOptionsDisabled}
      renderOption={({ label, category, attributes }) => {
        const showTooltip =
          attributes[0].required &&
          (!selectedFilters[category] ||
            selectedFilters[category].every(
              (selectedFilter) =>
                selectedFilter.filterId !== CNAE_FILTER_ID &&
                selectedFilter.filterId !== FRANCHISE_FILTER_ID
            ));
        return (
          <Tooltip
            title={
              showTooltip
                ? 'CNAE ou REDE são campos obrigatórios. Selecione pelo menos um deles.'
                : ''
            }
          >
            <div style={{ width: '100%' }}>{label}</div>
          </Tooltip>
        );
      }}
      onChange={(_, value) => {
        if (
          !value.attributes[0].multiple &&
          value.attributes[0].type !== 'range' &&
          value.attributes[0].type !== 'scoreSelect'
        ) {
          addFilter({ categoryId: value.category, filterId: value.id });
        } else {
          setCurrentFilter({ categoryId: value.category, filterId: value.id });
        }

        setInputValue('');
      }}
      onInputChange={(event, value) => setInputValue(value)}
      value={null}
      inputValue={inputValue}
      blurOnSelect
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            currentFilter ? currentFilterLabel : 'Selecione um filtro'
          }
          variant='outlined'
          InputLabelProps={{
            ...params.InputLabelProps,
            margin: 'dense',
          }}
          InputProps={{
            ...params.InputProps,
            margin: 'dense',
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton size='small' disabled>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            fullWidth: true,
          }}
          fullWidth
        />
      )}
      noOptionsText='Nenhum filtro encontrado'
      disableClearable
      freeSolo
    />
  );
};

export default FilterSearch;
