import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { AUNotifier, AULoadingManager } from '@assertiva/assertiva-ui';
import { sendAnalytics } from '../../utils/analytics';

import {
  getLayers,
  postLayer,
  deleteLayer,
  patchLayerColor,
  patchLayerName,
} from '../../services/layer';
import { getFilter, getFilterLayers } from '../../services/filter';
import {
  getLayerActions,
  postLayerAction,
  getLayerActionExportLimit,
} from '../../services/layerActions';

import { formatLabelString, maybePluralize } from '../../utils/general';

const useLayers = ({
  refreshLayers,
  changeSelectedFilters,
  changeFilterData,
  changeFilterResult,
  editLayerFilter,
  showLayersLoading,
  hideLayersLoading,
  openDrawer,
  changeActions,
  showDrawerLoading,
  hideDrawerLoading,
  showFilterLoading,
  hideFilterLoading,
  changeExportLimits,
  changeSelectedLayer,
  layerSelected,
  refreshMarkers,
  changeStatistics,
  setMadeInitialFetch,
}) => {
  const [layerActionsModal, setLayerActionsModal] = useState({
    isOpen: false,
    layerId: null,
    rowcount: 0,
  });
  const [onSelectMode, setOnSelectMode] = useState(false);
  const [layersSelected, setLayersSelect] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 5,
  });

  const { layers, layersOrder, count, totalPages } = useSelector((state) => ({
    layers: state.layers.layers,
    layersOrder: state.layers.layersOrder,
    count: state.layers.count,
    totalPages: state.layers.totalPages,
  }));

  useEffect(() => {
    const escapeFunction = (ev) => {
      if (ev.keyCode === 27) {
        setOnSelectMode(false);
        setLayersSelect([]);
      }
    };

    if (onSelectMode) {
      document.addEventListener('keydown', escapeFunction, false);
    } else {
      document.removeEventListener('keydown', escapeFunction, false);
    }
  }, [onSelectMode, setOnSelectMode, setLayersSelect]);

  const updateLayers = useCallback(async () => {
    try {
      AULoadingManager.show({ name: 'LINEAR' });
      showFilterLoading();
      showLayersLoading();

      const { data: response } = await getLayers({
        page: pagination.page,
        limit: pagination.rowsPerPage,
      });

      hideLayersLoading();

      refreshLayers({
        layers: response.data.items.layersById,
        layersOrder: response.data.items.layersOrder,
        count: response.data.count,
        totalPages: response.data.totalPages,
      });

      if (response.data.items.layersOrder.length) {
        const layerId = response.data.items.layersOrder[0];
        const layerData = response.data.items.layersById[layerId].data;
        const layerName = response.data.items.layersById[layerId].name;

        const [{ data: filterResult }, { data: layerMarkers }] =
          await Promise.all([
            getFilter({ ...layerData }),
            getFilterLayers({
              layers: response.data.items.layersOrder,
            }),
          ]);

        changeFilterResult(filterResult.data);
        changeSelectedLayer({ layerId, layerName });
        refreshMarkers(layerMarkers.data.markers);
        changeStatistics(layerMarkers.data.statistics);
      }
    } catch (err) {
      AUNotifier.error('Ocorreu um erro. Tente novamente mais tarde.');
      hideLayersLoading();
    } finally {
      AULoadingManager.close({ name: 'LINEAR' });
      hideFilterLoading();
    }
  }, [
    showFilterLoading,
    showLayersLoading,
    pagination.page,
    pagination.rowsPerPage,
    hideLayersLoading,
    refreshLayers,
    changeFilterResult,
    changeSelectedLayer,
    refreshMarkers,
    changeStatistics,
    hideFilterLoading,
  ]);

  useEffect(() => {
    updateLayers();
  }, [updateLayers]);

  const editLayer = (layerId) => {
    const {
      data: { points, zoom, params, ...filterData },
      selectedFilters,
    } = layers[layerId];

    sendAnalytics({ event: 'editar-button', page: 'camada' });

    changeSelectedFilters({ selectedFilters });
    changeFilterData({ filterData });

    editLayerFilter({ layerId });
  };

  const _deleteLayer = ({ layersId }) => {
    AULoadingManager.show({ name: 'LINEAR' });

    const layersName = [];
    const totalLayers = count;
    const layersCopy = { ...layers };
    const layersOrderCopy = [...layersOrder];
    const newCount = count - 1;
    const newTotalPages = Math.ceil(newCount / pagination.rowsPerPage);

    sendAnalytics({ event: 'excluir-button', page: 'camada' });

    if (Array.isArray(layersId) && layersId.length) {
      layersId.forEach((layer) => {
        if (layersCopy[layer]) {
          const deleteIndex = layersOrderCopy.indexOf(layer);

          if (layerSelected.layerName === layersCopy[layer].name) {
            changeFilterResult(null);
            changeSelectedLayer({ layerId: null, layerName: null });
            refreshMarkers(null);
            changeStatistics(null);
          }

          if (deleteIndex >= 0) {
            layersName.push(layersCopy[layer].name);
            layersOrderCopy.splice(deleteIndex, 1);
            delete layersCopy[layersId];
          }
        }
      });
    }

    refreshLayers({
      layers: layersCopy,
      layersOrder: layersOrderCopy,
      count: newCount,
      totalPages: newTotalPages,
    });

    let message;
    if (layersId.length === totalLayers && totalPages === 1) {
      message = 'Todas as camadas excluídas.';
    } else {
      message = `${maybePluralize(
        layersName.length,
        'Camada'
      )} ${formatLabelString(layersName)} ${maybePluralize(
        layersName.length,
        'excluída'
      )}.`;
    }

    if (onSelectMode) {
      setOnSelectMode(false);
      setLayersSelect([]);
    }

    deleteLayer({ layersId })
      .then(() => {
        AUNotifier.notify({
          message,
          options: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          },
        });

        if (pagination.page !== 0 && !layersOrderCopy.length) {
          setPagination({ page: 0, rowsPerPage: 5 });
        } else {
          updateLayers();
        }
      })
      .catch(() => updateLayers())
      .finally(() => AULoadingManager.close({ name: 'LINEAR' }));
  };

  const changeLayerName = ({ layerId, newLayerName, layerName }) => {
    AULoadingManager.show({ name: 'LINEAR' });

    layers[layerId].name = newLayerName;
    refreshLayers({
      layers,
      layersOrder,
      count,
      totalPages,
    });

    patchLayerName({
      layerId,
      newLayerName,
    })
      .then(() => {
        AUNotifier.notify({
          message: `Camada ${layerName} renomeada para ${newLayerName}.`,
          options: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          },
        });
      })
      .catch(() => updateLayers())
      .finally(() => AULoadingManager.close({ name: 'LINEAR' }));
  };

  const duplicateLayer = ({ layerName, selectedFilters, layerData }) => {
    AULoadingManager.show({ name: 'LINEAR' });

    postLayer({
      name: layerName.concat(' Cópia'),
      selectedFilters: selectedFilters,
      data: layerData,
    })
      .then(() => {
        updateLayers();

        AUNotifier.notify({
          message: `${layerName} duplicada`,
          options: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          },
        });
      })
      .finally(() => AULoadingManager.close({ name: 'LINEAR' }));
  };

  const changeLayerColor = ({ layerId, color }) => {
    AULoadingManager.show({ name: 'LINEAR' });
    layers[layerId].color = color;
    refreshLayers({
      layers: layers,
      layersOrder: layersOrder,
      count,
      totalPages,
    });

    patchLayerColor({
      layerId,
      color,
    })
      .then(() => {
        AUNotifier.notify({
          message: `Cor da camada alterada.`,
          options: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          },
        });
      })
      .catch(() => updateLayers())
      .finally(() => AULoadingManager.close({ name: 'LINEAR' }));
  };

  const saveLayerAction = (name) => {
    showDrawerLoading();
    openDrawer();

    postLayerAction({
      name,
      layerId: layerActionsModal.layerId,
      rowcount: layerActionsModal.rowcount,
    })
      .then(() => {
        updateLayerActions();
      })
      .finally(() => hideDrawerLoading());
  };

  const updateLayerActions = () => {
    showDrawerLoading();

    return getLayerActions({
      page: pagination.page,
      limit: pagination.rowsPerPage,
    })
      .then(({ data }) => {
        const { count, totalPages, items } = data.data;
        const { layerActionsById, layerActionsOrder } = items;
        changeActions({
          layerActionsById,
          layerActionsOrder,
          count,
          totalPages,
        });
        getLayerActionExportLimit().then((limits) => {
          changeExportLimits({
            standard: limits.standard,
            score: limits.score,
          });
        });
      })
      .finally(() => hideDrawerLoading());
  };

  return {
    layerActionsModal,
    setLayerActionsModal,
    layers,
    layersOrder,
    count,
    totalPages,
    onSelectMode,
    setOnSelectMode,
    pagination,
    setPagination,
    layersSelected,
    setLayersSelect,
    editLayer,
    _deleteLayer,
    changeLayerName,
    duplicateLayer,
    changeLayerColor,
    saveLayerAction,
  };
};

export default useLayers;
