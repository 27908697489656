import React from 'react';

import Box from '@material-ui/core/Box';

const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`settings-tabPanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
