import { ValidationUtils } from '@assertiva/assertiva-ui';
import ErrorMessages from '../constants/errorMessages';

const validateCnpj = (cnpj) => {
  const digitCount = cnpj.replace(/\D/g, '').length;

  if (digitCount === 0) {
    return ErrorMessages.FORM.REQUIRED;
  } else if (digitCount < 3) {
    return ErrorMessages.FORM.UNCOMPLETED_CNPJ;
  } else if (!ValidationUtils.isValidDocument(cnpj)) {
    return ErrorMessages.FORM.INVALID_CNPJ;
  }

  return false;
};

export { validateCnpj };
