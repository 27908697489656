import axios, { BASE_URL, API_CADASTRO } from './base';
import queryString from 'query-string';

export const getLayerActions = ({ page, limit }) => {
  return axios.get(`${BASE_URL}/maps/layer-actions`, {
    params: {
      page,
      limit,
    },
  });
};

export const postLayerAction = ({ name, layerId, rowcount }) => {
  return axios.post(`${BASE_URL}/maps/layer-actions`, {
    name,
    layerId,
    rowcount,
  });
};

export const deleteLayerAction = ({ layersActionsIds }) => {
  return axios.delete(`${BASE_URL}/maps/layer-actions`, {
    data: { layersActionsIds },
  });
};

export const getLayerActionExportLimit = async () => {
  let standard, score;

  await axios
    .get(`${API_CADASTRO}/backoffice-cliente/balance/calculation`, {
      params: {
        functionalityId: 14,
      },
      paramsSerializer: queryString.stringify,
    })
    .then((resp) => {
      standard = resp.data.quantity;
    })
    .catch(() => {
      standard = 0;
    });

  await axios
    .get(`${API_CADASTRO}/backoffice-cliente/balance/calculation`, {
      params: {
        functionalityId: 19,
      },
      paramsSerializer: queryString.stringify,
    })
    .then((resp) => {
      score = resp.data.quantity;
    })
    .catch(() => {
      score = 0;
    });

  return { standard, score };
};
