import { makeStyles, styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Paper } from '@material-ui/core';

const Styles = makeStyles(() => ({
  paper: {
    width: '368px',
  },
  blacklistContainer: {
    display: 'flex',
    flexDirection: 'column',

    margin: '16px',
    padding: '16px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',

    height: '100%',
  },
  textField: {
    marginTop: '24px',
    width: '100%',
    '&:last-of-type': {
      marginTop: '36px',
    },
  },
  warningIcon: {
    marginRight: '16px',
	  marginTop: '10px',
    fontSize: '30px',
    color: '#B00020',
  },
}));

const Title = styled(Typography)({
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.4px',
  color: 'rgba(0, 0, 0, 0.87)',
});

const AddButton = styled(Button)({
  width: '116px',
  height: '36px',

  padding: '10px 16px',
  marginTop: '28px',
  marginBottom: '32px',
  textTransform: 'uppercase',

  '&:disabled': {
    backgroundColor: '#9E9E9E',
    color: 'white',
  },
});

const DownloadExampleButton = styled(Button)({
  maxWidth: '261px',
  marginTop: '8px',
  padding: '6px 16px 6px 8px',

  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '16px',
  textTransform: 'uppercase',
});

const BlacklistWarning = styled(Paper)({
  padding: '8px',
  margin: '16px 0',
  display: 'flex',
  alignItems: 'center',
  background: '#FFDA88',
	borderRadius: '4px',
});

export { Styles, Title, AddButton, DownloadExampleButton, BlacklistWarning };
