import React from 'react';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';

import './ZoomSettings.css';

const ZoomSettings = ({ changeZoom }) => {
	return (
		<Paper classes={{ root: 'zoom-settings' }} elevation={4}>
			<List disablePadding>
				<ListItem
					classes={{ root: 'zoom-settings__list-item' }}
					onClick={() => changeZoom(window.map.zoom + 1)}
					button
					disableGutters
					dense
				>
					<Add fontSize='small' />
				</ListItem>
				<Divider classes={{ root: 'zoom-settings__divider' }} />
				<ListItem
					classes={{ root: 'zoom-settings__list-item' }}
					onClick={() => changeZoom(window.map.zoom - 1)}
					button
					disableGutters
					dense
				>
					<Remove fontSize='small' />
				</ListItem>
			</List>
		</Paper>
	);
};

export default ZoomSettings;
