import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const CRMSalesPipeline = ({
	pipelines,
	selectedPipeline: _selectedPipeline,
	pipelineStages,
	selectedPipelineStage: _selectedPipelineStage,
	prevStep,
	onSuccess,
}) => {
	const [selectedPipeline, setSelectedPipeline] = useState(_selectedPipeline);
	const [selectedPipelineStage, setSelectedPipelineStage] = useState(
		_selectedPipelineStage,
	);

	return (
		<div className='crm-export__content__step'>
			<div class='crm-export__content__step__pipeline'>
				<Typography variant='h6'>Selecione o Funil de Vendas</Typography>
				<Typography variant='body2' gutterBottom>
					Selecione o Funil de Vendas que as oportunidades serão inseridas.
				</Typography>
				<Autocomplete
					options={pipelines}
					getOptionLabel={(option) => option.name}
					noOptionsText='Nenhum funil de vendas encontrado'
					renderInput={(params) => (
						<TextField
							{...params}
							variant='outlined'
							fullWidth
							margin='dense'
							placeholder='Selecione um funil de vendas'
						/>
					)}
					value={selectedPipeline}
					onChange={(event, newValue) => {
						setSelectedPipeline(newValue);
					}}
				/>
			</div>

			<div class='crm-export__content__step__pipeline-stage'>
				<Typography variant='h6'>Selecione uma Etapa do Funil</Typography>
				<Typography variant='body2' gutterBottom>
					Selecione a etapa do Funil de Vendas em que as oportunidades serão
					enviadas
				</Typography>
				<Autocomplete
					options={
						(selectedPipeline &&
							pipelineStages &&
							pipelineStages[selectedPipeline.id]) ||
						[]
					}
					getOptionLabel={(option) => option.name}
					noOptionsText='Nenhuma etapa encontrado'
					renderInput={(params) => (
						<TextField
							{...params}
							variant='outlined'
							fullWidth
							margin='dense'
							placeholder='Selecione uma etapa do funil'
						/>
					)}
					value={selectedPipelineStage}
					onChange={(event, newValue) => {
						setSelectedPipelineStage(newValue);
					}}
					disabled={!selectedPipeline}
				/>
			</div>

			<div className='crm-export__content__step__footer'>
				<Grid container spacing={2}>
					<Grid item>
						<Button
							variant='contained'
							color='primary'
							onClick={() =>
								onSuccess({
									selectedPipeline,
									selectedPipelineStage,
								})
							}
							disabled={!selectedPipeline || !selectedPipelineStage}
						>
							Concluir
						</Button>
					</Grid>
					<Grid item>
						<Button
							variant='outlined'
							color='primary'
							onClick={() =>
								prevStep({
									selectedPipeline,
									selectedPipelineStage,
								})
							}
						>
							Voltar
						</Button>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

CRMSalesPipeline.defaultProps = {
	pipelines: [],
	selectedPipeline: null,
	pipelineStages: {},
	selectedPipelineStage: null,
};

export default CRMSalesPipeline;
