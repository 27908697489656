import { makeStyles, styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const ChipStyle = makeStyles({
  root: {
    margin: '4px',
    padding: '8px',
  },
});

const TextContainer = styled('div')({
  display: 'flex',
});

const Text = styled(Typography)({
  fontWeight: (props) => (props.bold ? 'bold' : 'normal'),
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.25px',
  color: '#000000de',
});

export { ChipStyle, TextContainer, Text };
