import React from 'react';

import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AUEmptyData } from '@assertiva/assertiva-ui';

import BlacklistTableFilter from './components/BlacklistTableFilter/index';
import FilterChip from './components/FilterChip/index';

import useBlacklist from './useBlacklist';
import Constants from './constants';

import * as Styled from './styles';

const Blacklist = ({ openDrawer }) => {
  const {
    loading,
    blacklist,
    pagination,
    handleChangeRowsPerPage,
    handleChangePage,
    isSelected,
    selected,
    handleSelectAllClick,
    handleSelectSingle,
    filter,
    setFilter,
    openFilter,
    setOpenFilter,
    cleanFilterAndPagination,
    deleteBlacklist,
    filterChip,
    setFilterChip,
    setPagination,
  } = useBlacklist();

  const TableToolbar = ({ numSelected }) => {
    const classes = Styled.ToolBarStyles();

    return (
      <Toolbar classes={{ root: classes.root }}>
        {numSelected > 0 ? (
          <Styled.ToolBarText>{numSelected} selecionado(s)</Styled.ToolBarText>
        ) : (
          <Styled.ToolBarText>
            Pesquise e adicione CNPJs que estão na sua blacklist.
          </Styled.ToolBarText>
        )}
        <Styled.ToolBarActions>
          {numSelected > 0 ? (
            <Tooltip title='Deletar'>
              <IconButton onClick={() => deleteBlacklist()}>
                <DeleteIcon style={{ color: '#8A00C1' }} />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Tooltip title='Filtrar CNPJ'>
                <IconButton onClick={() => setOpenFilter(!openFilter)}>
                  <FilterListIcon style={{ color: '#8A00C1' }} />
                  <Styled.ToolBarText type='filterButton'>
                    FILTRAR
                  </Styled.ToolBarText>
                </IconButton>
              </Tooltip>
              <FilterChip
                filterChip={filterChip}
                setFilterChip={setFilterChip}
                filterData={filter}
                setFilterData={setFilter}
              />
            </>
          )}
        </Styled.ToolBarActions>
      </Toolbar>
    );
  };

  const TableHeader = () => {
    const classes = Styled.ToolBarStyles();

    return (
      <TableHead>
        <TableRow>
          <TableCell padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={
                selected.length > 0 && selected.length < blacklist.items.length
              }
              checked={
                blacklist.items?.length > 0 &&
                selected.length === blacklist.items.length
              }
              onChange={handleSelectAllClick}
            />
          </TableCell>
          {Constants.listHeader.map((headCell) => (
            <TableCell
              key={headCell.id}
              align='left'
              classes={{ root: classes.column }}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  return (
    <Styled.Container>
      <TableToolbar numSelected={selected.length} />
      <BlacklistTableFilter
        open={openFilter}
        filterData={filter}
        setFilterData={setFilter}
        setPagination={setPagination}
        setOpenFilter={setOpenFilter}
        setFilterChip={setFilterChip}
      />
      <Styled.BodyContainer>
        <TableContainer>
          {loading && (
            <Styled.LoadingContainer>
              <CircularProgress />
            </Styled.LoadingContainer>
          )}
          <Table>
            <TableHeader />
            <TableBody>
              {blacklist &&
                blacklist.items?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);

                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      onClick={(event) => handleSelectSingle(event, row.id)}
                      key={`${row.cnpj}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox checked={isItemSelected} color='primary' />
                      </TableCell>
                      <TableCell align='left'>{row.cnpj}</TableCell>
                      <TableCell align='left'>{row.description}</TableCell>
                      <TableCell align='left'>{row.createdAt}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {!blacklist.items?.length && (
          <Styled.EmptyContainer>
            <AUEmptyData
              boxProps={{
                p: 2,
              }}
              subtitle='Nenhum CNPJ encontrado.'
            />
          </Styled.EmptyContainer>
        )}
        <Styled.Pagination
          rowsPerPageOptions={Constants.rowsPerPage}
          component='div'
          count={blacklist?.count || 0}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Linhas por página'
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
        />
      </Styled.BodyContainer>
      <Styled.AddButtonContainer>
        <Button
          id='blacklist-add-button'
          color='primary'
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={() =>
            openDrawer({
              drawerName: 'AddBlacklist',
              data: { cleanFilterAndPagination },
            })
          }
        >
          ADICIONAR...
        </Button>
      </Styled.AddButtonContainer>
    </Styled.Container>
  );
};

export default Blacklist;
