import React, { useEffect, useState } from 'react';
import {
  NavigateBefore,
  NavigateNext,
  SkipNext,
  SkipPrevious,
} from '@material-ui/icons';
import { MenuItem, Paper, Select, Typography } from '@material-ui/core';

import { IconButton, Styles } from './styles';

const INITIAL_PAGE = 0;
const Pagination = ({
  page,
  count,
  totalPages,
  labelRowsPerPage,
  rowsPerPage,
  rowsPerPageOptions,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const classes = Styles();

  const [disabledNextPage, setDisabledNextPage] = useState(false);
  const [disabledPreviusPage, setDisabledPreviusPage] = useState(false);

  useEffect(() => {
    setDisabledNextPage(false);
    setDisabledPreviusPage(false);

    if (page + 1 === totalPages) {
      setDisabledNextPage(true);
    }

    if (page === INITIAL_PAGE) {
      setDisabledPreviusPage(true);
    }
  }, [page, totalPages]);

  return (
    <Paper>
      <div className={classes.paginationButtonsContainer}>
        <IconButton
          disabled={disabledPreviusPage}
          onClick={() => {
            const newPage = INITIAL_PAGE;

            onChangePage(newPage);
          }}
        >
          <SkipPrevious style={{ cursor: 'pointer' }} />
        </IconButton>

        <IconButton
          disabled={disabledPreviusPage}
          onClick={() => {
            const newPage = page - 1;

            onChangePage(newPage);
          }}
        >
          <NavigateBefore style={{ cursor: 'pointer' }} />
        </IconButton>

        <Typography variant='body2'>
          {page + 1} de {totalPages}
        </Typography>

        <IconButton
          style={{ minWidth: 24, padding: 0 }}
          disabled={disabledNextPage}
          onClick={() => {
            const newPage = page + 1;

            onChangePage(newPage);
          }}
        >
          <NavigateNext style={{ cursor: 'pointer' }} />
        </IconButton>

        <IconButton
          style={{ minWidth: 24, padding: 0 }}
          disabled={disabledNextPage}
        >
          <SkipNext
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const lastPage = totalPages - 1;

              onChangePage(lastPage);
            }}
          />
        </IconButton>
      </div>
      <div className={classes.paginationButtonsContainer}>
        <Typography variant='body2'>{labelRowsPerPage} por página</Typography>

        <Select
          value={rowsPerPage}
          onChange={(event) => onChangeRowsPerPage(event.target?.value)}
        >
          {rowsPerPageOptions.map((value, index) => (
            <MenuItem key={String(index)} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </div>
    </Paper>
  );
};

export default Pagination;
