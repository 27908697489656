import React from 'react';
import { useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { sendAnalytics } from '../../../utils/analytics';

const FinalityConfig = ({ config, changeFinality }) => {
  const finalities = useSelector((state) => state.actionsDrawer.finality);
  return (
    <div className='export-list-modal__step-2'>
      <Typography variant='h6' className='export-list-modal__step-2__header'>
        Finalidade de uso
      </Typography>

      <Typography variant='body1' gutterBottom>
        Escolha a opção que mais se enquadre no uso dos dados:
      </Typography>
      <FormControl variant='outlined' margin='dense' style={{ width: '500px' }}>
        <InputLabel id='exportarLista-finalidade-select-label'>
          Selecione uma finalidade
        </InputLabel>
        <Select
          labelId='exportarLista-finalidade-select-label'
          id='exportarLista-finalidade-select'
          value={config.finality.id && config.finality}
          onChange={changeFinality}
          MenuProps={{ style: { width: '500px' } }}
        >
          {finalities.length &&
            finalities.map((f) => (
              <MenuItem
                key={f.id}
                id={`${f.label}-button`}
                className='finalidade-MenuItem'
                value={f}
                onClick={() =>
                  sendAnalytics({
                    event: `${f.label}-button`,
                    page: 'exportarLista',
                  })
                }
              >
                <div>
                  <Typography style={{ color: '#000000', fontSize: '14px' }}>
                    {f.label}
                  </Typography>

                  <Typography
                    style={{ color: '#00000080', fontSize: '12px' }}
                    gutterBottom
                  >
                    {f.description}
                  </Typography>
                </div>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

FinalityConfig.defaultProps = {
  config: {
    params: [],
    fileExtension: 'csv',
    finality: { id: null, label: '', description: '' },
  },
};

export default FinalityConfig;
