export const INIT_MAP = 'INIT_MAP';
export const SWITCH_TRAFFIC = 'SWITCH_TRAFFIC';
export const CHANGE_DRAWING_MODE = 'CHANGE_DRAWING_MODE';
export const ADD_DRAWING = 'ADD_DRAWING';
export const CHANGE_MAP_TYPE = 'CHANGE_MAP_TYPE';
export const SWITCH_DATA_TYPE = 'SWITCH_DATA_TYPE';
export const SHOW_MAP_LOADING = 'SHOW_MAP_LOADING';
export const HIDE_MAP_LOADING = 'HIDE_MAP_LOADING';

export const initMap = (google) => ({
	type: INIT_MAP,
	payload: google,
});

export const switchTraffic = () => ({
	type: SWITCH_TRAFFIC,
});

export const changeDrawingMode = (drawingMode) => ({
	type: CHANGE_DRAWING_MODE,
	payload: drawingMode,
});

export const addDrawing = ({ drawingMode, drawingData }) => ({
	type: ADD_DRAWING,
	payload: { drawingMode, drawingData },
});

export const changeMapType = (type) => ({
	type: CHANGE_MAP_TYPE,
	payload: type,
});

export const switchDataType = () => ({
	type: SWITCH_DATA_TYPE,
});

export const showMapLoading = () => ({
	type: SHOW_MAP_LOADING,
});

export const hideMapLoading = () => ({
	type: HIDE_MAP_LOADING,
});