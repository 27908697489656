// Mocks
import filterMock from '../../mocks/filters';

import {
  ADD_FILTER,
  REMOVE_FILTER,
  CHANGE_SELECTED_FILTERS,
  CHANGE_FILTER_DATA,
  REFRESH_FILTER_MARKERS,
  CHANGE_FILTER_STATISTICS,
  CHANGE_FILTER_RESULT,
  CLEAR_FILTER,
  EDIT_LAYER_FILTER,
  MAPS_CHANGE_FILTER,
  SAVE_FIELD_PARAMS,
  SHOW_FILTER_LOADING,
  HIDE_FILTER_LOADING,
  CHANGE_SELECTED_LAYER,
  CHANGE_FILTER_DATA_COOKIE,
} from './actions';

// Constants
import defaultFilterValues from '../../constants/defaultFilterValues';

const initialState = {
  categories: filterMock.categories,
  filters: filterMock.filtersByCategories,
  selectedFilters: [],
  filterData: {},
  markers: [],
  statistics: [],
  filterResult: null,
  layerId: null,
  layerSelected: { layerId: null, layerName: null},
  isEditing: false,
  isEmpty: true,
  mapsChangeFilter: false,
  fieldParams: {
    franchise: [],
  },
  filterLoading: { count: 0, isShowing: false },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_FILTER: {
      const { categoryId, filterId, position } = payload;
      const { name: fieldName } =
        state.filters[categoryId].filtersById[filterId].attributes[0];

      return {
        ...state,
        selectedFilters: [
          ...state.selectedFilters,
          { categoryId, filterId, position },
        ],
        filterData: {
          ...state.filterData,
          [fieldName]: !state.filterData[fieldName]
            ? defaultFilterValues[fieldName]
            : state.filterData[fieldName],
        },
        isEmpty: false,
      };
    }

    case REMOVE_FILTER: {
      const { selectedFilters: _selectedFilters, filterData: _filterData } =
        payload;

      return {
        ...state,
        selectedFilters: _selectedFilters,
        filterData: _filterData,
        isEmpty: _selectedFilters.length < 1,
        mapsChangeFilter: state.mapsChangeFilter && !!_selectedFilters.length,
      };
    }

    case CHANGE_SELECTED_FILTERS: {
      const { selectedFilters } = payload;

      return {
        ...state,
        selectedFilters,
        isEmpty: false,
      };
    }

    case CHANGE_FILTER_DATA: {
      const { fieldName, fieldValue, filterData, selectedFilters } = payload;

      if (filterData) {
        return {
          ...state,
          filterData,
        };
      }

      return {
        ...state,
        filterData: { ...state.filterData, [fieldName]: fieldValue },
        ...(selectedFilters ? { selectedFilters } : {}),
      };
    }

    case CHANGE_FILTER_DATA_COOKIE: {
      const { filterData } = payload;

      return {
        ...state,
        filterData,
      };
    }

    case REFRESH_FILTER_MARKERS: {
      const { markers } = payload;

      return {
        ...state,
        markers,
      };
    }

    case CHANGE_FILTER_STATISTICS: {
      const { statistics } = payload;

      return {
        ...state,
        statistics,
      };
    }

    case CHANGE_FILTER_RESULT: {
      const { result } = payload;

      return {
        ...state,
        filterResult: result,
      };
    }

    case CLEAR_FILTER: {
      return {
        ...state,
        selectedFilters: initialState.selectedFilters,
        filterData: initialState.filterData,
        markers: initialState.markers,
        statistics: initialState.statistics,
        layerId: initialState.layerId,
        isEditing: initialState.isEditing,
        isEmpty: initialState.isEmpty,
        mapsChangeFilter: initialState.mapsChangeFilter,
      };
    }

    case EDIT_LAYER_FILTER: {
      const { layerId } = payload;

      return {
        ...state,
        layerId,
        isEditing: true,
        mapsChangeFilter: true,
      };
    }

    case MAPS_CHANGE_FILTER: {
      const { status } = payload;

      return {
        ...state,
        mapsChangeFilter: status,
      };
    }

    case SAVE_FIELD_PARAMS: {
      const { fieldParams } = payload;

      return {
        ...state,
        fieldParams,
      };
    }

    case SHOW_FILTER_LOADING: {
      return {
        ...state,
        filterLoading: {
          count: state.filterLoading.count + 1,
          isShowing: true,
        },
      };
    }

    case HIDE_FILTER_LOADING: {
      return {
        ...state,
        filterLoading: {
          count:
            state.filterLoading.count > 0 ? state.filterLoading.count - 1 : 0,
          isShowing: state.filterLoading.count - 1 > 0,
        },
      };
    }

    case CHANGE_SELECTED_LAYER: {
      const { layerId, layerName } = payload;

      return {
        ...state,
        layerSelected: { layerId, layerName },
      };
    }

    default:
      return state;
  }
};
