import React, { useState, useEffect } from 'react';

import { AULoadingManager, AUNotifier } from '@assertiva/assertiva-ui';
import CRMToken from './components/CRMToken';
import CRMCampaignConfig from './components/CRMCampaignConfig';
import CRMSalesPipeline from './components/CRMSalesPipeline';
import CRMReport from './components/CRMReport';

import Drawer from '../customDrawer/CustomDrawer';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';

import {
  getOrigins,
  getUsers,
  getPipelines,
  postPiperunDeals,
} from '../../services/crm';
import { getExportListStatus } from '../../services/exportList';

import './style.css';

const CRMExport = ({
  layerId,
  maxLinesQtd,
  token,
  tokenIsValid,
  open,
  onClose,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [exportFinished, setExportFinished] = useState(false);
  const [state, setState] = useState({});
  const [users, setUsers] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [suborigins, setSuborigins] = useState({});
  const [pipelines, setPipelines] = useState([]);
  const [pipelineStages, setPipelineStages] = useState({});

  useEffect(() => {
    if (open) {
      setState({ token, tokenIsValid });

      // GET Screen Fields Data
      getScreenFieldsData();
    } else {
      setCurrentStep(0);
      setExportFinished(false);
      setState({});
    }
  }, [open]);

  const getScreenFieldsData = () => {
    getUsers({ integrationType: 1 }).then(({ data: response }) => {
      if (response.success && Object.keys(response.data).length) {
        setUsers(response.data.users);
      }
    });

    getOrigins({ integrationType: 1 }).then(({ data: response }) => {
      if (response.success && Object.keys(response.data).length) {
        setOrigins(response.data.origins);
        setSuborigins(response.data.suborigins);
      }
    });

    getPipelines({ integrationType: 1 }).then(({ data: response }) => {
      if (response.success && Object.keys(response.data).length) {
        setPipelines(response.data.pipelines);
        setPipelineStages(response.data.stages);
      }
    });
  };

  const exportToPiperun = (_state) => {
    AULoadingManager.show();

    // TODO: ADD ORIGIN

    const isSelectedAllUsers =
      _state.selectedUsers.filter(({ id }) => id === -1).length > 0;
    let userMails = [];

    if (isSelectedAllUsers) userMails = users.map(({ email }) => email);
    else
      userMails =
        _state.selectedUsers && _state.selectedUsers.map(({ email }) => email);

    postPiperunDeals({
      integrationType: 1,
      layerId,
      pipelineStageHash:
        _state.selectedPipelineStage && _state.selectedPipelineStage.hash,
      userMails,
      originName: _state.selectedOrigin && _state.selectedOrigin.name,
      suboriginName: _state.selectedSuborigin && _state.selectedSuborigin.name,
      rowCount: _state.linesQtd,
    })
      .then(({ data }) => {
        const { listId } = data.data;
        const interval = setInterval(() => {
          getExportListStatus({ listId })
            .then(({ data }) => {
              const { status, message } = data.data;
              if (status !== 0) {
                AULoadingManager.close();
                clearInterval(interval);
                if (status === 1) {
                  setCurrentStep(3);
                  setExportFinished(true);
                } else {
                  AUNotifier.error(
                    message === ''
                      ? 'Ocorreu um erro. Tente novamente mais tarde.'
                      : message
                  );
                }
              }
            })
            .catch((err) => {
              if (err && err.response && err.response.status === 422) {
                AUNotifier.error(err.response.data.error.message);
              } else {
                AUNotifier.error(
                  'Ocorreu um erro, Tente novamente mais tarde.'
                );
              }

              clearInterval(interval);
            });
        }, 2500);
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 422) {
          AUNotifier.error(err.response.data.error.message);
        } else {
          AUNotifier.error('Ocorreu um erro, Tente novamente mais tarde.');
        }
      });
  };

  const steps = {
    0: {
      title: 'Conta do Piperun',
      description:
        'Integre a sua conta do Piperun e defina a quantidade de linhas a serem exportadas',
      children: () => (
        <CRMToken
          getScreenFieldsData={getScreenFieldsData}
          maxLinesQtd={maxLinesQtd}
          token={state.token}
          linesQtd={state.linesQtd}
          maxLinesQtdSelected={state.maxLinesQtdSelected}
          tokenIsValid={state.tokenIsValid}
          onClose={onClose}
          nextStep={(localState) => {
            setCurrentStep(1);
            setState({ ...state, ...localState });
          }}
        />
      ),
    },
    1: {
      title: 'Configuração das Oportunidades',
      description:
        'Selecione a origem e sub-origem das oportunidades que serão criadas e para quem serão distribuidas',
      children: () => (
        <CRMCampaignConfig
          users={users}
          selectedUsers={state.selectedUsers}
          origins={origins}
          selectedOrigin={state.selectedOrigin}
          suborigins={suborigins}
          selectedSuborigin={state.selectedSuborigin}
          nextStep={(localState) => {
            setCurrentStep(2);
            setState({ ...state, ...localState });
          }}
          prevStep={(localState) => {
            setCurrentStep(0);
            setState({ ...state, ...localState });
          }}
        />
      ),
    },
    2: {
      title: 'Funil de Vendas e Etapa',
      description:
        'Escolha o Funil de Vendas e a Etapa que a nova campanha será inserida',
      children: () => (
        <CRMSalesPipeline
          pipelines={pipelines}
          selectedPipeline={state.selectedPipeline}
          pipelineStages={pipelineStages}
          selectedPipelineStage={state.selectedPipelineStage}
          onSuccess={(localState) => {
            setState({ ...state, ...localState });
            exportToPiperun({ ...state, ...localState });
          }}
          prevStep={(localState) => {
            setCurrentStep(1);
            setState({ ...state, ...localState });
          }}
        />
      ),
    },
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      classes={{ paper: 'crm-export' }}
      anchor={'right'}
      title='Enviar para CRM'
    >
      <Stepper
        classes={{ root: 'crm-export__content' }}
        activeStep={currentStep}
        orientation='vertical'
      >
        {Object.keys(steps).map((step, index) => {
          const { title, description, children } = steps[step];

          return (
            <Step key={index}>
              <StepLabel
                optional={
                  <Typography variant='caption'>{description}</Typography>
                }
              >
                {title}
              </StepLabel>
              <StepContent>{children()}</StepContent>
            </Step>
          );
        })}
        <CRMReport
          isOpen={exportFinished}
          onClose={onClose}
          exportData={state}
        />
      </Stepper>
    </Drawer>
  );
};

export default CRMExport;
