import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { maybePluralize } from '../../../../utils/general';

import './style.css';

const checkIfAllSelected = (layersSelected, layers) =>
  layersSelected.length === Object.keys(layers).length;

const LayerSelectHeader = ({
  layersSelected,
  layers,
  setLayersSelect,
  setOnSelectMode,
  deleteLayer,
}) => {
  const [allChecked, setAllChecked] = useState(false);
  const [anchorElLayerOptions, setAnchorElLayerOptions] = useState(null);
  const [warningModal, setWarningModal] = useState(false);
  const openLayerOptions = Boolean(anchorElLayerOptions);

  const changeSelected = () => {
    if (layersSelected.length >= 1) {
      setLayersSelect([]);
      setAllChecked(false);
    } else {
      setLayersSelect([...Object.keys(layers)]);
      setAllChecked(true);
    }
  };

  const getSelectedText = () => {
    if (checkIfAllSelected(layersSelected, layers)) {
      return 'Todas selecionadas';
    } else if (!layersSelected.length) {
      return 'Nenhuma selecionada';
    } else {
      return `${layersSelected.length} ${maybePluralize(
        layersSelected.length,
        'selecionada'
      )}`;
    }
  };

  const handleClickLayerOptions = (event) => {
    setAnchorElLayerOptions(event.currentTarget);
  };

  const handleCloseLayerOptions = () => {
    setAnchorElLayerOptions(null);
  };

  const onContinue = () => {
    deleteLayer({ layersId: Object.keys(layers) });
  };

  const onClose = () => {
    setWarningModal(false);
  };

  const getConfirmationModal = () => (
    <Dialog
      open={warningModal}
      onClose={onClose}
      className='layer-select-header__warning_delete_modal'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle
        classes={{ root: 'layer-select-header__warning_delete_modal_title' }}
      >
        Atenção!
      </DialogTitle>
      <DialogContent>
        <div className='layer-select-header__warning_delete_modal_content'>
          <Typography
            variant='body1'
            classes={{
              root: 'layer-select-header__warning_delete_modal_content_text',
            }}
          >
            Você realmente deseja excluir todas as camadas?
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onContinue} color='primary'>
          Continuar
        </Button>
        <Button onClick={onClose} color='primary'>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );

  const deleteAll = () => {
    setWarningModal(true);
  };

  useEffect(() => {
    if (checkIfAllSelected(layersSelected, layers)) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [layersSelected, layers]);

  return (
    <div className='layer-select-header'>
      <Paper
        variant='outlined'
        elevation={7}
        classes={{ root: 'layer-select-header__paper' }}
      >
        <FormControl classes={{ root: 'layer-select-header__form_control' }}>
          <FormControlLabel
            classes={{ root: 'layer-select-header__form_control_label' }}
            control={
              <>
                {layersSelected.length >= 1 &&
                layersSelected.length !== Object.keys(layers).length ? (
                  <Checkbox
                    checked
                    color='primary'
                    id='layerSelectHeader-checkbox'
                    onChange={changeSelected}
                    indeterminate
                  />
                ) : (
                  <Checkbox
                    checked={allChecked}
                    color='primary'
                    id='layerSelectHeader-checkbox'
                    onChange={changeSelected}
                  />
                )}
              </>
            }
          />
        </FormControl>
        <Typography
          className='layer-select-header__select_label'
          variant='body1'
          display='inline'
          color='primary'
        >
          {getSelectedText()}
        </Typography>
        <Button
          variant='contained'
          color='primary'
          onClick={handleClickLayerOptions}
        >
          Ações
        </Button>
        <Menu
          anchorEl={anchorElLayerOptions}
          keepMounted
          open={openLayerOptions}
          onClose={handleCloseLayerOptions}
        >
          <MenuItem
            key='deleteSelected'
            disabled={!layersSelected.length}
            value='deleteSelected'
            onClick={() => deleteLayer({ layersId: layersSelected })}
          >
            Excluir selecionadas
          </MenuItem>
          <MenuItem key='deleteAll' value='deleteAll' onClick={deleteAll}>
            Excluir todas
          </MenuItem>
          <Divider />
          <MenuItem
            key='selectLayers'
            value='selectLayers'
            onClick={() => {
              setOnSelectMode(false);
              setLayersSelect([]);
            }}
          >
            Cancelar
          </MenuItem>
        </Menu>
      </Paper>
      {getConfirmationModal()}
    </div>
  );
};

export default LayerSelectHeader;
