import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import Collapse from '@material-ui/core/Collapse';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ExportListWarningModal from '../exportListWarningModal/ExportListWarningModal';
import ChartComponent from '../chart/Chart';

import { sendAnalytics } from '../../../../utils/analytics';

import './style.css';

const ActionCard = ({
  listIsExpanded,
  expandList,
  layerId,
  layerActionId,
  layerName,
  name,
  summary,
  rowsExported,
  exportLimits,
  list,
  onDelete,
  openDrawer,
  updateLayerActions,
  isCompact,
  scoreChart,
}) => {
  const [filterInput, setFilterInput] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenExportListWarningModal, setIsOpenExportListWarningModal] =
    useState(false);

  return (
    <>
      <Paper
        elevation={4}
        variant={listIsExpanded ? 'elevation' : 'outlined'}
        className='actions-drawer__list'
      >
        <div className='actions-drawer__list__top-bar'>
          <Typography
            id='listaDeEmpresasEncontradas-label'
            variant='caption'
            color='textSecondary'
          >
            Lista de empresas encontradas - {layerName}
          </Typography>
          {!isCompact && (
            <IconButton size='small' onClick={expandList}>
              {listIsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </div>
        <div className='actions-drawer__list__header'>
          <Typography
            variant='body2'
            className='actions-drawer__list__header__name'
            id='tituloLista-label'
          >
            {name}
          </Typography>
          <Button
            id='actionsDrawer-acoes-button'
            variant='contained'
            color='primary'
            endIcon={<ArrowDropDownIcon />}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              sendAnalytics({ event: 'acoes-button', page: 'lista' });
            }}
          >
            Ações
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              id='acoes-exportar-button'
              onClick={() => {
                if (!summary.inactiveCompanies)
                  openDrawer({
                    drawerName: 'ListExport',
                    data: {
                      layerId,
                      listName: name,
                      layerActionId,
                      listQtdLines: summary.total,
                      wasExported: rowsExported > 0,
                      rowsExported,
                      exportLimits,
                      updateLayerActions,
                    },
                  });
                else setIsOpenExportListWarningModal(true);

                sendAnalytics({ event: 'exportar-button', page: 'lista' });
              }}
            >
              <ListItemIcon>
                <GetAppIcon fontSize='small' />
              </ListItemIcon>
              <Typography variant='inherit'>Exportar...</Typography>
            </MenuItem>
            <Divider />
            <MenuItem
              id='acoes-excluirLista-button'
              onClick={() => {
                onDelete();
                sendAnalytics({ event: 'excluirLista-button', page: 'lista' });
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize='small' />
              </ListItemIcon>
              <Typography variant='inherit'>Excluir lista</Typography>
            </MenuItem>
          </Menu>
        </div>
        <div className='actions-drawer__list__summary'>
          <div style={{ borderColor: '#8a00c1' }}>
            <Typography variant='body2' style={{ fontSize: 12 }}>
              Total de
              <br />
              Empresas:
            </Typography>
            <Typography
              id='actionsDrawer-totalDeEmpresas-value'
              variant='body2'
              style={{ fontWeight: 500 }}
            >
              {summary.total}
            </Typography>
          </div>

          <div style={{ borderColor: '#4DB546' }}>
            <Typography variant='body2' style={{ fontSize: 12 }}>
              Empresas
              <br />
              Ativas:
            </Typography>
            <Typography
              id='actionsDrawer-empresasAtivas-value'
              variant='body2'
              style={{ fontWeight: 500 }}
            >
              {summary.activeCompanies}
            </Typography>
          </div>

          <div style={{ borderColor: '#3333334C' }}>
            <Typography variant='body2' style={{ fontSize: 12 }}>
              Empresas
              <br />
              Inativas:
            </Typography>
            <Typography
              id='actionsDrawer-empresasInativas-value'
              variant='body2'
              style={{ fontWeight: 500 }}
            >
              {summary.inactiveCompanies}
            </Typography>
          </div>
        </div>
        {!isCompact ? (
          <Collapse in={listIsExpanded}>
            <Paper elevation={0}>
              <TextField
                id={`actionsDrawer-buscarResultado-input-${layerId}`}
                placeholder='Buscar resultado'
                variant='outlined'
                value={filterInput}
                onChange={(evt) => setFilterInput(evt.target.value)}
                InputLabelProps={{
                  margin: 'dense',
                }}
                InputProps={{
                  margin: 'dense',
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton size='small' disabled>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  fullWidth: true,
                }}
                fullWidth
              />
            </Paper>
            <div className='actions-drawer__list__items'>
              {list
                .filter(
                  (val) =>
                    filterInput === '' ||
                    val.razaoSocial.match(new RegExp(filterInput, 'i'))
                )
                .map(
                  (
                    {
                      nomeFantasia,
                      razaoSocial,
                      cnpj,
                      cidade,
                      uf,
                      dataAbertura,
                    },
                    index
                  ) => (
                    <Card key={`card-${index}`} variant='outlined'>
                      <CardContent>
                        {nomeFantasia && (
                          <div
                            style={{
                              marginBottom: '16px',
                            }}
                          >
                            <Typography
                              variant='body2'
                              color='primary'
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                              }}
                            >
                              Nome Fantasia
                            </Typography>
                            <Typography
                              variant='body2'
                              style={{
                                fontWeight: 500,
                                textTransform: 'uppercase',
                              }}
                            >
                              {nomeFantasia}
                            </Typography>
                          </div>
                        )}
                        <div
                          style={{
                            marginBottom: '16px',
                          }}
                        >
                          <Typography
                            variant='body2'
                            color='primary'
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                            }}
                          >
                            Razão Social
                          </Typography>
                          <Typography
                            variant='body2'
                            style={{
                              fontWeight: 500,
                              textTransform: 'uppercase',
                            }}
                          >
                            {razaoSocial}
                          </Typography>
                        </div>
                        <div
                          style={{
                            marginBottom: '16px',
                          }}
                        >
                          <Typography
                            variant='body2'
                            color='primary'
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                            }}
                          >
                            CNPJ
                          </Typography>
                          <Typography
                            variant='body2'
                            style={{
                              fontWeight: 500,
                              textTransform: 'uppercase',
                            }}
                          >
                            {cnpj}
                          </Typography>
                        </div>
                        <div
                          style={{
                            marginBottom: '16px',
                          }}
                        >
                          <Typography
                            variant='body2'
                            color='primary'
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                            }}
                          >
                            Município
                          </Typography>
                          <Typography
                            variant='body2'
                            style={{
                              fontWeight: 500,
                              textTransform: 'uppercase',
                            }}
                          >
                            {cidade}/{uf}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant='body2'
                            color='primary'
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                            }}
                          >
                            Data de abertura
                          </Typography>
                          <Typography
                            variant='body2'
                            style={{
                              fontWeight: 500,
                              textTransform: 'uppercase',
                            }}
                          >
                            {format(parseISO(dataAbertura), 'dd/MM/yyyy')}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  )
                )}
            </div>
          </Collapse>
        ) : (
          <ChartComponent scoreChart={scoreChart} />
        )}
      </Paper>
      <ExportListWarningModal
        summary={summary}
        open={isOpenExportListWarningModal}
        type='inactiveCompanies'
        onClose={() => setIsOpenExportListWarningModal(false)}
        onContinue={() => {
          setIsOpenExportListWarningModal(false);
          openDrawer({
            drawerName: 'ListExport',
            data: {
              layerId,
              listName: name,
              layerActionId,
              listQtdLines: summary.total,
              wasExported: rowsExported > 0,
              rowsExported,
              exportLimits,
              updateLayerActions,
            },
          });
        }}
      />
    </>
  );
};

export default ActionCard;
