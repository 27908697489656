import React, { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { groupBy as _groupBy } from 'lodash';

import Paper from '@material-ui/core/Paper';
import { Tooltip } from '@material-ui/core';
import { Place } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';

import HUD from '../../components/hud/HUD';
import Marker from '../../components/marker/Marker';
import ClusterMarker from '../../components/clusterMarker/ClusterMarker';

import * as actions from './actions';
import { refreshMarkers, changeStatistics } from '../layers/actions';
import {
  refreshFilterMarkers,
  changeFilterStatistics,
} from '../../components/dashboard/actions';

import { getFilterMarkers, getFilterLayers } from '../../services/filter';

import GeneralConstants from '../../constants/general';

import './style.css';

export const Map = ({
  currentView,
  initMap,
  filterIsEditing,
  filterLoading,
  filterIsEmpty,
  refreshMarkers,
  changeStatistics,
  refreshFilterMarkers,
  changeFilterStatistics,
  layerMarkers,
  filterLayerId,
  filterMarkers,
  filterData,
  switchTraffic,
  trafficEnabled,
  drawingMode,
  changeDrawingMode,
  addDrawing,
  changeMapType,
  mapType,
  dataType,
  layers,
  layersOrder,
  layersAreLoaded,
  isMapLoading,
  mapsChangeFilter,
  layerSelected,
}) => {
  const [isMapReady, setIsMapReady] = useState(false);

  useEffect(() => {
    if (currentView === 'map') {
      getFilterMarkers({
        points: '-43.888791,-91.60425,16.732481,-4.25415',
        zoom: 4,
        ...filterData,
      }).then(({ data: response }) => {
        if (response.data.markers.length) {
          const marker = response.data.markers[0];
          const newCenter = {
            lat: marker.geometry.coordinates[0],
            lng: marker.geometry.coordinates[1],
          };
          changeCenterByFilter(filterData, newCenter);
          refreshFilterMarkers(response.data.markers);
          changeFilterStatistics(response.data.statistics);
        }
      });
    }
  }, [currentView, filterLoading]);

  useEffect(() => {
    if (currentView === 'map' && layerSelected?.layerId !== null) {
      getFilterLayers({
        points: '-43.888791,-91.60425,16.732481,-4.25415',
        zoom: 4,
        layers: layersOrder,
      }).then(({ data: response }) => {
        const layerId = layerSelected.layerId;
        const marker = response.data.markers[layerId][0];
        const newCenter = {
          lat: marker.geometry.coordinates[0],
          lng: marker.geometry.coordinates[1],
        };
        changeCenterByFilter(layers[layerId].data, newCenter);
        refreshMarkers(response.data.markers);
        changeStatistics(response.data.statistics);
      });
    }
  }, [currentView, layerSelected]);

  const onMapsInit = (maps) => {
    initMap(maps);
    setIsMapReady(true);
  };

  const onMapsChange = () => {
    if (!isMapReady) {
      return 0;
    }

    const points = window.map.getBounds().toUrlValue();
    const zoom = window.map.getZoom();

    !filterIsEmpty &&
      mapsChangeFilter &&
      getFilterMarkers({
        points,
        zoom,
        ...filterData,
      }).then(({ data: response }) => {
        refreshFilterMarkers(response.data.markers);
        changeFilterStatistics(response.data.statistics);
      });

    layersAreLoaded &&
      layersOrder.length &&
      getFilterLayers({
        points,
        zoom,
        layers: layersOrder,
      }).then(({ data: response }) => {
        refreshMarkers(response.data.markers);
        changeStatistics(response.data.statistics);
      });
  };

  // Zoom settings
  const changeZoom = (zoom) => window.map.setZoom(zoom);

  // Center settings
  const changeCenter = (center) => window.map.setCenter(center);

  const renderMarker = ({
    name = 'Filtro atual',
    data: { properties, geometry },
    color = GeneralConstants.defaultMarkerColor,
    index,
  }) => {
    return (
      <Marker
        key={index}
        {...properties}
        // markerInfo={!properties.cluster}
        icon={() => (
          <span style={{ fontSize: 48 }}>
            <Tooltip title={name}>
              {properties.cluster ? (
                <div
                  className='cluster'
                  title=''
                  style={{
                    position: 'absolute',
                    width: '56px',
                    height: '56px',
                    cursor: 'zoom-in',
                  }}
                  onClick={() => {
                    changeZoom(window.map.zoom + 1);
                    window.map.setCenter({
                      lat: geometry.coordinates[0],
                      lng: geometry.coordinates[1],
                    });
                  }}
                >
                  <ClusterMarker color={color} value={properties.point_count} />
                </div>
              ) : (
                <Place htmlColor={color} fontSize='inherit' />
              )}
            </Tooltip>
          </span>
        )}
        lat={geometry.coordinates[0]}
        lng={geometry.coordinates[1]}
      />
    );
  };

  // Change Center and Zoom by Location Filter
  const changeCenterByFilter = (filters, newCenter) => {
    if ('street' in filters && filters['street'] !== undefined) {
      changeCenter(newCenter);
      changeZoom(16);
    } else if ('neighborhood' in filters && filters['neighborhood'] !== undefined) {
      changeCenter(newCenter);
      changeZoom(15);
    } else if ('city' in filters && filters['city'] !== undefined) {
      changeCenter(newCenter);
      changeZoom(11);
    } else if ('uf' in filters && filters['uf'] !== undefined) {
      changeCenter(newCenter);
      changeZoom(8);
    } else {
      changeCenter(GeneralConstants.mapCenter);
      changeZoom(GeneralConstants.defaultZoom);
    }
  };

  return (
    <>
      {currentView === 'map' && (
        <Paper elevation={5} className='map__container'>
          {isMapLoading && (
            <div className='map__container__loading'>
              <CircularProgress />
            </div>
          )}
          <HUD
            switchTraffic={switchTraffic}
            trafficEnabled={trafficEnabled}
            drawingMode={drawingMode}
            changeDrawingMode={changeDrawingMode}
            changeMapType={changeMapType}
            mapType={mapType}
            changeZoom={changeZoom}
          />
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyBmk42fHbMx52R_iHhE9T6XWthCQLhWd9A',
              libraries: ['drawing', 'visualization', 'places'],
              language: 'pt-BR',
            }}
            defaultCenter={GeneralConstants.mapCenter}
            defaultZoom={GeneralConstants.defaultZoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={onMapsInit}
            options={{
              clickableIcons: false,
              zoomControl: false,
              fullscreenControl: false,
            }}
            onChange={onMapsChange}
          >
            {dataType === 'marker' &&
              layersOrder?.length &&
              layersOrder.map(
                (layerId) =>
                  layerMarkers[layerId] &&
                  layers[layerId].isEnabled &&
                  layerMarkers[layerId].map((data, index) =>
                    renderMarker({
                      name: layers[layerId].name,
                      data,
                      color: layers[layerId].color,
                      index,
                    })
                  )
              )}
            {dataType === 'marker' &&
              filterMarkers.map((data, index) =>
                renderMarker({
                  data,
                  color: filterIsEditing
                    ? layers[filterLayerId].color
                    : undefined,
                  index,
                })
              )}
          </GoogleMapReact>
        </Paper>
      )}
    </>
  );
};

const mapStateToProps = ({ map, dashboard, layers }) => ({
  filterIsEditing: dashboard.isEditing,
  filterIsEmpty: dashboard.isEmpty,
  filterLayerId: dashboard.layerId,
  mapsChangeFilter: dashboard.mapsChangeFilter,
  filterData: dashboard.filterData,
  filterMarkers: dashboard.markers,
  selectedFilters: _groupBy(dashboard.selectedFilters, 'categoryId'),
  filterLoading: dashboard.filterLoading.isShowing,
  layerMarkers: layers.markers,
  layers: layers.layers,
  layersOrder: layers.layersOrder,
  layersAreLoaded: layers.isLoaded,
  trafficEnabled: map.trafficEnabled,
  drawingMode: map.drawingMode,
  mapType: map.mapType,
  dataType: map.dataType,
  isMapLoading: map.mapLoading.isShowing,
});

export default connect(mapStateToProps, {
  ...actions,
  refreshMarkers,
  changeStatistics,
  refreshFilterMarkers,
  changeFilterStatistics,
})(memo(Map));
