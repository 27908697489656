export default {
  listHeader: [
    { id: "list", label: "Listas" },
    { id: "date", label: "Data" },
    { id: "rowsAmount", label: "Quantidade de linhas" },
    { id: "downloadDeadline", label: "Prazo para download" },
    { id: "download", label: "Baixar" },
  ],
  rowsPerPage: [5, 10, 25],
  defaultFilterData: {
    listName: "",
    dates: {
      dateIni: { value: null, date: null },
      dateFin: { value: null, date: null },
    },
    showExpired: false,
  },
};
