import React, { useState } from 'react';

import MarkerInfo from '../markerInfo/MarkerInfo';

const Marker = ({
	icon: Icon,
	markerInfo,
	document,
	name,
	gender,
	age,
	estimatedIncome,
	educationalLevel,
}) => {
	const [showInfo, setShowInfo] = useState(false);

	return (
		<div className='marker' style={{ position: 'absolute' }}>
			<div
				style={{
					position: 'relative',
					left: '-50%',
					top: '-48px',
				}}
				onClick={() => markerInfo && setShowInfo(true)}
			>
				<Icon />
			</div>
			{markerInfo && showInfo && (
				<MarkerInfo
					onClose={() => markerInfo && setShowInfo(false)}
					info={[
						{ label: 'Nome', value: name },
						{ label: 'CPF', value: document },
						{ label: 'Sexo', value: gender },
						{ label: 'Idade', value: age },
						{ label: 'Renda estimada', value: estimatedIncome },
						{
							label: 'Nível de escolaridade',
							value: educationalLevel,
						},
					]}
				/>
			)}
		</div>
	);
};

export default Marker;
