import React from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import SearchIcon from '@material-ui/icons/Search';

const LocationAutocomplete = ({ name, label, placesType, handleChange }) => {
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: 'AIzaSyBmk42fHbMx52R_iHhE9T6XWthCQLhWd9A',
      options: {
        types: [placesType],
        componentRestrictions: { country: 'br' },
      },
      language: 'pt-BR',
    });

  const onSearchSelect = (place) => {
    return placesService.getDetails({ placeId: place.place_id }, (result) => {
      let street, neighborhood, city, uf;
      let value;

      result.address_components.forEach((e) => {
        if (e.types.includes('route')) {
          // street
          let type = (e.short_name).substr(0, (e.short_name).indexOf(' '));
          let address = (e.long_name).substr((e.long_name).indexOf(' ') + 1);
          street = [type, address].join(' ');
        } else if (e.types.includes('sublocality_level_1')) {
          // neighborhood
          neighborhood = e.short_name;
        } else if (e.types.includes('administrative_area_level_1')) {
          // uf
          uf = e.short_name;
        } else if (e.types.includes('administrative_area_level_2')) {
          // city
          city = e.long_name;
        }
      });

      if (name === 'city') {
        // city
        value = [city, uf];
      } else if (name === 'neighborhood') {
        // neighborhood
        value = [neighborhood, city, uf];
      } else {
        // street
        value = [street, neighborhood, city, uf];
      }

      return handleChange({
        target: {
          type: 'locationAutocomplete',
          name: name,
          value: value,
        },
      });
    });
  };

  return (
    <>
      <Autocomplete
        options={
          name === 'city' || name === 'street'
            ? placePredictions
            : placePredictions.filter(
                (place) => place.types.includes('sublocality_level_1') && place
              )
        }
        getOptionLabel={(option) => option.description}
        onChange={(evt, value) => onSearchSelect(value)}
        onInputChange={(event, value) => getPlacePredictions({ input: value })}
        blurOnSelect
        renderInput={(params) => (
          <TextField
            {...params}
            label={`Busque ${label}s`}
            name={name}
            variant='outlined'
            InputLabelProps={{
              ...params.InputLabelProps,
              margin: 'dense',
            }}
            InputProps={{
              ...params.InputProps,
              margin: 'dense',
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton size='small' disabled>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              fullWidth: true,
            }}
            fullWidth
          />
        )}
        renderOption={(option, state) => {
          return (
            <div>
              <span>{option.description}</span>
            </div>
          );
        }}
        noOptionsText={`Nenhum resultado encontrado`}
        disableClearable
        freeSolo
      />
    </>
  );
};

export default LocationAutocomplete;
