import React, { useState, useEffect } from 'react';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { toArray } from '../../../../utils/general';
import FilterConstants from '../../constants';

import './styles.css';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

function AutocompleteComponent({
  name,
  options,
  rest,
  handleChange,
  value,
  currentFilter,
}) {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue]  = useState('');

  const isAllSelectedFn = (values) =>
    values && values.some((v) => v === FilterConstants.ALL_SELECTED_OPTION);

  useEffect(() => {
    setIsEditing(currentFilter?.editMode);
  }, [currentFilter]);

  const getDisabled = (option) => {
    if (isAllSelected) {
      if (isEditing) return value.includes(option.value);
      return !isEditing && option.value !== FilterConstants.ALL_SELECTED_OPTION;
    }

    return isEditing && value.includes(option.value);
  };

  const _handleChange = (_, opt) => {
    let values = Array.isArray(opt) ? opt.map((o) => o.value) : opt.value;

    if (isAllSelectedFn(values)) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }

    handleChange({
      target: {
        type: 'autocomplete',
        name: name,
        value: values,
      },
    });
  };

  const _handleInputChange = (event, value) => {
    if (event?.type === 'change') {
      setInputValue(value);
    }
  }

  return (
    <>
      <Autocomplete
        options={options}
        groupBy={(option) => option.group || null}
        value={toArray(value).map((v) =>
          options.find((opt) => opt.value === v)
        )}
        getOptionDisabled={getDisabled}
        getOptionLabel={(option) => option.label}
        filterOptions={createFilterOptions({
          stringify: (option) => {
            let code = option.label.split(' - ');
            if (code && code.length) {
              code = code[0].replace(/[^a-zA-Z0-9 ]/g, '');
              return `${code} - ${option.label}`;
            }

            return option.label;
          },
          limit: 50,
        })}
        onChange={_handleChange}
        renderOption={(option, { selected }) => (
          <div className='filter-type__autocomplete'>
            <Checkbox
              color='primary'
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8, padding: 0 }}
              checked={selected}
            />
            {option.label}
          </div>
        )}
        onInputChange={_handleInputChange}
        inputValue={inputValue}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              variant='outlined'
              InputLabelProps={{
                ...params.InputLabelProps,
                margin: 'dense',
              }}
              InputProps={{
                ...params.InputProps,
                margin: 'dense',
                fullWidth: true,
                endAdornment: (
                  <InputAdornment
                    position='end'
                    classes={{
                      root: 'filter-type__autocomplete_textfield_input_adornment',
                    }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={`Busque ou selecione ${
                name === 'cnae' ? rest.label + 's' : rest.label
              }`}
              fullWidth
            />
          </>
        )}
        noOptionsText={rest.noOptionsText || 'Nenhuma opção encontrada'}
        disableClearable
        disableCloseOnSelect
        blurOnSelect={!rest.multiple}
        multiple
        renderTags={() => null}
      />
    </>
  );
}

export default AutocompleteComponent;
