import React from 'react';

import { Paper } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import './style.css';

const MarkerInfo = ({ onClose, children }) => {
	return (
		<Paper classes={{ root: 'marker-info' }}>
			<CloseIcon
				classes={{ root: 'marker-info__icon' }}
				fontSize='small'
				onClick={onClose}
			/>
			{children}
		</Paper>
	);
};

export default MarkerInfo;
