import React from 'react';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import GeneralConstants from '../../../../constants/general';
import UploadIcon from '../../../../assets/uploadIcon/uploadIcon.svg';
import Progress from './Progress';

import useUpload from './useUpload';

import * as Styled from './styles';

const Upload = ({ cleanFilterAndPagination, onClose, showNotify }) => {
  const classes = Styled.Styles();
  const {
    barProgress,
    selectFileEvent,
    isUploadEmpty,
    isUploadProgress,
    isUploadCompleted,
    isProcessNotStarted,
    isProcessInProgress,
    file,
    cancelUpload,
    getFileSizeString,
    addFileBlacklist,
    fileInfo,
    getProcessProgress,
  } = useUpload({ cleanFilterAndPagination, onClose, showNotify });

  const UploadProgress = () => (
    <div style={{ width: '100%' }}>
      {isUploadEmpty && (
        <>
          <Styled.UploadTitle align='left' component='p'>
            Clique aqui para importar seu arquivo
          </Styled.UploadTitle>
          <Styled.UploadSubtitle align='left' component='p'>
            Aceitamos arquivos .csv, .txt e .xls
          </Styled.UploadSubtitle>
        </>
      )}
      {isUploadProgress && (
        <>
          <Styled.UploadSubtitle>Carregando seu arquivo</Styled.UploadSubtitle>
          <Box height={18} />
          <Progress progress={barProgress} />
        </>
      )}
    </div>
  );

  const ProcessProgress = () => (
    <>
      {isProcessNotStarted ? (
        <>
          <Typography>{`Tamanho: ${getFileSizeString(file.size)}`}</Typography>
          <Styled.UploadActionsContainer>
            <Button
              color='primary'
              variant='contained'
              onClick={() => addFileBlacklist()}
            >
              ADICIONAR LOTE
            </Button>
            <Button
              classes={{ root: classes.cancel }}
              color='primary'
              variant='text'
              onClick={() => cancelUpload()}
            >
              CANCELAR
            </Button>
          </Styled.UploadActionsContainer>
        </>
      ) : (
        <Styled.ProcessProgressContainer>
          <Typography>
            CNPJs válidos:{' '}
            {isProcessInProgress ? (
              fileInfo.validItems
            ) : (
              <CircularProgress style={{ marginLeft: '8px' }} size={12} />
            )}
          </Typography>
          <Typography>
            CNPJs inválidos:{' '}
            {isProcessInProgress ? (
              fileInfo.invalidItems
            ) : (
              <CircularProgress style={{ marginLeft: '8px' }} size={12} />
            )}
          </Typography>

          <Box height={18} />
          <Styled.UploadSubtitle>
            {getProcessProgress.label}
          </Styled.UploadSubtitle>
          <Box height={18} />
          <Progress progress={getProcessProgress.bar} />
        </Styled.ProcessProgressContainer>
      )}
    </>
  );

  return (
    <>
      <Styled.Title variant='caption'>
        {!isUploadCompleted ? 'Inserir na blacklist - em lote' : ''}
        {isUploadCompleted ? 'Detalhes do arquivo' : ''}
      </Styled.Title>
      <Paper elevation={0} classes={{ root: classes.container }}>
        {!isUploadCompleted ? (
          <Button classes={{ root: classes.uploadButton }} component='label'>
            <input
              type='file'
              accept={GeneralConstants.fileTypesBlacklist.toString()}
              hidden
              onChange={selectFileEvent}
              disabled={!isUploadEmpty}
            />

            <img
              src={UploadIcon}
              alt='geotimize'
              height={104}
              width={104}
              style={{ marginRight: '16px' }}
            />
            <UploadProgress />
          </Button>
        ) : (
          <Styled.UploadCompletedContainer>
            <Chip size='small' label={file.name} />
            <ProcessProgress />
          </Styled.UploadCompletedContainer>
        )}
      </Paper>
    </>
  );
};

export default Upload;
