import { makeStyles, styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const Styles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',

    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',

    marginTop: '8px',
    marginBottom: '8px',
  },
  uploadButton: {
    width: '100%',
    '&:hover': {
      backgroundColor: '#FFF',
      boxShadow:
        '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px -1px rgba(0, 0, 0, 0.2)',
    },
  },
  cancel: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '16px',

    letterSpacing: '1.25px',
  },
}));

const Title = styled(Typography)({
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.4px',
  color: 'rgba(0, 0, 0, 0.87)',
});

const UploadTitle = styled(Typography)({
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.25px',

  color: 'rgba(0, 0, 0, 0.87)',

  marginBottom: '14px',
});

const UploadSubtitle = styled(Typography)({
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.25px',

  color: 'rgba(0, 0, 0, 0.6)',
});

const UploadCompletedContainer = styled('div')({
  width: '100%',
  margin: '16px',
  height: '138px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
});

const UploadActionsContainer = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const ProcessProgressContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
});

export {
  Styles,
  Title,
  UploadTitle,
  UploadSubtitle,
  UploadCompletedContainer,
  UploadActionsContainer,
  ProcessProgressContainer,
};
